import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import DetailsView from '../view-details'

const FakeDetailsContainer = props => {
  const details = [{
    title: 'Types de projets',
    content: props.projectTypes.join('\n') || 'Votre projet de travaux'
  }, {
    title: 'Localisation',
    content: props.address || 'Chez vous 😊'
  }]

  return <DetailsView details={details} />
}

const mapStateToProps = ({ draft }) => ({
  projectTypes: draft.draftContent.projectTypes,
  address: draft.draftContent.address
})

FakeDetailsContainer.propTypes = {
  projectTypes: PropTypes.arrayOf(PropTypes.string),
  address: PropTypes.string
}

export default connect(mapStateToProps, null)(FakeDetailsContainer)
