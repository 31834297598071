import React, { Component } from 'react'

import PropTypes from 'prop-types'

import { wrapDisplayName } from 'recompose'

import LoadingView from '../../components/view-loading'
import ErrorPage from '../../components/page-error'

const buildMiddleware = (SubComponent, displayName) => {
  const WithMiddleware = class extends Component {
    render () {
      const {
        error,
        loading,
        ...passThroughProps
      } = this.props

      return [{
        cond: !!error,
        view: () => <ErrorPage error={error} />
      }, {
        cond: !!loading,
        view: () => <LoadingView />
      }, {
        cond: true,
        view: () => <SubComponent {...passThroughProps} />
      }].find(x => x.cond).view()
    }
  }

  WithMiddleware.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object
  }

  WithMiddleware.displayName = wrapDisplayName(
    SubComponent,
    displayName || 'withMiddleware'
  )

  return WithMiddleware
}

export default buildMiddleware
