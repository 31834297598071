import React from 'react'

import PageHeaderView from '../view-project-header'

const fakeProfile = props => ({
  firstName: null
})

const fakeFacilitator = {
  firstName: 'Florence'
}

const fakeSeller = {
  firstName: 'Kévin'
}

const AnonymousHeaderContainer = props => (
  <PageHeaderView
    profile={fakeProfile(props)}
    facilitator={fakeFacilitator}
    seller={fakeSeller}
  />
)

export default AnonymousHeaderContainer
