import {
  PROJECT__LOAD_SUCCESS,
  PROJECT__LOAD_ERROR,
  PROJECT__LOAD_START,
  PROJECT__LOAD_WORKTYPES_SUCCESS,
  PROJECT__LOAD_WORKTYPES_ERROR,
  PROJECT__LOAD_WORKTYPES_START,
  PROJECT__LOAD_BY_ID_START,
  PROJECT__LOAD_BY_ID_SUCCESS,
  PROJECT__LOAD_BY_ID_ERROR,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_START,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR,
  PROJECT__LOAD_SELLER_BY_PROJECT_START,
  PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS,
  PROJECT__LOAD_SELLER_BY_PROJECT_ERROR,
  PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_START,
  PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_SUCCESS,
  PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_ERROR,
  PROJECT__LOAD_RECOMMENDATIONS_START,
  PROJECT__LOAD_RECOMMENDATIONS_SUCCESS,
  PROJECT__LOAD_RECOMMENDATIONS_ERROR,
  PROJECT__UPDATE_NAME_START,
  PROJECT__UPDATE_NAME_SUCCESS,
  PROJECT__UPDATE_NAME_ERROR,
  PROJECT__CHANGE_PAGE,

  PROJECT__CREATE_START,
  PROJECT__CREATE_SUCCESS,
  PROJECT__CREATE_ERROR,
  PROJECT__UPDATE_PAGINATION_PARAMS,
} from '../actions'

import { isMobile } from '../utils'

const defaultDates = {
  startDate: null,
  endDate: null
}

export const paginationParamsDefaultDates = {
  createdAt: defaultDates,
  dateExpedition: defaultDates,
  dateDepotMontage: defaultDates,
  dateFinInstruction: defaultDates,
  dateReceptionDpc: defaultDates,
  dateDepotPc: defaultDates,
  decision: defaultDates
}

const initialState = {
  projects: null,
  projectsLoading: false,
  projectsError: null,

  workTypes: null,
  workTypesLoading: false,
  worktypesError: null,

  selectedProjectId: null,
  selectedProject: null,
  selectedProjectLoading: false,
  selectedProjectError: null,

  selectedProjectFacilitator: null,
  facilitatorLoading: false,
  facilitatorError: null,
  facilitatorToLoadProjectId: null,

  selectedProjectSeller: null,
  sellerLoading: false,
  sellerError: null,
  sellerToLoadProjectId: null,

  fullConfirmationToken: null,
  fullConfirmationTokenLoading: false,
  fullConfirmationTokenError: null,

  recommendations: null,
  recommendationsLoading: false,
  recommendationsError: null,

  updateNameLoading: false,
  updateNameError: null,

  page: 'projet',

  createdProject: null,
  createProjectLoading: false,
  createProjectError: null,

  paginationParams: {
    limit: isMobile ? 10 : 15,
    offset: 0,
    queryString: null,
    dealstatus: [],
    dealStatusTitles: [],
    workTypeCodes: [],
    dates: paginationParamsDefaultDates
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECT__LOAD_SUCCESS: {
      const lastVisitedProject = action.payload.data[0]
      return {
        ...state,
        projectsLoading: false,
        projectsFailed: false,
        projects: action.payload,
        selectedProject: !state.selectedProject
          ? lastVisitedProject
          : state.selectedProject,
        selectedProjectId: !state.selectedProjectId
          ? lastVisitedProject?.id
          : state.selectedProjectId
      }
    }

    case PROJECT__LOAD_ERROR:
      return {
        ...state,
        projectsLoading: false,
        projectsError: action.payload,
      }

    case PROJECT__LOAD_START:
      return {
        ...state,
        projectsLoading: true,
        projectsError: null,
      }

    case PROJECT__LOAD_WORKTYPES_SUCCESS: {
      const metaToAdd = Array.isArray(action.payload)
        ? action.payload.reduce(
          (acc, meta) => Object.assign(acc, { [meta.id]: meta }),
          {}
        )
        : action.payload
      return {
        ...state,
        workTypesLoading: false,
        workTypes: metaToAdd,
      }
    }

    case PROJECT__LOAD_WORKTYPES_ERROR:
      return {
        ...state,
        workTypesLoading: false,
        worktypesError: action.payload,
      }

    case PROJECT__LOAD_WORKTYPES_START:
      return {
        ...state,
        workTypesLoading: true,
        worktypesError: null,
      }

    case PROJECT__LOAD_BY_ID_START:
      return {
        ...state,
        selectedProjectLoading: true,
        selectedProjectId: action.payload,
      }

    case PROJECT__LOAD_BY_ID_SUCCESS:
      return {
        ...state,
        selectedProjectLoading: false,
        selectedProjectError: null,
        selectedProject: action.payload,
      }

    case PROJECT__LOAD_BY_ID_ERROR:
      return {
        ...state,
        selectedProjectLoading: false,
        selectedProjectError: action.payload,
      }

    case PROJECT__LOAD_FACILITATOR_BY_PROJECT_START:
      return {
        ...state,
        facilitatorLoading: true,
        facilitatorError: null,
        facilitatorToLoadProjectId: action.payload,
      }

    case PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS:
      return {
        ...state,
        facilitatorLoading: false,
        facilitatorToLoadProjectId: null,
        selectedProjectFacilitator: action.payload,
      }

    case PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR:
      return {
        ...state,
        facilitatorLoading: false,
        facilitatorToLoadProjectId: null,
        facilitatorError: action.payload,
      }

    case PROJECT__LOAD_SELLER_BY_PROJECT_START:
      return {
        ...state,
        sellerLoading: true,
        sellerError: null,
        sellerToLoadProjectId: action.payload,
      }

    case PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS:
      return {
        ...state,
        sellerLoading: false,
        sellerToLoadProjectId: null,
        selectedProjectSeller: action.payload,
      }

    case PROJECT__LOAD_SELLER_BY_PROJECT_ERROR:
      return {
        ...state,
        sellerLoading: false,
        sellerToLoadProjectId: null,
        sellerError: action.payload,
      }
    case PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_START:
      return {
        ...state,
        fullConfirmationToken: null,
        fullConfirmationTokenLoading: true,
        fullConfirmationTokenError: null,
      }
    case PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_SUCCESS:
      return {
        ...state,
        fullConfirmationToken: action.payload,
        fullConfirmationTokenLoading: false,
        fullConfirmationTokenError: null,
      }
    case PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_ERROR:
      return {
        ...state,
        fullConfirmationToken: null,
        fullConfirmationTokenLoading: false,
        fullConfirmationTokenError: action.payload,
      }

    // Recommendations
    case PROJECT__LOAD_RECOMMENDATIONS_START:
      return {
        ...state,
        recommendationsLoading: true,
        recommendationsError: null,
      }

    case PROJECT__LOAD_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        recommendationsLoading: false,
        recommendations: action.payload,
      }

    case PROJECT__LOAD_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        recommendationsLoading: false,
        recommendationsError: action.payload,
      }

    // Update project name
    case PROJECT__UPDATE_NAME_START:
      return {
        ...state,
        updateNameLoading: true,
        updateNameError: null,
      }
    case PROJECT__UPDATE_NAME_SUCCESS:
      return {
        ...state,
        updateNameLoading: false,
        selectedProject: {
          ...state.selectedProject,
        },
        projects: state.projects
          ? {
            ...state.projects,
            data: state.projects.data.map((p) => {
              if (p.id === action.payload.project.id) {
                return {
                  ...p,
                  projectname: action.payload.newProjectName,
                  name: action.payload.newProjectName,
                }
              }
              return p
            }),
          }
          : state.projects,
      }
    case PROJECT__UPDATE_NAME_ERROR:
      return {
        ...state,
        updateNameLoading: false,
        updateNameError: action.payload,
      }
    case PROJECT__CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case PROJECT__CREATE_START:
      return {
        ...state,
        createProjectLoading: true,
        createProjectError: null,
        createdProject: null,
      }
    case PROJECT__CREATE_SUCCESS:
      return {
        ...state,
        createProjectLoading: false,
        createdProject: action.payload,
        selectedProject: action.payload,
        selectedProjectId: action.payload.id
        // Update projects
        // projects: {
        //   ...state.projects,
        //   data: [...state.projects.data, action.payload]
        // }
      }
    case PROJECT__CREATE_ERROR:
      return {
        ...state,
        createProjectLoading: false,
        createProjectError: action.payload
      }

    case PROJECT__UPDATE_PAGINATION_PARAMS:
      return {
        ...state,
        paginationParams: action.payload
      }

    default:
      return state
  }
}
