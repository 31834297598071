import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const ActionItemLinksStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const ActionItemLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  cursor: ${props => props.isDone ? 'default' : 'pointer'};

  &:not(:nth-last-of-type(1)) {
    & > div:nth-of-type(1) {
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 11px;
        border-left: 1px ${({ isDone }) => (isDone ? 'solid' : 'dashed')};
        border-color: ${({ theme, isDone }) => isDone ? theme.colors.green : theme.colors.tertiary};
      }
    }
  }
`

export const ActionItemLinkDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme, isDone }) => isDone ? theme.colors.green : theme.colors.tertiary};
  ${({ disabled }) => disabled && {
    opacity: 0.5
  }}
`

const actionItemLinkTextCss = (props) => css`
  color: ${props.isDone ? props.theme.colors.green : props.theme.colors.tertiary};
  opacity: ${props.disabled && !props.isDone ? 0.5 : 1}
`

export const ActionItemLinkText = styled.a`
  ${actionItemLinkTextCss}
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-decoration-line: underline;
  ${({ disabled }) => disabled && {
    cursor: 'not-allowed'
  }}
`

export const ActionItemLinkTargetIcon = styled.div`
  ${actionItemLinkTextCss}
  display: flex;
  & > svg {
    width: 10px !important;
    height: 10px !important;
  }
`
