import {
  PROJECT__LOAD_SUCCESS,
  PROJECT__LOAD_ERROR,
  PROJECT__LOAD_START,
  PROJECT__LOAD_WORKTYPES_START,
  PROJECT__LOAD_WORKTYPES_SUCCESS,
  PROJECT__LOAD_WORKTYPES_ERROR,
  PROJECT__LOAD_BY_ID_START,
  PROJECT__LOAD_BY_ID_SUCCESS,
  PROJECT__LOAD_BY_ID_ERROR,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_START,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR,
  PROJECT__LOAD_SELLER_BY_PROJECT_START,
  PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS,
  PROJECT__LOAD_SELLER_BY_PROJECT_ERROR,
  PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_START,
  PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_SUCCESS,
  PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_ERROR,
  PROJECT__UPDATE_LAST_VISIT,
  PROJECT__UPDATE_NAME_START,
  PROJECT__UPDATE_NAME_SUCCESS,
  PROJECT__UPDATE_NAME_ERROR,
  PROJECT__CREATE_START,
  PROJECT__CREATE_SUCCESS,
  PROJECT__CREATE_ERROR,
  PROJECT__LOAD_RECOMMENDATIONS_START,
  PROJECT__LOAD_RECOMMENDATIONS_SUCCESS,
  PROJECT__LOAD_RECOMMENDATIONS_ERROR,
  PROJECT__CHANGE_PAGE,
  PROJECT__UPDATE_PAGINATION_PARAMS,
} from '.'

export const startLoadingProjects = (params) => ({
  type: PROJECT__LOAD_START,
  payload: params,
})

export const loadProjectsSuccess = (projects) => ({
  type: PROJECT__LOAD_SUCCESS,
  payload: projects,
})

export const loadProjectsError = (error) => ({
  type: PROJECT__LOAD_ERROR,
  payload: error,
})

export const startLoadingWorktypes = () => ({
  type: PROJECT__LOAD_WORKTYPES_START,
})

export const loadWorktypeSuccess = (meta) => ({
  type: PROJECT__LOAD_WORKTYPES_SUCCESS,
  payload: meta,
})

export const loadWorktypeError = (error) => ({
  type: PROJECT__LOAD_WORKTYPES_ERROR,
  payload: error,
})

export const startLoadingProjectById = (id) => ({
  type: PROJECT__LOAD_BY_ID_START,
  payload: id,
})

export const loadProjectByIdSuccess = (project) => ({
  type: PROJECT__LOAD_BY_ID_SUCCESS,
  payload: project,
})

export const loadProjectByIdError = (error) => ({
  type: PROJECT__LOAD_BY_ID_ERROR,
  payload: error,
})

export const startLoadingFacilitatorByProject = (projectId) => ({
  type: PROJECT__LOAD_FACILITATOR_BY_PROJECT_START,
  payload: projectId,
})

export const loadFacilitatorByProjectSuccess = (facilitator) => ({
  type: PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS,
  payload: facilitator,
})

export const loadFacilitatorByProjectError = (error) => ({
  type: PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR,
  payload: error,
})

export const startLoadingSellerByProject = (projectId) => ({
  type: PROJECT__LOAD_SELLER_BY_PROJECT_START,
  payload: projectId,
})

export const loadSellerByProjectSuccess = (seller) => ({
  type: PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS,
  payload: seller,
})

export const loadSellerByProjectError = (error) => ({
  type: PROJECT__LOAD_SELLER_BY_PROJECT_ERROR,
  payload: error,
})

export const startLoadingFullConfirmationToken = (confirmationToken) => ({
  type: PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_START,
  payload: confirmationToken,
})

export const loadFullConfirmationTokenSuccess = (fullConfirmationToken) => ({
  type: PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_SUCCESS,
  payload: fullConfirmationToken,
})

export const loadFullConfirmationTokenError = (error) => ({
  type: PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_ERROR,
  payload: error,
})

export const updateLastVisit = (projectId) => ({
  type: PROJECT__UPDATE_LAST_VISIT,
  payload: projectId,
})

// Recommendations
export const startLoadingRecommendations = () => ({
  type: PROJECT__LOAD_RECOMMENDATIONS_START,
})

export const loadRecommendationsSuccess = (meta) => ({
  type: PROJECT__LOAD_RECOMMENDATIONS_SUCCESS,
  payload: meta,
})

export const loadRecommendationsError = (error) => ({
  type: PROJECT__LOAD_RECOMMENDATIONS_ERROR,
  payload: error,
})

export const startLoadingUpdateName = (project, newProjectName) => ({
  type: PROJECT__UPDATE_NAME_START,
  payload: { project, newProjectName },
})

export const updateNameSuccess = (project, newProjectName) => {
  return {
    type: PROJECT__UPDATE_NAME_SUCCESS,
    payload: { project, newProjectName },
  }
}

export const updateNameError = (error) => ({
  type: PROJECT__UPDATE_NAME_ERROR,
  payload: error,
})

export const changePage = (page) => ({
  type: PROJECT__CHANGE_PAGE,
  payload: page,
})

export const createProjectStart = (data) => ({
  type: PROJECT__CREATE_START,
  payload: data,
})

export const createProjectSuccess = (createdProject) => {
  return {
    type: PROJECT__CREATE_SUCCESS,
    payload: createdProject,
  }
}

export const createProjectError = (error) => ({
  type: PROJECT__CREATE_ERROR,
  payload: error,
})

export const updateProjectPaginationParams = (newParams) => ({
  type: PROJECT__UPDATE_PAGINATION_PARAMS,
  payload: newParams,
})
