import styled from '@emotion/styled'
import { mq } from '../../utils/style'

export const PageCicContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 18px;
  margin: 0;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  overflow: auto;
`

export const MainTitle = styled.h2`
  color: white;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content:center;
  background-color: #4D5B89;
  font-weight: 700;
  line-height: 28px;
  height: 70px;
  font-size: 18px;
  width: 100%;
  flex-shrink: 0;
  border-radius 5px 5px 0 0;
`

export const Title = styled.h3`
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 40px;
`
export const Text = styled.p`
    font-size: 14px;
    line-height: 14px;
    font-weight: ${props => props.bold ? '700' : '400'};
    margin-bottom: ${props => props.noMargin ? '0' : '40px'};
`

export const TextInput = styled.input`
    font-size: 14px;
    line-height: 14px;
    font-weight: ${props => props.bold ? '700' : '400'};
    & :checked {
        accent-color: ${({ theme }) => theme.colors.primary};
    }
`
export const TextLabel = styled.label`
    font-size: 14px;
    line-height: 14px;
    font-weight: ${props => props.bold ? '700' : '400'};
    margin-left: 4px;
`

export const PartnerContent = styled.div`
  display: flex;
  padding: 40px;
`
export const Content = styled.div`
    width: 70%;
    @media (max-width: 768px) {
        width: 100%;
    }
`
export const SideImage = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: ${(props) => props.isLuko ? "center" : 'inherit'};
  & img:first-of-type {
    margin-bottom: ${(props) => props.isLuko ? "0" : '40px'};
  }
  @media (max-width: 768px) {
      display: none;
  }
`

export const MainLogo = styled.img`
    width: 164px;
    margin-bottom: 30px;
    margin-left: ${props => props.isLuko ? '-30px' : '0'};
`

export const Item = styled.li`
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
`

export const Icon = styled.img`
    margin-right: 5px;
`

export const ItemsList = styled.ul`
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 14px;
`
export const AdviseBlock = styled.div`
    display: flex;
    & input[type=checkbox]{
        margin:0 10px 0 0;
        align-self: baseline;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
        & :checked {
            accent-color: ${({ theme }) => theme.colors.primary};
        }
    }
`

export const Advise = styled.label`
    color: ${({ theme }) => theme.colors.primary + 73};
    font-size: 12px;
    display: flex;
    margin-bottom: 40px;
`
export const Photo = styled.img`
    width: 340px;
`

export const ConfirmButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.primary};
    border: none;
    border-radius: 2px;
    min-width: 156px;
    width: 207px;
    height: 40px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: 1;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.workSans};
    & :disabled {
        background-color: #00155626;
    }
`

export const CheckboxWrapper = styled.span`
    display: flex;
    align-items:center;
    margin-left: ${props => props.isFirst ? '0' : '10px'};
`

export const CheckboxContainer = styled.div`
    display: flex;
    margin-bottom: ${props => props.lowMargin ? '5px' : '40px'};
`
