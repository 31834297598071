import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { closeOverlay } from '../../actions/overlay'
import { APIACTIONS, OVERLAYS } from '../../constants'
import ConfirmationOverlay from '../overlay-confirmation'
import CreateProjectOverlay from '../overlay-create-project'
import IframeOverlay from '../overlay-iframe'
import ImageOverlay from '../overlay-image'
import PdfPreviewOverlay from '../overlay-pdf'
import ProjectAdditionalInfoOverlay from '../overlay-project-additional-info'
import SetPasswordOverlay from '../overlay-set-password'
import SetProjectsListOverlay from '../overlay-set-projects-list'
import TypeformOverlay from '../overlay-typeform'
import Portal from '../portal'
import Button, { BUTTON_SIZES } from '../styled/button'
import {
  BottomBar,
  CloseButton,
  Overlay,
  OverlayContentWrapper
} from './styled'
import AccountActivationOverlay from '../AccountActivationOverlay/AccountActivationOverlay'
import TerrealAccountActivationOverlay from '../TerrealAccountActivationOverlay/TerrealAccountActivationOverlay'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import NewProjectOverlay from '../NewProjectOverlay/NewProjectOverlay'

const noOp = () => null

class OverlayContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.wrapOverlay = this.wrapOverlay.bind(this)
    this.onTypeformSubmit = this.onTypeformSubmit.bind(this)
    this.getOverlayContent = this.getOverlayContent.bind(this)
    this.handlePropagation = this.handlePropagation.bind(this)
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this)
  }

  handleCloseOverlay(event) {
    if (!this.props.passwordChangeSuccess) {
      this.props.closeOverlay()
    }
  }

  handlePropagation(event) {
    event.stopPropagation()
  }

  onTypeformSubmit() { }

  wrapOverlay(content) {
    return (downloadLink = null) => (
      <Portal portalRoot="overlay-root">
        <OverlayContentWrapper onClick={this.handleCloseOverlay}>
          <CloseButton onClick={this.handleCloseOverlay}>
            <FontAwesomeIcon icon={faXmark} />
          </CloseButton>
          <Overlay>{content}</Overlay>
          <BottomBar>
            {downloadLink && (
              <Button
                color="white"
                size={BUTTON_SIZES.LARGE}
                outlined
                as="a"
                href={downloadLink}
                onClick={this.handlePropagation}
                target="_blank"
              >
                <FontAwesomeIcon icon={faArrowToBottom} />
                &nbsp; Télécharger
              </Button>
            )}
          </BottomBar>
        </OverlayContentWrapper>
        {this.props.createProjectOverlayOpened && <CreateProjectOverlay />}
      </Portal>
    )
  }

  getOverlayContent(type) {
    return (
      {
        [OVERLAYS.IFRAME]: () =>
          this.wrapOverlay(
            <IframeOverlay src={this.props.iframeOverlaySrc} />
          )(),
        [OVERLAYS.CONFIRMATION[APIACTIONS.PANELSET]]: () =>
          this.wrapOverlay(
            <ConfirmationOverlay
              title="Votre panneau est-il installé ?"
              handleConfirm={() => { }}
              handleReject={this.props.closeOverlay}
            />
          )(),
        [OVERLAYS.CHANGEPASSWORD]: () => <SetPasswordOverlay />,
        [OVERLAYS.SELECTPROJECTSLIST]: () =>
          this.wrapOverlay(<SetProjectsListOverlay />)(),
        [OVERLAYS.IMAGE]: () =>
          this.wrapOverlay(<ImageOverlay src={this.props.imageOverlaySrc} />)(
            this.props.imageOverlaySrc
          ),
        [OVERLAYS.PDF]: () =>
          this.wrapOverlay(
            <PdfPreviewOverlay src={this.props.pdfOverlaySrc} />
          )(this.props.pdfOverlaySrc),
        [OVERLAYS.TYPEFORM]: () =>
          this.wrapOverlay(
            <TypeformOverlay
              typeformUrl={this.props.typeformOverlayUrl}
              handleClose={this.props.closeOverlay}
              handleSubmit={this.onTypeformSubmit}
            />
          )(),
        [OVERLAYS.CREATE_PROJECT]: () => <CreateProjectOverlay />,
        [OVERLAYS.PROJECT_ADDITIONAL_iNFO]: () => (
          <ProjectAdditionalInfoOverlay />
        ),
        [OVERLAYS.ACCOUNT_ACTIVATION]: () => <AccountActivationOverlay />,
        [OVERLAYS.TERREAL_ACCOUNT_ACTIVATION]: () => <TerrealAccountActivationOverlay />,
        [OVERLAYS.NEW_PROJECT]: () => <NewProjectOverlay />
      }[type] || noOp
    )()
  }

  render() {
    return this.props.currentOverlayType
      ? this.getOverlayContent(this.props.currentOverlayType)
      : null
  }
}

const mapStateToProps = ({ user, project, overlay }) => ({
  passwordChangeSuccess: user.passwordChangeSuccess,
  projectId: project.id,
  currentOverlayType: overlay.currentOverlayType,
  iframeOverlaySrc: overlay.iframeOverlaySrc,
  confirmationOverlayTitle: overlay.confirmationOverlayTitle,
  confirmationActionId: overlay.confirmationActionId,
  imageOverlaySrc: overlay.imageOverlaySrc,
  pdfOverlaySrc: overlay.pdfOverlaySrc,
  typeformOverlayUrl: overlay.typeformOverlayUrl,
  typeformActionId: overlay.typeformActionId,
  createProjectOverlayOpened: overlay.createProjectOverlayOpened
})

const mapDispatchToProps = (dispatch) => ({
  closeOverlay: () => dispatch(closeOverlay())
})

OverlayContainer.propTypes = {
  currentOverlayType: PropTypes.string,
  iframeOverlaySrc: PropTypes.string,
  imageOverlaySrc: PropTypes.string,
  pdfOverlaySrc: PropTypes.string,
  typeformOverlayUrl: PropTypes.string,
  confirmationOverlayTitle: PropTypes.string,
  confirmationActionId: PropTypes.string,
  passwordChangeSuccess: PropTypes.bool.isRequired,

  closeOverlay: PropTypes.func.isRequired,
  createProjectOverlayOpened: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayContainer)
