import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startLoadingNotificationsMeta } from '../../actions/notification'

import buildMiddleware from './template'

const withNotificationsMetaFetch = (SubComponent) => {
  const WithNotificationsMetaFetchWrapper = buildMiddleware(
    SubComponent,
    'WithNotificationsMeta'
  )

  const WithNotificationsMetaFetch = class extends Component {
    componentDidMount () {
      this.props.startLoadingNotificationsMeta()
    }

    render () {
      const {
        startLoadingNotificationsMeta,
        ...passThroughProps
      } = this.props

      return <WithNotificationsMetaFetchWrapper {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ notification }) => ({
    loading: notification.notificationsMetaLoading || (!notification.notificationsMeta && !notification.notificationsMetaError),
    error: notification.notificationsMetaError
  })

  const mapDispatchToProps = dispatch => ({
    startLoadingNotificationsMeta: () => dispatch(startLoadingNotificationsMeta())
  })

  WithNotificationsMetaFetch.propTypes = {
    startLoadingNotificationsMeta: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithNotificationsMetaFetch)
}

export default withNotificationsMetaFetch
