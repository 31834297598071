import {
  ALERT__ADD,
  ALERT__REMOVE,
  ALERT__DELETE
} from '../actions'

const initialState = {
  alertsList: [],
  alertsRemoved: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ALERT__ADD:
      return {
        ...state,
        alertsList: state.alertsList.concat([action.payload])
      }

    case ALERT__REMOVE:
      return {
        ...state,
        alertsRemoved: state.alertsRemoved.concat([action.payload])
      }

    case ALERT__DELETE:
      return {
        ...state,
        alertsList: state.alertsList.filter(a => a.id !== action.payload),
        alertsRemoved: state.alertsList.filter(a => a.id !== action.payload)
      }

    default:
      return state
  }
}
