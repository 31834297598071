import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from '@emotion/core'
import { animationDelay } from '../../constants/styles'

export const wrapperWidth = 75

export const openNotifOverlayAnimation = 'open-notif-overlay-animation'
export const closeNotifOverlayAnimation = 'close-notif-overlay--animation'

export const NotificationOverlayWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
`

export const NotificationOverlayContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-size: small;
  background: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  animation: ${({ animation }) => animation} ${animationDelay}s;

  @keyframes ${openNotifOverlayAnimation} {
    from {
      top: -100vh;
    }
    to {
      top: 0;
    }
  }

  @keyframes ${closeNotifOverlayAnimation} {
    from {
      top: 0;
    }
    to {
      top: -100vh;
    }
  }
`

export const NotificationOverlayTitleWrap = styled('div')`
  display: flex;
  padding: 0 25px;
  border-bottom: 1px solid #e4e4e4;
  box-shadow: 0px 2px 9px 2px rgb(208 208 210 / 20%);
  color: ${({ theme }) => theme.colors.primary};

  @media screen and (max-width: 1024px) {
    height: 70px;
    min-height: 70px;
    align-items: center;
  }
`

export const NotificationOverlayContentWrap = styled('div')`
  display: flex;
  padding: 15px;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
  padding: ${({ theme }) => theme.spacings.medium}px 0;
  z-index: ${({ theme }) => theme.layers.menu};
  height: 100%;
`

export const NotificationOverlayContent = styled('div')`
  display: flex;
  width: 100%;
  padding: 5px 0;
`

export const NotificationOverlayTitle = styled('div')`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: large;
`
export const SidebarOverlayIcon = styled(FontAwesomeIcon)`
  width: auto !important;
  height: 25px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) =>
    props.disabled ? props.theme.colors.medium : props.theme.colors.primary};
`

export const NotificationTextWrap = styled('div')`
  display: flex;
  padding: 5px 20px;
  flex-direction: column;
`

export const NotificationText = styled('div')`
  margin-bottom: 10px;
`

export const NotificationDate = styled('div')`
  font-size: smaller;
`

const notifTextCss = css`
  font-size: 11px;
  font-weight: 500;
`

export const NotifText = styled.a`
  ${notifTextCss}
  margin-bottom: 7px;
  line-height: 1.2;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`

export const NotifProjectName = styled.span`
  ${notifTextCss}
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
`

export const ArrowBack = styled('div')`
  display: flex;
  align-items: center;
`
