import { faCog } from '@fortawesome/pro-duotone-svg-icons-v5'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import {
  faFolderOpen,
  faLayerPlus,
  faListTree
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toggleMultiNotifsMenu, toggleOptionsMenu } from '../../actions/menus'
import { startLoadingNotificationsByUser } from '../../actions/notification'
import { openCreateProjectOverlay, setAccountActivationOverlay, setNewProjectOverlay } from '../../actions/overlay'
import { changePage } from '../../actions/project'
import { isBtoBSelector, projectSelector, userSelector } from '../../selectors'
import { notifSelector } from '../../selectors/notification'
import OptionsMenuContainer from '../container-options-menu'
import CreateProjectOverlay from '../overlay-create-project'
import {
  CreateFolderButton,
  IconOverlayContainer,
  SidebarHeader,
  SidebarLinkWrapper,
  SidebarLogoOverlay,
  SidebarLogoWrapper,
  SidebarOverlayWrapper,
  SidebarWrapper,
  SideContentOverlayWrapper,
  SideOverlayWrapper
} from './styled'
import { DEMO_ROUTE, MY_FOLDERS_ROUTE } from '../../constants/router'

const SidebarContainerOverlay = (props) => {
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const history = useHistory()
  const isDemoProject = history.location.pathname === DEMO_ROUTE

  const { selectedProject } = useSelector(projectSelector)
  const [homeText, setHomeText] = useState('')
  const isBtoB = useSelector(isBtoBSelector)
  const tunnelBaseUrl = window.processRuntime.env.PAYMENT_TUNNEL_BASE_URL

  useEffect(() => {
    if (selectedProject) {
      const { name, projectname, dealname } = selectedProject
      setHomeText(name || projectname || dealname)
    }
  }, [])


  const {
    userNotifications,
    userNotificationsLoading,
    userNotificationsError
  } = useSelector(notifSelector)

  const handleClickSwitchProject = (event) => {
    event.preventDefault();
    dispatch(changePage('switch'));
    props.onClose();
    history.push(MY_FOLDERS_ROUTE);
  };

  const showOverlaySwitchProject = (event) => {
    if (isDemoProject) {
      dispatch(setAccountActivationOverlay());
    } else {
      handleClickSwitchProject(event);
    }
  };

  const handleClickHome = (event) => {
    event.preventDefault()
    dispatch(changePage('projet'))
    props.onClose()
    history.push('/')
  }

  React.useMemo(() => {
    if (
      user &&
      user.profile &&
      !userNotifications &&
      !userNotificationsLoading &&
      !userNotificationsError
    ) {
      dispatch(startLoadingNotificationsByUser(user, { limit: 15, offset: 0 }))
    }
  }, [
    user,
    userNotifications,
    userNotificationsLoading,
    userNotificationsError
  ])

  const handleClickCreateProject = (event) => {
    event.preventDefault()

    if (isDemoProject) {
      dispatch(setAccountActivationOverlay())
    } else {
      if (isBtoB) {
        dispatch(openCreateProjectOverlay())
        props.onClose()
      } else {
        dispatch(setNewProjectOverlay())
      }
    }
  }

  return (
    <SidebarOverlayWrapper>
      <SideOverlayWrapper animation={props.animation}>
        <SidebarWrapper>
          <SideContentOverlayWrapper>
            <SidebarHeader>
              <SidebarLinkWrapper href={window.processRuntime.env.HP_BASE_URL}>
                <SidebarLogoOverlay
                  src={isBtoB ? "https://pmdc-public.s3.eu-west-2.amazonaws.com/common/logos/AlgarPRO+logo.png" : "https://pmdc-public.s3.eu-west-2.amazonaws.com/common/logos/logo_algar2.svg"}
                  alt="Index"
                />
              </SidebarLinkWrapper>
              <div onClick={props.onClose}>
                <FontAwesomeIcon size="2x" icon={faXmark} />
              </div>
            </SidebarHeader>
            <SidebarLogoWrapper onClick={showOverlaySwitchProject}>
              <IconOverlayContainer>
                <FontAwesomeIcon size="3x" icon={faListTree} />
              </IconOverlayContainer>
              <div>Mes dossiers</div>
            </SidebarLogoWrapper>
            <SidebarLogoWrapper onClick={handleClickHome}>
              <IconOverlayContainer>
                <FontAwesomeIcon size="3x" icon={faFolderOpen} />
              </IconOverlayContainer>
              <div>{homeText}</div>
            </SidebarLogoWrapper>
            {/* Create new project */}
            <CreateFolderButton onClick={handleClickCreateProject}>
              <FontAwesomeIcon
                color={props.theme.colors.primary}
                icon={faLayerPlus}
              />
              <span>Créer un dossier</span>
            </CreateFolderButton>
          </SideContentOverlayWrapper>
        </SidebarWrapper>
        <SidebarWrapper>
          <OptionsMenuContainer />
        </SidebarWrapper>
      </SideOverlayWrapper>
      {props.createProjectOverlayOpened && <CreateProjectOverlay />}
    </SidebarOverlayWrapper>
  )
}

const mapStateToProps = ({ router, menus, overlay }) => ({
  currentRoute: router.location.pathname,
  optionsMenuOpened: menus.optionsMenuOpened,
  multiNotifsMenuOpened: menus.multiNotifsMenuOpened,
  createProjectOverlayOpened: overlay.createProjectOverlayOpened
})

const mapDispatchToProps = (dispatch) => ({
  handleOptionsClick: (event) => {
    event.stopPropagation()
    return dispatch(toggleOptionsMenu())
  },
  handleNotifIconClick: (event) => {
    event.stopPropagation()
    return dispatch(toggleMultiNotifsMenu())
  }
})

SidebarContainerOverlay.propTypes = {
  theme: PropTypes.object.isRequired,
  currentRoute: PropTypes.string.isRequired,
  optionsMenuOpened: PropTypes.bool.isRequired,
  handleOptionsClick: PropTypes.func.isRequired,
  multiNotifsMenuOpened: PropTypes.bool.isRequired,
  handleNotifIconClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  animation: PropTypes.string.isRequired,
  sales: PropTypes.object
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SidebarContainerOverlay))
