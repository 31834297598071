import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux'
import { closeOverlay } from '../../actions/overlay'
import { startChangingPassword } from '../../actions/user'
import { LoadingSpinnerSmall } from '../loading-spinner'
import CustomConfirmOverlay from '../overlay-custom-confirm'
import SetPasswordView from '../view-set-password'
import { ConfirmTextContainer, SetPasswordSubTitle } from './styles'

const conditions = (password, confirmPassword) => ({
  atLeast8Characters: password.length >= 8,
  atLeastOneLowercase: password.match(/[a-z]/),
  atLeastOneUppercase: password.match(/[A-Z]/),
  atLeastOneNumber: password.match(/\d+/),
  samePassword: password && confirmPassword && password === confirmPassword
})

const findCondError = (condObj) => {
  return Object.values(condObj).filter((x) => !x).length > 0
}

const SetPasswordOverlay = ({
  passwordChangeSuccess,
  startChangingPassword,
  passwordChangeError,
  passwordChangeLoading
}) => {
  const dispatch = useDispatch()
  const [state, setState] = React.useState({
    password: '',
    confirmPassword: ''
  })
  const { password, confirmPassword } = state
  const conds = conditions(password, confirmPassword)

  const handleCloseModal = () => {
    dispatch(closeOverlay())
  }

  const goToLogin = () => {
    window.location.pathname = '/login'
  }

  return (
    <Fragment>
      {passwordChangeSuccess ? (
        <CustomConfirmOverlay
          title="Mot de passe modifié"
          body={
            <p>
              Votre mot de passe a été modifié. <br />
              <b>Connectez-vous.</b>
            </p>
          }
          handleConfirm={goToLogin}
          confirmText="Connexion"
          confirmActionOnly={true}
          handleReject={handleCloseModal}
        />
      ) : (
        <CustomConfirmOverlay
          title="Mot de passe"
          body={
            <>
              <SetPasswordSubTitle>
                Votre mot de passe doit contenir au moins 8 <br /> caractères,
                une majuscule, <br /> une minuscule et un chiffre.
              </SetPasswordSubTitle>
              <SetPasswordView
                state={state}
                setState={setState}
                error={passwordChangeError}
              />
            </>
          }
          handleConfirm={() => startChangingPassword(password)}
          confirmText={
            <ConfirmTextContainer>
              Valider
              {passwordChangeLoading && (
                <LoadingSpinnerSmall size={15} borderWidth={2} />
              )}
            </ConfirmTextContainer>
          }
          confirmDisabled={findCondError(conds)}
          rejectIcon={faTimes}
          handleReject={handleCloseModal}
        />
      )}
    </Fragment>
  )
}

const mapStateToProps = ({ user }) => ({
  passwordChangeError: user.passwordChangeError,
  passwordChangeLoading: user.passwordChangeLoading,
  passwordChangeSuccess: user.passwordChangeSuccess
})

const mapDispatchToProps = (dispatch) => ({
  startChangingPassword: (pwd) => dispatch(startChangingPassword(pwd))
})

SetPasswordOverlay.propTypes = {
  startChangingPassword: PropTypes.func.isRequired,
  passwordChangeError: PropTypes.object,
  passwordChangeLoading: PropTypes.bool.isRequired,
  passwordChangeSuccess: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SetPasswordOverlay))
