import {
  INTERVAL__RESET_ID,
  INTERVAL__SET_ID,
  OVERLAY__ACCOUNT_ACTIVATION,
  OVERLAY__TERREAL_ACCOUNT_ACTIVATION,
  OVERLAY__CLOSE,
  OVERLAY__CLOSE_CREATE_PROJECT_IN_OTHER_OVERLAY,
  OVERLAY__OPEN_CREATE_PROJECT,
  OVERLAY__OPEN_CREATE_PROJECT_IN_OTHER_OVERLAY,
  OVERLAY__OPEN_PROJECT_ADDITIONAL_INFO,
  OVERLAY__SELECT_PROJECTS_LIST,
  OVERLAY__SET_CHANGE_PASSWORD,
  OVERLAY__SET_CONFIRMATION,
  OVERLAY__SET_IFRAME,
  OVERLAY__SET_IMAGE,
  OVERLAY__SET_PDF,
  OVERLAY__SET_TYPEFORM,
  OVERLAY__NEW_PROJECT_ACTIVATION
} from '.'

export const setIframeOverlay = (src) => ({
  type: OVERLAY__SET_IFRAME,
  payload: src
})

export const setImageOverlay = (src) => ({
  type: OVERLAY__SET_IMAGE,
  payload: src
})

export const setPdfOverlay = (src) => ({
  type: OVERLAY__SET_PDF,
  payload: src
})

export const setTypeformOverlay = (url) => ({
  type: OVERLAY__SET_TYPEFORM,
  payload: url
})

export const setConfirmationOverlay = (subKey, actionId) => ({
  type: OVERLAY__SET_CONFIRMATION,
  payload: { actionId, subKey }
})

export const setChangePasswordOverlay = () => ({
  type: OVERLAY__SET_CHANGE_PASSWORD
})

export const setSelectProject = () => ({
  type: OVERLAY__SELECT_PROJECTS_LIST
})

export const closeOverlay = () => ({
  type: OVERLAY__CLOSE
})

export const setIDInterval = (id) => ({
  type: INTERVAL__SET_ID,
  payload: id
})

export const resetIDInterval = () => ({
  type: INTERVAL__RESET_ID
})

export const openCreateProjectOverlay = () => ({
  type: OVERLAY__OPEN_CREATE_PROJECT
})

export const openCreateProjectOverlayInOtherOverlay = () => ({
  type: OVERLAY__OPEN_CREATE_PROJECT_IN_OTHER_OVERLAY
})

export const closeCreateProjectOverlayInOtherOverlay = () => ({
  type: OVERLAY__CLOSE_CREATE_PROJECT_IN_OTHER_OVERLAY
})

export const openProjectAdditionalInfoOverlay = () => ({
  type: OVERLAY__OPEN_PROJECT_ADDITIONAL_INFO
})

export const setAccountActivationOverlay = () => ({
  type: OVERLAY__ACCOUNT_ACTIVATION
})

export const setTerrealAccountActivationOverlay = () => ({
  type: OVERLAY__TERREAL_ACCOUNT_ACTIVATION
})
export const setNewProjectOverlay = () => ({
  type: OVERLAY__NEW_PROJECT_ACTIVATION
})
