import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { faSignOut, faLockAlt, faKey } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import {
  ParameterWrap,
  ParameterContent,
  ParameterFooter,
  MenuButton,
} from './styled'
import Button, { BUTTON_SIZES } from '../styled/button'
import { setChangePasswordOverlay } from '../../actions/overlay'

const PageParameter = (props) => {
  const history = useHistory()

  const handleLogout = (event) => {
    event.stopPropagation()
    history.push('/logout')
  }

  const handleLogin = (event) => {
    event.stopPropagation()
    history.push('/login')
  }

  return (
    <ParameterWrap>
      <ParameterContent onClick={props.onSetPasswordClick}>
        {props.accessToken && (
          <MenuButton>
            <FontAwesomeIcon
              size="1x"
              icon={faLockAlt}
              color={props.theme.colors.dark}
            />
            <span>Changer de mot de passe</span>
          </MenuButton>
        )}
      </ParameterContent>
      <ParameterFooter>
        {props.accessToken && (
          <Button
            color="tertiary"
            onClick={handleLogout}
            size={BUTTON_SIZES.LARGE}
          >
            <FontAwesomeIcon
              size="1x"
              icon={faSignOut}
              color={props.theme.colors.white}
            />
            &nbsp;
            <span>Déconnexion</span>
          </Button>
        )}
        {!props.accessToken && (
          <Button
            color="primary"
            onClick={handleLogin}
            size={BUTTON_SIZES.LARGE}
          >
            <FontAwesomeIcon
              size="1x"
              icon={faKey}
              color={props.theme.colors.white}
            />
            &nbsp;
            <span>Connexion</span>
          </Button>
        )}
      </ParameterFooter>
    </ParameterWrap>
  )
}

const mapStateToProps = ({ user }) => ({
  accessToken: user.accessToken,
})

const mapDispatchToProps = (dispatch) => ({
  onSetPasswordClick: (event) => {
    event.stopPropagation()
    return dispatch(setChangePasswordOverlay())
  },
})

PageParameter.propTypes = {
  accessToken: PropTypes.string,
  onSetPasswordClick: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(PageParameter))
