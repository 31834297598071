import { faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useJsApiLoader } from '@react-google-maps/api'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { changeCreateProjectInput } from '../../actions/create-project-form'
import { createProjectStart } from '../../actions/project'
import { algarDeskFolderTypes } from '../../constants/project'
import {
  allProjectsSelector,
  createProjectSelector,
  isAllowedToUseAlgarDeskSelector,
  isBtoBSelector,
  isOnlyDeskSelector,
  userSelector
} from '../../selectors'
import { createProjectFormSelector } from '../../selectors/form'
import { LoadingSpinnerSmall } from '../loading-spinner'
import SelectInput from '../select-input'
import SelectInputProjectTypes from '../select-input-project-types'
import useDefaultProjectType from './hooks/use-default-project-type'
import {
  CreateButton,
  CreateProjectConatainer,
  ErrorMessage,
  InputContainer,
  InputContainerDesk,
  InputLabel,
  InputText,
  LoadingContainer,
  PlacesAutocompleteInput,
  PlacesAutocompleteInputWrapper,
  StyledAutocompleteLabel,
  StyledLi,
  StyledUl,
  SuggestionListItem,
  SuggestionsList,
  Title,
  ValidateButtonContainer,
  WarningUniqueProject
} from './styled'

const libraries = ['places']

const PageCreateProject = ({ showTitle, showValidateBtn, theme }) => {
  const tunnelBaseUrl = window.processRuntime.env.PAYMENT_TUNNEL_BASE_URL

  const { createProjectLoading, createProjectError } = useSelector(
    createProjectSelector
  )

  const dispatch = useDispatch()

  const formValues = useSelector(createProjectFormSelector)
  const { address, projectTypesCodes, projectname, typeClient } = formValues

  const [folderType, setFolderType] = useState(algarDeskFolderTypes[1])
  const defaultProjectType = useDefaultProjectType(folderType)
  const [projectTypes, setProjectTypes] = useState(defaultProjectType)
  const [hasUniqueProjectType, setHasUniqueProjectType] = useState(false)

  const isBtoB = useSelector(isBtoBSelector)
  const isOnlyDesk = useSelector(isOnlyDeskSelector)
  const isAllowedToUseDesk = useSelector(isAllowedToUseAlgarDeskSelector)

  const isClientDesk = isAllowedToUseDesk && folderType.value === algarDeskFolderTypes[0].value

  // const isClientDeskEmpty = isAllowedToUseDesk && folderType.value === algarDeskFolderTypes[1].value

  const newProjectData = {
    address,
    projectTypesCodes,
    projectname,
    typeClient
  }

  const onAddressChange = (event) => {
    dispatch(changeCreateProjectInput({ name: 'address', value: event }))
  }

  const uniqueProjectTypes = ['shop-sign', 'commercial-parcel', 'PMR-access', 'store-terrace']

  const onChangeProjectTypes = (selectedOptions) => {
    const projectValues = selectedOptions.map(el => el.value)
    const isAnyProjectTypeIncluded = projectValues.some(value => uniqueProjectTypes.includes(value))
    const lastProjectTypeAdded = selectedOptions[selectedOptions.length - 1]
    if (isAnyProjectTypeIncluded) {
      setHasUniqueProjectType(true)
      setProjectTypes(lastProjectTypeAdded)
    } else {
      if (setHasUniqueProjectType) setHasUniqueProjectType(false)
      setProjectTypes(selectedOptions)
    }
    // selectedOptions can contain multiple worktype now, should be an array
    dispatch(
      changeCreateProjectInput({
        name: 'projectTypesCodes',
        value: selectedOptions.map(el => el.value)
      })
    )
  }

  const onChangeProjectName = (event) => {
    dispatch(
      changeCreateProjectInput({
        name: 'projectname',
        value: event.target.value
      })
    )
  }

  const onChangeFolderType = (e) => {
    // prevent next logic to be run, need to be done better
    if (e.length === 0) return
    // this logic is for the multi=true used to keep the same UX and UI on inputs, but we do not want multi here
    if (folderType === algarDeskFolderTypes[1]) setFolderType(algarDeskFolderTypes[0])
    else setFolderType(algarDeskFolderTypes[1])
    onChangeProjectTypes([])
  }

  const handleClickValidate = React.useCallback(
    (event) => {
      event.preventDefault()
      if ((isBtoB || isAllowedToUseDesk) && address && projectTypes) {
        dispatch(createProjectStart(newProjectData))
      } else {
        window.location.href = `${tunnelBaseUrl}/adresse?action=create-new-project`
      }
    },
    [isBtoB, isAllowedToUseDesk, address, projectTypes, newProjectData]
  )

  const onSuggestionSelect = (address, placeID) => {
    dispatch(changeCreateProjectInput({ name: 'address', value: address }))
  }

  const loadingGMap = useJsApiLoader({
    googleMapsApiKey: window.processRuntime.env.GOOGLE_API_KEY,
    libraries: libraries
  })

  const createButtonDisabled =
    createProjectLoading ||
    (isBtoB && (!address || !projectTypes || projectTypes.length === 0))

  // Update typeClient
  React.useEffect(() => {
    const _typeClient = isClientDesk ? 'Desk' : 'BtoB'
    dispatch(
      changeCreateProjectInput({ name: 'typeClient', value: _typeClient })
    )
  }, [isClientDesk])

  return (
    <CreateProjectConatainer allowDesk={isAllowedToUseDesk}>
      {showTitle && (
        <Title>
          <span>Créer un nouveau dossier</span>
        </Title>
      )}
      {createProjectError && (
        <ErrorMessage>{createProjectError.message}</ErrorMessage>
      )}
      {isBtoB || isAllowedToUseDesk ? (
        <Fragment>
          {isAllowedToUseDesk && (
            <InputContainerDesk>
              <InputLabel htmlFor="folder-type">Type de dossier</InputLabel>
              <SelectInput
                options={
                  isOnlyDesk ? [algarDeskFolderTypes[0]] : algarDeskFolderTypes
                }
                closeMenuOnSelect={true}
                placeholder="Choisissez un type de dossier"
                hideSelectedOptions={true}
                value={folderType}
                isMulti={true}
                onChange={(event) => onChangeFolderType(event)}
              />
            </InputContainerDesk>
          )}

          {loadingGMap.isLoaded ? (
            <PlacesAutocomplete
              value={address !== undefined ? address : ''}
              onChange={onAddressChange}
              onSelect={onSuggestionSelect}
              searchOptions={{ componentRestrictions: { country: ['fr'] } }}
              debounce={500}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <Fragment>
                  <StyledAutocompleteLabel htmlFor="address">
                    Adresse postale de votre projet
                  </StyledAutocompleteLabel>
                  <PlacesAutocompleteInputWrapper>
                    <PlacesAutocompleteInput
                      {...getInputProps({
                        placeholder: '1 rue de Paris, 75000 Paris',
                        id: 'address'
                      })}
                    />
                    {loading && (
                      <LoadingContainer>
                        <LoadingSpinnerSmall size={15} borderWidth={2} />
                      </LoadingContainer>
                    )}
                    <SuggestionsList>
                      {suggestions.map((suggestion, index) => {
                        return (
                          <SuggestionListItem
                            {...getSuggestionItemProps(suggestion)}
                            key={`${index}-${suggestion.id}`}
                          >
                            <span>{suggestion.description}</span>
                          </SuggestionListItem>
                        )
                      })}
                    </SuggestionsList>
                  </PlacesAutocompleteInputWrapper>
                </Fragment>
              )}
            </PlacesAutocomplete>
          ) : (
            <Fragment>
              <StyledAutocompleteLabel>Adresse postale de votre projet </StyledAutocompleteLabel>
              <PlacesAutocompleteInputWrapper>
                <PlacesAutocompleteInput
                  type="text"
                  placeholder="1 rue de Paris, 75000 Paris" />
              </PlacesAutocompleteInputWrapper>
            </Fragment>
          )
          }

          <InputContainer>
            <InputLabel htmlFor="project-name">Nom du dossier</InputLabel>
            <InputText
              type="text"
              value={projectname}
              onChange={onChangeProjectName}
              placeholder="Extension M. Bonnet"
              onClick={(e) => e.stopPropagation()}
              id="project-name"
            />
          </InputContainer>
          <InputContainer>
            <InputLabel htmlFor="project-types">Types de projets</InputLabel>
            <SelectInputProjectTypes
              value={projectTypes}
              onChange={onChangeProjectTypes}
              name="project-types"
              isBtoB={isBtoB}
              isDesk={isClientDesk}
              hideSelectedOptions={true}
              closeMenuOnSelect={true}
            />
          </InputContainer>
          {hasUniqueProjectType &&
            <WarningUniqueProject>Attention, le projet {projectTypes?.label} doit être déposé de manière indépendante à tout autre projet.
              <br />
              Si vous souhaitez créer un dossier avec plusieurs projets incluant une demande d’{projectTypes?.label}, créez alors un dossier avec le projet {projectTypes?.label} et un autre dossier avec les projets restants.</WarningUniqueProject>
          }
        </Fragment>
      ) : (
        <Fragment>
          <StyledUl>
            <StyledLi>Décrivez votre dossier</StyledLi>
            <StyledLi>
              Un de nos conseillers vous contact pour vous soumettre un devis.
            </StyledLi>
            <StyledLi>Démarrez votre nouveau dossier !</StyledLi>
          </StyledUl>
        </Fragment>
      )}
      {showValidateBtn && (
        <ValidateButtonContainer isbtob={isBtoB}>
          <CreateButton
            disabled={createButtonDisabled}
            onClick={createButtonDisabled ? undefined : handleClickValidate}
          >
            <FontAwesomeIcon color={theme.colors.primary} icon={faLayerPlus} />
            <span>Créer le dossier</span>
            {createProjectLoading && (
              <LoadingSpinnerSmall size={15} borderWidth={2} />
            )}
          </CreateButton>
        </ValidateButtonContainer>
      )}
    </CreateProjectConatainer>
  )
}

PageCreateProject.defaultProps = {
  showTitle: true,
  showValidateBtn: true
}

PageCreateProject.propTypes = {
  showTitle: PropTypes.bool,
  showValidateBtn: PropTypes.bool
}

export default withTheme(PageCreateProject)
