import React from 'react'
import { connect } from 'react-redux'

import PageHeaderView from '../view-project-header'

const fakeProfile = props => ({
  firstName: props.firstName
})

const fakeFacilitator = {
  firstName: 'Florence'
}

const fakeSeller = {
  firstName: 'Kévin'
}

const FakeHeaderContainer = props => (
  <PageHeaderView
    profile={fakeProfile(props)}
    facilitator={fakeFacilitator}
    seller={fakeSeller}
  />
)

const mapStateToProps = ({ draft }) => ({
  firstName: draft.draftContent.firstName
})

export default connect(mapStateToProps, null)(FakeHeaderContainer)
