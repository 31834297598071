import { useSelector } from 'react-redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { STEP__LOAD_BY_PROJECT_START, STEP__LOAD_META_START } from '../actions'
import {
  loadStepsbyProjectError,
  loadStepsByProjectSuccess,
  loadStepsMetaError,
  loadStepsMetaSuccess
} from '../actions/step'
import { logError } from '../actions/system'
import { TYPES_CLIENTS } from '../constants'
import { getCodeFromError } from '../utils/error'
import PMDC from '../utils/pmdc'

function* loadStepsMeta() {
  try {
    const { user, step, project } = yield select()

    const projectSteps = step.steps || []
    const projectStepCodes = projectSteps.map(({ metaCode }) => metaCode)
    const selectedProject = project.selectedProject

    const isAlgarDesk = selectedProject?.typeClient === "Desk"

    const clientType = isAlgarDesk ? TYPES_CLIENTS.DESK : user.profile?.clientType || TYPES_CLIENTS.BTOC
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getStepsMeta,
      clientType
    )

    const dynamicSteps = data.filter(({ state, appearanceCodes }) => {
      const _appearanceCodes = appearanceCodes || []
      return (
        state === 'dynamic' &&
        projectStepCodes.some((item) => _appearanceCodes.includes(item))
      )
    })

    const dynamicStepsCodes = dynamicSteps.map(({ code }) => code)

    let metaSteps = []

    /**
     * REPLACE ONE STEP DEPENDING HIS replaceByCodes
    */
    const substituteCodes = data
      .map(({ replaceByCodes }) => (replaceByCodes ? replaceByCodes : []))
      .flat()

    // Add isSubstitute props in each metaStep
    // And return only not substitute metaStep
    metaSteps = data
      .map((item) => ({
        ...item,
        isSubstitute: substituteCodes.includes(item.code)
      }))
      .filter(({ isSubstitute }) => !isSubstitute)

    // Find substitute and return it if exist
    metaSteps = metaSteps.map((item) => {
      const { replaceByCodes } = item
      let substitute = null
      if (replaceByCodes && Array.isArray(replaceByCodes) && replaceByCodes.length > 0) {
        substitute = projectSteps?.findLast(({ meta }) => {
          const { code } = meta || {}
          return typeof code === "string" && replaceByCodes.includes(code)
        })
      }
      return substitute ? substitute?.meta : item
    })



    // SHOW DEFAUTL STEP AND DYNAMIC STEP (COMPARED WITH PROJECT STEPS)
    metaSteps = metaSteps.filter(({ state, code }) => {
      return state === 'default' || dynamicStepsCodes.includes(code)
    })

    metaSteps = metaSteps.sort((a, b) => a.groupIndex - b.groupIndex)

    yield put(loadStepsMetaSuccess(metaSteps))
  } catch (err) {
    yield put(
      loadStepsMetaError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération des étapes du projet'
      })
    )
    yield put(logError(err))
  }
}

function* loadStepsByProject() {
  try {
    const { user, project } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getStepsByProject,
      project.selectedProjectId
    )
    const sortedData = data.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    )
    yield put(loadStepsByProjectSuccess(sortedData))
  } catch (err) {
    yield put(
      loadStepsbyProjectError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération de la timeline'
      })
    )
    yield put(logError(err))
  }
}

export default [
  takeLatest(STEP__LOAD_META_START, loadStepsMeta),
  takeLatest(STEP__LOAD_BY_PROJECT_START, loadStepsByProject)
]
