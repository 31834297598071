import {
  GEOCODING__ADDRESS_START,
  GEOCODING__ADDRESS_SUCCESS,
  GEOCODING__ADDRESS_ERROR,
  GEOCODING__TOGGLE_SUGGESTIONS,
  GEOCODING__OPEN_SUGGESTIONS,
  GEOCODING__CLOSE_SUGGESTIONS
} from '.'

export const geocodingAddressStart = (address) => ({
  type: GEOCODING__ADDRESS_START,
  payload: address
})

export const geocodingAddressSuccess = (suggestions) => ({
  type: GEOCODING__ADDRESS_SUCCESS,
  payload: suggestions
})

export const geocodingAddressError = (err) => ({
  type: GEOCODING__ADDRESS_ERROR,
  payload: err
})

export const geocodingToggleSuggestions = () => ({
  type: GEOCODING__TOGGLE_SUGGESTIONS
})

export const geocodingOpenSuggestions = () => ({
  type: GEOCODING__OPEN_SUGGESTIONS
})

export const geocodingCloseSuggestions = () => ({
  type: GEOCODING__CLOSE_SUGGESTIONS
})