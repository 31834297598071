import chroma from 'chroma-js'
import { mapValues, get as deepGet, round } from 'lodash'

const DARK_LIGHT_LUMINANCE_TRIGGER = 0.55
const _isLight = (trigger) => (color, ...lumiArgs) => !!(chroma(color).luminance(...lumiArgs) > trigger)
const isLight = _isLight(DARK_LIGHT_LUMINANCE_TRIGGER)

const baseSize = 11
const deviceFontsSansSerif = 'BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif'

const _getInvert = (dark, light) => (color) => isLight(color) ? dark : light

const greyshades = {
  dark: '#3f3f3f',
  light: '#f9f9f9',
  semiLight: '#EAEAEA',
  white: '#ffffff',
  black: '#000000',
  grey: '#869391',
  greyDarker: '#3f3f3f',
  greySemiLight: '#999999',
  greySemiLightest: '#f0f0f0',
  greyLightest: '#ebebeb',
}

const boxshadow = {
  primary: 'rgb(0 0 0 / 14%) 0px 12px 24px -6px'
}

const colors = {
  primary: '#001556',
  primaryLighter: 'rgba(0, 21, 86, 0.06)',
  primarySemiLight: '#969EB9',
  primaryLight: '#e6ecff',
  secondary: '#ffc64c',
  secondaryDark: '#ff9d00',
  tertiary: '#F13426',
  tertiaryLight: '#F1342680',
  medium: '#BBB',
  green: '#6EB894',
  placeholder: '#C4C4C4',
  beige: '#F4F1E9',
  overOrSelected: '#00155610',
}

const derivatedColors = {
  warning: colors.secondary,
  error: colors.secondaryDark
}

const getInvert = _getInvert(greyshades.dark, greyshades.light)

export default {
  fonts: {
    primary: `Gotham, ${deviceFontsSansSerif}`,
    secondary: `"Source Sans Pro", ${deviceFontsSansSerif}`,
    workSans: `"Work Sans", ${deviceFontsSansSerif}`
  },
  sizes: {
    tiny: round(baseSize * 0.5, 2),
    small: round(baseSize * 0.75, 2),
    normal: baseSize,
    h6: round(baseSize * 1.1, 2),
    h5: round(baseSize * 1.25, 2),
    h4: round(baseSize * 1.45, 2),
    h3: round(baseSize * 1.875, 2),
    h2: round(baseSize * 2.25, 2),
    h1: round(baseSize * 2.75, 2),
  },
  fontSizes: {
    title: {
      large: 18,
      medium: 16,
      small: 18
    },
    text: {
      large: 16,
      medium: 14,
      small: 16
    },
    description: {
      large: 14,
      medium: 12,
      small: 14
    }
  },
  fontWeights: {
    primary: {
      light: 300,
      regular: 400,
      bold: 500
    }
  },
  scrollbar: {
    width: 4,
    padding: 4
  },
  breakpoints: [1025],
  screen: {
    mobile: 769,
    tablet: 1024,
    deskSmall: 1216,
    desktop: 1408
  },
  colors: {
    ...colors,
    ...greyshades,
    ...derivatedColors
  },
  boxshadow: {
    ...boxshadow
  },
  colorsInverts: {
    ...mapValues(colors, getInvert),
    ...mapValues(greyshades, getInvert),
    ...mapValues(derivatedColors, getInvert)
  },
  spinner: {
    size: {
      desktop: 50,
      mobile: 30
    }
  },
  radiuses: {
    tiny: '3px',
    small: '4px',
    normal: '8px',
    large: '16px'
  },
  layers: {
    app: 1,
    menu: 5,
    overlay: 500,
    popup: 600,
    alerts: 999
  },
  spacings: {
    small: baseSize * 0.5,
    normal: baseSize * 0.75,
    medium: baseSize * 1.5,
    large: baseSize * 3
  }
}

export const keyOfTheme = (path) => {
  return function (props, propName) {
    const obj = deepGet(props.theme, path)
    const propVal = props[propName]

    if (!propVal) {
      return
    }

    if (!(propVal in obj)) {
      throw new TypeError(
        `Property ${propName} should be a key of props.theme.${path}(${Object.keys(
          obj
        )}), ${JSON.stringify(propVal)} given`
      )
    }
  }
}

export const colorOfTheme = keyOfTheme('colors')
export const colorInvertOfTheme = keyOfTheme('colorsInverts')
export const radiusOfTheme = keyOfTheme('radiuses')
export const boxshadowOfTheme = keyOfTheme('boxshadow')
