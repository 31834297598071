import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faExclamationTriangle,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons'

import FileSpinner from '../styled/file-spinner'

import {
  maxFileNameLength,
  UploadBarWrapper,
  UploadBar,
  ProgressBar,
  FileName,
  RemoveButton
} from './styled'

import LoadingSpinner from '../loading-spinner'

const UploadsView = ({
  descriptor,
  theme,
  abort,
  hasError,
  isDone
}) => {
  const percentileWidth = descriptor.progress && descriptor.total
    ? Math.floor((descriptor.progress / descriptor.total) * 100)
    : null
  const statusIcon = hasError
    ? faExclamationTriangle
    : (isDone ? faCheck : null)

  const fileName = descriptor.name.length > maxFileNameLength
    ? `${descriptor.name.slice(0, maxFileNameLength - 3)}...`
    : descriptor.name

  const statusColor = hasError
    ? theme.colors.error
    : theme.colors.primary
  return (
    <UploadBarWrapper key={descriptor.id} progress={percentileWidth}>
      <UploadBar>
        <ProgressBar progress={percentileWidth} isError={hasError} />
      </UploadBar>
      <FileName>{fileName}</FileName>
      <FileSpinner>
        {isDone || hasError
          ? (
            <FontAwesomeIcon
              icon={statusIcon}
              size='lg'
              color={statusColor}
              fixedWidth
            />
          )
          : <LoadingSpinner size={15} />}
      </FileSpinner>
      <RemoveButton
        onClick={abort}
        disabled={percentileWidth === 100 && !hasError}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          size='lg'
          color={theme.colors.tertiary}
          fixedWidth
        />
      </RemoveButton>
    </UploadBarWrapper>
  )
}

UploadsView.propTypes = {
  descriptor: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  hasError: PropTypes.bool.isRequired,
  abort: PropTypes.func.isRequired,
  isDone: PropTypes.bool.isRequired
}

export default withTheme(UploadsView)
