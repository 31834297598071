import React, { Component } from 'react'

import buildMiddleware from './template'

const withQueryParam = (queryKey, passedOptions) => {
  const options = {
    propName: queryKey,
    optional: false,
    ...passedOptions
  }

  return (SubComponent) => {
    const WithQueryParamWrapper = buildMiddleware(
      SubComponent,
      `WithQueryParam[${options.propName || queryKey}]`
    )

    const WithQueryParamRetreive = class extends Component {
      constructor (props) {
        super(props)

        this.state = {
          [options.propName]: null,
          error: null,
          loading: true
        }
      }

      render () {
        return (
          <WithQueryParamWrapper
            {...{
              [options.propName]: this.state[options.propName],
              error: this.state.error,
              loading: this.state.loading
            }}
            {...this.props}
          />
        )
      }

      componentDidMount () {
        try {
          const queryParam = new URL(
            window.location
          )
            .searchParams
            .get(queryKey)

          if (!options.optional && !queryParam) {
            throw new Error(`${queryKey} not found in query string`)
          }

          this.setState({
            [options.propName]: queryParam,
            error: null,
            loading: false
          })
        } catch (err) {
          console.error(err)

          this.setState({
            [options.propName]: null,
            error: {
              message: 'Erreur lors de la récupération du paramètre'
            },
            loading: false
          })
        }
      }
    }

    WithQueryParamRetreive.displayName = `WithQueryParamRetreive[${queryKey}]`

    return WithQueryParamRetreive
  }
}

export default withQueryParam
