import React from 'react'
import PropTypes from 'prop-types'

import { ReactTypeformEmbed } from 'react-typeform-embed'

import { ExtendableOverlayContent } from '../container-overlay/styled'

const TypeformOverlay = props => (
  <ExtendableOverlayContent>
    <ReactTypeformEmbed
      url={props.typeformUrl}
      onSubmit={props.handleSubmit}
    />
  </ExtendableOverlayContent>
)

TypeformOverlay.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  typeformUrl: PropTypes.string.isRequired
}

export default TypeformOverlay
