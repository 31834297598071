import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { headerHeight } from '../../constants/styles'
import { mq, ratioToPercent } from '../../utils/style'
import { FlexRow } from '../styled'
import { headerHeightDesktop } from '../view-project-header/styled'

export const sidebarWrapperWidthLarge = 247
export const sidebarWrapperWidthMedium = 176

const wrapperBorderWidth = 1

export const SidebarWrapper = styled.aside`
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  z-index: 1000;
  padding: 40px 0 0 40px;
  width: ${ratioToPercent(1)};
  justify-content: center;
  position: fixed;
  overflow: hidden;

  @media screen and (min-width: 1025px) {
    flex-direction: column;
    width: ${sidebarWrapperWidthMedium}px;
    top: ${headerHeight}px;
    justify-content: space-between;
    height: calc(100vh - ${headerHeight}px);
    padding-left: 20px;
  }

  @media screen and (min-width: 1441px) {
    width: ${sidebarWrapperWidthLarge}px;
    padding-left: 40px;
  }
`

export const SideWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  ${(props) =>
    mq(props)({
      position: [null, 'relative'],
      flexDirection: [null, 'column'],
      width: ['auto', '100%']
    })}
`

const iconContainerCss = () => css`
  width: ${sidebarWrapperWidthLarge - wrapperBorderWidth}px;
  height: ${sidebarWrapperWidthLarge - wrapperBorderWidth}px !important;
  max-width: 100%;
  max-height: 100%;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SidebarLink = styled.a`
  ${iconContainerCss}
`

export const SidebarLogo = styled(SidebarLink)`
  ${(props) =>
    mq(props)({
      height: [null, headerHeightDesktop(props)]
    })}
`

export const sidebarImageCss = (props) => css`
  ${mq(props)({
  width: [`20px !important`, `14px !important`],
  height: [`20px !important`, `14px !important`]
})}
`

export const SidebarImage = styled.img`
  ${sidebarImageCss}
`

export const SidebarIcon = styled(FontAwesomeIcon)`
  ${sidebarImageCss}
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ disabled, theme }) => disabled ? theme.colors.medium : theme.colors.primary};

  &:hover {
    opacity: 0.9;
  }
`

export const SidebarButton = styled.button`
  ${iconContainerCss}
  border: none;
  background-color: transparent;
`

export const IconContainer = styled.div`
  position: relative;
  color: ${(props) => (props.active ? props.theme.colors.primary : '#8A90A1')};
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 10px 2px;
  border-radius: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;

  ${SidebarIcon} {
    color: ${(props) => props.active ? props.theme.colors.primary : '#8A90A1'};
  }
  &: hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: #E8E5DD;
    ${SidebarIcon} {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const HomeIconContainer = styled(IconContainer)`
  flex-shrink: 0;
  & > p,
  & > span {
    max-width: calc(100% - 20px);
  }
`

export const MenuText = styled.p`
  font-weight: ${({ active }) => (active ? 600 : 500)};
  font-size: 0.875em;
  line-height: normal;

  @media screen and (min-width: 1025px) {
    font-size: 0.938em;
  }

  @media screen and (min-width: 1441px) {
    font-size: 1em;
  }
`

export const NotificationIconContainer = styled.div`
  position: relative;
`

export const RedPoint = styled(SidebarIcon)`
  position: absolute;
  color: ${(props) =>
    props.disabled ? props.theme.colors.medium : props.theme.colors.tertiary};
  ${(props) =>
    mq(props)({
      top: ['22%', '22%'],
      left: ['52%', '57%'],
      width: [`10px !important`, `10px !important`],
      height: [`10px !important`, `10px !important`]
    })}
`
