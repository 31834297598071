import { faTimes } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { useDispatch } from 'react-redux'

import { closeOverlay } from '../../actions/overlay'
import CustomConfirmOverlay from '../overlay-custom-confirm'
import styled from '@emotion/styled'

const Body = styled.p`
  max-width: 424px;
  min-height: 95px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`

const NewProjectOverlay = () => {
  const dispatch = useDispatch()

  const handleCloseModal = () => {
    dispatch(closeOverlay())
  }

  return (
    <CustomConfirmOverlay
      cancelActionOnly={true}
      title="Un nouveau projet à venir ?"
      body={
        <Body>
          Super nouvelle ! Nous vous invitons à en discuter dès maintenant avec votre conseiller !
          <br />
          <br />
          Pour le contacter, composez le 09 74 48 37 61.
        </Body>
      }
      rejectText="Fermer"
      rejectIcon={faTimes}
      handleReject={handleCloseModal}
      handleConfirm={() => {}}
    />
  )
}

export default NewProjectOverlay
