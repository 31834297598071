import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Link } from 'react-router-dom'

import { mq, reverseMq } from '../../utils/style'
import { FlexColumn, baseButtonCss } from '../styled'
import { wrapperWidth } from '../view-sidebar/styled'

export const MenuWrapper = styled(FlexColumn)`
  width: auto;
  position: absolute;
  bottom: ${wrapperWidth}px;
  background-color: #FFF;
  padding: ${props => props.theme.spacings.medium}px 0;
  z-index: ${props => props.theme.layers.menu};
  ${props => mq(props)({
  left: ['20px', `${wrapperWidth}px`],
  bottom: ['10px', '0px']
})}


  ${props => reverseMq(props)({
  right: [null, '0']
})}
`

const menuItemCss = props => css`
  ${baseButtonCss(props)}
  width: 16em;
  padding: 15px 0;
  color: ${props.theme.colors.primary};
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: ${props.theme.sizes.h5}px;

  &:hover,
  &:hover > * {
    color: ${props.theme.colors.primary};
  }

  & > span {
    display: inline-block;
    margin-left: ${props.theme.spacings.small}px;
  }
`

export const MenuButton = styled('button')`
  ${menuItemCss}
  & span {
    @media (max-width: 1024px) {
      font-size: 14px;
      font-weight: 400;
      margin-left: 15px;
    }
  }
`

export const MenuLink = styled(Link)`
  ${menuItemCss}
  text-decoration: none;
  font-weight: 400;
  & span {
    @media (max-width: 1024px) {
      font-size: 14px;
      font-weight: 400;
      margin-left: 15px;
    }
  }
`
