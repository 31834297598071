import { omit } from 'lodash'
import { buildURI } from '../../../utils/api/helpers'

const sources = {
  koumoulCadaster: {
    id: 'koumoul-cadaster',
    type: 'vector',
    url: buildURI('https://koumoul.com', '/s/tileserver/data/cadastre.json').toString()
  }
}

const NO_PARCEL_ID = 'dumb'

const highlightsLayers = (plotsIds) => {
  const filter = ['==', ['get', 'id'], (plotsIds[0] || NO_PARCEL_ID)]
  return {
    cadasterParcellesHighlightFill: {
      id: 'cadaster-parcelles-highlight-fill',
      source: sources.koumoulCadaster.id,
      'source-layer': 'parcelles',
      type: 'fill',
      minzoom: 14,
      paint: {
        'fill-color': '#ffc64c',
        'fill-opacity': 0.1
      },
      filter
    },
    cadasterParcellesHighlightLinesShadow: {
      id: 'cadaster-parcelles-highlight-lines-shadow',
      source: sources.koumoulCadaster.id,
      'source-layer': 'parcelles',
      type: 'line',
      minzoom: 14,
      layout: {
        'line-cap': 'round'
      },
      paint: {
        'line-color': 'black',
        'line-blur': {
          stops: [
            [15, 3],
            [19, 10]
          ]
        },
        'line-opacity': {
          stops: [
            [14, 0],
            [16, 0.5]
          ]
        },
        'line-width': {
          stops: [
            [15, 1.5],
            [19, 7]
          ]
        }
      },
      filter
    },
    cadasterParcellesHighlightLines: {
      id: 'cadaster-parcelles-highlight-lines',
      source: sources.koumoulCadaster.id,
      'source-layer': 'parcelles',
      type: 'line',
      minzoom: 14,
      layout: {
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#ffc64c',
        'line-opacity': {
          stops: [
            [14, 0],
            [16, 0.9]
          ]
        },
        'line-width': {
          stops: [
            [15, 0.5],
            [19, 4]
          ]
        }
      },
      filter
    }
  }
}

const formattedSources = Object.values(sources).reduce((acc, source) => ({
  ...acc,
  [source.id]: omit(source, 'id')
}), {})

export {
  formattedSources as sources,
  highlightsLayers,
  NO_PARCEL_ID
}
