import { useState } from 'react'
import { useHover as useHoverGesture } from 'react-use-gesture'

const useHover = () => {
  const [isHovered, setHovered] = useState(false)

  const handleState = ({ hovering }) => {
    setHovered(hovering)
  }

  const bindGesture = useHoverGesture(handleState)

  return [isHovered, bindGesture]
}

export default useHover
