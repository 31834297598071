import {
  take,
  takeLeading,
  put,
  fork,
  delay,
  cancel,
  cancelled
} from 'redux-saga/effects'

import {
  SYNC__SESSION_START,
  SYNC__SESSION_STOP
} from '../actions'

import { startRefreshingSession } from '../actions/user'

const minimalDelay = 1000

const isValidDelay = delayMs => typeof delayMs === 'number' && delayMs >= minimalDelay

function * sessionSync () {
  try {
    const delayMs = parseInt(window.processRuntime.env.USER_SESSION_REFRESH_DELAY, 10)
    if (!isValidDelay(delayMs)) {
      console.warn(`Delay "${delayMs}"ms is not valid and has been replaced by a default 1000ms delay for session refresh`)
    } else {
      console.log(`Session sync start [${delayMs}ms]`)
    }
    while (true) {
      yield delay(isValidDelay(delayMs) ? delayMs : minimalDelay)
      yield put(startRefreshingSession())
    }
  } finally {
    if (yield cancelled()) {
      console.log('Session sync canceled')
    }
  }
}

function * startSessionSync () {
  const sync = yield fork(sessionSync)
  try {
    yield take(SYNC__SESSION_STOP)
  } finally {
    yield cancel(sync)
  }
}

export default [
  takeLeading(SYNC__SESSION_START, startSessionSync)
]
