import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startLogout } from '../../actions/user'

import LoadingView from '../view-loading'

class PageLogout extends Component {
  componentWillMount () {
    this.props.startLogout()
  }

  render () {
    return <LoadingView />
  }
}

const mapDispatchToProps = dispatch => ({
  startLogout: () => dispatch(startLogout())
})

PageLogout.propTypes = {
  startLogout: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(PageLogout)
