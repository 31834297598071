import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faExclamation,
  faCheck
} from '@fortawesome/pro-solid-svg-icons'

import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import {
  AlertWrapper,
  AlertBox,
  AlertIcon,
  AlertText,
  CloseButton
} from './styled'

const alertStatuses = {
  error: {
    icon: faExclamationTriangle,
    getColor: props => props.theme.colors.error
  },
  success: {
    icon: faCheck,
    getColor: props => props.theme.colors.primary
  }
}

const alertSchema = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.oneOf(Object.keys(alertStatuses))
}

const AlertsView = (props) => {
  const { icon, getColor } = (
    alertStatuses[props.alert.type] || {
      icon: faExclamation,
      getColor: () => props.theme.colors.primary
    }
  )

  return (
    <AlertWrapper key={props.alert.id}>
      <AlertBox>
        <AlertIcon>
          <FontAwesomeIcon
            icon={icon}
            color={getColor(props)}
            size='2x'
            fixedWidth
          />
        </AlertIcon>
        <AlertText>{props.alert.message}</AlertText>
        <CloseButton
          value={props.alert.id}
          onClick={props.removeAlert(props.alert.id)}
          disabled={props.disableRemove}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color={props.theme.colors.tertiary}
            size='lg'
            fixedWidth
          />
        </CloseButton>
      </AlertBox>
    </AlertWrapper>
  )
}

AlertsView.propTypes = {
  alert: PropTypes.shape(alertSchema).isRequired,
  theme: PropTypes.object.isRequired,
  removeAlert: PropTypes.func.isRequired,
  disableRemove: PropTypes.bool
}

export default withTheme(AlertsView)
