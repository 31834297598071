import {
  FILES__LIST_START,
  FILES__LIST_SUCCESS,
  FILES__LIST_ERROR,

  FILES__UPLOAD_START,
  FILES__UPLOAD_PROGRESS,
  FILES__UPLOAD_CANCEL,
  FILES__UPLOAD_SUCCESS,
  FILES__UPLOAD_REMOVE,
  FILES__UPLOAD_ERROR,
  FILES__UPLOAD_ERROR_CLOSE,

  FILES__DELETE_START,
  FILES__DELETE_SUCCESS,
  FILES__DELETE_ERROR,

  FILES__TEMPLATE_FOLDER_FETCH_START,
  FILES__TEMPLATE_FOLDER_FETCH_SUCCESS,
  FILES__TEMPLATE_FOLDER_FETCH_ERROR
} from '../actions'

const initialState = {
  filesList: null,
  filesListError: null,
  filesListLoading: false,
  filesListProjectId: null,

  filesToDelete: [],
  filesDeletionErrors: [],

  filesUploading: [],
  filesUploadErrors: [],

  templateFolderContents: null,
  templateFolderLoading: false,
  templateFolderError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FILES__LIST_START:
      return {
        ...state,
        filesList: null,
        filesListError: null,
        filesListLoading: true,
        filesListProjectId: action.payload
      }

    case FILES__LIST_SUCCESS:
      return {
        ...state,
        filesList: action.payload,
        filesListLoading: false
      }

    case FILES__LIST_ERROR:
      return {
        ...state,
        filesListLoading: false,
        filesListError: action.payload,
        filesListProjectId: null
      }

    case FILES__UPLOAD_START:
      return {
        ...state,
        filesUploading: state.filesUploading.concat(
          action.payload
            .map(obj => ({
              ...obj.descriptor,
              progress: 0,
              total: null,
              completed: false
            }))
            .filter(desc => !state.filesUploading.includes(desc.id))
        )
      }

    case FILES__UPLOAD_PROGRESS:
      return {
        ...state,
        filesUploading: (
          state.filesUploading
            .map(obj => obj.id === action.payload.id
              ? {
                ...obj,
                progress: action.payload.progress,
                total: action.payload.total
              }
              : obj
            )
        )
      }

    case FILES__UPLOAD_ERROR_CLOSE:
      return {
        ...state,
        filesUploadErrors: (
          state.filesUploadErrors
            .filter(f => f.fileId !== action.payload)
        )
      }

    case FILES__UPLOAD_SUCCESS:
      return {
        ...state,
        filesList: (
          state.filesList
            .concat([action.payload.file])
        ),
        filesUploading: (
          state.filesUploading
            .map(desc => desc.id === action.payload.id
              ? {
                ...desc,
                completed: true
              }
              : desc
            )
        )
      }

    case FILES__UPLOAD_CANCEL:
      return {
        ...state,
        filesUploading: (
          state.filesUploading
            .map(desc => desc.id === action.payload
              ? {
                ...desc,
                completed: true
              }
              : desc
            )
        )
      }

    case FILES__UPLOAD_REMOVE:
      return {
        ...state,
        filesUploading: (
          state.filesUploading
            .filter(f => f.id !== action.payload)
        )
      }

    case FILES__UPLOAD_ERROR:
      return {
        ...state,
        filesUploadErrors: (
          state.filesUploadErrors
            .filter(f => f.id !== action.payload.id)
            .concat([action.payload])
        )
      }

    case FILES__DELETE_START:
      return state.filesToDelete.includes(action.payload)
        ? state
        : {
          ...state,
          filesToDelete: (
            state.filesToDelete.concat([action.payload])
          ),
          filesDeletionErrors: (
            state.filesDeletionErrors
              .filter(e => e.fileId !== action.payload.fileId)
              .concat([action.payload])
          )
        }

    case FILES__DELETE_SUCCESS:
      return {
        ...state,
        filesToDelete: (
          state.filesToDelete.filter(fileId => fileId !== action.payload)
        ),
        filesList: state.filesList.filter(f => f.id !== action.payload)
      }

    case FILES__DELETE_ERROR:
      return {
        ...state,
        filesToDelete: (
          state.filesToDelete.filter(fileId => fileId !== action.payload.fileId)
        ),
        filesDeletionErrors: (
          state.filesDeletionErrors
            .filter(e => e.fileId !== action.payload.fileId)
            .concat([action.payload])
        )
      }

    case FILES__TEMPLATE_FOLDER_FETCH_START:
      return {
        ...state,
        templateFolderContents: null,
        templateFolderLoading: true,
        templateFolderError: null
      }

    case FILES__TEMPLATE_FOLDER_FETCH_SUCCESS:
      return {
        ...state,
        templateFolderContents: action.payload,
        templateFolderLoading: false
      }

    case FILES__TEMPLATE_FOLDER_FETCH_ERROR:
      return {
        ...state,
        templateFolderLoading: false,
        templateFolderError: action.payload
      }

    default:
      return state
  }
}
