import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BaseButton } from '../styled'

export const ErrorMessage = styled('strong')`
  color: ${props => props.theme.colors.error};
  text-align: center;
`

export const actionButtonCss = props => css`
  display: inline-block;
  background-color: transparent;
  color: ${props.theme.colors.tertiary};
  font-family: ${props.theme.fonts.workSans};
  font-size: 12px;
  font-weight: 500;
  border: none;
  padding: 0;
  margin-top: ${props.theme.spacings.normal}px;
  line-height: 1em;
  text-align: left;
`

export const ActionButton = styled(BaseButton)`
  ${actionButtonCss}
  text-decoration: underline;
`

export const ActionDone = styled('span')`
  ${actionButtonCss}
  color: ${props => props.theme.colors.primary};
`

export const ActionLink = styled('a')`
  ${actionButtonCss}
  text-decoration: underline;
  max-width: fit-content;
`

export const ActionIcon = styled(FontAwesomeIcon)`
  margin-right: ${props => props.theme.spacings.small}px;
`
