import React from 'react'
import PropTypes from 'prop-types'

import NotificationsView from '../view-notifications'

import { ActionLink } from '../container-notifications/styled'

const notifications = [
  {
    id: 'fakeNotificationId1',
    createdAt: new Date(Date.now()).toISOString(),
    title: 'Décrivez votre projet',
    isRead: false,
    bypassReadable: true,
    description:
      "Décrivez maintenant votre projet pour pouvoir bénéficier de toutes les fonctionnalités de votre tableau de bord et d'un suivi personnalisé.",
    action: (
      <ActionLink href={window.processRuntime.env.TUNNEL_URL}>
        Décrire mon projet
      </ActionLink>
    ),
  },
]

const FakeNotificationsContainer = (props) => (
  <NotificationsView notifications={props.forActions ? notifications : []} />
)

FakeNotificationsContainer.propTypes = {
  forActions: PropTypes.bool,
}

export default FakeNotificationsContainer
