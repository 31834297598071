import axios from 'axios'

import { buildURI } from './helpers'

const baseURL = 'https://koumoul.com'

const Client = axios.create({
  transformRequest: [
    (data, headers) => {
      delete headers['content-type']
      return JSON.stringify(data)
    }
  ]
})

const request = (path, options = {}, qsParams = {}) => (
  Client.request({
    headers: { 'x-taxman-key': window.processRuntime.env.KOUMOUL_KEY },
    url: buildURI(baseURL, path, qsParams).toString(),
    ...options
  })
)

const get = (path) => request(path, { method: 'get' })
const post = (path, data) => request(path, { method: 'post', data })

export default {
  get,
  post
}
