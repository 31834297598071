import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'

import { FlexColumn } from '../styled'

const ErrorViewport = styled(FlexColumn)`
  padding: ${props => props.theme.spacings.medium}px;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.sizes.h3}px;
  color: ${props => props.theme.colors.error};
  text-align: center;
`

const ErrorPageContainer = ({ error, ...restProps }) => (
  <WithThemeErrorPageView
    message={(error && error.message) || ErrorPageContainer.defaultErrorMessage}
    {...restProps}
  />
)
ErrorPageContainer.defaultErrorMessage = 'Erreur'

ErrorPageContainer.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }).isRequired
}

const ErrorPageView = ({ message, theme }) => (
  <ErrorViewport>
    <FontAwesomeIcon
      icon={faExclamationTriangle}
      color={theme.colors.error}
    />
    <br />
    {message}
  </ErrorViewport>
)

ErrorPageView.propTypes = {
  message: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}

export const ErrorMessageSmall = styled.p`
  font-size: ${props => props.theme.sizes.normal}px;
  color: ${props => props.theme.colors.error};
  font-weight: 500;
`;

const WithThemeErrorPageView = withTheme(ErrorPageView)

export default ErrorPageContainer
