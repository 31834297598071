import { property } from 'lodash'

export const componentsTree = (tree) =>
  (tree.find((item) => item.cond) || { render: () => null }).render()

// lodash.sortBy compliant
export const FILE_SORTBY_CRITERIAS = [
  property('index'),
  property('isFolder'),
  property('canWrite'),
  property('name'),
]

export const FILE_SORTBY_ORDERS = ['asc', 'desc', 'desc', 'asc']

export const SUB_FILE_SORTBY_CRITERIAS = [
  property('createdAt'),
  property('canWrite'),
  property('name'),
]

export const SUB_FILE_SORTBY_ORDERS = ['desc', 'desc', 'asc']

export const compareFiles = (f1, f2) =>
  f1.isFolder
    ? f2.isFolder
      ? f1.name > f2.name
        ? 1
        : -1
      : -1
    : f2.isFolder
    ? 1
    : f1.name > f2.name
    ? 1
    : -1
