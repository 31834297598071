import facepaint from 'facepaint'

const sortBreakpoints = (bp1, bp2) => {
  return bp1 < bp2 ? -1 : 1
}

// Convert ratio to CSS percentile value
export const ratioToPercent = value => typeof value === 'number'
  ? `${value * 100}%`
  : value

// Return media query basis for Facepaint
export const mq = props => facepaint(
  props.theme.breakpoints
    .sort(sortBreakpoints)
    .map(bp => `@media screen and (min-width: ${bp}px)`)
)

export const reverseMq = props => facepaint(
  props.theme.breakpoints
    .sort(sortBreakpoints)
    .reverse()
    .map(bp => `@media screen and (max-width: ${bp - 1}px)`)
)
