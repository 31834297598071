const compareIndexes = op => (nx, ny, nxy) => (x, y) => (
  typeof x === 'number'
    ? (typeof y === 'number' ? op(x, y) : ny)
    : (typeof y === 'number' ? nx : nxy)
)

const _sup = compareIndexes((x, y) => x > y)(false, true, false)
const _eq = compareIndexes((x, y) => x === y)(false, false, true)

export const compareSteps = (left, right) => [{
  cond: (
    _sup(left.groupIndex, right.groupIndex) || (
      _eq(left.groupIndex, right.groupIndex) &&
      _sup(left.indexInGroup, right.indexInGroup)
    )
  ),
  val: 1
}, {
  cond: (
    _eq(left.groupIndex, right.groupIndex) &&
    _eq(left.indexInGroup, right.indexInGroup)
  ),
  val: 0
}, {
  cond: true,
  val: -1
}].find(x => x.cond).val
