import project from './project'
import user from './user'
import step from './step'
import notification from './notification'
import action from './action'
import alert from './alert'
import overlay from './overlay'
import files from './files'
import draft from './draft'
import menus from './menus'
import geocodingAddress from './geocoding-address'
import createProjectForm from './create-project-form'

export default {
  project,
  user,
  step,
  notification,
  action,
  alert,
  overlay,
  files,
  draft,
  menus,
  geocodingAddress,
  createProjectForm
}
