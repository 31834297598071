import {
  USER__CHECK_SESSION_START,
  USER__CHECK_SESSION_SUCCESS,
  USER__CHECK_SESSION_ERROR,
  USER__REFRESH_SESSION_START,

  USER__LOGIN_START,
  USER__LOGIN_SUCCESS,
  USER__LOGIN_ERROR,

  USER__LOGOUT_START,
  USER__LOGOUT_SUCCESS,
  USER__LOGOUT_ERROR,

  USER__FORCE_LOGIN,

  USER__FETCH_BY_ACCOUNT_START,
  USER__FETCH_BY_ACCOUNT_SUCCESS,
  USER__FETCH_BY_ACCOUNT_ERROR,

  USER__CONFIRMATION_TOKEN_RETRIEVE_START,
  USER__CONFIRMATION_TOKEN_RETRIEVE_SUCCESS,
  USER__CONFIRMATION_TOKEN_RETRIEVE_ERROR,

  USER__ACCOUNT_CONFIRMATION_START,
  USER__ACCOUNT_CONFIRMATION_SUCCESS,
  USER__ACCOUNT_CONFIRMATION_ERROR,

  USER__ACCOUNT_LINK_START,
  USER__ACCOUNT_LINK_SUCCESS,
  USER__ACCOUNT_LINK_ERROR,

  USER__CHECK_ACCOUNT_LINKS_START,
  USER__CHECK_ACCOUNT_LINKS_SUCCESS,
  USER__CHECK_ACCOUNT_LINKS_ERROR,

  USER__CREATE_EMAIL_PASSWORD_ACCOUNT_START,
  USER__CREATE_EMAIL_PASSWORD_ACCOUNT_SUCCESS,
  USER__CREATE_EMAIL_PASSWORD_ACCOUNT_ERROR,

  USER__CHANGE_PASSWORD_START,
  USER__CHANGE_PASSWORD_SUCCESS,
  USER__CHANGE_PASSWORD_ERROR,

  USER__UPDATE_CONNECTED_AT,

  ENABLE_HUBSPOT_CHAT
} from '.'

export const startCheckingSession = () => ({
  type: USER__CHECK_SESSION_START
})

export const checkSessionSuccess = hash => ({
  type: USER__CHECK_SESSION_SUCCESS,
  payload: hash
})

export const checkSessionError = error => ({
  type: USER__CHECK_SESSION_ERROR,
  payload: error
})

export const startRefreshingSession = () => ({
  type: USER__REFRESH_SESSION_START
})

export const startLogin = authOptions => ({
  type: USER__LOGIN_START,
  payload: authOptions
})

export const loginSuccess = hash => ({
  type: USER__LOGIN_SUCCESS,
  payload: hash
})

export const loginError = error => ({
  type: USER__LOGIN_ERROR,
  payload: error
})

export const startLogout = () => ({
  type: USER__LOGOUT_START
})

export const logoutSuccess = () => ({
  type: USER__LOGOUT_SUCCESS
})

export const logoutError = error => ({
  type: USER__LOGOUT_ERROR,
  payload: error
})

export const forceLogin = () => ({
  type: USER__FORCE_LOGIN
})

export const startFetchingUserByAccount = () => ({
  type: USER__FETCH_BY_ACCOUNT_START
})

export const fetchUserByAccountSuccess = user => ({
  type: USER__FETCH_BY_ACCOUNT_SUCCESS,
  payload: user
})

export const fetchUserByAccountError = error => ({
  type: USER__FETCH_BY_ACCOUNT_ERROR,
  payload: error
})

export const startRetreivingConfirmationToken = () => ({
  type: USER__CONFIRMATION_TOKEN_RETRIEVE_START
})

export const confirmationTokenRetrieveSuccess = confirmationToken => ({
  type: USER__CONFIRMATION_TOKEN_RETRIEVE_SUCCESS,
  payload: confirmationToken
})

export const confirmationTokenRetrieveError = error => ({
  type: USER__CONFIRMATION_TOKEN_RETRIEVE_ERROR,
  payload: error
})

export const startAccountConfirmation = () => ({
  type: USER__ACCOUNT_CONFIRMATION_START
})

export const accountConfirmationSuccess = projectId => ({
  type: USER__ACCOUNT_CONFIRMATION_SUCCESS,
  payload: projectId
})

export const accountConfirmationError = error => ({
  type: USER__ACCOUNT_CONFIRMATION_ERROR,
  payload: error
})

export const startLinkingAccount = () => ({
  type: USER__ACCOUNT_LINK_START
})

export const accountLinkingSuccess = () => ({
  type: USER__ACCOUNT_LINK_SUCCESS
})

export const accountLinkingError = error => ({
  type: USER__ACCOUNT_LINK_ERROR,
  payload: error
})

export const startCheckingAccountLinks = () => ({
  type: USER__CHECK_ACCOUNT_LINKS_START
})

export const checkAccountLinksSuccess = result => ({
  type: USER__CHECK_ACCOUNT_LINKS_SUCCESS,
  payload: result
})

export const checkAccountLinksError = error => ({
  type: USER__CHECK_ACCOUNT_LINKS_ERROR,
  payload: error
})

export const startCreatingEmailPasswordAccount = password => ({
  type: USER__CREATE_EMAIL_PASSWORD_ACCOUNT_START,
  payload: password
})

export const createEmailPasswordAccountSuccess = () => ({
  type: USER__CREATE_EMAIL_PASSWORD_ACCOUNT_SUCCESS
})

export const createEmailPasswordAccountError = error => ({
  type: USER__CREATE_EMAIL_PASSWORD_ACCOUNT_ERROR,
  payload: error
})

export const updateConnectedAt = () => ({
  type: USER__UPDATE_CONNECTED_AT
})

export const startChangingPassword = password => ({
  type: USER__CHANGE_PASSWORD_START,
  payload: password
})

export const passwordChangeSuccess = () => ({
  type: USER__CHANGE_PASSWORD_SUCCESS
})

export const passwordChangeError = error => ({
  type: USER__CHANGE_PASSWORD_ERROR,
  payload: error
})

export const enableHubspotChat = () => ({
  type: ENABLE_HUBSPOT_CHAT,
})