import { FORM2, FORM3 } from "./typeform"

// API-related constants
export const APIACTIONS = {
  MANDATE_SIGN: 'mandateSign',
  FORM_ON_BOARDING: 'formOnboarding',
  FORM_ON_BOARDING_BTOB: 'formOnboardingBtoB',
  FORM_ON_BOARDING_DESK: 'formOnboardingDesk',
  CASE_V1_FEEDBACK: 'caseV1Feedback',
  CASE_V2_FEEDBACK: 'caseV2Feedback',
  CASE_V3_FEEDBACK: 'caseV3Feedback',
  MAYOR_REQUEST_V1_FEEDBACK: 'mayorRequestV1Feedback',
  MAYOR_REQUEST_V2_FEEDBACK: 'mayorRequestV2Feedback',
  CASE_SENT: 'caseSent',
  CASE_RECEIVED: 'caseReceived',
  CASE_UNDER_INVESTIGATION: 'caseUnderInvestigation',
  MAYOR_REQUEST_UPLOAD: 'mayorRequestUpload',
  MAYOR_REQUEST_UPLOAD_DESK: 'mayorRequestUploadDesk',
  MAYOR_REQUEST_SENT: 'mayorRequestSent',
  MAYOR_REQUEST_RECEIVED: 'mayorRequestReceived',
  REFUSAL_ANALYSIS: 'refusalAnalysis',
  PANEL_RECEIVED: 'panelReceived',
  PANELSET: 'panelSet',
  WAITING_PAYMENT: 'waitingPayment',
  ADVICE_CALL_MAYOR_REQUEST: 'adviceCallMayorRequest',
  ADVICE_CALL_MAYOR_REQUEST_BTOB: 'adviceCallMayorRequestBtoB',
  REFUSAL_CALL: 'refusalCall'
}

export const OVERLAYS = {
  IFRAME: 'iframe',
  IMAGE: 'image',
  PDF: 'pdf',
  TYPEFORM: 'typeform',
  CONFIRMATION: {
    [APIACTIONS.PANELSET]: 'confirmation-install-pannel'
  },
  CHANGEPASSWORD: 'change-password',
  SELECTPROJECTSLIST: 'select-projects-list',
  CREATE_PROJECT: 'create-project-overlay',
  PROJECT_ADDITIONAL_iNFO: 'project-additional-info',
  ACCOUNT_ACTIVATION: 'account-activation',
  TERREAL_ACCOUNT_ACTIVATION: 'terreal-account-activation',
  NEW_PROJECT: 'newproject-activation'
}

export const AUTH0_AVAILABLE_LANGUAGES = [
  'af',
  'ar',
  'bg',
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fa',
  'fi',
  'fr',
  'he',
  'hr',
  'hu',
  'id',
  'it',
  'ja',
  'ko',
  'lt',
  'lv',
  'ms',
  'nb',
  'nl',
  'nn',
  'no',
  'pl',
  'pt-br',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sr',
  'sv',
  'tr',
  'ua',
  'uk',
  'vi',
  'zh-tw',
  'zh'
]

export const TYPEFORM = {
  WORKTYPESFORM2: FORM2,
  WORKTYPESFORM3: FORM3,
}

export const TYPES_DECLARANTS = {
  PARTICULIER: 'Particulier',
  SOCIETE: 'Société'
}

export const TYPES_CLIENTS = {
  BTOC: 'client_BtoC',
  BTOBTOC: 'client_BtoBtoC',
  BTOB: 'client_BtoB',
  DESK: 'client_DESK'
}

export const TYPES_DEAL = {
  BTOB: 'BtoB',
  BTOC: 'BtoC',
  BTOBTOC: 'BtoBtoC',
  ABONNEMENT: 'Abonnement',
  DESK: 'Desk'
}

export const TYPES_OFFRE = {
  STORE: 'store',
  CMI: 'cmi',
  PRO_CUSTOM: 'pro_custom',
  PRO_SILVER: 'pro_silver',
  PRO_GOLD: 'pro_gold',
  DESK: 'desk',
  PARTICULIER: 'particulier',
  APPORTEUR_AFFAIRE: 'apporteur_affaire'
}

export const EMOJI_CLASS = '__class__emoji'

export const BTOB_CUSTOMERS = {
  hexaom: "Hexaom",
  getir: "Getir",
  esm: "Esm",
  greenkub: "Greenkub",
  patrimoniaconcept: "Patrimonia Concept",
  romaineudeline: "Romain Eudeline",
  sco2bois: "Sco2bois",
  facadeduroy: "Facade du Roy",
  proactif: "Pro Actif",
  artfacadeenvironnement: "Art Facade Environnement",
  loeilk: "Loeilk",
  pjcharpente: "PJ Charpente",
  yvelinestradition: "Yvelines Tradition",
  e2vetancheite: "E2V Etancheite",
  ingenuus: "Ingenuus",
  piscinessudevasion: "Piscines Sud Evasion",
  maisonseco: "Maisons Eco",
  pactiso: "Pactiso",
  bhenergy: "BH Energy",
  immofix: "Immofix",
  lilletravauxdeco: "Lille Travaux Deco",
  extensionbois: "Extension Bois",
  pickaflat: "Pick a Flat",
  yourtefrancaise: "La Yourte Francaise",
  ingenieurconseil: "Ingenieur Conseil BTP",
  quadrapol: "Quadrapol",
  aunisconstruction: "Aunis Construction",
  oscaropower: "Oscaro Power",
  palmako: "Palmako",
  totalenergie: "Total Energie",
  ilyos: "Ilyos",
  effy: "Effy",
  evisions: "E-visions",
  signarama: "Signarama",
  ximiti: "Ximiti",
  pano: "Pano",
  cajoo: "Cajoo",
  edfenr: "Edf Enr",
  terreal: "Terreal",
  smmaisons: "SM Maisons",
  anavi: "Anavi",
  greenadour: "Green Adour"
}

export const linkTypes = {
  SIGNATURE_PROCEDURE: 'signature-procedure-link',
  TYPEFORM: 'typeform-link',
  CONFIGURE_PROJECT: 'configure-project-link',
  CONFIRM_PAYMENT: 'confirm-payment-link',
  BACK_TO_QUOTE: 'back-to-quote-link'
}
