import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { ExtendableOverlayContent } from '../container-overlay/styled'

const Iframe = styled('iframe')`
`

const IframeOverlay = props => (
  <ExtendableOverlayContent>
    <Iframe src={props.src} width='100%' height='100%' />
  </ExtendableOverlayContent>
)

IframeOverlay.propTypes = {
  src: PropTypes.string.isRequired
}

export default IframeOverlay
