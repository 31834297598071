import { FORM__CHANGE_CREATE_PROJECT_INPUT } from '../actions'

const initialState = {
  address: '',
  projectTypesCodes: [],
  typeClient: '',
  projectname: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FORM__CHANGE_CREATE_PROJECT_INPUT: {
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    }

    default:
      return state
  }
}
