import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { projectWorkTypesSelector } from '../../selectors'
import DetailsView from '../view-details'

const optional = (obj) => (obj ? [obj] : [])

class DetailsContainer extends Component {
  render() {
    const details = [
      {
        id: 1,
        title: 'Nom du dossier',
        content:
          this.props.project.name ||
          this.props.project.projectname ||
          this.props.project.dealname
      },
      {
        id: 2,
        title: 'Localisation',
        content: this.props.project.address
      },
      {
        id: 3,
        title: 'Types de projets',
        content: this.props.projectWorkTypes
          .map((worktype) => worktype.name[0].toUpperCase() + worktype.name.slice(1))
          .join(', ')
      },
      ...optional({
        id: 4,
        title: 'Numéro de dossier',
        content: this.props.project.numeroInstruction
      })
    ]

    return <DetailsView details={details} />
  }
}

DetailsContainer.propTypes = {
  project: PropTypes.object,
  projectWorkTypes: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
  projectWorkTypes: projectWorkTypesSelector(state)
})

export default connect(mapStateToProps, null)(DetailsContainer)
