import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import {
  startDeletingFile,
  startFilesListing,
  startUploadingFiles
} from '../../actions/files'
import { setImageOverlay, setPdfOverlay } from '../../actions/overlay'
import { userSelector } from '../../selectors'
import {
  filesListSelector,
  filesSelector,
  filesUploadingSelector,
  loadingFilesIdsSelector,
  rootFilesSelector
} from '../../selectors/file'
import LoadingSpinner from '../loading-spinner'
import PageError from '../page-error'
import FilesView from '../view-files'
import { LoadingOrErrorContainer } from './styled'

const FilesContainer = (props) => {
  const dispatch = useDispatch()

  const {
    params: { id: currentProjectId }
  } = useRouteMatch()

  const { accessToken } = useSelector(userSelector)

  const { filesListLoading, filesListError } = useSelector(filesSelector)

  const files = useSelector(filesListSelector)
  const rootFiles = useSelector(rootFilesSelector)
  const loadingFilesIds = useSelector(loadingFilesIdsSelector)
  const uploadingFilesDescriptors = useSelector(filesUploadingSelector)

  const getFileDownloadLink = useCallback(
    (fileId) => {
      const apiBaseUrl = window.processRuntime.env.API_URL
      return `${apiBaseUrl}/v1/files/${fileId}?authKey=${accessToken}`
    },
    [accessToken]
  )

  const previewFile = (fileId, mimeType) => {
    if (mimeType.match(/image\/.*/)) {
      dispatch(setImageOverlay(getFileDownloadLink(fileId)))
    } else if (mimeType.match(/.*\/pdf/)) {
      dispatch(setPdfOverlay(getFileDownloadLink(fileId)))
    }
  }

  const _startDeletingFile = (fileId) => dispatch(startDeletingFile(fileId))
  const _startUploadingFiles = (files) => dispatch(startUploadingFiles(files))

  React.useEffect(() => {
    if (!filesListError && currentProjectId) {
      dispatch(startFilesListing(currentProjectId))
    }
  }, [filesListError, currentProjectId])

  if (filesListLoading) {
    return (
      <LoadingOrErrorContainer>
        <LoadingSpinner />
      </LoadingOrErrorContainer>
    )
  }

  if (filesListError) {
    return (
      <LoadingOrErrorContainer>
        <PageError error={filesListError} />
      </LoadingOrErrorContainer>
    )
  }

  return files ? (
    <FilesView
      files={files}
      rootFiles={rootFiles}
      loadingFilesIds={loadingFilesIds}
      deleteFile={_startDeletingFile}
      handleFileUpload={_startUploadingFiles}
      getFileDownloadLink={getFileDownloadLink}
      previewFile={previewFile}
      uploadingFilesDescriptors={uploadingFilesDescriptors}
      className={props.className}
      accessToken={accessToken}
    />
  ) : null
}

export default FilesContainer
