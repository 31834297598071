import { faCheckCircle, faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React from 'react'
import { Case, Default, Switch } from 'react-if'
import useHover from '../../hooks/use-hover'
import { HomeBlock, HomeBlockContent, HomeBlockTitle } from '../styled'
import {
  NotificationBlock,
  NotificationContentBlock,
  NotificationDescription,
  NotificationIcon as BaseNotificationIcon,
  NotificationIconButton,
} from './styled'

export const NotificationIcon = withTheme(
  ({ theme, notification, onClick, ...restProps }) => {
    const [isNotificationHovered, bindNotificationHover] = useHover()
    const isNotificationReadable = !notification.isRead && !notification.bypassReadable

    return (
      <NotificationIconButton
        {...(isNotificationReadable && !notification.action
          ? bindNotificationHover()
          : {})}
        isReadable={isNotificationReadable}
        isHovered={isNotificationHovered}
        onClick={onClick}
        notification={notification}
        {...restProps}
      >
        <Switch>
          <Case
            condition={() =>
              notification.isReading ||
              (notification.isRead && notification.wasJustRead)
            }
          >
            {() => (
              <BaseNotificationIcon
                icon={faCheckCircle}
                color={theme.colors.primary}
                size="2x"
                transform="shrink-4 up-1"
                notification={notification}
              />
            )}
          </Case>

          <Case condition={() => notification.isRead}>
            {() => (
              <BaseNotificationIcon
                icon={faCheckCircle}
                color={theme.colors.medium}
                size="2x"
                transform="shrink-4 up-1"
                notification={notification}
              />
            )}
          </Case>

          <Case
            condition={() => isNotificationReadable && isNotificationHovered}
          >
            {() => (
              <BaseNotificationIcon
                icon={faCheckCircle}
                color={theme.colors.primary}
                size="2x"
                transform="shrink-4 up-1"
                notification={notification}
              />
            )}
          </Case>

          <Default>
            {() => (
              <BaseNotificationIcon
                icon={faInfoCircle}
                color={theme.colors.tertiary}
                size="2x"
                transform="shrink-4 up-1"
                notification={notification}
              />
            )}
          </Default>
        </Switch>
      </NotificationIconButton>
    )
  }
)

const Notification = withTheme(({ notification, onReadRequested }) => {
  return (
    <NotificationBlock notification={notification}>
      <NotificationIcon notification={notification} onClick={onReadRequested} />
      <NotificationContentBlock>
        <NotificationDescription notification={notification}>
          {notification.meta.text}
        </NotificationDescription>
      </NotificationContentBlock>
    </NotificationBlock>
  )
})

const notificationSchema = {
  title: PropTypes.string,
  isRead: PropTypes.boolean,
  meta: PropTypes.object,
}

Notification.propTypes = {
  notification: PropTypes.shape(notificationSchema).isRequired,
  onReadRequested: PropTypes.func,
}

const NotificationsView = ({ notifications, onReadRequested }) => {
  return (
    <HomeBlock>
      <HomeBlockTitle>Mes notifications</HomeBlockTitle>
      <HomeBlockContent>
        {notifications.map((notification, index) => (
          <Notification
            key={`${index}-${notification.id}`}
            notification={notification}
            onReadRequested={
              onReadRequested && onReadRequested.bind(null, notification._id)
            }
          />
        ))}
      </HomeBlockContent>
    </HomeBlock>
  )
}

NotificationsView.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape(notificationSchema)),
  onReadRequested: PropTypes.func,
}

export default NotificationsView
