import styled from '@emotion/styled'

import { FlexRow, BaseButton } from '../styled'

export const AlertWrapper = styled('div')`
  width: 100%;
  display: none;
  padding: ${props => props.theme.spacings.small}px;
`

export const AlertBox = styled(FlexRow)`
  width: 100%;
  max-width: 100%;
  padding: ${props => props.theme.spacings.normal}px;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.radiuses.small};
  background-color: #FFF;
  align-items: center;
  justify-content: space-between;
`

export const AlertIcon = styled('div')`
  margin-left: ${props => props.theme.spacings.small}px;
`

export const AlertText = styled('p')`
  
  padding: 0 ${props => props.theme.spacings.small}px 0 0;
`

export const CloseButton = styled(BaseButton)`
  flex-shrink: 0;
  padding: 0;
  background-color: transparent;
  border: none;
`
