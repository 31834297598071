import PropTypes from 'prop-types'
import React, { Fragment, useRef } from 'react'
import { useSelector } from 'react-redux'
import { isAlgarDeskSelector, isBtoBSelector } from '../../selectors'
import { compareSteps } from '../../utils/step'
import {
  Point,
  SmallPoint,
  SmallStep,
  Step,
  StepName,
  StepSpacer,
  stepStatus,
  StepsWrapper
} from './styled'

const statusOrderMap = (comparison) =>
  comparison
    ? comparison < 0
      ? stepStatus.ACTIVE
      : stepStatus.INACTIVE
    : stepStatus.CURRENT

const StepContainer = ({ step, status, isLast, lastActive }) => {
  const StepComponent = step.isMilestone ? Step : SmallStep
  let currentStepRef = useRef()

  // TODO : Chek it
  // What is lastActive ? It causes a problem with swiper
  // currentStepRef is conditioned by lastActive

  // useEffect(() => {
  //   if (currentStepRef.current) {
  //     currentStepRef.current.scrollIntoView({
  //       behavior: 'auto',
  //       inline: 'center'
  //     })
  //   }
  // }, [])

  return (
    <Fragment>
      <StepComponent
        status={status}
        isMilestone={step.isMilestone}
        ref={lastActive ? currentStepRef : null}
      >
        {step.isMilestone ? (
          <Point status={status} isMilestone={step.isMilestone} />
        ) : (
          <SmallPoint status={status} isMilestone={step.isMilestone} />
        )}
        <StepName status={status} isMilestone={step.isMilestone}>
          {step.name}
        </StepName>
      </StepComponent>
      {!isLast && <StepSpacer status={status} />}
    </Fragment>
  )
}

const stepPropTypeShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired
}

StepContainer.propTypes = {
  step: PropTypes.shape(stepPropTypeShape).isRequired,
  status: PropTypes.oneOf(Object.values(stepStatus))
}

const ViewSteps = ({ steps, currentStep }) => {
  const isBtoB = useSelector(isBtoBSelector)
  const isDesk = useSelector(isAlgarDeskSelector)

  return (
    //Check if the loaded steps are for btob or not
    <StepsWrapper btobStepper={isBtoB} deskStepper={isDesk}>
      {steps.map((step, idx) => {
        const status = currentStep
          ? step?.code === '65'
            ? stepStatus.DANGER
            : statusOrderMap(compareSteps(step, currentStep))
          : stepStatus.INACTIVE

        return (
          <StepContainer
            key={step.id}
            status={status}
            step={step}
            isLast={idx + 1 >= steps.length}
            lastActive={status === 'current'}
          />
        )
      })}
    </StepsWrapper>
  )
}

ViewSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape(stepPropTypeShape).isRequired)
    .isRequired,
  currentStep: PropTypes.shape(stepPropTypeShape)
}

export default ViewSteps
