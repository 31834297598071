import React, { Component } from 'react'

import FakeHeaderContainer from '../container-fake-header'
import DraftMapContainer from '../container-draft-map'
import FakeDetailsContainer from '../container-fake-details'
import DraftNotificationsContainer from '../container-draft-notifications'
import FakeFilesContainer from '../container-fake-files'
import DraftStepsContainer from '../container-draft-steps'

import {
  ViewProject,
  ProjectBody,
  BottomBlock,
  StepsBlock,
  StatusBlock,
  MapBlock,
  NotificationsBlock,
  FileBlock
} from '../page-project/styled'

class PageDraftProject extends Component {
  render () {
    return (
      <ViewProject>
        <FakeHeaderContainer />
        <ProjectBody>
          <BottomBlock>
            <MapBlock>
              <DraftMapContainer />
            </MapBlock>

            <StatusBlock>
              <FakeDetailsContainer />
            </StatusBlock>

            <DossierBlock>
              <DraftNotificationsContainer />
            </DossierBlock>

            <FileBlock>
              <FakeFilesContainer />
            </FileBlock>
          </BottomBlock>

          <StepsBlock>
            <DraftStepsContainer />
          </StepsBlock>
        </ProjectBody>
      </ViewProject>
    )
  }
}

export default PageDraftProject
