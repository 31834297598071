import { upperFirst } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { userProfileSelector, worktypesSelector } from '../../selectors'

const compare = (a, b) => {
  if (a.label < b.label) return -1
  if (a.label > b.label) return 1
  return 0
}

export const useFormattedWorkTypes = (props) => {
  const workTypes = useSelector(worktypesSelector)
  const [formattedWorkTypes, setFormattedWorkTypes] = React.useState([])
  const { contactWorktypes } = useSelector(userProfileSelector)
  const { isBtoB, isDesk } = props

  React.useEffect(() => {
    if (workTypes) {
      let formattedWorkTypes = Object.values(workTypes)
        .map((wt) => ({
          value: wt.tunnelCode,
          label: upperFirst(wt.name),
        }))
        .filter((wt) => wt.value !== 'other')
        .sort(compare)

      if (isBtoB && !isDesk) {
        if (contactWorktypes) {
          formattedWorkTypes = formattedWorkTypes.filter((wt) =>
            contactWorktypes.includes(wt.value)
          )
        }
      }

      setFormattedWorkTypes(formattedWorkTypes)
    }
  }, [workTypes, props, contactWorktypes])

  return formattedWorkTypes
}
