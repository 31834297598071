import { WebAuth, Authentication } from 'auth0-js'

const AuthClient = new WebAuth({
  clientID: window.processRuntime.env.AUTH0_CLIENT_ID,
  domain: window.processRuntime.env.AUTH0_DOMAIN,
  audience: window.processRuntime.env.AUTH0_AUDIENCE,
  responseType: 'token',
  redirectUri: new URL(window.location.href).origin,
  scope: 'openid profile email'
})

export const HelperClient = new Authentication({
  domain: window.processRuntime.env.AUTH0_DOMAIN,
  clientID: window.processRuntime.env.AUTH0_CLIENT_ID
})

export const parseHash = () => (
  new Promise((resolve, reject) => {
    AuthClient.parseHash((err, res) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
)

export const checkSession = (options = {}) => (
  new Promise((resolve, reject) => {
    AuthClient.checkSession(options, (err, res) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
)

export const signupAndAuthorize = (options = {}) => (
  new Promise((resolve, reject) => {
    AuthClient.signupAndAuthorize(options, (err, res) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
)

export default AuthClient
