import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadingNotificationsByUser } from '../../actions/notification'
import {
  startLoadingFacilitatorByProject,
  startLoadingProjects,
  startLoadingSellerByProject,
} from '../../actions/project'
import {
  projectPageSelector,
  projectPaginationParamsSelector,
  projectSelector,
  userSelector,
} from '../../selectors'
import { notifSelector } from '../../selectors/notification'
import { isMobile } from '../../utils'
import MultiNotificationsList from '../container-multi-notifs-menu/multi-notifs-list'
import LoadingSpinner from '../loading-spinner'
import ErrorPage from '../page-error'
import PageParameter from '../page-parameter'
import { MainTitle } from '../page-project-list/styled'
import { ViewProject } from '../page-project/styled'
import {
  NotificationsWrapper,
  PageNotificationListContainer,
  PageNotificationListContent,
  LoadingContainer,
  MainLoadingContainer,
} from './styled'
import { ProjectListWrapper } from '../page-project-list/styled'

const PageNotificationList = () => {
  const dispatch = useDispatch()
  const [isScrolling, setIsScrolling] = useState(false);
  const projectPage = useSelector(projectPageSelector)

  const user = useSelector(userSelector)
  const profile = user?.profile

  const {
    projects,
    projectsLoading,
    projectsError,
    selectedProjectFacilitator,
    facilitatorLoading,
    selectedProjectSeller,
    sellerLoading,
    selectedProjectId,
  } = useSelector(projectSelector)

  const { limit, offset } = useSelector(projectPaginationParamsSelector)

  const {
    userNotifications,
    userNotificationsLoading,
    userNotificationsError,
    paginationParams,
    userNotificationsTotal,
  } = useSelector(notifSelector)

  const { limit: notifLimit, offset: notifOffset, nexPage } = paginationParams

  const handleClickPaginationPage = (event) => {
    const selectedPage = event.selected
    const newOffset = selectedPage * notifLimit
    dispatch(
      startLoadingNotificationsByUser(user, {
        limit: notifLimit,
        offset: newOffset,
      })
    )
  }

  const handleScroll = React.useCallback(
    (ev) => {
      const el = ev.target
      const isBottom = el.scrollHeight - el.scrollTop === el.clientHeight

      if (
        isBottom &&
        user &&
        userNotifications &&
        userNotifications.length < userNotificationsTotal
      ) {
        dispatch(
          startLoadingNotificationsByUser(user, {
            limit: notifLimit,
            offset: nexPage * notifLimit,
          })
        )
      }
      if (isScrolling === false) setIsScrolling(true);
      setTimeout(() => { setIsScrolling(false) }, 1500);
    },
    [user, notifLimit, nexPage, userNotifications, userNotificationsTotal]
  )

  // Get projects for seller in header
  React.useEffect(() => {
    if (!projects && !projectsLoading && !projectsError) {
      dispatch(startLoadingProjects({ limit, offset }))
    }
  }, [projects, projectsLoading, projectsError])

  // Get facilitator, seller
  React.useEffect(() => {
    if (profile && selectedProjectId) {
      if (!selectedProjectFacilitator) {
        dispatch(startLoadingFacilitatorByProject(selectedProjectId))
      }
      if (!selectedProjectSeller) {
        dispatch(startLoadingSellerByProject(selectedProjectId))
      }
    }
  }, [
    profile,
    selectedProjectFacilitator,
    selectedProjectSeller,
    selectedProjectId,
  ])

  // Get notifications
  React.useMemo(() => {
    if (
      profile &&
      !userNotifications &&
      !userNotificationsLoading &&
      !userNotificationsError
    ) {
      dispatch(
        startLoadingNotificationsByUser(user, {
          limit: notifLimit,
          offset: notifOffset,
        })
      )
    }
  }, [
    profile,
    userNotifications,
    userNotificationsLoading,
    userNotificationsError,
  ])

  if (projectsLoading || facilitatorLoading || sellerLoading) {
    return (
      <MainLoadingContainer>
        <LoadingSpinner />
      </MainLoadingContainer>
    )
  }

  return (
    <ViewProject>
      {isMobile && projectPage === 'parametres' ? (
        <PageParameter />
      ) : (
        <ProjectListWrapper>
          <PageNotificationListContainer>
            {userNotificationsLoading && (
              <LoadingContainer>
                <LoadingSpinner size={40} />
              </LoadingContainer>
            )}
            {projectsError ||
              (userNotificationsError && !userNotificationsLoading) ? (
              <ErrorPage error={projectsError || userNotificationsError} />
            ) : (
              <Fragment>
                <MainTitle>
                  <span>Notifications</span>
                </MainTitle>
                <PageNotificationListContent
                  onScroll={handleScroll}
                  isLoading={userNotificationsLoading}
                  isScrolling={isScrolling}
                >
                  {(userNotifications && userNotifications.length > 0) && (
                    <NotificationsWrapper>
                      <MultiNotificationsList
                        notifications={userNotifications}
                        isPage={true}
                      />
                    </NotificationsWrapper>
                  )}
                </PageNotificationListContent>
              </Fragment>
            )}
          </PageNotificationListContainer>
        </ProjectListWrapper>
      )}
    </ViewProject>
  )
}

export default PageNotificationList
