import styled from '@emotion/styled'
import { mq } from '../../utils/style'

export const ProjectsListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: ${(props) => props.theme.spacings.medium}px;
  padding-top: ${(props) => props.theme.spacings.large}px;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  height: 100%;
`

export const MainTitle = styled('h2')`
  color: ${(props) => props.theme.colors.primary};
  min-width: 150px;
  display: flex;
  position: relative;
  font-size: ${(props) => props.theme.sizes.h2}px;
  font-family: ${(props) => props.theme.fonts.workSans};
  font-weight: normal;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacings.medium}px;
  line-height: 1.5em;
  & svg {
    left: -23px;
    position: absolute;
  }
`

export const SubTitle = styled.div`
  width: 100%;
  position: relative;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: ${(props) => props.theme.spacings.medium}px;
  text-align: center;
  padding-top: ${(props) => (props?.isMobile ? 20 : 0)}px;
`

export const ProjectContainer = styled('div')`
  border: ${(props) => (props?.isMobile ? 'none' : `1px solid ${(props) => props.theme.colors.primary}`)};
  border-radius: 5px;
  height: -webkit-fill-available;

  overflow-x: hidden;
  overflow-y: scroll;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  & > p {
    width: fit-content;
  }
  ${(props) =>
    mq(props)({
      justifyContent: [null, 'center'],
    })}
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

export const ProjectListContainer = styled('table')`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  overflow: hidden;
`

export const ProjectTitle = styled('tr')`
  line-height: 1.5;
`

export const ProjectTableHead = styled('thead')`
  border-bottom: 1px solid lightgrey;
`

export const ProjectInfoTitle = styled('th')`
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  text-align: left;
  font-weight: 500;
`
export const ProjectLine = styled('tr')`
  height: 35px;
  &:hover {
    cursor: pointer;
  }
  &.selectedProject {
    background-color: ${(props) => props.theme.colors.primarySelected};
    @media screen and (max-width: 1160px) {
      height: auto;
    }
  }
`
export const ProjectInfo = styled('td')`
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
`

export const ProjectInfoNameContainer = styled(ProjectInfo)`
  flex-direction: column;
  justify-content: center;
`

export const ProjectInfoName = styled.div`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  width: 100%;
  position: relative;
`

export const ProjectInfoWorkType = styled(ProjectInfo)`
  overflow: hidden;
`

export const ProjectInfoAdress = styled(ProjectInfo)`
  width: 35%;
`

export const ProjectDate = styled(ProjectInfo)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const ProjectDateTitle = styled(ProjectInfoTitle)`
  display: none;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`

export const Button = styled('a')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  padding: 5px;
  margin: 10px 0px 10px 10px;
  border-radius: 2px;
  border: none;
  background-color: ${(props) => props.theme.colors.dark}60;
  color: white;
  font-size: 13.75px;
  text-decoration: none;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.workSans};
  ${(props) =>
    props.hovered &&
    `&:hover{
        background-color:${props.theme.colors.dark}80;
      }`}
  @media screen and (min-width: 769px) {
    width: 107px;
    height: 37px;
  }
`

export const ValidateButtonDisabled = styled('button')`
  font: unset;
  background-color: ${(props) => props.theme.colors.primarySelected};
  width: 70px;
`

export const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-self: flex-end;
  @media screen and (min-width: 769px) {
    margin: 10px 0px 10px 20px;
  }
`

export const ValidateButton = styled.button`
  width: unset;
  background-color: ${(props) => props.theme.colors.primary};
  height: 30px;
  padding: 5px;
  margin: 10px 0px 10px 10px;
  border-radius: 2px;
  border: none;
  color: white;
  line-height: 1.3;
  font-size: 13.75px;
  font-family: ${(props) => props.theme.fonts.workSans};
  @media screen and (min-width: 769px) {
    width: 107px;
    height: 37px;
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors.primarySelected};
  }
`

//For the top property, here is the idea: Each line is 60px height on desktop. So Position * 60 , we add the 60 px of the titleLine, and we retrieve 20
// the tooltip is a little above the workType it is related.
export const Tooltip = styled('span')`
  display: none;
  max-width: 80%;
  background-color: ${(props) => props.theme.colors.dark};
  color: white;
  position: absolute;
  top: ${(props) => props.index * 60 + 60 - 20}px;
  padding: 8px;
  border-radius: 10px;
  pointer-events: none;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    margin-bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    border: 4px solid #000;
    border-color: black transparent transparent transparent;
    display: none;
  }
`

export const SingleProjectText = styled.p`
  display: flex;
  justify-content: center;
  font-weight: 300;
  margin-top: 10%;
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.workSans};
  color: ${(props) => props.theme.colors.medium};
`
export const ProjectInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const ButtonCreateProject = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 142px;
  height: 29px;
  border: 0.5px solid ${(props) => props.theme.colors.primary};
  border-radius: 2px;
  box-shadow: inset 0px 0px 4px ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  padding: 5px 10px;
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.workSans};
  font-weight: normal;
  outline: none;
  &:focus {
    outline: none;
  }

  position: absolute;
  top: -10px;
  right: 0;

  @media screen and (max-width: 600px) {
    position: relative;
    margin: 20px auto 0px;
  }
`
