import GpWfsClient from 'geoportal-wfs-client'

import Koumoul from './koumoul'

const Client = new GpWfsClient({
  apiKey: window.processRuntime.env.GEO_KEY,
  headers: {
    Referer: window.processRuntime.env.GEO_REFERER
  }
})

const checkInsee = async (plotId, query) => {
  const res = await Koumoul.get(`/s/insee-mapping/api/v1/city/${plotId.slice(0, 5)}`)
  return Object.assign(query, res && res.POLE ? { code_com: res.POLE.slice(2) } : {})
}

const getFeatureCollectionByPlotId = async (plotId) => {
  const query = await composeQuery(plotId)
  return Client.getFeatures('CADASTRALPARCELS.PARCELLAIRE_EXPRESS:parcelle', query)
}

const composeQuery = (plotId) => {
  const query = {
    code_insee: plotId.slice(0, 5),
    code_dep: plotId.slice(0, 2),
    code_com: plotId.slice(2, 5),
    section: plotId.slice(8, 10),
    numero: plotId.slice(10, 14)
  }

  const absorbedCommune = plotId.slice(5, 8)

  return absorbedCommune === '000' ? checkInsee(plotId, query) : query
}

export default {
  getFeatureCollectionByPlotId,
  Client
}
