import axios from 'axios'
import * as d3 from 'd3-ease'
import 'mapbox-gl/dist/mapbox-gl.css'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ReactMapGL, {
  FlyToInterpolator,
  Marker,
  TRANSITION_EVENTS
} from 'react-map-gl'
import MarkerSvg from '../../static/marker-orange.svg'
import { franceViewport, parisViewport } from '../../utils/map'
import { buildMapboxURI } from '../../utils/mapbox'
import {
  layers as baseLayers,
  sources as baseSources
} from './layers/background'
import { highlightsLayers, sources as cadasterSources } from './layers/cadaster'
import { MapHomeBlock, ThrottleButton } from './styled'

const MapContainer = ({ project }) => {
  const token = window.processRuntime.env.MAPBOX_ACCESS_TOKEN

  const googleApiBaseUrl = window.processRuntime.env.GOOGLE_API_BASE_URL
  const googleApiKey = window.processRuntime.env.GOOGLE_API_KEY

  // In case the transitionDuration is changed, think about update the setTimeout duration on the useeffect (should be transitionDuration +1000ms)
  const initialViewportZoomOptions = {
    transitionDuration: 3000,
    transitionInterruption: TRANSITION_EVENTS.UPDATE,
    transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
    transitionDuration: 'auto',
    transitionEasing: d3.easeCubic
  }

  const initialViewport = { ...franceViewport }

  const [state, setState] = useState({
    viewport: initialViewport,
    throttled: window.processRuntime.env.NODE_ENV === 'development' || false,
    showMarker: false,
    mapStyle: {
      version: 8,
      metadata: {
        'mapbox:autocomposite': true,
        'mapbox:type': 'template'
      },
      sources: {
        'mapbox-raster': baseSources['mapbox-raster'],
        'ign-raster': baseSources['mapbox-raster'],
        // 'ign-raster': baseSources['ign-raster'],
        ...cadasterSources
      },
      layers: [
        ...baseLayers,
        ...Object.values(highlightsLayers(project.plotsIds))
      ],
      glyphs: buildMapboxURI(
        '/fonts/v1/permettez-moi-de-construire/{fontstack}/{range}.pbf'
      ).toString()
    }
  })

  const onViewportChange = (nextViewport) => {
    setState((prevState) => ({ ...prevState, viewport: nextViewport }))
  }

  const getCoordinatesFromAddress = async (address) => {
    try {
      const { data } = await axios.get(
        `${googleApiBaseUrl}/maps/api/geocode/json?address=${address}&key=${googleApiKey}`
      )
      if (data?.results?.length) {
        return data.results[0].geometry.location
      } else {
        return null
      }
    } catch (error) {
      throw error
    }
  }

  const setViewportToParis = () => {
    setState((prevState) => ({
      ...prevState,
      viewport: {
        ...prevState.viewport,
        ...parisViewport,
        ...initialViewportZoomOptions,
        zoom: 15
      }
    }))
  }

  const handleUnthrottle = () => {
    setState((prevState) => ({ ...prevState, throttled: false }))
  }

  React.useEffect(() => {
    if (!state.throttled) {
      if (project.location && project.location.coordinates) {
        setState((prevState) => ({
          ...prevState,
          viewport: {
            ...prevState.viewport,
            longitude: project.location.coordinates[0],
            latitude: project.location.coordinates[1],
            zoom: 16.5,
            ...initialViewportZoomOptions
          }
        }))
      } else if (project.address) {
        getCoordinatesFromAddress(project.address)
          .then((data) => {
            if (data) {
              setState((prevState) => ({
                ...prevState,
                viewport: {
                  ...prevState.viewport,
                  longitude: data.lng,
                  latitude: data.lat,
                  zoom: 16.5,
                  ...initialViewportZoomOptions
                }
              }))
            } else {
              setViewportToParis()
            }
          })
          .catch(() => {
            setViewportToParis()
          })
      } else {
        setViewportToParis()
      }
    }
  }, [state.throttled, project.location, project.address])

  React.useEffect(() => {
    if (!state.throttled) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, showMarker: true }))
      }, 4000)
    }
  }, [state.viewport.zoom])

  return (
    <MapHomeBlock>
      {state.throttled === false ? (
        <ReactMapGL
          {...state.viewport}
          width="100%"
          height="100%"
          onViewportChange={(nextViewport) => onViewportChange(nextViewport)}
          mapboxApiAccessToken={token}
          mapStyle={state.mapStyle}
          scrollZoom={false}
          dragPan={false}
          dragRotate={false}
          doubleClickZoom={false}
          touchZoom={false}
          touchRotate={false}
          attributionControl={false}
          touchAction="pan-x"
        >
          {state.showMarker && (
            <Marker
              latitude={state.viewport.latitude}
              longitude={state.viewport.longitude}
              offsetLeft={-5}
              offsetTop={-30}
            >
              <img src={MarkerSvg} />
            </Marker>
          )}
        </ReactMapGL>
      ) : (
        <ThrottleButton onClick={handleUnthrottle}>
          Charger la carte
        </ThrottleButton>
      )}
    </MapHomeBlock>
  )
}

MapContainer.propTypes = {
  project: PropTypes.object.isRequired
}

export default MapContainer
