import { css } from '@emotion/core'
import { EMOJI_CLASS } from '../constants'

export default (theme) => css`
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
  @import url('https://d1skpbvsub1lnb.cloudfront.net/hp/polices/Gotham/stylesheet-v2.css');

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  html,
  body,
  #app-root {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    background-color: ${theme.colors.beige};
    font-family: ${theme.fonts.workSans};
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.primary};
  }

  ::-webkit-scrollbar {
    width: ${theme.scrollbar.width}px;
    height: ${theme.scrollbar.width}px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 21, 86, 0.2);
    border-radius: ${theme.scrollbar.width}px;
    border: 0.5em solid rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.colors.primary};
  }

  ::-webkit-scrollbar-track {
    background-color: ${theme.colors.semiLight};
    border-radius: ${theme.scrollbar.width}px;
    border: 1px solid rgba(0, 21, 86, 0.06);
  }

  ::-webkit-scrollbar-track:hover {
    border: 1px solid rgba(0, 21, 86, 0.4);
  }

  #app-root,
  #overlay-root,
  #popup-root {
    position: relative;
  }

  #app-root {
    display: flex;
    z-index: ${theme.layers.app};
  }

  #overlay-root {
    z-index: ${theme.layers.overlay};
  }

  #popup-root {
    z-index: ${theme.layers.popup};
  }

  #alerts-root {
    z-index: ${theme.layers.alerts};
  }

  html {
    font-size: ${theme.sizes.normal}px;
    font-family: ${theme.fonts.workSans};
  }

  strong {
    font-weight: 500;
  }

  button:hover,
  input[type='button']:hover,
  input[type='submit']:hover {
    cursor: pointer;
  }

  button:disabled:hover,
  input[type='button']:disabled:hover,
  input[type='submit']:disabled:hover {
    cursor: not-allowed;
  }

  /* Xeno */
  body .slaask-button.hide {
    display: none;
  }

  body .slaask-button {
    left: 20px !important;
  }

  body .slaask-widget {
    left: 20px !important;
  }

  input {
    font-family: ${theme.fonts.workSans};
  }

  @media screen and (max-width: 1024px) {
    body .slaask-button {
      display: none;
      top: 50% !important;
      width: 40px !important;
      height: 40px !important;
    }
  }

  /* Emoji */
  .${EMOJI_CLASS} {
    color: transparent;
    text-shadow: 0 0 #fdb733;
  }

  .mapboxgl-canvas-container {
    position: absolute;
    top: 0;
    left: 0;
  }
`
