import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn } from '../styled'
import { PasswordForm } from '../view-set-password/styled'
import { mq } from '../../utils/style'

export const SetPasswordWrapper = styled(FlexColumn)`
  justify-content: center;
  overflow: auto;
  padding: ${props => props.theme.spacings.medium}px;
  flex: 1;

  ${props => mq(props)({
    justifyContent: ['flex-start', 'center']
  })}

  ${PasswordForm} {
    flex-shrink: 0;
    max-height: initial;
  }
`

export const SetPasswordIcon = styled(FontAwesomeIcon)`
  font-size: ${props => props.theme.sizes.h1 * 1.25}px;
  display: inline-block;
  margin-bottom: ${props => props.theme.spacings.small}px;
  color: ${props => props.theme.colors.primary};
`

export const SetPasswordTitle = styled('h1')`
  line-height: 1.5em;
  text-align: center;
  flex-shrink: 0;
  font-size: ${props => props.theme.sizes.h2}px;
  color: ${props => props.theme.colors.primary};
`
