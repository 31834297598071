import styled from '@emotion/styled'
import { MainTitle } from '../page-project-list/styled'

export const TallyFormContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`

export const TallyFormTitle = styled(MainTitle)`
  margin-bottom: 0;
  position: relative;
`

const btnBackIconSize = 24

export const BtnBack = styled.button`
  appearance: none;
  background-color: transparent;
  border-radius: 50%;
  border: 0;
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colors.white};
  width: 50px;
  aspect-ratio: 1;

  & > svg {
    width: ${btnBackIconSize}px;
    height: ${btnBackIconSize}px;
    min-width: ${btnBackIconSize}px;
    min-height: ${btnBackIconSize}px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export const TallyFormBody = styled.div`
  width: 100%;
  height: calc(100dvh - 240px);
  overflow-y: auto;
  padding: 20px;
`

export const TallyFormIframe = styled.iframe`
  position: relative;
  top: -315px;
`
