import { faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeOverlay } from '../../actions/overlay'
import { createProjectStart } from '../../actions/project'
import {
  createProjectSelector,
  isAllowedToUseAlgarDeskSelector,
  isBtoBSelector
} from '../../selectors'
import { createProjectFormSelector } from '../../selectors/form'
import { LoadingSpinnerSmall } from '../loading-spinner'
import PageCreateProject from './page-create-project'
import {
  ConfirmTextContent,
  CustomConfirmOverlayStyled,
  ModalBodyContainer
} from './styled'

const CreateProjectOverlay = () => {
  const dispatch = useDispatch()

  const isBtoB = useSelector(isBtoBSelector)
  const isAllowedToUseDesk = useSelector(isAllowedToUseAlgarDeskSelector)
  const { createProjectLoading } = useSelector(createProjectSelector)
  const formValues = useSelector(createProjectFormSelector)
  const { address, projectTypesCodes } = formValues

  const disabledConfirm =
    createProjectLoading ||
    (isBtoB && (!address || projectTypesCodes.length === 0))

  const handleClickValidate = useCallback(
    (event) => {
      event.preventDefault()
      if (!disabledConfirm && (isBtoB || isAllowedToUseDesk)) {
        dispatch(createProjectStart(formValues))
      }
    },
    [disabledConfirm, isBtoB, isAllowedToUseDesk, formValues]
  )

  const handleCloseModal = () => {
    dispatch(closeOverlay())
  }

  return (
    <CustomConfirmOverlayStyled
      title="Créer un dossier"
      body={
        <ModalBodyContainer>
          <PageCreateProject showTitle={false} showValidateBtn={false} />
        </ModalBodyContainer>
      }
      handleConfirm={handleClickValidate}
      confirmText={
        <ConfirmTextContent>
          <FontAwesomeIcon icon={faLayerPlus} />
          <span>Créer le dossier</span>
          {createProjectLoading && (
            <LoadingSpinnerSmall size={15} borderWidth={2} />
          )}
        </ConfirmTextContent>
      }
      handleReject={handleCloseModal}
      confirmDisabled={disabledConfirm}
      rejectIcon={faTimes}
    />
  )
}

export default CreateProjectOverlay
