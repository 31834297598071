import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import * as Sentry from '@sentry/browser'

import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware, ConnectedRouter as RawConnectedRouter } from 'connected-react-router'

import rootReducer from './reducers'
import sagas, { combineSagas, createSagaMiddleware } from './sagas'

import {
  sentryUserMiddleware,
  sentryReduxStateMiddleware
} from './utils/redux/middlewares'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true
  }) : compose

const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware(Sentry)

// Compose Sentry middlewares
const sentryMiddlewares = [
  sentryUserMiddleware,
  sentryReduxStateMiddleware
].map(middleware => middleware(Sentry))

const middlewares = [
  sagaMiddleware,
  routerMiddleware(history),
  ...sentryMiddlewares
]

const store = createStore(
  combineReducers({
    ...rootReducer,
    router: connectRouter(history)
  }),
  composeEnhancers(
    applyMiddleware(...middlewares)
  )
)

// Run sagas
sagaMiddleware.run(combineSagas, sagas)

const ReduxProvider = (props) => (
  <Provider store={store}>
    {props.children}
  </Provider>
)

ReduxProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default ReduxProvider

export const ConnectedRouter = (props) => (
  <RawConnectedRouter history={history}>
    {props.children}
  </RawConnectedRouter>
)

ConnectedRouter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}
