import styled from '@emotion/styled'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React from 'react'
import OverlayCentered from '../overlay-centered'
import { FlexRow, InvisibleButton } from '../styled'

const ConfirmationWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.theme.spacings.large * 3}px
    ${(props) => props.theme.spacings.large}px;
`

const ButtonsWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`

const ConfirmationTitle = styled('h4')`
  font-size: ${(props) => props.theme.sizes.h3}px;
  margin-bottom: ${(props) => props.theme.spacings.medium}px;
`

const ConfirmationButton = styled(InvisibleButton)`
  margin: ${(props) => props.theme.spacings.medium}px;
`

const ConfirmationOverlay = (props) => (
  <OverlayCentered noPadding={true}>
    <ConfirmationWrapper>
      <ConfirmationTitle>{props.title}</ConfirmationTitle>
      <ButtonsWrapper>
        <ConfirmationButton
          color={props.theme.colors.primary}
          onClick={props.handleConfirm}
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            color={props.theme.colors.primary}
            size="3x"
          />
        </ConfirmationButton>
        <ConfirmationButton
          color={props.theme.colors.primary}
          onClick={props.handleReject}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            color={props.theme.colors.tertiary}
            size="3x"
          />
        </ConfirmationButton>
      </ButtonsWrapper>
    </ConfirmationWrapper>
  </OverlayCentered>
)

ConfirmationOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  rejectText: PropTypes.string,
}

export default withTheme(ConfirmationOverlay)
