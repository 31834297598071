import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Transition } from 'react-transition-group'
import { connect } from 'react-redux'
import { isNil } from 'lodash'

import { deleteAlert } from '../../actions/alert'

import AlertsView from '../view-alerts'

const duration = 200

const getOpacityFromStatus = props => {
  const value = {
    entering: 1,
    entered: 1,
    exiting: 0.01,
    exited: 0.01
  }[props.status]
  return isNil(value) ? 1 : value
}

const TransitionWrapper = styled('div')`
  width: 100%;
  opacity: 0;
  opacity: ${getOpacityFromStatus};
  transition: opacity ${duration}ms linear;
`

class AlertsTransitionManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      in: false
    }
  }

  static getDerivedStateFromProps (props, state) {
    return props.alertsRemoved.includes(props.alert.id)
      ? { ...state, in: false }
      : state
  }

  componentDidMount () {
    // A slight delay is required to trigger the transition animation
    window.setTimeout(() => {
      this.setState({ in: true })
    }, 50)
  }

  componentDidUpdate () {
    if (this.state.in === false) {
      window.setTimeout(() => {
        this.props.deleteAlert(this.props.alert.id)
      }, duration)
    }
  }

  render () {
    return (
      <Transition in={this.state.in} timeout={duration}>
        {status => (
          <TransitionWrapper status={status}>
            <AlertsView
              alert={this.props.alert}
              removeAlert={this.props.removeAlert}
              disableRemove={!this.state.in}
            />
          </TransitionWrapper>
        )}
      </Transition>
    )
  }
}

const mapStateToProps = ({ alert }) => ({
  alertsRemoved: alert.alertsRemoved
})

const mapDispatchToProps = dispatch => ({
  deleteAlert: alertId => dispatch(deleteAlert(alertId))
})

AlertsTransitionManager.propTypes = {
  alert: PropTypes.object.isRequired,
  removeAlert: PropTypes.func.isRequired,
  deleteAlert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsTransitionManager)
