import {
  DRAFT__DATA_FETCH_START,
  DRAFT__DATA_FETCH_SUCCESS,
  DRAFT__DATA_FETCH_ERROR
} from '.'

export const startFetchingDraftData = () => ({
  type: DRAFT__DATA_FETCH_START
})

export const draftContentFetchSuccess = result => ({
  type: DRAFT__DATA_FETCH_SUCCESS,
  payload: result
})

export const draftContentFetchError = error => ({
  type: DRAFT__DATA_FETCH_ERROR,
  payload: error
})
