import { createSelector } from 'reselect'
import { orderBy } from 'lodash'

const wasPreviouslyRead = (notif) => notif.isRead && !notif.wasJustRead

export const notifSelector = (state) => state.notification

export const notificationsSelector = (state) => state.notification.notifications

export const orderedNotificationsSelector = createSelector(
  [notificationsSelector],
  (notifications) =>
    orderBy(notifications, [wasPreviouslyRead, 'createdAt'], ['asc', 'desc'])
)

export const userNotificationsSelector = (state) => {
  return {
    userNotifications: state.notification.userNotifications,
    userNotificationsError: state.notification.userNotificationsError,
    userNotificationsLoading: state.notification.userNotificationsLoading,
    userNotificationsTotal: state.notification.userNotificationsTotal,
  }
}
