import React from 'react'
import { Switch, Case, Default } from 'react-if'

import PropTypes from 'prop-types'

import { wrapDisplayName } from 'recompose'

import LoadingView from '../../components/view-loading'
import ErrorPage from '../../components/page-error'

const withWait = (SubComponent, mandatory) => {
  const WithWait = ({
    loading,
    error,
    ...passThroughProps
  }) => (
    <Switch>
      <Case condition={!!loading}>
        {() => <LoadingView />}
      </Case>

      <Case condition={!!mandatory && !!error}>
        {() => <ErrorPage error={error} />}
      </Case>

      <Default>
        {() => <SubComponent {...passThroughProps} />}
      </Default>
    </Switch>
  )

  WithWait.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object
  }

  WithWait.displayName = wrapDisplayName(
    SubComponent,
    'withWait'
  )

  return WithWait
}

export default withWait
