import styled from '@emotion/styled'
import { animationDelay } from '../../constants/styles'
import { ProjectStatusDesk } from '../page-project-list/styled'

const headerHeight = 90
const footerHeight = 54

export const openAnimationName = 'open-animation'
export const closeAnimationName = 'close-animation'

export const ProjectAdditionalInfoStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: ${headerHeight}px 1fr ${footerHeight}px;
  background: ${({ theme }) => theme.colors.white};
  animation: ${({ animation }) => animation} ${animationDelay}s;

  @keyframes ${openAnimationName} {
    from {
      top: 100vh;
    }
    to {
      top: 0;
    }
  }

  @keyframes ${closeAnimationName} {
    from {
      top: 0;
    }
    to {
      top: 100vh;
    }
  }
`

export const ProjectAdditionalInfoHeader = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`

export const ProjectAdditionalInfoBtnClose = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  cursor: pointer;

  & > svg {
    width: 17px;
    height: 17px;
  }
`

export const ProjectAdditionalInfoBody = styled.div`
  width: 100%;
  height: auto;
  max-height: calc(100vh - ${headerHeight}px + ${footerHeight}px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 30px 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.primary};
`

export const ProjectAdditionalInfoBodyLine = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 121px 1fr;
  gap: 20px;
`

export const ProjectAdditionalInfoFooter = styled.div`
  width: 100%;
  height: ${footerHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px -1px 2px rgba(0, 21, 86, 0.1);
`

export const GotToFolderBtn = styled.button`
  appearance: none;
  width: auto;
  height: 34px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border: none;
  border-radius: 2px;
  padding: 8px 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
`

export const StyledProjectStatusDesk = styled(ProjectStatusDesk)`
  margin-left: 0;
`
