import {
  ACTIONS__LOAD_BY_PROJECT_ERROR,
  ACTIONS__LOAD_BY_PROJECT_START,
  ACTIONS__LOAD_BY_PROJECT_SUCCESS,

  ACTIONS__LOAD_BY_USER_ERROR,
  ACTIONS__LOAD_BY_USER_START,
  ACTIONS__LOAD_BY_USER_SUCCESS,

  ACTIONS__LOAD_META_ERROR,
  ACTIONS__LOAD_META_START,
  ACTIONS__LOAD_META_SUCCESS,

  ACTIONS__UPDATE_STATUS_ERROR,
  ACTIONS__UPDATE_STATUS_START,
  ACTIONS__UPDATE_STATUS_SUCCESS,

  ACTIONS__UPDATE_LINK_STATUS_START,
  ACTIONS__UPDATE_LINK_STATUS_SUCCESS,
  ACTIONS__UPDATE_LINK_STATUS_ERROR,

  ACTIONS__UPDATE_PAGINATION_PARAMS
} from '.'

export const startLoadingActionsByProject = () => ({
  type: ACTIONS__LOAD_BY_PROJECT_START,
})

export const loadActionsByProjectSuccess = (actions) => ({
  type: ACTIONS__LOAD_BY_PROJECT_SUCCESS,
  payload: actions,
})

export const loadActionsByProjectError = (error) => ({
  type: ACTIONS__LOAD_BY_PROJECT_ERROR,
  payload: error,
})

export const startLoadingActionsByUser = (user, paginationParams) => ({
  type: ACTIONS__LOAD_BY_USER_START,
  payload: { user, paginationParams },
})

export const loadActionsByUserSuccess = (actions, total) => ({
  type: ACTIONS__LOAD_BY_USER_SUCCESS,
  payload: { actions, total },
})

export const loadActionsByUserError = (error) => ({
  type: ACTIONS__LOAD_BY_USER_ERROR,
  payload: error,
})

export const startLoadingActionsMeta = () => ({
  type: ACTIONS__LOAD_META_START,
})

export const loadActionsMetaSuccess = (meta) => ({
  type: ACTIONS__LOAD_META_SUCCESS,
  payload: meta,
})

export const loadActionsMetaError = (error) => ({
  type: ACTIONS__LOAD_META_ERROR,
  payload: error,
})

export const startUpdateActionStatus = (actionId, status) => ({
  type: ACTIONS__UPDATE_STATUS_START,
  payload: { actionId, status }
})

export const updateActionStatusSuccess = (actionId, status) => ({
  type: ACTIONS__UPDATE_STATUS_SUCCESS,
  payload: { actionId, status }
})

export const updateActionStatusError = ({ actionId, error }) => ({
  type: ACTIONS__UPDATE_STATUS_ERROR,
  payload: { actionId, error },
})

export const startUpdateActionLinkStatus = (actionId, linkId, status) => ({
  type: ACTIONS__UPDATE_LINK_STATUS_START,
  payload: { actionId, linkId, status }
})

export const updateActionLinkStatusSuccess = (updatedAction) => ({
  type: ACTIONS__UPDATE_LINK_STATUS_SUCCESS,
  payload: { updatedAction }
})

export const updateActionLinkStatusError = ({ actionId, linkId, error }) => ({
  type: ACTIONS__UPDATE_LINK_STATUS_ERROR,
  payload: { actionId, linkId, error },
})

export const updatePaginationParams = (newParams) => ({
  type: ACTIONS__UPDATE_PAGINATION_PARAMS,
  payload: newParams,
})
