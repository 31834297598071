import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropType from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { geocodingCloseSuggestions } from '../../actions/geocoding-address'
import {
  closeCreateProjectOverlayInOtherOverlay,
  closeOverlay,
} from '../../actions/overlay'
import { OVERLAYS } from '../../constants'
import { overlaySelector } from '../../selectors'
import { geocodingAddressSelector } from '../../selectors/geocoding-address'
import theme from '../../theme'
import {
  CloseButtonStyled,
  OverlayCenteredChildrenContainer,
  OverlayCenteredContainer,
} from './styled'

const OverlayCentered = ({
  children,
  isFullScreen,
  withCloseButton,
  noPadding,
  isTransparent,
  showContent,
  className,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { suggestionsOpened } = useSelector(geocodingAddressSelector)
  const { currentOverlayType } = useSelector(overlaySelector)

  const handleClose = React.useCallback(
    (event) => {
      event.stopPropagation()
      if (suggestionsOpened) {
        dispatch(geocodingCloseSuggestions())
      } else {
        if (currentOverlayType === OVERLAYS.CREATE_PROJECT) {
          dispatch(closeCreateProjectOverlayInOtherOverlay())
          dispatch(closeOverlay())
        } else {
          dispatch(closeOverlay())
        }
      }
    },
    [suggestionsOpened]
  )

  return (
    <OverlayCenteredContainer
      onClick={onClose ?? handleClose}
      isTransparent={isTransparent}
      className={className}
    >
      {showContent && (
        <OverlayCenteredChildrenContainer
          onClick={(event) => {
            event.stopPropagation()
            if (suggestionsOpened) {
              dispatch(geocodingCloseSuggestions())
            }
          }}
          isFullScreen={isFullScreen}
          noPadding={noPadding}
          isTransparent={isTransparent}
        >
          {withCloseButton && (
            <CloseButtonStyled onClick={onClose ?? handleClose}>
              <FontAwesomeIcon
                color={theme.colors.greySemiLight}
                icon={faTimes}
                size="2x"
              />
            </CloseButtonStyled>
          )}
          {children}
        </OverlayCenteredChildrenContainer>
      )}
    </OverlayCenteredContainer>
  )
}

OverlayCentered.defaultProps = {
  isFullScreen: false,
  withCloseButton: true,
  noPadding: false,
  isTransparent: false,
  showContent: true,
  className: '',
}

OverlayCentered.propTypes = {
  children: PropType.node.isRequired,
  isFullScreen: PropType.bool,
  withCloseButton: PropType.bool,
  noPadding: PropType.bool,
  isTransparent: PropType.bool,
  showContent: PropType.bool,
  className: PropType.string,
  onClose: PropType.func,
}

export default OverlayCentered
