import styled from '@emotion/styled'
import { animated } from '@react-spring/web'

export const Container = styled('div')`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-size: 14px;
  line-height: 21px;
  --webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
`

export const Frame = styled('div')`
  position: relative;
  padding: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.primary};
`

export const Title = styled('span')`
  display: flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  color: ${({ isOpen }) => isOpen ? '#001556' : '#8A90A1'};
  margin-bottom: 5px;
  padding: 10px 2px;
  border-radius: 5px;
  height: 30px;
  width: 100%;
  font-size: 16px;
  overflow: hidden;
  font-weight: 500;
  & div > svg {
    color: ${({ isOpen }) => isOpen ? '#001556' : '#8A90A1'};
  }
  & :hover {
    color: ${(props) => props.theme.colors.primary};
    background-color: #E8E5DD;
    div > svg {
      color: #001556;
    }
  }
`

export const Content = styled(animated.div)`
  will-change: transform, opacity, height;
  margin-left: 6px;
  padding: 0px 0px 0px 14px;
  border-left: 1px solid ${({ theme }) => theme.colors.primary};
  overflow: hidden;
`

export const Partner = styled.p`
display: flex;
align-items: center;
width: 100%;
vertical-align: middle;
height: 30px;
cursor: ${(props) => props.available ? 'pointer' : 'disabled'};
color: ${(props) => props.available ? props.theme.colors.primary : '#8A90A1'};
padding: 10px 2px;
margin 0 0 5px;
border-radius: 5px;
font-size: 16px;
font-weight: 500;
&: hover {
  color: ${(props) => props.available && props.theme.colors.primary};
  background-color: ${props => props.available && '#E8E5DD'};
}
`
