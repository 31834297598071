import { createSelector } from 'reselect'

export const filesSelector = state => state.files

export const filesListSelector = state => (
  state.files.filesList
)

export const filesDeletionSelector = state => (
  state.files.filesToDelete
)

export const filesUploadingSelector = state => (
  state.files.filesUploading
)

export const rootFilesSelector = createSelector(
  [filesListSelector],
  (filesList) => (
    Array.isArray(filesList) && filesList
      .filter(f => f.isRoot)
  ) || null
)

export const loadingFilesIdsSelector = createSelector(
  [filesListSelector, filesDeletionSelector],
  (filesList, filesToDelete) => (
    filesList?.reduce((acc, f) => (
      filesToDelete.includes(f.id)
        ? acc.concat([f.id])
        : acc
    ), [])
  )
)

export const finishedUploadsSelector = createSelector(
  [filesUploadingSelector],
  (uploadingFiles) => (
    uploadingFiles.filter(f => f.completed === true)
  )
)

export const finishedUploadsIdsSelector = createSelector(
  [finishedUploadsSelector],
  (finishedUploads) => (
    finishedUploads.map(desc => desc.id)
  )
)

export const uploadErrorsSelector = state => (
  state.files.filesUploadErrors
)

export const uploadErrorsIdsSelector = createSelector(
  [uploadErrorsSelector],
  (uploadErrors) => (
    uploadErrors.map(e => e.id)
  )
)
