import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withTemplateFolderContents } from '../../utils/middlewares'

import FilesView from '../view-files'

const noop = () => null

const FakeFilesContainer = (props) => (
  <FilesView
    files={props.files}
    rootFiles={props.files}
    loadingFilesIds={[]}
    deleteFile={noop}
    uploadFiles={noop}
    getFileDownloadLink={noop}
    uploadingFilesDescriptors={[]}
    disableInput
  />
)

const mapStateToProps = ({ files }) => ({
  files: files.templateFolderContents
})

FakeFilesContainer.propTypes = {
  files: PropTypes.array.isRequired
}

export default withTemplateFolderContents(connect(mapStateToProps, null)(FakeFilesContainer))
