import { createSelector } from 'reselect'

export const draftLngLatSelector = state => {
  const {
    longitude: rawLongitude,
    latitude: rawLatitude
  } = state.draft.draftContent

  let latitude, longitude

  try {
    longitude = parseFloat(rawLongitude)
    latitude = parseFloat(rawLatitude)
  } catch (err) {
    longitude = null
    latitude = null
  }

  return { longitude, latitude }
}

export const draftPlotsIdsSelector = state => {
  const rawPlot = state.draft.draftContent.plot

  return (rawPlot && [rawPlot]) || []
}

export const draftLocationSelector = createSelector(
  [draftLngLatSelector],
  ({ longitude, latitude }) => (
    (longitude && latitude)
      ? { type: 'Point', coordinates: [longitude, latitude] }
      : null
  )
)

export const draftProjectForMapSelector = createSelector(
  [draftLocationSelector, draftPlotsIdsSelector],
  (location, plotsIds) => ({
    plotsIds,
    location
  })
)
