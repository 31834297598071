export const deepCompare = comparators => (left, right) => {
  const result = comparators.reduce((acc, comparator) => {
    return !acc
      ? comparator(left, right)
      : acc
  }, 0)

  return result
}

export const compareNumberField = field => (left, right) => {
  return left[field] - right[field]
}

export const intersperse = (list, item) => (
  list.reduce((acc, x, idx) => (
    idx < list.length - 1
      ? acc.concat([x, item])
      : acc.concat([x])
  ), [])
)

export const each = (amount, callback) => {
  if (!(typeof amount === 'number' && amount > 0)) {
    throw new Error(`Tasks amount must be a non-null, positive number, got "${amount}" instead`)
  }

  return new Array(amount)
    .fill(null)
    .map((_x, idx) => callback(idx))
}

export const isMobile = window.matchMedia('(max-width: 1024px)').matches
