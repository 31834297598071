import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import { PageHemeaContainer, MainTitle, PartnerContent, Content, SideImage, MainLogo, Title, Text, Item, Icon, ItemsList, AdviseBlock, Advise, Photo, ConfirmButton } from '../page-hemea/styled'
import { ProjectListWrapper } from '../page-project-list/styled'
import YellowStar from '../../static/yellow-star.svg'
import LukoLogo from '../../static/luko.svg'
import LukoHighRes from '../../static/lukohighres.png'

const PageLuko = () => {
    const [isAccepted, setIsAccepted] = useState(false)
    const history = useHistory()

    // we don't want the user to go directly to this page. In case of change, data from users & projects must be load first.
    if (history.action === 'POP') {
        history.push('/')
        return null
    }
    const redirectToLuko = () => {
        window.open(window.processRuntime.env.LUKO_PARTNER_URL, '_blank');
    }

    return (
        <ProjectListWrapper>
            <PageHemeaContainer>
                <MainTitle>Assurances</MainTitle>
                <PartnerContent>
                    <Content>
                        <MainLogo src={LukoLogo} alt="Luko Logo" isLuko />
                        <Title>Présentation de notre partenaire Luko</Title>
                        <Text noMargin>Luko est une néo-assurance habitation qui redéfinit experience de l’assurance.</Text>
                        <Text>Des contrats clairs, des remboursements 2x plus rapides, un modèle 100 % digital et transparent : avec Luko, c’est facile. Vous gagnez du temps et de l’argent.</Text>
                        <Text>Luko propose plusieurs offres d’assurances :</Text>
                        <ItemsList>
                            <Item>
                                <Icon src={YellowStar} alt="star-logo" />
                                L’assurance habitation
                            </Item>
                            <Item>
                                <Icon src={YellowStar} alt="star-logo" />
                                La responsabilité civile
                            </Item>
                            <Item>
                                <Icon src={YellowStar} alt="star-logo" />
                                L’assurance emprunteur (prêt immobilier)
                            </Item>
                        </ItemsList>
                        <Text>Luko c’est des contrats clairs, des remboursements 2x plus rapides et un modèle 100 % digital et transparent.</Text>
                        <Text>Changer d’assurance habitation ou d’assurance emprunteur et faire de belles économies est désormais possible et facile avec Luko</Text>
                        <Title>1 mois d’assurance habitation offert grâce à Algar</Title>
                        <Text noMargin>En un clic, vous serez redirigé vers Luko pour établir votre devis et votre souscription en ligne.</Text>
                        <Text bold>Cette mise en relation est gratuite</Text>
                        <AdviseBlock>
                            <Advise>
                                <input type="checkbox" onChange={e => setIsAccepted(e.target.checked)} />
                                En cochant cette case, j’accepte être redirigé vers le site internet de Luko via un lien affilié pour effectuer une demande de devis et / ou de souscription et profiter de l’offre un mois d’assurance habitation offert.
                            </Advise>
                        </AdviseBlock>
                        <ConfirmButton onClick={() => redirectToLuko()} disabled={!isAccepted}>
                            En profiter
                        </ConfirmButton>
                    </Content>
                    <SideImage isLuko>
                        <Photo src={LukoHighRes} alt="" />
                    </SideImage>
                </PartnerContent>
            </PageHemeaContainer>
        </ProjectListWrapper>
    )
}

export default PageLuko