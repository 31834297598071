import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { mq, ratioToPercent, reverseMq } from '../../utils/style'
import { FlexColumn, FlexRow, globalFluidContainerCss } from '../styled'

export const projectBodyGapCss = () => css`
  gap: 10px;

  @media screen and (min-width: 1441px) {
    gap: 20px;
  }
`

export const ProjectBody = styled(FlexRow)`
  ${globalFluidContainerCss}
  ${projectBodyGapCss}
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  padding: 20px 10px;
  flex-direction: column-reverse;

  &.modal-open {
    padding: 0;
  }

  @media screen and (min-width: 769px) {
    padding: 40px;
    flex-direction: row;

    &.modal-open {
      padding: 40px;
    }
  }

  @media screen and (min-width: 1025px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    min-height: 752px;
    overflow-y: auto;
  }

  @media screen and (min-width: 1441px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

export const ViewProject = styled(FlexColumn)`
  flex-grow: 1;
  padding-left: 0;
  overflow-x: hidden;
`

export const BottomBlock = styled(FlexRow)`
  height: 100%;
  scroll-behavior: smooth;
  justify-content: stretch;
  overflow: visible !important; // check if this is not breaking anything else

  ${(props) =>
    reverseMq(props)({
      WebkitOverflowScrolling: [null, 'touch']
    })}

  ${(props) =>
    mq(props)({
      flexWrap: ['nowrap', 'wrap'],
      overflowX: ['scroll', 'auto'],
      width: ['100%', 'calc(100% - 278px + 10px)']
    })}

  ${(props) =>
    reverseMq(props)({
      WebkitScrollSnapType: [null, 'mandatory'],
      scrollSnapType: [null, 'x mandatory'],
      scrollSnapDestination: [null, '50% 50%']
    })}

  width: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const StepsBlock = styled(FlexRow)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 278px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.beige};
  box-shadow: ${({ theme }) => theme.boxshadow.primary};
  ${(props) =>
    reverseMq(props)({
      WebkitOverflowScrolling: [null, 'touch']
    })}

  ${(props) =>
    mq(props)({
      width: [1, 'auto'].map(ratioToPercent),
      maxWidth: ['unset', '278px'],
      justifyContent: [null, 'flex-end']
    })}
  @media (max-width: 1024px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    padding: 15px;
  }
`

export const SecondaryBlock = styled(FlexColumn)`
  width: ${ratioToPercent(1)};
  min-width: ${ratioToPercent(1)};

  ${(props) =>
    mq(props)({
      padding: [`10px 0`, '5px']
    })}

  ${(props) =>
    mq(props)({
      height: [null, 1 / 3].map(ratioToPercent)
    })}

  ${(props) =>
    reverseMq(props)({
      scrollSnapCoordinates: [null, '50% 50%'],
      scrollSnapAlign: 'center'
    })}

  @media screen and (max-width: 768px) {
    height: max-content;
  }
`

const halfSecondaryBlockWidth = [1, 1 / 2].map(ratioToPercent)

export const HalfSecondaryBlock = styled(SecondaryBlock)`
  ${(props) =>
    mq(props)({
      width: halfSecondaryBlockWidth,
      minWidth: halfSecondaryBlockWidth
    })}
`

export const MapBlock = styled(SecondaryBlock)`
  overflow: hidden;
  border-radius: 5px;

  ${(props) =>
    mq(props)({
      maxHeight: ['141px', 'max-content']
    })}
`
export const ActionsBlock = styled(HalfSecondaryBlock)`
  @media screen and (max-width: 1024px) {
    padding: 0;
    height: 50%;
  }
`

export const FileBlock = styled(SecondaryBlock)`
  @media screen and (max-width: 768px) {
    height: 50%;
  }
`

export const DossierBlock = styled(HalfSecondaryBlock)``

export const StatusBlock = styled(HalfSecondaryBlock)``

export const PageCreateProjectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: calc(50px + 35px);
`
