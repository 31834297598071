import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Transition } from 'react-transition-group'
import { connect } from 'react-redux'
import { isNil } from 'lodash'

import {
  finishedUploadsIdsSelector,
  uploadErrorsIdsSelector
} from '../../selectors/file'
import {
  filesUploadRemove,
  filesUploadCancel
} from '../../actions/files'

import UploadsView from '../view-uploads'

const duration = 200

const getOpacityFromStatus = props => {
  const value = {
    entering: 0.01,
    entered: 1,
    exiting: 0.01,
    exited: 0.01
  }[props.status]
  return isNil(value) ? 1 : value
}

const totalMaxHeight = props => props.theme.spacings.small * 4
const getMaxHeightFromStatus = props => {
  const value = {
    entering: totalMaxHeight,
    entered: totalMaxHeight,
    exiting: 0.1,
    exited: 0.1
  }[props.status]
  return isNil(value) ? totalMaxHeight : value
}

const TransitionWrapper = styled('div')`
  height: 0;
  height: ${getMaxHeightFromStatus}px;
  opacity: 0;
  opacity: ${getOpacityFromStatus};
  transition: ${duration}ms;
  flex-shrink: 0;
  overflow: hidden;
`

class UploadsTransitionManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      shouldOut: false,
      in: false,
      mounted: false
    }
    this.cancelUpload = this.cancelUpload.bind(this)
    this.removeUpload = this.removeUpload.bind(this)
  }

  static getDerivedStateFromProps (props, state) {
    return props.uploadsFinished.includes(props.descriptor.id)
      ? { ...state, shouldOut: true }
      : state
  }

  componentDidMount () {
    // A slight delay is required to trigger the transition animation
    window.setTimeout(() => {
      this.setState({ in: true, mounted: true })
    }, 50)
  }

  componentDidUpdate () {
    // Make timeout possible by defering actual fadeout animation
    if (this.state.shouldOut === true && this.state.in === true) {
      window.setTimeout(() => {
        this.setState({ shouldOut: false, in: false })
      }, this.props.descriptor.timeout || 0)
    } else if (this.state.in === false && this.state.mounted === true) {
      window.setTimeout(() => {
        this.props.removeUpload(this.props.descriptor.id)
      }, duration)
    }
  }

  cancelUpload () {
    this.props.cancelUpload(this.props.descriptor.id)
  }

  removeUpload () {
    this.setState({ in: false })
  }

  render () {
    const hasError = this.props.uploadErrorsIds
      .includes(this.props.descriptor.id)
    return (
      <Transition in={this.state.in} timeout={duration}>
        {status => (
          <TransitionWrapper status={status}>
            <UploadsView
              descriptor={this.props.descriptor}
              hasError={hasError}
              abort={hasError ? this.removeUpload : this.cancelUpload}
              isDone={this.props.uploadsFinished.includes(this.props.descriptor.id)}
            />
          </TransitionWrapper>
        )}
      </Transition>
    )
  }
}

const mapStateToProps = (state) => ({
  uploadsFinished: finishedUploadsIdsSelector(state),
  uploadErrorsIds: uploadErrorsIdsSelector(state)
})

const mapDispatchToProps = dispatch => ({
  removeUpload: uploadId => dispatch(filesUploadRemove(uploadId)),
  cancelUpload: uploadId => dispatch(filesUploadCancel(uploadId))
})

UploadsTransitionManager.propTypes = {
  descriptor: PropTypes.object.isRequired,
  uploadsFinished: PropTypes.array.isRequired,
  uploadErrorsIds: PropTypes.array.isRequired,
  removeUpload: PropTypes.func.isRequired,
  cancelUpload: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadsTransitionManager)
