import {
  take,
  put,
  call,
  select,
  all,
  race,
  takeEvery
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import axios from 'axios'

import PMDC from '../utils/pmdc'
import { getCodeFromError } from '../utils/error'

import {
  FILES__LIST_START,
  FILES__UPLOAD_START,
  FILES__DELETE_START,
  FILES__UPLOAD_CANCEL,
  FILES__TEMPLATE_FOLDER_FETCH_START
} from '../actions'

import {
  filesListingSuccess,
  filesListingError,
  fileDeletionSuccess,
  fileDeletionError,
  filesAdvanceProgress,
  filesUploadSuccess,
  filesUploadError,
  templateFolderFetchSuccess,
  templatFolderFetchError
} from '../actions/files'

import { addErrorAlert, addSuccessAlert } from '../actions/alert'

import { logError } from '../actions/system'

function* listFilesByProjectId() {
  try {
    const { user, files } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getFilesByProject,
      files.filesListProjectId
    )

    let formattedData = []
    if (data) {
      formattedData = data.map((file, index) => {
        let _index = index
        let _name = file.name

        switch (file.name) {
          case `Mes documents`:
            _index = 1000
            break
          case `Dossier réalisé`:
            _index = 2000
            _name = `Production du dossier`
            break
          case `Documents reçus de l'administration`:
            _index = 3000
            _name = `Documents reçus de la mairie`
            break
          case `Urbanisme`:
            _index = 4000
            break
        }

        return {
          ...file,
          index: _index,
          name: _name
        }
      })
    }

    yield put(filesListingSuccess(formattedData))
  } catch (err) {
    yield put(
      filesListingError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération des fichiers'
      })
    )
    yield put(logError(err))
  }
}

function* watchUploadProgress(file, progressChannel) {
  try {
    while (true) {
      const infos = yield take(progressChannel)
      if (infos.finished) {
        yield put(
          filesUploadSuccess({
            id: infos.id,
            file: {
              ...infos.response.data,
              createdAt: new Date().toISOString()
            }
          })
        )
        yield put(
          addSuccessAlert({
            message: `Le fichier "${file.name}" a été uploadé avec succès !`
          })
        )
        break
      } else {
        yield put(filesAdvanceProgress(infos))
      }
    }
  } catch (err) {
    progressChannel.close()
    const errormsg = `Erreur lors de l'upload du fichier "${file.name}"`
    yield put(
      filesUploadError({
        code: getCodeFromError(err),
        message: errormsg,
        id: file.id
      })
    )
    yield put(
      addErrorAlert({
        message: errormsg
      })
    )
    yield put(logError(err))
  }
}

function* watchUploadCancel(file, progressChannel) {
  try {
    while (true) {
      const action = yield take(FILES__UPLOAD_CANCEL)
      if (action.payload === file.id) {
        yield progressChannel.close()
        break
      }
    }
  } catch (err) {
    progressChannel.close()
    yield put(
      filesUploadError({
        code: getCodeFromError(err),
        message: "Erreur lors de l'annulation de l'upload",
        id: file.id
      })
    )
    yield put(logError(err))
  }
}

function* handleFileUpload(file) {
  const formData = yield new window.FormData()
  yield formData.append('file', file.content)
  const { user, files } = yield select()

  // Define upload progress channel
  const progressChannel = eventChannel((emit) => {
    const source = axios.CancelToken.source()
    PMDC(user.accessToken, user.decodedAccessToken)
      .uploadFileByProject(files.filesListProjectId, formData, {
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          // TODO : Actually process upload progress
          emit({
            id: file.id,
            progress: progressEvent.loaded,
            total: progressEvent.total,
            finished: false
          })
        }
      })
      .then((res) => {
        emit({
          id: file.id,
          finished: true,
          response: res
        })
      })
      .catch((err) => {
        emit(err)
      })

    // Return unsubscribe function
    const unsubscribe = () => {
      source.cancel('Request cancelled')
    }
    return unsubscribe
  })

  yield race([
    call(watchUploadProgress, file, progressChannel),
    call(watchUploadCancel, file, progressChannel)
  ])
}

function* uploadFiles(action) {
  yield all(action.payload.map((obj) => call(handleFileUpload, obj.file)))
}

function* deleteFileById(action) {
  const fileId = action.payload
  try {
    const { user } = yield select()
    yield call(
      PMDC(user.accessToken, user.decodedAccessToken).deleteFileById,
      fileId
    )
    yield put(fileDeletionSuccess(fileId))
  } catch (err) {
    yield put(
      fileDeletionError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la suppression du fichier',
        fileId
      })
    )
    yield put(logError(err))
  }
}

function* fetchTemplateFolderContents() {
  try {
    const res = yield call(PMDC().getTemplateFolderContents)
    yield put(templateFolderFetchSuccess(res.data))
  } catch (err) {
    yield put(
      templatFolderFetchError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération des fichiers'
      })
    )
    yield put(logError(err))
  }
}

export default [
  takeEvery(FILES__LIST_START, listFilesByProjectId),
  takeEvery(FILES__UPLOAD_START, uploadFiles),
  takeEvery(FILES__DELETE_START, deleteFileById),
  takeEvery(FILES__TEMPLATE_FOLDER_FETCH_START, fetchTemplateFolderContents)
]
