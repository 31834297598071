import { faCheckSquare } from '@fortawesome/pro-duotone-svg-icons'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadingUpdateName } from '../../actions/project'
import { updateProjectNameSelector } from '../../selectors'
import { isEllipsisActive } from '../../utils/dom'
import { LoadingSpinnerSmall } from '../loading-spinner'
import { HomeBlock, HomeBlockTitle } from '../styled'
import TextTooltip from '../tooltip'
import { isMobile } from '../../utils'
import {
  DetailContent,
  DetailTitle,
  DetailContainer,
  EditProjectNameIcon,
  ErrorMessage,
  FormContainerProject,
  Input,
  SaveProjectNameIcon,
  SingleDetail,
  HomeBlockDetails
} from './styled'
import { useHistory } from 'react-router-dom'
import { DEMO_ROUTE } from '../../constants/router'

const ProjectDetail = ({ detail, id }) => {
  const dispatch = useDispatch()
  const detailContentRef = useRef(null)
  const showDetailContentTooltip = detailContentRef?.current
    ? isEllipsisActive(detailContentRef.current)
    : false

  const { updateNameLoading, updateNameError, selectedProject } = useSelector(
    updateProjectNameSelector
  )

  const isName = detail.id === 1
  const [isEdit, setIsEditName] = useState(false)
  const [name, setName] = useState(isName ? detail.content : '')

  const {
    location: { pathname }
  } = useHistory()

  const isDemoProject = pathname === DEMO_ROUTE

  const handleClickEdit = () => setIsEditName(!isEdit)
  const handleChangeName = (event) => setName(event.target.value)

  const handleSave = (event) => {
    event.preventDefault()
    dispatch(startLoadingUpdateName(selectedProject, name))
  }

  // Hide input after update
  useEffect(() => {
    if (isEdit) handleClickEdit()
  }, [selectedProject])

  return (
    <SingleDetail>
      <DetailTitle>{detail.title}</DetailTitle>
      {isName && isEdit ? (
        <Fragment>
          <FormContainerProject>
            <Input
              type="text"
              value={name}
              onChange={handleChangeName}
              disabled={updateNameLoading}
              onKeyPress={(e) =>
                e.key === 'Enter' && name ? handleSave(e) : undefined
              }
            />
            <SaveProjectNameIcon
              onClick={name ? handleSave : undefined}
              icon={faCheckSquare}
              size="1x"
              disabled={!name || updateNameLoading}
            />
            {updateNameLoading && !updateNameError && (
              <LoadingSpinnerSmall size={15} />
            )}
          </FormContainerProject>
          {updateNameError && !updateNameLoading && (
            <ErrorMessage>{updateNameError.message}</ErrorMessage>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {showDetailContentTooltip ? (
            <TextTooltip
              element={
                <DetailContent ref={detailContentRef}>
                  {detail.content}
                </DetailContent>
              }
              text={detail.content}
              multiline={false}
            />
          ) : (
            <DetailContainer>
              <DetailContent ref={detailContentRef}>
                {detail.content}
              </DetailContent>
              {isName && !isMobile && (
                <EditProjectNameIcon
                  size="2x"
                  icon={faPenToSquare}
                  onClick={
                    isEdit || isDemoProject ? undefined : handleClickEdit
                  }
                  disabled={isEdit || isDemoProject}
                />
              )}
            </DetailContainer>
          )}
        </Fragment>
      )}
    </SingleDetail>
  )
}

const detailPropShape = {
  id: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string
}

ProjectDetail.propTypes = {
  detail: PropTypes.shape(detailPropShape).isRequired
}

const DetailsView = (props) => {
  return (
    <HomeBlock centerOnMobile>
      <HomeBlockTitle>Références</HomeBlockTitle>
      <HomeBlockDetails>
        {props.details.map((detail, i) => (
          <ProjectDetail
            key={detail.title}
            id={`detail-${i}`}
            detail={detail}
          />
        ))}
      </HomeBlockDetails>
    </HomeBlock>
  )
}

DetailsView.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape(detailPropShape)),
  id: PropTypes.string
}

export default DetailsView
