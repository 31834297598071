import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { PageHemeaContainer, MainTitle, PartnerContent, Content, SideImage, MainLogo, Title, Text, Item, Icon, ItemsList, AdviseBlock, Advise, Photo, ConfirmButton } from './styled'
import { ProjectListWrapper } from '../page-project-list/styled'
import { useSelector } from 'react-redux'
import {
    projectSelector,
    selectedProjectSelector,
    userSelector,
    isHemeaSubmittedSelector
} from '../../selectors'
import YellowStar from '../../static/yellow-star.svg'
import aHighRes from '../../static/imageAhighres.jpg'
import bHighRes from '../../static/imageBhighres.jpg'

const PageHemea = () => {
    const [isAccepted, setIsAccepted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const history = useHistory()

    // we don't want the user to go directly to this page. In case of change, data from users & projects must be load first.
    if (history.action === 'POP') {
        history.push('/')
        return null
    }

    const isHemeaSubmitted = useSelector(isHemeaSubmittedSelector)

    const selectedProject = useSelector(selectedProjectSelector)
    const { profile } = useSelector(userSelector)
    const { workTypesIds } = (selectedProject || {})
    const { workTypes } = useSelector(projectSelector)
    const projectWorkTypes = Object.values(workTypes).filter((wt) =>
        workTypesIds.includes(wt.id)
    ).map(w => w = w.tunnelCode)
    const { lastName, firstName, phoneNumber } = profile
    const { address } = selectedProject
    const { dealId } = selectedProject.dealAssociation

    const sendInfo = () => {
        const dataForHemea = {
            dealId,
            projectWorkTypes,
            address,
            firstName,
            lastName,
            phoneNumber,
            isAccepted
        }
        axios.post(window.processRuntime.env.MAKE_HEMEA_SCENARIO, dataForHemea)
            .then((response) => {
                setIsSubmitted(true)
            }, (error) => {
                console.log(error)
            })
    }

    return (
        <ProjectListWrapper>
            <PageHemeaContainer>
                <MainTitle>Artisans</MainTitle>
                <PartnerContent>
                    <Content>
                        <MainLogo src="https://images.hemea.com/static/logo-hemea-black-v2.png" alt="Hemea Logo" />
                        <Title>Présentation de notre partenaire Hemea</Title>
                        <Text>Hemea est un cabinet d'architecture et de rénovation qui accompagne les particuliers sur leurs projets de rénovation, extension, surélévation et construction.</Text>
                        <Text>Opérant sur la France entière en proposant un accompagnement de A à Z depuis la phase de conception jusqu'à la livraison du chantier, nous avons choisi pour la qualité de son réseau d'entrepreneurs, soigneusement sélectionnés. Hemea apporte un accompagnement du début à la fin du projet en sécurisant les flux financiers et en apportant des garanties exclusives.</Text>
                        <ItemsList>
                            <Item>
                                <Icon src={YellowStar} alt="star-logo" />
                                Respect du planning et des budgets</Item>
                            <Item>
                                <Icon src={YellowStar} alt="star-logo" />
                                Accompagnement sur-mesure</Item>
                            <Item>
                                <Icon src={YellowStar} alt="star-logo" />
                                Équipe d’experts</Item>
                            <Item>
                                <Icon src={YellowStar} alt="star-logo" />
                                Sélection de garanties exclusives (pénalités de retard, compte séquestre, etc)</Item>
                        </ItemsList>
                        <Text>Nous savons à quel point il est difficile de trouver des artisans de qualité et surtout de confiance.</Text>
                        <Text>Pour vous simplifier la vie, nous avons établi un partenariat exclusif avec Hemea qui vous offrira une prise en charge et un service optimal.</Text>
                        <Title>Comment être mis en relation avec Hemea ?</Title>
                        <Text noMargin>En un clic, vos coordonnées et informations de votre projet seront transmis à Hemea de manière sécurisée. Une personne de chez Hemea pourra alors vous contacter pour vous établir un devis avec ses artisans de qualité.</Text>
                        <Text bold>Cette mise en relation est gratuite</Text>
                        <AdviseBlock>
                            <Advise>
                                <input type="checkbox" onChange={e => setIsAccepted(e.target.checked)} checked={isHemeaSubmitted ? true : isAccepted} />
                                En cochant cette case, j’accepte que mes coordonées et les informations de mon projet soient transmises à la société Hemea et uniquement à la société Hemea de manière sécurisée. J’accepte également que la société Hemea prenne contact avec moi par téléphone et / ou par e-mail.
                            </Advise>
                        </AdviseBlock>
                        <ConfirmButton disabled={!isAccepted || isHemeaSubmitted || isSubmitted} onClick={() => sendInfo()}>
                            {isSubmitted || isHemeaSubmitted ? 'Demande envoyée' : 'Envoyer ma demande'}
                        </ConfirmButton>
                    </Content>
                    <SideImage>
                        <Photo src={aHighRes} alt="" />
                        <Photo src={bHighRes} alt="" />
                    </SideImage>
                </PartnerContent>
            </PageHemeaContainer>
        </ProjectListWrapper>
    )
}

export default PageHemea