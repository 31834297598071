import uuidv4 from 'uuid/v4'

import {
  FILES__LIST_START,
  FILES__LIST_SUCCESS,
  FILES__LIST_ERROR,

  FILES__UPLOAD_START,
  FILES__UPLOAD_PROGRESS,
  FILES__UPLOAD_CANCEL,
  FILES__UPLOAD_SUCCESS,
  FILES__UPLOAD_REMOVE,
  FILES__UPLOAD_ERROR,

  FILES__DELETE_START,
  FILES__DELETE_SUCCESS,
  FILES__DELETE_ERROR,

  FILES__TEMPLATE_FOLDER_FETCH_START,
  FILES__TEMPLATE_FOLDER_FETCH_SUCCESS,
  FILES__TEMPLATE_FOLDER_FETCH_ERROR
} from '.'

export const startFilesListing = projectId => ({
  type: FILES__LIST_START,
  payload: projectId
})

export const filesListingSuccess = files => ({
  type: FILES__LIST_SUCCESS,
  payload: files
})

export const filesListingError = error => ({
  type: FILES__LIST_ERROR,
  payload: error
})

export const startUploadingFiles = files => ({
  type: FILES__UPLOAD_START,
  payload: files.map(file => {
    const id = uuidv4()
    return {
      descriptor: {
        id,
        timeout: 1500,
        name: file.name
      },
      file: {
        id,
        content: file,
        name: file.name
      }
    }
  })
})

export const filesUploadCancel = uploadId => ({
  type: FILES__UPLOAD_CANCEL,
  payload: uploadId
})

export const filesUploadRemove = uploadId => ({
  type: FILES__UPLOAD_REMOVE,
  payload: uploadId
})

export const filesUploadSuccess = infos => ({
  type: FILES__UPLOAD_SUCCESS,
  payload: infos
})

export const filesUploadError = error => ({
  type: FILES__UPLOAD_ERROR,
  payload: error
})

export const filesAdvanceProgress = progressData => ({
  type: FILES__UPLOAD_PROGRESS,
  payload: progressData
})

export const startDeletingFile = fileId => ({
  type: FILES__DELETE_START,
  payload: fileId
})

export const fileDeletionSuccess = fileId => ({
  type: FILES__DELETE_SUCCESS,
  payload: fileId
})

export const fileDeletionError = error => ({
  type: FILES__DELETE_ERROR,
  payload: error
})

export const startFetchingTemplateFolder = () => ({
  type: FILES__TEMPLATE_FOLDER_FETCH_START
})

export const templateFolderFetchSuccess = contents => ({
  type: FILES__TEMPLATE_FOLDER_FETCH_SUCCESS,
  payload: contents
})

export const templatFolderFetchError = error => ({
  type: FILES__TEMPLATE_FOLDER_FETCH_ERROR,
  payload: error
})
