export const dateFilters = [
    { code: 'createdAt', name: 'Créé le' },
    { code: 'dateExpedition', name: 'Déposé le' },
    { code: 'dateDepotMontage', name: "Début de l'instruction" },
    { code: 'dateFinInstruction', name: "Fin d'instruction estimée" },
    { code: 'dateReceptionDpc', name: "Réception d'une DPC" },
    { code: 'dateDepotPc', name: 'Dépôt des pièces complémentaires' },
    { code: 'decision', name: 'Décision' }
]

export const filterModels = [
    { title: 'En préparation', subtitle: 'Dossiers en préparation avant dépôt', textColor: '#001556', backgroundColor: '#BEC1C9' },
    { title: 'En instruction', subtitle: 'En attente de la décision de la mairie', textColor: '#D19617', backgroundColor: '#F9DDA1' },
    { title: 'Pièces complémentaires', subtitle: 'Dossiers en cours de modification ', textColor: '#2E292F', backgroundColor: '#C2ADC4' },
    { title: 'En approche de validation tacite', subtitle: 'Vadidations tacites à venir dans les 10 jours', textColor: '#455548', backgroundColor: '#D9C395' },
    { title: 'Validations', subtitle: 'Dossiers validés ces 30 derniers jours', textColor: '#455548', backgroundColor: '#6EB894' },
    { title: 'Refus', subtitle: 'Dossiers refusés ces 30 derniers jours', textColor: '#FF5151', backgroundColor: '#F8B7B2' }
]

export const filterGroups = [
    { name: '', dealstageIncluded: '', }
]