import {
  STEP__LOAD_META_START,
  STEP__LOAD_META_SUCCESS,
  STEP__LOAD_META_ERROR,
  STEP__LOAD_BY_PROJECT_START,
  STEP__LOAD_BY_PROJECT_SUCCESS,
  STEP__LOAD_BY_PROJECT_ERROR
} from '.'

export const startLoadingStepMetas = payload => ({
  type: STEP__LOAD_META_START,
  payload
})

export const loadStepsMetaSuccess = meta => ({
  type: STEP__LOAD_META_SUCCESS,
  payload: meta
})

export const loadStepsMetaError = error => ({
  type: STEP__LOAD_META_ERROR,
  payload: error
})

export const startLoadingStepsByProject = () => ({
  type: STEP__LOAD_BY_PROJECT_START
})

export const loadStepsByProjectSuccess = steps => ({
  type: STEP__LOAD_BY_PROJECT_SUCCESS,
  payload: steps
})

export const loadStepsbyProjectError = error => ({
  type: STEP__LOAD_BY_PROJECT_ERROR,
  payload: error
})
