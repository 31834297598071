import axios from 'axios'
import URI from 'urijs'

const getPmdcAxiosInstance = (accessToken, axiosOptions, directory = '/v1') => {
  const baseURL = URI(window.processRuntime.env.API_URL)
    .directory(directory)
    .toString()

  return axios.create({
    baseURL,
    ...axiosOptions,
    headers: {
      ...((accessToken && { Authorization: `Bearer ${accessToken}` }) || {}),
      ...((axiosOptions && axiosOptions.headers) || {})
    }
  })
}

export default getPmdcAxiosInstance
