import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { chain } from 'lodash'

import { faTimes, faFolder } from '@fortawesome/pro-light-svg-icons'
import { faDownload, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { faPlusCircle, faAngleLeft } from '@fortawesome/pro-solid-svg-icons'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons-v5'

import { FILE_SORTBY_CRITERIAS, FILE_SORTBY_ORDERS } from '../../utils/render'
import { isMobile } from '../../utils'
import FileView from '../view-file'

import {
  FilesBlockTitle,
  FileUploadButton,
  FileUploadText,
  FilesBlockBackBtn,
  FilesBlockTitleContainer
} from './styled'

import UploadsTransitionManager from '../uploads-transition-manager'

import CustomConfirmOverlay from '../overlay-custom-confirm'
import {
  HomeBlockContentFiles,
  HomeBlockFiles,
  HomeBlockFilesContainer
} from '../view-file/styled'
import { setAccountActivationOverlay } from '../../actions/overlay'
import { connect } from 'react-redux'
import { DEMO_ROUTE } from '../../constants/router'

class FilesView extends PureComponent {
  constructor(props) {
    super(props)
    this.handleFileDelete = this.handleFileDelete.bind(this)
    this.handleDownloadFile = this.handleDownloadFile.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.state = {
      fileSelected: null,
      expandedFolders: [],
      animation: '',
      downloadLoading: false
    }
  }

  handleFileDelete() {
    if (this.state.fileSelected?.id) {
      this.props.deleteFile(this.state.fileSelected.id)
      this.closeModal()
    }
  }

  async handleDownloadFile() {
    this.setState((prevState) => ({ ...prevState, downloadLoading: true }))

    const { id, name, mimeType } = this.state.fileSelected
    // this.props.previewFile(id, mimeType)
    const apiBaseUrl = window.processRuntime.env.API_URL
    const downloadLink = `${apiBaseUrl}/v1/files/${id}?authKey=${this.props.accessToken}`

    const fileToDownload = await fetch(downloadLink)
    const fileBlob = await fileToDownload.blob()
    const fileURL = URL.createObjectURL(fileBlob)

    const anchor = document.createElement('a')
    anchor.href = fileURL
    anchor.download = name
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)

    URL.revokeObjectURL(fileURL)

    this.setState((prevState) => ({ ...prevState, downloadLoading: false }))
  }

  openModal(event, process) {
    const { name, value } = event.currentTarget
    const mimeType = event.currentTarget.dataset.mimetype

    this.setState((prevState) => ({
      ...prevState,
      fileSelected: {
        id: value,
        name,
        process,
        mimeType
      }
    }))
    document
      .getElementsByClassName('projectBody')[0]
      ?.classList.toggle('modal-open')
  }

  closeModal() {
    this.setState((prevState) => ({
      ...prevState,
      fileSelected: null
    }))
    document
      .getElementsByClassName('projectBody')[0]
      ?.classList.remove('modal-open')
  }

  getModalInfos() {
    let modalTitle = ''
    let modalDesc = ''
    let confirmText = ''

    const { fileSelected } = this.state

    if (fileSelected) {
      if (fileSelected.process === 'delete') {
        modalTitle = 'Suppression'
        confirmText = 'Supprimer'
        modalDesc = `Voulez-vous supprimer<br/><b>${fileSelected.name}</b> ?`
      } else {
        modalTitle = 'Téléchargement'
        confirmText = 'Télécharger'
        modalDesc = `Voulez-vous télécharger<br/><b>${fileSelected.name}</b> ?`
      }
    }

    return {
      modalDesc,
      modalTitle,
      confirmText
    }
  }

  handleClick(event) {
    const isFolder = event.currentTarget.dataset.isfolder === 'true'
    const id = event.currentTarget.value
    const mimeType = event.currentTarget.dataset.mimetype

    if (isFolder) {
      this.setState((prevState) => ({
        expandedFolders: prevState.expandedFolders.includes(id)
          ? prevState.expandedFolders.filter((expandedId) => expandedId !== id)
          : [...prevState.expandedFolders, id],
        animation: 'slideInRight'
      }))
    } else {
      this.props.previewFile(id, mimeType)
    }
  }

  render() {
    const { expandedFolders, fileSelected, animation, downloadLoading } = this.state
    const isFolderExpanded = expandedFolders.length > 0

    return (
      <HomeBlockFiles className={this.props.className}>
        <HomeBlockFilesContainer className={animation}>
          <FilesBlockTitleContainer isFolderExpanded={isFolderExpanded}>
            <FilesBlockTitle>
              {isFolderExpanded && isMobile ? (
                <FilesBlockBackBtn
                  onClick={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      expandedFolders: [],
                      animation: 'slideInLeft'
                    }))
                  }
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    size="sm"
                    color={this.props.theme.colors.primary}
                  />
                  <FontAwesomeIcon
                    icon={faFolder}
                    size="lg"
                    color={this.props.theme.colors.primary}
                    fixedWidth
                  />
                  <p>
                    {
                      this.props.rootFiles.filter(
                        (a) => a.id === this.state.expandedFolders[0]
                      )[0].name
                    }
                  </p>
                </FilesBlockBackBtn>
              ) : (
                <p>Documents</p>
              )}
            </FilesBlockTitle>
            {window.location.pathname === DEMO_ROUTE ? (
              <FileUploadButton
                color={this.props.theme.colors.primary}
                invertColor={this.props.theme.colors.white}
                onClick={() => {
                  this.props.setAccountActivationOverlay()
                }}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="1x"
                  color={this.props.theme.colors.white}
                  fixedWidth
                  swapOpacity
                />
                <FileUploadText>Ajouter un document</FileUploadText>
              </FileUploadButton>
            ) : (
              <Dropzone
                onDrop={this.props.handleFileUpload}
                disabled={this.props.disableInput}
              >
                {({ getRootProps, getInputProps }) => (
                  <FileUploadButton
                    {...getRootProps()}
                    disabled={this.props.disableInput}
                    color={this.props.theme.colors.primary}
                    invertColor={this.props.theme.colors.white}
                    hasExpandedFiles={this.state.expandedFolders.length > 0}
                  >
                    <input {...getInputProps()} />
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      size="1x"
                      color={this.props.theme.colors.white}
                      fixedWidth
                      swapOpacity
                    />
                    <FileUploadText>Ajouter un document</FileUploadText>
                  </FileUploadButton>
                )}
              </Dropzone>
            )}
          </FilesBlockTitleContainer>

          <HomeBlockContentFiles isFolderExpanded={isFolderExpanded}>
            {chain(this.props.uploadingFilesDescriptors)
              .orderBy(FILE_SORTBY_CRITERIAS, FILE_SORTBY_ORDERS)
              .filter((file) => file.name !== 'Urbanisme')
              .map((descriptor) => (
                <UploadsTransitionManager
                  key={descriptor.id}
                  descriptor={descriptor}
                />
              ))
              .value()}
            {chain(
              isMobile && expandedFolders.length > 0
                ? this.props.rootFiles.filter(
                  (a) => a.id === expandedFolders[0]
                )
                : this.props.rootFiles
            )
              .orderBy(FILE_SORTBY_CRITERIAS, FILE_SORTBY_ORDERS)
              .filter((file) => file.name !== 'Contrat & Facturation')
              .map((f) => (
                <FileView
                  key={f.id}
                  expandedFolders={expandedFolders}
                  loadingFilesIds={this.props.loadingFilesIds}
                  onClick={this.handleClick}
                  onFileDelete={(e) => this.openModal(e, 'delete')}
                  onFileDownload={(e) => this.openModal(e, 'download')}
                  theme={this.props.theme}
                  getFileDownloadLink={this.props.getFileDownloadLink}
                  files={this.props.files}
                  canRead={f.canRead}
                  canWrite={f.canWrite}
                  {...f}
                />
              ))
              .value()}
          </HomeBlockContentFiles>

          {fileSelected && (
            <CustomConfirmOverlay
              title={this.getModalInfos().modalTitle}
              body={
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.getModalInfos().modalDesc
                  }}
                />
              }
              handleConfirm={
                fileSelected.process === 'delete'
                  ? this.handleFileDelete
                  : this.handleDownloadFile
              }
              handleReject={this.closeModal}
              confirmIcon={
                fileSelected.process === 'delete'
                  ? faTrash
                  : downloadLoading
                    ? faSpinnerThird
                    : faDownload
              }
              rejectIcon={faTimes}
              confirmText={this.getModalInfos().confirmText}
              actionType={`${fileSelected.process === 'delete' ? 'delete' : ''
                }`}
              confirmDisabled={downloadLoading}
              confirmClassName={downloadLoading && 'has-download-file-loading'}
            />
          )}
        </HomeBlockFilesContainer>
      </HomeBlockFiles>
    )
  }
}

FilesView.propTypes = {
  theme: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  rootFiles: PropTypes.array.isRequired,
  loadingFilesIds: PropTypes.array.isRequired,
  deleteFile: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func,
  getFileDownloadLink: PropTypes.func.isRequired,
  uploadingFilesDescriptors: PropTypes.array.isRequired,
  disableInput: PropTypes.bool,
  previewFile: PropTypes.func.isRequired,
  className: PropTypes.string
}

const mapDispatchToProps = (dispatch) => ({
  setAccountActivationOverlay: () => dispatch(setAccountActivationOverlay())
})

export default connect(null, mapDispatchToProps)(withTheme(FilesView))
