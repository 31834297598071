import uuidv4 from 'uuid/v4'

import {
  ALERT__ADD,
  ALERT__REMOVE,
  ALERT__DELETE
} from '.'

export const addAlert = alert => ({
  type: ALERT__ADD,
  payload: {
    ...alert,
    id: uuidv4()
  }
})

export const addErrorAlert = alert => addAlert({
  timeout: 5000,
  ...alert,
  type: 'error'
})

export const addSuccessAlert = alert => addAlert({
  timeout: 5000,
  ...alert,
  type: 'success'
})

export const removeAlert = alertId => ({
  type: ALERT__REMOVE,
  payload: alertId
})

export const deleteAlert = alertId => ({
  type: ALERT__DELETE,
  payload: alertId
})
