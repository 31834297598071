import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startCheckingSession } from '../../actions/user'

import withWait from './with-wait'

const withCheckSession = (SubComponent, mandatory) => {
  const SubComponentWithWait = withWait(SubComponent, mandatory)

  const WithCheckSession = class extends Component {
    componentDidMount () {
      this.props.startCheckingSession()
    }

    render () {
      const {
        startCheckingSession,
        accessToken,
        accessTokenLoading,
        accessTokenError,
        ...passThroughProps
      } = this.props

      return (
        <SubComponentWithWait
          loading={accessTokenLoading || (!accessTokenError && !accessToken)}
          error={accessTokenError}
          isAuthenticated={!!accessToken}
          {...passThroughProps}
        />
      )
    }
  }

  WithCheckSession.propTypes = {
    startCheckingSession: PropTypes.func.isRequired,
    accessToken: PropTypes.string,
    accessTokenLoading: PropTypes.bool.isRequired,
    accessTokenError: PropTypes.object
  }

  const mapStateToProps = ({ user }) => ({
    accessToken: user.accessToken,
    accessTokenLoading: user.accessTokenLoading,
    accessTokenError: user.accessTokenError
  })

  const mapDispatchToProps = dispatch => ({
    startCheckingSession: () => dispatch(startCheckingSession())
  })

  return connect(mapStateToProps, mapDispatchToProps)(WithCheckSession)
}

export default withCheckSession
