import {
  faArrowLeft,
  faBars,
  faBell,
  faCaretDown,
  faCircle,
  faCircleUser
} from '@fortawesome/pro-solid-svg-icons'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toggleMultiNotifsMenu, toggleOptionsMenu } from '../../actions/menus'
import { startLoadingNotificationsByUser } from '../../actions/notification'
import { changePage } from '../../actions/project'
import { animationDelay } from '../../constants/styles'
import useWindowSize from '../../hooks/useWindowSize'
import {
  projectPageSelector,
  projectSelector,
  userSelector,
  isBtoBSelector,
  isInitialisationTerrealSelector,
  isTerrealCustomerSelector
} from '../../selectors'
import { notifSelector } from '../../selectors/notification'
import IndexProImage from '../../static/algar-pro-logo-white.svg'
import IndexTerrealImage from '../../static/terreal.svg'
import IndexImage from '../../static/algar-logo-white.svg'
import MultiNotifsMenuContainer from '../container-multi-notifs-menu'
import { IconContainer } from '../container-multi-notifs-menu/styled'
import NotificationsContainerOverlay from '../container-notification-overly'
import {
  closeNotifOverlayAnimation,
  openNotifOverlayAnimation
} from '../container-notification-overly/styled'
import SidebarContainerOverlay from '../container-sidebar-overly'
import {
  closeBurgerMenuAnimation,
  openBurgerMenuAnimation
} from '../container-sidebar-overly/styled'

import useOnClickOutside from '../../hooks/useClickOutside'
import {
  HeaderContent,
  HeaderHeadlineWrapper,
  HeaderIcon,
  HeaderSvgContainer,
  LogoWrapper,
  NotifContainer,
  ProjectHeader,
  RedPointIcon,
  Title,
  TitleWrapper,
  UserAccount,
  UserAccountMenu,
  UserAccountMenuItem,
  UserAccountMenuContent,
  UserArrow,
  UserAvatar,
  UserContent,
  UserMenuContainer,
  UserAccountMenuTitle
} from './styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLockKeyhole,
  faArrowRightFromBracket
} from '@fortawesome/pro-regular-svg-icons'
import { setChangePasswordOverlay } from '../../actions/overlay'

const getAvatarUrl = (props) =>
  [
    window.processRuntime.env.API_URL,
    'v1',
    'workers',
    props.facilitator ? props.facilitator.id : props.seller.id,
    'avatar'
  ].join('/')

const ProjectHeaderView = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const refUserMenu = useRef(null)
  const { isTabletOrMobile,isMobile } = useWindowSize()
  const {
    location: { pathname }
  } = useHistory()

  const user = useSelector(userSelector)
  const isBtoB = useSelector(isBtoBSelector)
  const isTerrealCustomer = useSelector(isTerrealCustomerSelector)
  const isInitialisationTerreal = useSelector(isInitialisationTerrealSelector)
  const [homeIcon, setHomeIcon] = useState('')
  const [imageStyle, setImageStyle] = useState({})

  useEffect(() => {
    if (isBtoB) {
      if (isTerrealCustomer) {
        setHomeIcon(IndexTerrealImage)
        setImageStyle({
          transform: 'scale(1.8)',
          marginLeft: isMobile ? '0px' : '25px'
        })
      } else {
        setHomeIcon(IndexProImage)
        setImageStyle({ transform: 'scale(1)' })
      }
    } else {
      setHomeIcon(IndexImage)
      setImageStyle({ transform: 'scale(1)' })
    }
  },  [isBtoB, isInitialisationTerreal, isTerrealCustomer, isMobile])

  const {
    userNotifications,
    userNotificationsLoading,
    userNotificationsError
  } = useSelector(notifSelector)
  const projectPage = useSelector(projectPageSelector)

  const { selectedProjectFacilitator, selectedProjectSeller } = useSelector(projectSelector)

  const [openBurgerMenuOverlay, setOpenBurgerMenuOverlay] = useState(false)
  const [openNotificationOverlay, setOpenNotificationOverlay] = useState(false)

  const [burgerMenuAnimation, setBurgerMenuAnimation] = useState(
    openBurgerMenuAnimation
  )

  const [notifOverlayAnimation, setNotifOverlayAnimation] = useState(
    openNotifOverlayAnimation
  )

  const [openUserAccount, setOpenUserAccount] = useState(false)
  const isCreateProjectPage = projectPage === 'page-create-new-project'
  const isHasUnreadNotif = userNotifications?.find((notif) => !notif.isRead) || false

  useOnClickOutside(refUserMenu, () => setOpenUserAccount(false), 'userAccountBtn')

  // Get userNotifications
  useEffect(() => {
    if (
      user &&
      user.profile &&
      !userNotifications &&
      !userNotificationsLoading &&
      !userNotificationsError
    ) {
      dispatch(startLoadingNotificationsByUser(user, { limit: 15, offset: 0 }))
    }
  }, [
    user,
    userNotifications,
    userNotificationsLoading,
    userNotificationsError,
  ])

  const handleOpenBurgerMenu = () => {
    setBurgerMenuAnimation(openBurgerMenuAnimation)
    setOpenBurgerMenuOverlay(true)
  }

  const handleCloseBurgerMenu = () => {
    setBurgerMenuAnimation(closeBurgerMenuAnimation)
    setTimeout(() => {
      setOpenBurgerMenuOverlay(false)
    }, animationDelay * 1000)
  }

  const handleOpenNotificationOverlay = () => {
    setNotifOverlayAnimation(openNotifOverlayAnimation)
    setOpenNotificationOverlay(true)
  }

  const handleCloseNotificationOverlay = () => {
    setNotifOverlayAnimation(closeNotifOverlayAnimation)
    setTimeout(() => {
      setOpenNotificationOverlay(false)
    }, animationDelay * 1000)
  }

  const onClickArrowLeft = () => {
    dispatch(changePage('switch'))
  }

  const handleClickChangePassword = () => {
    setOpenUserAccount(false)
    dispatch(setChangePasswordOverlay())
  }

  const handleLogout = () => {
    setOpenUserAccount(false)
    history.push('/logout')
  }

  return (
    <ProjectHeader pathname={pathname}>
      <HeaderContent>
        <HeaderHeadlineWrapper>
          {isTabletOrMobile &&
            (isCreateProjectPage ? (
              <IconContainer onClick={onClickArrowLeft}>
                <HeaderIcon size="3x" icon={faArrowLeft} />
              </IconContainer>
            ) : (
              <IconContainer onClick={handleOpenBurgerMenu}>
                <HeaderIcon size="3x" icon={faBars} />
              </IconContainer>
            ))}

          {homeIcon !== '' && (
            <LogoWrapper
              href={window.processRuntime.env.HP_BASE_URL}
              title="Algar.co"
              isbtob={isBtoB}
            >
              <img src={homeIcon} style={imageStyle} alt="accueil" />
            </LogoWrapper>
          )}
        </HeaderHeadlineWrapper>

        <UserContent>
          <NotifContainer>
            {isTabletOrMobile ? (
              <IconContainer onClick={handleOpenNotificationOverlay}>
                <HeaderIcon size="3x" icon={faBell} />
                {userNotifications?.filter((notif) => !notif.isRead).length >
                  0 && <RedPointIcon size="3x" icon={faCircle} />}
              </IconContainer>
            ) : (
              <>
                <IconContainer title="Notifications">
                  <HeaderSvgContainer>
                    <HeaderIcon
                      size="3x"
                      icon={faBell}
                      onClick={props.handleNotifIconClick}
                      title="Notifications"
                    />
                  </HeaderSvgContainer>
                  {isHasUnreadNotif && (
                    <RedPointIcon size="1x" icon={faCircle} />
                  )}
                </IconContainer>
                {props.multiNotifsMenuOpened && <MultiNotifsMenuContainer />}
              </>
            )}
          </NotifContainer>
          {user && user.profile && (
            <UserMenuContainer>
              <UserAccount
                id="userAccountBtn"
                onClick={() => setOpenUserAccount(!openUserAccount)}
              >
                <UserAvatar size="3x" icon={faCircleUser} />
                <span>{`${user.profile.firstName} ${user.profile.lastName}`}</span>
                <UserArrow size="3x" icon={faCaretDown} />
              </UserAccount>
              {openUserAccount && (
                <UserAccountMenu ref={refUserMenu}>
                  <UserAccountMenuTitle>Mon compte</UserAccountMenuTitle>
                  <UserAccountMenuContent>
                    <UserAccountMenuItem onClick={handleClickChangePassword}>
                      <FontAwesomeIcon icon={faLockKeyhole} />
                      <span>Changer de mot de passe</span>
                    </UserAccountMenuItem>
                    <UserAccountMenuItem onClick={handleLogout}>
                      <FontAwesomeIcon icon={faArrowRightFromBracket} />
                      <span>Déconnexion</span>
                    </UserAccountMenuItem>
                  </UserAccountMenuContent>
                </UserAccountMenu>
              )}
            </UserMenuContainer>
          )}
        </UserContent>

        {/* {projectPage === 'parametres' && (
          <TitleWrapper>
            <IconContainer>
              <HeaderIcon size="3x" icon={faCog} />
            </IconContainer>
            <Title>Paramètres</Title>
          </TitleWrapper>
        )} */}

        {isCreateProjectPage && (
          <TitleWrapper>
            <Title>Créer un nouveau projet</Title>
          </TitleWrapper>
        )}
      </HeaderContent>

      {openNotificationOverlay && (
        <NotificationsContainerOverlay
          onClose={handleCloseNotificationOverlay}
          animation={notifOverlayAnimation}
        />
      )}

      {openBurgerMenuOverlay && (
        <SidebarContainerOverlay
          onClose={handleCloseBurgerMenu}
          sales={{
            name: selectedProjectFacilitator?.firstName || selectedProjectSeller?.firstName,
            isFacilitator: !!selectedProjectFacilitator,
            phoneNumber: selectedProjectFacilitator?.phoneNumber || selectedProjectSeller?.phoneNumber
          }}
          animation={burgerMenuAnimation}
        />
      )}
    </ProjectHeader>
  )
}

const mapStateToProps = ({ router, menus, project }) => ({
  currentRoute: router.location.pathname,
  optionsMenuOpened: menus.optionsMenuOpened,
  multiNotifsMenuOpened: menus.multiNotifsMenuOpened,
  project: project.projects
})

const mapDispatchToProps = (dispatch) => ({
  handleOptionsClick: (event) => {
    event.stopPropagation()
    return dispatch(toggleOptionsMenu())
  },
  handleNotifIconClick: (event) => {
    event.stopPropagation()
    return dispatch(toggleMultiNotifsMenu())
  }
})

ProjectHeaderView.propTypes = {
  theme: PropTypes.object.isRequired,
  currentRoute: PropTypes.string.isRequired,
  optionsMenuOpened: PropTypes.bool.isRequired,
  handleOptionsClick: PropTypes.func.isRequired,
  multiNotifsMenuOpened: PropTypes.bool.isRequired,
  handleNotifIconClick: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ProjectHeaderView))
