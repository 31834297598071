import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { compose } from 'recompose'

import {
  withUserFetch,
  withWorktypesFetch,
  withConfirmationTokenRetrieve,
  withCheckSession,
  withDraftData,
  withFullConfirmationTokenFetch,
  withQueryParamStore,
  withStepsMetaFetch,
} from '../../utils/middlewares'

import {
  confirmationTokenRetrieveSuccess
} from '../../actions/user'

import _HomePage from '../page-home'
import ProjectPage from '../page-project'
import _ProjectByConfirmationTokenPage from '../page-project-from-confirmation-token'
import LoginPage from '../page-login'
import LogoutPage from '../page-logout'
import _ConfirmationPage from '../page-confirmation'
import _ConfirmationCallbackPage from '../page-confirmation-callback'
import SetPasswordPage from '../page-set-password'
import TestComponentPage from '../page-test-component'
import ErrorPage from '../page-error'

import PageDraftProject from '../page-draft-project'
import PageFakeProject from '../page-fake-project'

import _PageProjectList from '../page-project-list'

import _PageNotificationList from '../page-notification-list'
import _PageHemea from '../page-hemea'
import _PageLuko from '../page-luko'
import _PageCic from '../page-cic'
import _PageProjectDraft from '../page-project/PageProjectDraft'
import TallyForm from '../forms/tally-form'
import { DEMO_ROUTE, MY_FOLDERS_ROUTE } from '../../constants/router'

const ERROR_NODEV = {
  message: 'Cette page est réservée au développement'
}

const ERROR_NOAUTH = {
  message: 'Vous n\'êtes pas authentifié'
}

const ERROR_NOTFOUND = {
  message: 'Page inconnue'
}

const PageNotFound = props => (
  <ErrorPage error={ERROR_NOTFOUND} {...props} />
)

const DevRoute = ({ component: SubComponent, isAuthenticated, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      window.processRuntime.env.NODE_ENV === 'development'
        ? <SubComponent {...props} />
        : <ErrorPage error={ERROR_NODEV} {...props} />
    )}
  />
)

const PrivateErrorRoute = ({ component: SubComponent, isAuthenticated, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      isAuthenticated
        ? <SubComponent {...props} />
        : <ErrorPage error={ERROR_NOAUTH} {...props} />
    )}
  />
)

const PrivateRedirectRoute = ({ component: SubComponent, isAuthenticated, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      isAuthenticated
        ? <SubComponent {...props} />
        : <Redirect to='/login' />
    )}
  />
)

const PrivateDraftRoute = ({ component: SubComponent, isAuthenticated, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      isAuthenticated
        ? <SubComponent {...props} />
        : <Redirect to='/projet/nouveau' />
    )}
  />
)

const AnonRoute = ({ component: SubComponent, isAuthenticated, ...rest }) => (
  <Route
    {...rest} render={(props) => (
      isAuthenticated
        ? <Redirect to='/' />
        : <SubComponent {...props} />
    )}
  />
)

const routePropTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  isAuthenticated: PropTypes.bool
}

PrivateErrorRoute.propTypes = routePropTypes
AnonRoute.propTypes = routePropTypes
PrivateRedirectRoute.propTypes = routePropTypes
PrivateDraftRoute.propTypes = routePropTypes

const HomePage = withUserFetch(_HomePage)
const PageProjectList = compose(withUserFetch, withWorktypesFetch)(_PageProjectList)
const PageHemea = compose(withUserFetch)(_PageHemea)
const PageCic = compose(withUserFetch)(_PageCic)

const PageNotificationList = compose(withUserFetch)(_PageNotificationList)

const ProjectByConfirmationTokenPage = compose(
  withQueryParamStore(
    params => params.confirmationTokenValue,
    dispatch => confirmationToken => dispatch(confirmationTokenRetrieveSuccess(confirmationToken))
  ),
  withFullConfirmationTokenFetch
)(_ProjectByConfirmationTokenPage)
const ConfirmationPage = withConfirmationTokenRetrieve(_ConfirmationPage)
const ConfirmationCallbackPage = withConfirmationTokenRetrieve(_ConfirmationCallbackPage)


const PageProjectDraft = compose(withUserFetch, withWorktypesFetch, withStepsMetaFetch)(_PageProjectDraft)
const PageTallyForm = compose(withUserFetch)(TallyForm)


const MainPageSwitch = (props) => (
  <Switch>
    <PrivateRedirectRoute
      exact
      path='/'
      component={HomePage}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path={MY_FOLDERS_ROUTE}
      component={PageProjectList}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/hemea'
      component={PageHemea}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/cic'
      component={PageCic}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/luko'
      component={_PageLuko}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/mes-notifications'
      component={PageNotificationList}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/formulaire/:projectId/:formId'
      component={PageTallyForm}
      {...props}
    />
    <PrivateDraftRoute
      exact
      path='/projet'
      component={HomePage}
      {...props}
    />
    <Route
      exact
      path='/projet/brouillon'
      component={PageDraftProject}
      {...props}
    />
    {/* WARNING - The following route has been left to 'Route'
      * for debug purposes, but should be changed later to 'AnonRoute'
      * when launching in Production !
      */
    }
    <Route
      exact
      path='/projet/nouveau'
      component={PageFakeProject}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/projet/:id'
      component={ProjectPage}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/projet/:id/:slider'
      component={ProjectPage}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/confirmation-token/:confirmationTokenValue/project'
      component={ProjectByConfirmationTokenPage}
      {...props}
    />
    <AnonRoute
      exact
      path='/login'
      component={LoginPage}
      {...props}
    />
    <PrivateErrorRoute
      exact
      path='/login-callback'
      component={() => <Redirect to='/' />}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/logout'
      component={LogoutPage}
      {...props}
    />
    <PrivateErrorRoute
      exact
      path='/confirmation'
      component={ConfirmationPage}
      {...props}
    />
    <PrivateErrorRoute
      exact
      path='/confirmation-callback'
      component={ConfirmationCallbackPage}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path='/mot-de-passe'
      component={SetPasswordPage}
      {...props}
    />
    <DevRoute
      exact
      path='/test-component'
      component={TestComponentPage}
      {...props}
    />
    <PrivateRedirectRoute
      exact
      path={DEMO_ROUTE}
      component={PageProjectDraft}
      {...props}
    />
    <Route component={PageNotFound} />
  </Switch>
)

MainPageSwitch.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

export default withDraftData(withCheckSession(MainPageSwitch, false))
