import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Component } from 'react'

class Portal extends Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
    this.portalRoot = document.getElementById(props.portalRoot)
    if (!this.portalRoot) {
      console.error(`Couldn't find root element '${props.portalRoot}' to render Modal`)
    }
  }

  componentDidMount () {
    if (this.portalRoot) {
      this.portalRoot.appendChild(this.el)
    }
  }

  componentWillUnmount () {
    if (this.portalRoot) {
      this.portalRoot.removeChild(this.el)
    }
  }

  render () {
    return this.portalRoot
      ? ReactDOM.createPortal(this.props.children, this.el)
      : null
  }
}

Portal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  portalRoot: PropTypes.string.isRequired
}

export default Portal
