import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { animationDelay } from '../../constants/styles'

export const wrapperWidth = 75

const sideOverlayWrapperMaxWidth = '100vw'
const sideOverlayWrapperMaxWidthTablet = '323px'

export const openBurgerMenuAnimation = 'open-burger-menu-animation'
export const closeBurgerMenuAnimation = 'close-burger-menu-animation'

export const SidebarOverlayWrapper = styled('div')`
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: small;
`

export const CreateFolderButton = styled.button`
  display: flex;
  align-self: baseline;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 2px;
  min-width: 156px;
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  opacity: 1;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.workSans};
  margin-top: 5px;

  & > span {
    margin-left: 10px;
    margin-right: 10px;
  }

  & > svg {
    width: 20px !important;
    height: 20px !important;
    color: ${({ theme }) => theme.colors.white};
  }
`

export const SideOverlayWrapper = styled.div`
  position: relative;
  height: 100%;
  max-width: calc(${sideOverlayWrapperMaxWidth} - 52px);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${({ animation }) => animation} ${animationDelay}s;

  @keyframes ${openBurgerMenuAnimation} {
    from {
      left: calc(-${sideOverlayWrapperMaxWidth} + 52px);
    }
    to {
      left: 0;
    }
  }

  @keyframes ${closeBurgerMenuAnimation} {
    from {
      left: 0;
    }
    to {
      left: calc(-${sideOverlayWrapperMaxWidth} + 52px);
    }
  }

  @media screen and (min-width: 769px) {
    max-width: ${sideOverlayWrapperMaxWidthTablet};

    @keyframes ${openBurgerMenuAnimation} {
      from {
        left: -${sideOverlayWrapperMaxWidthTablet};
      }
      to {
        left: 0;
      }
    }

    @keyframes ${closeBurgerMenuAnimation} {
      from {
        left: 0;
      }
      to {
        left: -${sideOverlayWrapperMaxWidthTablet};
      }
    }
  }
`

export const SideButtonOverlayWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  color: #99999980;
`

export const SideContentOverlayWrapper = styled('div')`
  padding: 20px 0;
`

export const SidebarCloseButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
`

export const SidebarLogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 15px 0px;
  & div {
    @media (max-width: 1024px) {
      font-size: 14px;
      font-weight: 400;
    }
  }
`

export const SidebarLinkWrapper = styled('a')`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
`

export const SidebarText = styled('div')`
  padding: 25px 0;
  line-height: 20px;
`

export const SidebarWrapper = styled('div')`
  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;
`

export const TextImportant = styled('b')`
  font-weight: 500;
`
export const IconOverlayContainer = styled('div')`
  margin-right: 15px;
  & svg {
    width: 20px !important;
    height: 20px !important;
  }
`

export const SidebarOverlayButton = styled.button`
  width: 45px;
  height: 74px !important;
  max-width: 100%;
  max-height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary};
`

export const CloseIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  width: 20px !important;
  height: 20px !important;
  color: #99999980;
`

export const SidebarLogoOverlay = styled('img')`
  height: 3.5rem;
  max-width: 200px !important;
  max-height: 3.5rem !important;
`

export const SidebarHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`
