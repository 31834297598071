// PROJECT
export const PROJECT__LOAD_START = 'PROJECT/LOAD_START'
export const PROJECT__LOAD_SUCCESS = 'PROJECT/LOAD_SUCCESS'
export const PROJECT__LOAD_ERROR = 'PROJECT/LOAD_ERROR'

export const PROJECT__LOAD_WORKTYPES_START = 'PROJECT/LOAD_WORKTYPES_START'
export const PROJECT__LOAD_WORKTYPES_SUCCESS = 'PROJECT/LOAD_WORKTYPES_SUCCESS'
export const PROJECT__LOAD_WORKTYPES_ERROR = 'PROJECT/LOAD_WORKTYPES_ERROR'

export const PROJECT__LOAD_BY_ID_START = 'PROJECT/LOAD_BY_ID_START'
export const PROJECT__LOAD_BY_ID_SUCCESS = 'PROJECT/LOAD_BY_ID_SUCCESS'
export const PROJECT__LOAD_BY_ID_ERROR = 'PROJECT/LOAD_BY_ID_ERROR'

export const PROJECT__LOAD_FACILITATOR_BY_PROJECT_START = 'PROJECT/LOAD_FACILITATOR_BY_PROJECT_START'
export const PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS = 'PROJECT/LOAD_FACILITATOR_BY_PROJECT_SUCCESS'
export const PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR = 'PROJECT/LOAD_FACILITATOR_BY_PROJECT_ERROR'

export const PROJECT__LOAD_SELLER_BY_PROJECT_START = 'PROJECT/LOAD_SELLER_BY_PROJECT_START'
export const PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS = 'PROJECT/LOAD_SELLER_BY_PROJECT_SUCCESS'
export const PROJECT__LOAD_SELLER_BY_PROJECT_ERROR = 'PROJECT/LOAD_SELLER_BY_PROJECT_ERROR'

export const PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_START = 'PROJECT/LOAD_FULL_CONFIRMATION_TOKEN_START'
export const PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_SUCCESS = 'PROJECT/LOAD_FULL_CONFIRMATION_TOKEN_SUCCESS'
export const PROJECT__LOAD_FULL_CONFIRMATION_TOKEN_ERROR = 'PROJECT/LOAD_FULL_CONFIRMATION_TOKEN_ERROR'

export const PROJECT__UPDATE_LAST_VISIT = 'PROJECT/UPDATE_LAST_VISIT'

export const PROJECT__UPDATE_NAME_START = 'PROJECT/UPDATE_NAME_START'
export const PROJECT__UPDATE_NAME_SUCCESS = 'PROJECT/UPDATE_NAME_SUCCESS'
export const PROJECT__UPDATE_NAME_ERROR = 'PROJECT/UPDATE_NAME_ERROR'

export const PROJECT__CHANGE_PAGE = 'PROJECT/CHANGE_PAGE'

export const PROJECT__CREATE_START = 'PROJECT/CREATE_START'
export const PROJECT__CREATE_SUCCESS = 'PROJECT/CREATE_SUCCESS'
export const PROJECT__CREATE_ERROR = 'PROJECT/CREATE_ERROR'

export const PROJECT__UPDATE_PAGINATION_PARAMS = 'PROJECT/UPDATE_PAGINATION_PARAMS'

// STEPS
export const STEP__LOAD_BY_PROJECT_START = 'STEP/LOAD_BY_PROJECT_START'
export const STEP__LOAD_BY_PROJECT_SUCCESS = 'STEP/LOAD_BY_PROJECT_SUCCESS'
export const STEP__LOAD_BY_PROJECT_ERROR = 'STEP/LOAD_BY_PROJECT_ERROR'

export const STEP__LOAD_META_START = 'STEP/LOAD_META_START'
export const STEP__LOAD_META_SUCCESS = 'STEP/LOAD_META_SUCCESS'
export const STEP__LOAD_META_ERROR = 'STEP/LOAD_META_ERROR'

// NOTIFICATIONS
// GET NOTIFICATION WITHOUT LOADING
export const NOTIFICATIONS__GET_BY_PROJECT_START = 'NOTIFICATIONS/GET_BY_PROJECT_START'

export const NOTIFICATIONS__LOAD_BY_PROJECT_START = 'NOTIFICATIONS/LOAD_BY_PROJECT_START'
export const NOTIFICATIONS__LOAD_BY_PROJECT_SUCCESS = 'NOTIFICATIONS/LOAD_BY_PROJECT_SUCCESS'
export const NOTIFICATIONS__LOAD_BY_PROJECT_ERROR = 'NOTIFICATIONS/LOAD_BY_PROJECT_ERROR'

export const NOTIFICATIONS__LOAD_BY_USER_START = 'NOTIFICATIONS/LOAD_BY_USER_START'
export const NOTIFICATIONS__LOAD_BY_USER_SUCCESS = 'NOTIFICATIONS/LOAD_BY_USER_SUCCESS'
export const NOTIFICATIONS__LOAD_BY_USER_ERROR = 'NOTIFICATIONS/LOAD_BY_USER_ERROR'

export const NOTIFICATIONS__LOAD_META_START = 'NOTIFICATIONS/LOAD_META_START'
export const NOTIFICATIONS__LOAD_META_SUCCESS = 'NOTIFICATIONS/LOAD_META_SUCCESS'
export const NOTIFICATIONS__LOAD_META_ERROR = 'NOTIFICATIONS/LOAD_META_ERROR'

export const NOTIFICATIONS__READ_NOTIFICATION_START = 'NOTIFICATIONS/READ_NOTIFICATION_START'
export const NOTIFICATIONS__READ_NOTIFICATION_SUCCESS = 'NOTIFICATIONS/READ_NOTIFICATION_SUCCESS'
export const NOTIFICATIONS__READ_NOTIFICATION_ERROR = 'NOTIFICATIONS/READ_NOTIFICATION_ERROR'

export const NOTIFICATIONS__RESET_NB_CALL = 'NOTIFICATIONS/RESET_NB_CALL'
export const NOTIFICATIONS__UPDATE_PAGINATION_PARAMS = 'NOTIFICATIONS/UPDATE_PAGINATION_PARAMS'

// ACTIONS
export const ACTIONS__LOAD_BY_PROJECT_START = 'ACTIONS/LOAD_BY_PROJECT_START'
export const ACTIONS__LOAD_BY_PROJECT_SUCCESS = 'ACTIONS/LOAD_BY_PROJECT_SUCCESS'
export const ACTIONS__LOAD_BY_PROJECT_ERROR = 'ACTIONS/LOAD_BY_PROJECT_ERROR'

export const ACTIONS__LOAD_BY_USER_START = 'ACTIONS/LOAD_BY_USER_START'
export const ACTIONS__LOAD_BY_USER_SUCCESS = 'ACTIONS/LOAD_BY_USER_SUCCESS'
export const ACTIONS__LOAD_BY_USER_ERROR = 'ACTIONS/LOAD_BY_USER_ERROR'

export const ACTIONS__LOAD_META_START = 'ACTIONS/LOAD_META_START'
export const ACTIONS__LOAD_META_SUCCESS = 'ACTIONS/LOAD_META_SUCCESS'
export const ACTIONS__LOAD_META_ERROR = 'ACTIONS/LOAD_META_ERROR'

export const ACTIONS__UPDATE_STATUS_START = 'ACTIONS/UPDATE_STATUS_START'
export const ACTIONS__UPDATE_STATUS_SUCCESS = 'ACTIONS/UPDATE_STATUS_SUCCESS'
export const ACTIONS__UPDATE_STATUS_ERROR = 'ACTIONS/UPDATE_STATUS_ERROR'

export const ACTIONS__UPDATE_LINK_STATUS_START = 'ACTIONS/UPDATE_LINK_STATUS_START'
export const ACTIONS__UPDATE_LINK_STATUS_SUCCESS = 'ACTIONS/UPDATE_LINK_STATUS_SUCCESS'
export const ACTIONS__UPDATE_LINK_STATUS_ERROR = 'ACTIONS/UPDATE_LINK_STATUS_ERROR'

export const ACTIONS__UPDATE_PAGINATION_PARAMS = 'ACTIONS/UPDATE_PAGINATION_PARAMS'

// USER
export const USER__CHECK_SESSION_START = 'USER/CHECK_SESSION_START'
export const USER__REFRESH_SESSION_START = 'USER/REFRESH_SESSION_START'
export const USER__CHECK_SESSION_SUCCESS = 'USER/CHECK_SESSION_SUCCESS'
export const USER__CHECK_SESSION_ERROR = 'USER/CHECK_SESSION_ERROR'

export const USER__LOGIN_START = 'USER/LOGIN_START'
export const USER__LOGIN_SUCCESS = 'USER/LOGIN_SUCCESS'
export const USER__LOGIN_ERROR = 'USER/LOGIN_ERROR'

export const USER__LOGOUT_START = 'USER/LOGOUT_START'
export const USER__LOGOUT_SUCCESS = 'USER/LOGOUT_SUCCESS'
export const USER__LOGOUT_ERROR = 'USER/LOGOUT_ERROR'

export const USER__FORCE_LOGIN = 'USER/FORCE_LOGIN'

export const USER__FETCH_BY_ACCOUNT_START = 'USER/FETCH_BY_ACCOUNT_START'
export const USER__FETCH_BY_ACCOUNT_SUCCESS = 'USER/FETCH_BY_ACCOUNT_SUCCESS'
export const USER__FETCH_BY_ACCOUNT_ERROR = 'USER/FETCH_BY_ACCOUNT_ERROR'

export const USER__CONFIRMATION_TOKEN_RETRIEVE_START = 'USER/CONFIRMATION_TOKEN_RETRIEVE_START'
export const USER__CONFIRMATION_TOKEN_RETRIEVE_SUCCESS = 'USER/CONFIRMATION_TOKEN_RETRIEVE_SUCCESS'
export const USER__CONFIRMATION_TOKEN_RETRIEVE_ERROR = 'USER/CONFIRMATION_TOKEN_RETRIEVE_ERROR'

export const USER__ACCOUNT_CONFIRMATION_START = 'USER/ACCOUNT_CONFIRMATION_START'
export const USER__ACCOUNT_CONFIRMATION_SUCCESS = 'USER/ACCOUNT_CONFIRMATION_SUCCESS'
export const USER__ACCOUNT_CONFIRMATION_ERROR = 'USER/ACCOUNT_CONFIRMATION_ERROR'

export const USER__ACCOUNT_LINK_START = 'USER/ACCOUNT_LINK_START'
export const USER__ACCOUNT_LINK_SUCCESS = 'USER/ACCOUNT_LINK_SUCCESS'
export const USER__ACCOUNT_LINK_ERROR = 'USER/ACCOUNT_LINK_ERROR'

export const USER__CHECK_ACCOUNT_LINKS_START = 'USER/CHECK_ACCOUNT_LINKS_START'
export const USER__CHECK_ACCOUNT_LINKS_SUCCESS = 'USER/CHECK_ACCOUNT_LINKS_SUCCESS'
export const USER__CHECK_ACCOUNT_LINKS_ERROR = 'USER/CHECK_ACCOUNT_LINKS_ERROR'

export const USER__CREATE_EMAIL_PASSWORD_ACCOUNT_START = 'USER/CREATE_EMAIL_PASSWORD_ACCOUNT_START'
export const USER__CREATE_EMAIL_PASSWORD_ACCOUNT_SUCCESS = 'USER/CREATE_EMAIL_PASSWORD_ACCOUNT_SUCCESS'
export const USER__CREATE_EMAIL_PASSWORD_ACCOUNT_ERROR = 'USER/CREATE_EMAIL_PASSWORD_ACCOUNT_ERROR'

export const USER__CHANGE_PASSWORD_START = 'USER/CHANGE_PASSWORD_START'
export const USER__CHANGE_PASSWORD_SUCCESS = 'USER/CHANGE_PASSWORD_SUCCESS'
export const USER__CHANGE_PASSWORD_ERROR = 'USER/CHANGE_PASSWORD_ERROR'

export const USER__UPDATE_CONNECTED_AT = 'USER/UPDATE_CONNECTED_AT'

// SYNC
export const SYNC__SESSION_START = 'SYNC/SESSION_START'
export const SYNC__SESSION_STOP = 'SYNC/SESSION_STOP'

// ALERTS
export const ALERT__ADD = 'ALERT/ADD'
export const ALERT__REMOVE = 'ALERT/REMOVE'
export const ALERT__DELETE = 'ALERT/DELETE'

// FILES
export const FILES__LIST_START = 'FILES/LIST_START'
export const FILES__LIST_SUCCESS = 'FILES/LIST_SUCCESS'
export const FILES__LIST_ERROR = 'FILES/LIST_ERROR'

export const FILES__UPLOAD_START = 'FILES/UPLOAD_START'
export const FILES__UPLOAD_PROGRESS = 'FILES/UPLOAD_PROGRESS'
export const FILES__UPLOAD_CANCEL = 'FILES/UPLOAD_CANCEL'
export const FILES__UPLOAD_SUCCESS = 'FILES/UPLOAD_SUCCESS'
export const FILES__UPLOAD_REMOVE = 'FILES/UPLOAD_REMOVE'
export const FILES__UPLOAD_ERROR = 'FILES/UPLOAD_ERROR'
export const FILES__UPLOAD_ERROR_CLOSE = 'FILES/CLOSE_ERROR'

export const FILES__DELETE_START = 'FILES/DELETE_START'
export const FILES__DELETE_SUCCESS = 'FILES/DELETE_SUCCESS'
export const FILES__DELETE_ERROR = 'FILES/DELETE_ERROR'

export const FILES__TEMPLATE_FOLDER_FETCH_START = 'FILES/TEMPLATE_FOLDER_FETCH_START'
export const FILES__TEMPLATE_FOLDER_FETCH_SUCCESS = 'FILES/TEMPLATE_FOLDER_FETCH_SUCCESS'
export const FILES__TEMPLATE_FOLDER_FETCH_ERROR = 'FILES/TEMPLATE_FOLDER_FETCH_ERROR'

// TUNNEL
export const DRAFT__DATA_FETCH_START = 'DRAFT/DRAFT_DATA_FETCH_START'
export const DRAFT__DATA_FETCH_SUCCESS = 'DRAFT/DRAFT_DATA_FETCH_SUCCESS'
export const DRAFT__DATA_FETCH_ERROR = 'DRAFT/DRAFT_DATA_FETCH_ERROR'

// MENUS
export const MENUS__OPTIONS_OPEN = 'MENUS/OPTIONS_OPEN'
export const MENUS__OPTIONS_CLOSE = 'MENUS/OPTIONS_CLOSE'
export const MENUS__OPTIONS_TOGGLE = 'MENUS/OPTIONS_TOGGLE'

// MENUS MULTI NOTIFS
export const MENUS__MULTI_NOTIFS_OPEN = 'MENUS/MULTI_NOTIFS_OPEN'
export const MENUS__MULTI_NOTIFS_CLOSE = 'MENUS/MULTI_NOTIFS_CLOSE'
export const MENUS__MULTI_NOTIFS_TOGGLE = 'MENUS/MULTI_NOTIFS_TOGGLE'

// OVERLAY
export const OVERLAY__SET_IFRAME = 'OVERLAY/SET_IFRAME'
export const OVERLAY__SET_IMAGE = 'OVERLAY/SET_IMAGE'
export const OVERLAY__SET_PDF = 'OVERLAY/SET_PDF'
export const OVERLAY__SET_TYPEFORM = 'OVERLAY/SET_TYPEFORM'
export const OVERLAY__SET_CONFIRMATION = 'OVERLYAY/SET_CONFRIMATION'
export const OVERLAY__SET_CHANGE_PASSWORD = 'OVERLAY/SET_PASSWORD'
export const OVERLAY__SELECT_PROJECTS_LIST = 'OVERLAY/SELECT_PROJECT'
export const OVERLAY__OPEN_CREATE_PROJECT = 'OVERLAY/OPEN_CREATE_PROJECT'
export const OVERLAY__OPEN_CREATE_PROJECT_IN_OTHER_OVERLAY = 'OVERLAY/OPEN_CREATE_PROJECT_IN_OTHER_OVERLAY'
export const OVERLAY__CLOSE_CREATE_PROJECT_IN_OTHER_OVERLAY = 'OVERLAY/CLOSE_CREATE_PROJECT_IN_OTHER_OVERLAY'
export const OVERLAY__CLOSE = 'OVERLAY/CLOSE'
export const OVERLAY__OPEN_PROJECT_ADDITIONAL_INFO = 'OVERLAY/OPEN_PROJECT_ADDITIONAL_INFO'
export const OVERLAY__ACCOUNT_ACTIVATION = 'OVERLAY/ACCOUNT_ACTIVATION'
export const OVERLAY__TERREAL_ACCOUNT_ACTIVATION = 'OVERLAY/TERREAL_ACCOUNT_ACTIVATION'
export const OVERLAY__NEW_PROJECT_ACTIVATION = 'OVERLAY/PROJECT_ACTIVATION'

// SYSTEM
export const SYSTEM__ERROR = 'SYSTEM/ERROR'

// RECOMMENDATIONS
export const PROJECT__LOAD_RECOMMENDATIONS_START = 'PROJECT/LOAD_RECOMMENDATIONS_START'
export const PROJECT__LOAD_RECOMMENDATIONS_SUCCESS = 'PROJECT/LOAD_RECOMMENDATIONS_SUCCESS'
export const PROJECT__LOAD_RECOMMENDATIONS_ERROR = 'PROJECT/LOAD_RECOMMENDATIONS_ERROR'

// INTERAVAL TIME
export const INTERVAL__SET_ID = 'INTERVAL/SET_ID'
export const INTERVAL__RESET_ID = 'INTERVAL/RESET_ID'

// GEOCODING ADDRESS
export const GEOCODING__ADDRESS_START = 'GEOCODING/ADDRESS_START'
export const GEOCODING__ADDRESS_SUCCESS = 'GEOCODING/ADDRESS_SUCCESS'
export const GEOCODING__ADDRESS_ERROR = 'GEOCODING/ADDRESS_ERROR'
export const GEOCODING__TOGGLE_SUGGESTIONS = 'GEOCODING/TOGGLE_SUGGESTIONS'
export const GEOCODING__OPEN_SUGGESTIONS = 'GEOCODING/OPEN_SUGGESTIONS'
export const GEOCODING__CLOSE_SUGGESTIONS = 'GEOCODING/CLOSE_SUGGESTIONS'


// FORM
export const FORM__CHANGE_CREATE_PROJECT_INPUT = 'FORM/CHANGE_CREATE_PROJECT_INPUT'

// HUBSPOT
export const ENABLE_HUBSPOT_CHAT = 'ENABLE_HUBSPOT_CHAT'