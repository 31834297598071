import {
  STEP__LOAD_META_START,
  STEP__LOAD_META_SUCCESS,
  STEP__LOAD_META_ERROR,
  STEP__LOAD_BY_PROJECT_START,
  STEP__LOAD_BY_PROJECT_SUCCESS,
  STEP__LOAD_BY_PROJECT_ERROR
} from '../actions'

const initialState = {
  steps: null,
  stepsLoading: false,
  stepsError: false,

  stepsMeta: null,
  stepsMetaLoading: false,
  stepsMetaError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case STEP__LOAD_META_START:
      return {
        ...state,
        stepsMetaLoading: true,
        stepsMetaError: null
      }

    case STEP__LOAD_META_SUCCESS:
      return {
        ...state,
        stepsMeta: action.payload,
        stepsMetaLoading: false
      }

    case STEP__LOAD_META_ERROR:
      return {
        ...state,
        stepsMetaLoading: false,
        stepsMetaError: action.payload
      }

    case STEP__LOAD_BY_PROJECT_START:
      return {
        ...state,
        stepsLoading: true,
        stepsError: null
      }

    case STEP__LOAD_BY_PROJECT_SUCCESS:
      return {
        ...state,
        stepsLoading: false,
        steps: action.payload
      }

    case STEP__LOAD_BY_PROJECT_ERROR:
      return {
        ...state,
        stepsLoading: false,
        stepsError: action.payload
      }

    default:
      return state
  }
}
