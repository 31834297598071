import styled from '@emotion/styled'
import { headerHeight } from '../../constants/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FlexRow } from '../styled'
import Button from '../styled/button'
import chroma from 'chroma-js'
import { css } from '@emotion/core'

export const ExtendableOverlayContentWrapper = styled.div`
  position: ${({ container }) => container === 'fit-parent' ? 'absolute' : 'fixed'};
  inset: 0;
  overflow-x: hidden;
  overflow-y: ${({ noScroll }) => (noScroll ? 'hidden' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background: rgba(63, 63, 63, 0.4);

  @media (max-width: 1024px) {
    width: 100vw;
    height: calc(100vh - ${headerHeight}px);
  }
`

export const ModalOverlayContentWrapper = styled.div`
  max-height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: ${({ noScroll }) => (noScroll ? 'hidden' : 'auto')};
  border-radius: 5px;
  min-width: 364px;
  min-height: 165px;

  @media screen and (max-width: 610px) {
    height: auto;
    min-height: 165px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.5;
  padding: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 5px;
`

export const ButtonsWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 15px 0;

  @media (max-width: 1024px) {
    gap: 9px;
  }
`

export const modalContentHeadCss = ({ theme }) => css`
  position: relative;
  width: 100%;
  padding: 11px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 17px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: ${theme.colors.white};
  background-color: ${chroma(theme.colors.primary).alpha(0.7).hex()};
  border-radius: 5px 5px 0 0;
`

export const ContentHead = styled.div`
  ${modalContentHeadCss}
`

export const BtnClose = styled.button`
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 18px;
    height: 18px;
    color: white;
  }
`

export const ContentBody = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 29px 12px 38px;
  width: 100%;

  & > p {
    & > b {
      font-weight: 600;
    }
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    width: calc(100% - 24px);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #EDE8DD;
  }
`

export const ContentButton = styled(Button)`
  margin: 0;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background-color: ${({ color, theme }) => color === 'tertiary' ? chroma(theme.colors.primary).alpha(0.15).hex() : 'rgba(163, 163, 163, 0.15)'};
  color: ${({ color, theme }) => color === 'tertiary' ? theme.colors.primary : '#A3A3A3'};
  opacity: ${({ color }) => (color === 'tertiary' ? 0.7 : 1)};
  border-radius: 2px;

  &.delete {
    background-color: rgba(255, 81, 81, 0.15);
    color: #ff5151;
  }

  &.download {
    &:not(disabled):hover {
      border-color: ${({ theme }) => theme.colors.primary} !important;
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  &:not(disabled):hover {
    color: #ffffff;
    opacity: 1;
  }

  &:focus,
  &:hover:focus {
    box-shadow: none;
  }

  &.has-download-file-loading {
    & > svg {
      animation: rotating 1s linear infinite;
    }

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`

export const ContentButtonIcon = styled(FontAwesomeIcon)`
  margin-left: 4px;
`
