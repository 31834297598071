import React from 'react'
import PropTypes from 'prop-types'
import AirBnbPropTypes from 'airbnb-prop-types'
import chroma from 'chroma-js'

import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import { css } from '@emotion/core'

import { colorOfTheme, radiusOfTheme } from '../../theme'

export const BUTTON_SIZES = {
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large'
}

const radiusFromProps = props => (
  props.radius
    ? props.theme.radiuses[props.radius]
    : (
      props.size === BUTTON_SIZES.SMALL
        ? props.theme.radiuses.tiny
        : props.theme.radiuses.small
    )
)

const fontSizeFromButtonSize = props => (
  {
    [BUTTON_SIZES.SMALL]: props.theme.sizes.small,
    [BUTTON_SIZES.NORMAL]: props.theme.sizes.normal,
    [BUTTON_SIZES.LARGE]: props.theme.sizes.h5
  }[props.size] || props.theme.sizes.normal
)

const buttonLineHeight = 1.3

const baseButtonCss = props => css`
  outline: none;
  border-width: 1px;
  border-style: solid;

  border-radius: ${props.radius};
  font-family: ${props.theme.fonts.workSans};
  text-decoration: none;

  box-shadow: 0 0 0 1px transparent;

  &:focus,
  &:hover:focus {
    box-shadow: 0 0 0 1px ${chroma(props.color).alpha(0.5).hex()};
  }

  &:disabled {
    opacity: 0.7;
  }

  font-size: ${fontSizeFromButtonSize(props)}px;
  line-height: ${buttonLineHeight};

  padding: 0.5em 1em;
  min-width: 7.5em;

  text-align: center;

  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const StyledFullButton = styled('button')`
  ${baseButtonCss}

  background-color: ${props => props.color};
  border-color: transparent;
  color: ${props => props.invertColor};

  &:not(:disabled):hover {
    background-color: ${props => chroma(props.color).darken(0.15).hex()};
    border-color: ${props => chroma(props.color).darken(0.15).hex()};
  }
`

const StyledOutlinedButton = styled('button')`
  ${baseButtonCss}

  background-color: transparent;
  border-color: ${props => props.color};
  color: ${props => props.color};

  &:not(:disabled):hover {
    background-color: ${props => props.color};
    border-color: transparent;
    color: ${props => props.invertColor};
  }
`

const Button = ({
  theme,
  color,
  radius,
  outlined,
  size,
  ...passThroughProps
}) => {
  const themeColor = theme.colors[color]
  const themeInvertColor = theme.colorsInverts[color]
  const themeRadius = radiusFromProps({ theme, radius, size })

  const StyledButton = outlined
    ? StyledOutlinedButton
    : StyledFullButton

  return (
    <StyledButton
      color={themeColor}
      invertColor={themeInvertColor}
      radius={themeRadius}
      size={size}
      {...passThroughProps}
    />
  )
}

Button.propTypes = {
  // This should be required,
  // but getting a 'cannot read apply of undefined'
  color: AirBnbPropTypes.and([
    PropTypes.string,
    colorOfTheme
  ]),
  radius: AirBnbPropTypes.and([
    PropTypes.string,
    radiusOfTheme
  ]),
  outlined: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)).isRequired,
  as: PropTypes.string
}

Button.defaultProps = {
  outlined: false,
  color: 'light',
  size: BUTTON_SIZES.NORMAL
}

export default withTheme(Button)
