import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { baseButtonCss, FlexColumn } from '../styled'
import faceSadIcon from '../../static/face-sad.svg'
import { descriptionFontSizeCss, textFontSizeCss } from '../../styles/common'
import { modalContentHeadCss } from '../overlay-custom-confirm/styled'

export const MenuWrapper = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  border-radius: ${({ isMobile }) => (isMobile ? 0 : 5)}px;
  box-shadow: ${({ isMobile }) => isMobile ? 'none' : `0px 0px 0px 1px rgba(0, 21, 86, 0.06)`};

  z-index: ${({ theme }) => theme.layers.menu};
  position: ${({ isMobile }) => (isMobile ? `relative` : 'absolute')};
  width: ${({ isMobile }) => (isMobile ? `100%` : '520px')};
  height: ${({ isMobile }) => (isMobile ? `100%` : '540px')};
  top: ${({ isMobile }) => (isMobile ? `0` : '30px')};
  left: ${({ isMobile }) => (isMobile ? `0` : `auto`)};
  right: ${({ isMobile }) => (isMobile ? `0` : `0`)};
`

export const MultiNotificationsListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${({ isMobile }) => (isMobile ? 'unset' : 'auto')};
  padding: 0;
`

export const ShowMoreLinkWrapper = styled.div`
  position: relative;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  min-height: 40px;
  width: 92%;

  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  border-top: 1px solid #EDE8DD;
  margin: 0 20px;

  & > a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const menuItemCss = (props) => css`
  ${baseButtonCss(props)}
  width: 100%;
  padding: ${props.theme.spacings.small * 1.2}px;
  color: ${props.disabled ? props.theme.colors.placeholder : props.theme.colors.primary};
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: ${props.theme.sizes.h5}px;
  & > div > span {
    display: inline-block;
  }
`

export const MenuItem = styled.a`
  ${menuItemCss}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${({ isPage }) => (isPage ? '10px 15px' : '10px 15px')} !important;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.overOrSelected};
    text-decoration: none;
  }

  @media (min-width: 576px) {
    padding: ${({ isPage }) => (isPage ? '10px 30px' : '16px 30px')} !important;
  }
`

export const NotifContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.spacings.normal + 1.75}px;
`

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const FolderContainer = styled.div`
  margin: 5px 10px 0 0;
  position: relative;
`

export const FolderIcon = styled(FontAwesomeIcon)`
  color: inherit;
  width: ${({ ispage }) => (ispage === 'true' ? 24.98 : 17)}px !important;
  height: ${({ ispage }) => (ispage === 'true' ? 19.3 : 15)}px !important;
  margin-top: ${({ ispage }) => (ispage === 'true' ? 4 : 0)}px;
`

export const RedPointIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.tertiary};
  position: absolute;
  width: ${({ ispage }) => (ispage === 'true' ? 8 : 6)}px !important;
  height: ${({ ispage }) => (ispage === 'true' ? 8 : 6)}px !important;
  top:  ${({ ispage }) => (ispage === 'true' ? 0 : -20)}%;
  left: ${({ ispage }) => (ispage === 'true' ? 75 : 75)}%;
`

export const NotifText = styled.p`
  font-weight: 400;
  ${textFontSizeCss}
  margin: 0 0 7px;
  line-height: 16.42px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

`

export const NotifProjectName = styled.span`
  ${textFontSizeCss}
  font-weight: 500;
`

export const NotifDate = styled.span`
  font-size: ${({ isPage }) => (isPage ? 14 : 10)}px;
  ${descriptionFontSizeCss}
  font-weight: 400;
`

export const NotifNameRecap = styled.span`
  font-size: ${({ isPage }) => (isPage ? 14 : 10)}px;
  ${descriptionFontSizeCss}
  font-weight: 600;
`

export const EmptyNotifContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 45px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 21, 86, 0.6);
`

export const EmptyNotifText = styled.span`
  &::after {
    content: url('${faceSadIcon}');
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 19px;
    margin-left: 5px;
  }
`

export const MultiNotificationsListTitle = styled.h3`
  ${modalContentHeadCss}
`