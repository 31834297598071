import {
  MENUS__OPTIONS_OPEN,
  MENUS__OPTIONS_CLOSE,
  MENUS__OPTIONS_TOGGLE,
  MENUS__MULTI_NOTIFS_OPEN,
  MENUS__MULTI_NOTIFS_CLOSE,
  MENUS__MULTI_NOTIFS_TOGGLE
} from '../actions'

const initialState = {
  optionsMenuOpened: false,
  multiNotifsMenuOpened: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MENUS__OPTIONS_OPEN:
      return {
        ...state,
        optionsMenuOpened: true
      }

    case MENUS__OPTIONS_CLOSE:
      return {
        ...state,
        optionsMenuOpened: false
      }

    case MENUS__OPTIONS_TOGGLE:
      return {
        ...state,
        optionsMenuOpened: !state.optionsMenuOpened
      }

    case MENUS__MULTI_NOTIFS_OPEN:
      return {
        ...state,
        multiNotifsMenuOpened: true
      }

    case MENUS__MULTI_NOTIFS_CLOSE:
      return {
        ...state,
        multiNotifsMenuOpened: false
      }

    case MENUS__MULTI_NOTIFS_TOGGLE:
      return {
        ...state,
        multiNotifsMenuOpened: !state.multiNotifsMenuOpened
      }

    default:
      return state
  }
}
