import styled from '@emotion/styled'

export const DatepickerInputStyled = styled.div`
  .react-datepicker-popper {
    & * {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1rem;
    }
  }
  .react-datepicker__header,
  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    background-color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  .react-datepicker__current-month {
    line-height: 24px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--today {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary};
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-range:hover {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .react-datepicker__navigation-icon::before,
  .react-datepicker__month-read-view--down-arrow {
    border-color: ${({ theme }) => theme.colors.primary};
    border-width: 2px 2px 0 0;
    width: 8px;
    height: 8px;
  }
  .react-datepicker__triangle {
    display: none;
  }
`
