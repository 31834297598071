import React from 'react'
import styled from '@emotion/styled'

import { mq,reverseMq } from '../../utils/style'
import { InvisibleButton, FlexRow } from '../styled'

const mobileMargin = props => props.theme.spacings.large + props.theme.spacings.medium
const screenMargin = props => props.theme.spacings.large * 2

const stopPropagation = (event) => {
  event.stopPropagation()
}

export const OverlayContentWrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(63, 63, 63, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Overlay = styled('div')`
  position: absolute;
  border-radius: ${props => props.theme.radiuses.normal};
  overflow: hidden;
  z-index: 3;

  ${props => mq(props)({
    top: [`${mobileMargin(props)}px`, `${screenMargin(props)}px`],
    left: [`${mobileMargin(props)}px`, `${screenMargin(props)}px`],
    right: [`${mobileMargin(props)}px`, `${screenMargin(props)}px`],
    bottom: [`${mobileMargin(props)}px`, `${screenMargin(props)}px`]
  })}

`

export const TopBar = styled(FlexRow)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${props => mq(props)({
    height: [`${mobileMargin(props)}px`, `${screenMargin(props)}px`]
  })}
  align-items: flex-start;
  justify-content: flex-end;
  padding: ${props => props.theme.spacings.medium}px;
  z-index: 4;
`

export const BottomBar = styled(FlexRow)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  ${props => mq(props)({
    height: [`${mobileMargin(props)}px`, `${screenMargin(props)}px`]
  })}
  align-items: center;
  justify-content: center;
  z-index: 4;
`

export const CloseButton = styled(InvisibleButton)`
  position: absolute;
  top: 0;
  right: 20px;
  top: 14px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white};
`

const ExtendableOverlayContentWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: ${props => props.noScroll ? 'hidden' : 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ExtendableOverlayContent = ({ children, ...props }) => (
  <ExtendableOverlayContentWrapper {...props} onClick={stopPropagation}>
    {children}
  </ExtendableOverlayContentWrapper>
)

const ModalOverlayContentWrapper = styled('div')`
  max-height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: ${props => props.noScroll ? 'hidden' : 'auto'};
  border-radius: ${props => props.squared ? '0' : '5px'};
`

export const ModalOverlayContent = ({ children, ...props }) => (
  <ExtendableOverlayContentWrapper noScroll>
    <ModalOverlayContentWrapper {...props} onClick={stopPropagation}>
      {children}
    </ModalOverlayContentWrapper>
  </ExtendableOverlayContentWrapper>
)

const ModalFullwidthOverlayContentWrapper = styled('div')`
  width: 100%;
  max-height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: ${props => props.noScroll ? 'hidden' : 'auto'};
  border-radius: ${props => props.squared ? '0' : props.theme.radiuses.normal};
`

export const ModalFullwidthOverlayContent = ({ children, ...props }) => (
  <ExtendableOverlayContentWrapper noScroll>
    <ModalFullwidthOverlayContentWrapper {...props} onClick={stopPropagation}>
      {children}
    </ModalFullwidthOverlayContentWrapper>
  </ExtendableOverlayContentWrapper>
)

const ModalFullwidthFullHeightOverlayContentWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: ${props => props.noScroll ? 'hidden' : 'auto'};
  border-radius: ${props => props.squared ? '0' : props.theme.radiuses.normal};

  @media screen and (min-width: 769px) {
    height: 80%;
  }
`

export const ModalFullwidthFullHeightOverlayContent = ({ children, ...props }) => (
  <ExtendableOverlayContentWrapper noScroll>
    <ModalFullwidthFullHeightOverlayContentWrapper {...props} onClick={stopPropagation} noScroll>
      {children}
    </ModalFullwidthFullHeightOverlayContentWrapper>
  </ExtendableOverlayContentWrapper>
)
