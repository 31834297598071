class Auth0CheckSessionError extends Error {
  constructor (original, message, code) {
    super(message)
    this.code = code
    this.name = 'Auth0CheckSessionError'
  }
}

export const getCodeFromError = err => (
  err && [
    (err.response && err.response.status),
    (err && err.code)
  ].find(x => x)
) || null

const errPayloadIsAuth0Like = obj => obj && (obj.error && obj.description)

export const buildErrFromCheckSessionError = err => {
  if (err instanceof Error) {
    if ('original' in err) {
      return err
    }

    return new Auth0CheckSessionError(
      err,
      err.message,
      null
    )
  }

  if (errPayloadIsAuth0Like(err)) {
    return new Auth0CheckSessionError(
      err.original,
      err.description,
      err.error
    )
  }
}
