import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStepsMetaFetch } from '../../utils/middlewares'

import {
  draftStepMetaSelector,
  stepsMetaSelector
} from '../../selectors/step'

import ViewSteps from '../view-steps'

class DraftStepsContainer extends Component {
  render () {
    return (
      <ViewSteps
        steps={this.props.actualStepsMeta}
        currentStep={this.props.currentStepMeta}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  actualStepsMeta: stepsMetaSelector(state),
  currentStepMeta: draftStepMetaSelector(state)
})

const shapeOfStepMeta = {
  isMilestone: PropTypes.bool.isRequired,
  groupIndex: PropTypes.number.isRequired,
  indexInGroup: PropTypes.number
}

DraftStepsContainer.propTypes = {
  actualStepsMeta: PropTypes.arrayOf(PropTypes.shape(shapeOfStepMeta)).isRequired,
  currentStepMeta: PropTypes.shape(shapeOfStepMeta)
}

export default withStepsMetaFetch(connect(mapStateToProps, null)(DraftStepsContainer))
