import styled from '@emotion/styled'
import ProjectsPagination from '../page-project-list/pagination'

export const PageNotificationListContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 18px;
  margin: 0;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  overflow: auto;

  & > h2 {
    margin-bottom: 30px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

`

export const PageNotificationListContent = styled.div`
  width: 100%;
  height: 100%;
  max-height: ${({ isLoading }) => isLoading ? 'calc(100vh - 140px - 150px)' : 'calc(100vh - 140px - 90px)'};
  padding: 0;

  ::-webkit-scrollbar-thumb {
    background-color: ${({ isScrolling }) => isScrolling ? '#001556' : 'transparent'};
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (max-width: 768px) {
    max-height: calc(100vh - 130px - 100px);
  }
`

export const NotificationsWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 1037px) {
    padding: 0;
  }

  & > div {
    max-width: 1038px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 1037px) {
      max-width: 100%;
    }
  }
`

export const NoNotificationsText = styled.p`
  font-weight: 300;
  font-size: 15px;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.workSans};
  color: ${({ theme }) => theme.colors.medium};
`

export const Pagination = styled(ProjectsPagination)`
  justify-content: flex-start;
  margin-top: 15px;
`

export const LoadingContainer = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`

export const MainLoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
