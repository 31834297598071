import React, { Fragment, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import {
  startLoadingProjectById,
  startLoadingProjects,
  startLoadingRecommendations,
  startLoadingWorktypes
} from '../../actions/project'
import { startFetchingUserByAccount } from '../../actions/user'
import { projectSelector, userSelector, isInitialisationTerrealSelector } from '../../selectors'
import StepsContainer from '../container-steps'
import PageCreateProject from '../overlay-create-project/page-create-project'
import PageError from '../page-error'
import PageParameter from '../page-parameter'
import PageProjectList from '../page-project-list'
import LoadingView from '../view-loading'
import DashboardDesktop from './Layouts/DashboardDesktop'
import DashboardMobile from './Layouts/DashboardMobile'
import PMDC from '../../utils/pmdc'
import { setTerrealAccountActivationOverlay, setIframeOverlay } from '../../actions/overlay'
import DashboardTablet from './Layouts/DashboardTablet'
import {
  BottomBlock,
  PageCreateProjectContainer,
  ProjectBody,
  StepsBlock,
  ViewProject
} from './styled'

export const StepsRenderer = () => {
  return (
    <StepsBlock>
      <StepsContainer />
    </StepsBlock>
  )
}

const ProjectPage = () => {
  const dispatch = useDispatch()

  const {
    params: { id: currentProjectId }
  } = useRouteMatch()

  const [state, setState] = useState({
    isMobile: false,
    isTabletOrMobile: false
  })

  const { profile, profileLoading, profileError, hubspotChatEnabled, accessToken, decodedAccessToken } = useSelector(userSelector)
  const isInitialisationTerreal = useSelector(isInitialisationTerrealSelector)


  if (hubspotChatEnabled) {
    PMDC(accessToken, decodedAccessToken).getHubspotToken().then((hubspotToken) => {
      window.hsConversationsSettings = {
        identificationEmail: profile.email,
        identificationToken: hubspotToken.data
      }
      window.HubSpotConversations.widget.load()
    }).catch((error) => {
      console.error("Une erreur s'est produite lors de la récupération du token HubSpot :", error);
    })
  }

  const {
    page,
    selectedProject,
    selectedProjectLoaging,
    selectedProjectError,
    projects,
    projectsLoading,
    projectsError,
    paginationParams,
    workTypes,
    workTypesLoading,
    worktypesError,
    recommendations,
    recommendationsLoading,
    recommendationsError
  } = useSelector(projectSelector)

  const loading =
    profileLoading ||
    selectedProjectLoaging ||
    projectsLoading ||
    workTypesLoading ||
    recommendationsLoading
  const error =
    profileError ||
    selectedProjectError ||
    projectsLoading ||
    worktypesError ||
    recommendationsError

  const renderTabletAndMobilePages = useCallback(
    (project) => {
      switch (page) {
        case 'projet':
          return (
            <BottomBlock>
              <DashboardMobile project={project} />
            </BottomBlock>
          )

        case 'switch':
          return <PageProjectList />

        case 'parametres':
          return <PageParameter />

        case 'page-create-new-project':
          return (
            <PageCreateProjectContainer>
              <PageCreateProject showTitle={false} />
            </PageCreateProjectContainer>
          )

        default:
          return null
      }
    },
    [page]
  )

  const setIsMobile = () => {
    setState((prev) => ({
      ...prev,
      isMobile: window.innerWidth < 769,
      isTabletOrMobile: window.innerWidth < 1025
    }))
  }

  React.useEffect(() => {
    setIsMobile()

    window.addEventListener('resize', setIsMobile)

    return () => {
      window.removeEventListener('resize', setIsMobile)
    }
  }, [])

  // Get profile
  React.useEffect(() => {
    if (!profile) {
      dispatch(startFetchingUserByAccount())
    }
  }, [profile])

  // Get project by id
  React.useEffect(() => {
    if (profile && currentProjectId && !selectedProjectError) {
      dispatch(startLoadingProjectById(currentProjectId))
      dispatch(startLoadingProjects(paginationParams))
    }
  }, [profile, currentProjectId, selectedProjectError])

  // If new terreal account, dispatch overlay alert
  React.useEffect(() => {
    if (isInitialisationTerreal) {
      dispatch(setTerrealAccountActivationOverlay())
    }
  })

  // Get projects
  React.useEffect(() => {
    if (profile && !projects && !projectsError) {
      dispatch(startLoadingProjects(paginationParams))
    }
  }, [profile, projects, projectsError])

  // Get worktypes
  React.useEffect(() => {
    if (profile && !workTypes && !worktypesError) {
      dispatch(startLoadingWorktypes())
    }
  }, [profile, workTypes, worktypesError])

  // Get recommendations
  React.useEffect(() => {
    if (profile && !recommendations && !recommendationsError) {
      dispatch(startLoadingRecommendations())
    }
  }, [profile, recommendations, recommendationsError])

  return (
    <ViewProject>
      <ProjectBody className="projectBody">
        {loading ? (
          <LoadingView />
        ) : error ? (
          <PageError error={error} />
        ) : (
          selectedProject && (
            <Fragment>
              {state.isTabletOrMobile ? (
                <Fragment>
                  {renderTabletAndMobilePages(selectedProject)}
                </Fragment>
              ) : (
                <Fragment>
                  <BottomBlock>
                    <DashboardDesktop project={selectedProject} />
                  </BottomBlock>
                  <StepsRenderer />
                </Fragment>
              )}
            </Fragment>
          )
        )}
      </ProjectBody>
    </ViewProject>
  )
}

export default ProjectPage
