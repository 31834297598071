import { call, put, takeLatest } from 'redux-saga/effects'
import { GEOCODING__ADDRESS_START } from '../actions'
import {
  geocodingAddressError,
  geocodingAddressSuccess,
} from '../actions/geocoding-address'
import { logError } from '../actions/system'
import { getSuggestions } from '../utils/api/geocoding-address'
import { getCodeFromError } from '../utils/error'

function* getAddressSuggestions(action) {
  try {
    const data = yield call(getSuggestions, action.payload)
    yield put(geocodingAddressSuccess(data))
  } catch (err) {
    yield put(
      geocodingAddressError({
        code: getCodeFromError(err),
        message: 'Error lors de la geocodage',
      })
    )
    yield put(logError(err))
  }
}

export default [takeLatest(GEOCODING__ADDRESS_START, getAddressSuggestions)]
