import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { closeOverlay } from '../../actions/overlay'
import { ModalFullwidthFullHeightOverlayContent } from '../container-overlay/styled'
import PageProjectList from "../page-project-list";

const SetProjectsListOverlay = (props) => {
  const { closeOverlay } = props

  return (
    <ModalFullwidthFullHeightOverlayContent>
      <PageProjectList onCancel={closeOverlay} />
    </ModalFullwidthFullHeightOverlayContent>
  )
}

const mapStateToProps = ({ project }) => ({
  closeOverlay: PropTypes.func.isRequired,
})

const mapDispatchToProps = (dispatch) => ({
  closeOverlay: () => dispatch(closeOverlay()),
})

SetProjectsListOverlay.propTypes = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SetProjectsListOverlay))
