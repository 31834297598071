import React from 'react'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ConfirmationContainer from '../container-confirmation'

const ConfirmationCallbackPage = ({ confirmationToken }) => (
  <ConfirmationContainer confirmationToken={confirmationToken} />
)

const mapStateToProps = ({ user }) => ({
  confirmationToken: user.confirmationToken
})

ConfirmationCallbackPage.propTypes = ({
  // TODO: useful ?
  // decodedIdToken: PropTypes.shape({
  //   [`${window.processRuntime.env.AUTH0_CUSTOM_CLAIMS_DOMAIN}/app_metadata`]: PropTypes.shape({
  //     justSignedUp: PropTypes.bool.isRequired
  //   }).isRequired
  // }).isRequired,
  confirmationToken: PropTypes.string.isRequired
})

export default connect(mapStateToProps, null)(ConfirmationCallbackPage)
