import React from 'react'

import NotificationsView from '../view-notifications'

const notifications = [{
  id: 'draftNotificationId1',
  createdAt: (new Date(Date.now())).toISOString(),
  title: 'Surveillez vos e-mails !',
  isRead: false,
  bypassReadable: true,
  description: 'Vous êtes actuellement en mode déconnecté. Afin de bénéficier de toutes les fonctionnalités de votre tableau de bord, validez votre inscription en cliquant dans l\'e-mail que vous avez reçu.'
}]

const FakeNotificationsContainer = props => (
  <NotificationsView notifications={notifications} />
)

export default FakeNotificationsContainer
