// TODO : change this action data

export const fakeActions = [
  {
    status: 'unread',
    _id: '6412b7439527a4203ab1ec56',
    links: [
      {
        _id: '6412b7439527a4203ab1ec57',
        type: 'typeform-link',
        typeformId: 'HyJicS',
        typeformDomain: 'https://permettezmoideconstruire.typeform.com',
        dealId: '12338515229',
        status: 'unread',
        url: window.processRuntime.env.HUBSPOT_SALES_URL
      }
    ],
    projectId: 'a0f8a1fd-6144-4ec0-bf87-2d21ea8debcb',
    userId: 100072,
    metaCode: 'formOnboardingBtoB',
    createdAt: '2023-03-16T06:29:23.594Z',
    updatedAt: '2023-03-16T06:30:05.503Z',
    __v: 0,
    id: '6412b7439527a4203ab1ec56',
    meta: {
      id: 3,
      name: 'Attente réponse formlaire',
      text: "Votre formulaire de départ est prêt ! Merci de le compléter au plus vite pour lancer la production de votre dossier.",
      code: 'formOnboardingBtoB',
      clientTypes: ['client_BtoB']
    }
  }
]
