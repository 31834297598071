export const isTypeformNotification = (notification) => {
  return notification.metaId === 2 || notification.metaId === 45 || notification.metaId === 46
}

export const isTypeformNotificationPart2Or3 = (notification) => {
  return notification.metaId === 45 || notification.metaId === 46
}

export const isReadTypeformNotification = (notifications) => {
  if (notifications && notifications.length > 0) {
    const notifs = notifications.filter((n) => isTypeformNotification(n))
    if (notifs.length > 0) {
      return notifs.every((n) => isTypeformNotification(n) && n.isRead === true)
    }
  }
  return false
}
