export const algarDeskFolderTypes = [
  {
    label: `Sans production des pièces`,
    value: `Dépôt et gestion administrative`,
    info: `La création d'un dossier implique uniquement la gestion administrative de ce même dossier.`,
  },
  {
    label: `Avec production des pièces`,
    value: `Création du dossier, dépôt & gestion administrative`,
    info: `La création d'un dossier classique implique la conception graphique, l'étude juridique et la gestion administrative de ce même dossier.`,
  },
]
