export const hasFilterDateSelector = (state) => {
    const {
        createdAt,
        dateExpedition,
        dateDepotMontage,
        dateFinInstruction,
        dateReceptionDpc,
        dateDepotPc,
        decision
    } = state.project.paginationParams.dates

    if (
        (createdAt.startDate && createdAt.endDate) ||
        (dateExpedition.startDate && dateExpedition.endDate) ||
        (dateDepotMontage.startDate && dateDepotMontage.endDate) ||
        (dateFinInstruction.startDate && dateFinInstruction.endDate) ||
        (dateReceptionDpc.startDate && dateReceptionDpc.endDate) ||
        (dateDepotPc.startDate && dateDepotPc.endDate) ||
        (decision.startDate && decision.endDate)
    ) {
        return true
    }

    return false
}