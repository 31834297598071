import React from 'react'
import { Redirect } from 'react-router-dom'

import { compose } from 'recompose'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import LoginPage from '../page-login'

import withQueryParam from '../../utils/middlewares/stateful-query-param'

const QUERY_KEY_USER_EMAIL = window.processRuntime.env.QUERY_KEY_USER_EMAIL
const SELF_ORIGIN = new URL(window.location.href).origin
const CONFIRMATION_PATH = 'confirmation-callback'

const ConfirmationPage = props => (
  !props.accessToken
    ? (
      <LoginPage
        redirectUri={`${SELF_ORIGIN}/${CONFIRMATION_PATH}?token=${props.confirmationToken}`}
        initialScreen='login'
        loginHint={props.userEmail}
      />
    )
    : <Redirect to={`/${CONFIRMATION_PATH}?token=${props.confirmationToken}`} />
)

const mapStateToProps = ({ user }) => ({
  accessToken: user.accessToken,
  confirmationToken: user.confirmationToken
})

ConfirmationPage.propTypes = ({
  accessToken: PropTypes.string,
  confirmationToken: PropTypes.string.isRequired,
  userEmail: PropTypes.string
})

export default compose(
  withQueryParam(QUERY_KEY_USER_EMAIL, {
    propName: 'userEmail',
    optional: true
  }),
  connect(mapStateToProps, null)
)(ConfirmationPage)
