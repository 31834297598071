import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { animationDelay } from '../../constants/styles'
import OverlayCentered from '../overlay-centered'

export const SetPasswordModal = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  max-width: 403px;
`

export const SuccessButton = styled('a')`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  text-align: center;
`

export const SetPasswordTitle = styled('h2')`
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;

  & > span {
    display: block;
  }
`

export const SetPasswordSubTitle = styled.p`
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-bottom: 15px;
`

export const PasswordLock = styled(FontAwesomeIcon)`
  width: 24px;
  height: 29px;
  margin-bottom: 15px;
`

export const AnimatedOverlayCentered = styled(OverlayCentered)`
  & > div {
    animation: ${({ animationName }) => animationName} ${animationDelay}s;

    @keyframes show {
      from {
        top: -50%;
      }
      to {
        top: 50%;
      }
    }

    @keyframes hide {
      from {
        top: 50%;
      }
      to {
        top: -50%;
      }
    }
  }
`

export const ConfirmTextContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`
