import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Portal from '../portal'
import _ from 'lodash'

import { FlexColumn } from '../styled'
import { removeAlert } from '../../actions/alert'
import { mq, reverseMq, ratioToPercent } from '../../utils/style'

import AlertsTransitionManager from '../alerts-transition-manager'

const AlertsOverlay = styled(FlexColumn)`
  position: fixed;
  max-width: 100%;
  left: 0;
  bottom: 0;
  align-items: flex-start;
  z-index: ${props => props.theme.layers.alerts};
  ${props => mq(props)({
    width: [ratioToPercent(1), '450px']
  })}
  ${props => reverseMq(props)({
    right: [null, `${props.theme.spacings.normal}px`]
  })}
`

class AlertsContainer extends Component {
  componentDidUpdate (prevProps) {
    if (this.props.alerts.length > prevProps.alerts.length) {
      _.xorBy(prevProps.alerts, this.props.alerts, 'id')
        .filter(alert => typeof alert.timeout === 'number')
        .forEach(alert => {
          window.setTimeout(() => {
            if (this.props.alerts.find(a => a.id === alert.id)) {
              this.props.removeAlert(alert.id)()
            }
          }, alert.timeout)
        })
    }
  }

  render () {
    return (
      <Portal portalRoot='alerts-root'>
        <AlertsOverlay>
          {this.props.alerts.map(alert => (
            <AlertsTransitionManager
              key={alert.id}
              alert={alert}
              removeAlert={this.props.removeAlert}
            />
          ))}
        </AlertsOverlay>
      </Portal>
    )
  }
}

const mapStateToProps = ({ alert }) => ({
  alerts: alert.alertsList
})

const mapDispatchToProps = dispatch => ({
  removeAlert: id => () => dispatch(removeAlert(id))
})

AlertsContainer.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer)
