import React from 'react'
// import PropTypes from 'prop-types'

import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons'

import Button, { BUTTON_SIZES } from '../styled/button'

const StyledButton = styled(Button)``

const ButtonLine = styled('div')`
  margin: 20px;

  & > ${StyledButton} + ${StyledButton} {
    margin-left: 10px;
  }
`

const TestComponentPage = props => (
  <div>
    <ButtonLine>
      <StyledButton
        color='primary'
      >
        Hey
      </StyledButton>

      <StyledButton
        color='primary'
        outlined
      >
        Ho
      </StyledButton>

      <StyledButton
        color='primary'
        size={BUTTON_SIZES.SMALL}
      >
        Hey
      </StyledButton>

      <StyledButton
        color='primary'
        size={BUTTON_SIZES.LARGE}
      >
        Hey
      </StyledButton>
    </ButtonLine>

    <ButtonLine>
      <StyledButton
        color='primary'
        disabled
      >
        Hey
      </StyledButton>

      <StyledButton
        color='primary'
        outlined
        disabled
      >
        Ho
      </StyledButton>

      <StyledButton
        color='primary'
        size={BUTTON_SIZES.SMALL}
        disabled
      >
        Hey
      </StyledButton>

      <StyledButton
        color='primary'
        size={BUTTON_SIZES.LARGE}
        disabled
      >
        Hey
      </StyledButton>
    </ButtonLine>

    <ButtonLine>
      <StyledButton
        color='primary'
        href='https://www.google.com'
      >
        This is a anchor
      </StyledButton>

      <StyledButton
        color='primary'
        disabled
        href='https://www.google.com'
      >
        Disabled ?
      </StyledButton>
    </ButtonLine>

    <ButtonLine>
      <StyledButton
        color='primary'
      >
        <FontAwesomeIcon icon={faArrowToBottom} />
        &nbsp;
        With icon
      </StyledButton>

      <StyledButton
        color='primary'
        outlined
      >
        <FontAwesomeIcon icon={faArrowToBottom} />
        &nbsp;
        Ho
        &nbsp;
        <FontAwesomeIcon icon={faArrowToBottom} />
      </StyledButton>
    </ButtonLine>
  </div>
)

export default TestComponentPage
