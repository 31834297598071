import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  startLoadingProjects,
  updateProjectPaginationParams,
} from '../../actions/project'
import {
  allProjectsSelector,
  projectPaginationParamsSelector,
  createProjectSelector
} from '../../selectors'
import { isMobile } from '../../utils'
import { ReactPaginateStyled } from './styled'

const ProjectsPagination = (props) => {
  const dispatch = useDispatch()
  const projectsWithTotal = useSelector(allProjectsSelector)
  const { createdProject, createProjectLoading } = useSelector(createProjectSelector)
  const projectTotal = projectsWithTotal ? projectsWithTotal.total : 0
  const paginationParams = useSelector(projectPaginationParamsSelector)
  const { limit, queryString } = paginationParams
  const pageCount = projectTotal <= limit ? 1 : Math.ceil(projectTotal / limit)
  const { onPageChange } = props

  const forcePage = useMemo(() => {
    if (createProjectLoading) {
      return null;
    } else {
      if (createdProject !== null) return 0;
    }
    return 0;
  }, [createProjectLoading])

  const handlePageClick = (event) => {
    const selectedPage = event.selected
    const newOffset = selectedPage * limit
    dispatch(
      updateProjectPaginationParams({ ...paginationParams, offset: newOffset })
    )
    dispatch(
      startLoadingProjects({
        ...paginationParams,
        offset: newOffset,
        queryString,
      })
    )
  }

  return (
    <ReactPaginateStyled
      className={props.className}
      previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
      nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
      previousClassName="pagination__prev"
      nextClassName="pagination__next"
      breakLabel="..."
      breakClassName="pagination__break"
      pageCount={props.pageCount || pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={isMobile ? 1 : 2}
      onPageChange={(event) => {
        onPageChange ? onPageChange(event) : handlePageClick(event)
      }}
      pageClassName="pagination__page"
      activeClassName="is__active"
      disabledClassName="is__disable"
      forcePage={forcePage}
    />
  )
}

ProjectsPagination.propTypes = {
  pageCount: PropTypes.number,
  onPageChange: PropTypes.func,
}

export default ProjectsPagination
