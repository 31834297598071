import { put, takeLatest } from 'redux-saga/effects'

import { getCodeFromError } from '../utils/error'

import {
  DRAFT__DATA_FETCH_START
} from '../actions'

import {
  draftContentFetchSuccess,
  draftContentFetchError
} from '../actions/draft'

import {
  logError
} from '../actions/system'

const STORAGE_KEY = 'draft-data'

const blankProject = {
  firstName: null,
  projectTypes: [],
  address: null,
  latitude: null,
  longitude: null,
  plot: null
}

const silentJsonParse = str => {
  try {
    return JSON.parse(str)
  } catch (err) {
    return null
  }
}

function * startFetchingDraftData () {
  try {
    const draftBasis = yield silentJsonParse(window.sessionStorage.getItem(STORAGE_KEY)) || blankProject
    const draft = yield Array
      .from(new URLSearchParams(window.location.search).entries())
      .reduce((acc, [k, param]) => (
        Object.keys(blankProject).includes(k)
          ? {
            ...acc,
            [k]: Array.isArray(blankProject[k])
              ? param.split(',')
              : param
          }
          : acc
      ), draftBasis)
    yield window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(draft))
    yield put(draftContentFetchSuccess(draft))
  } catch (err) {
    yield put(draftContentFetchError({
      code: getCodeFromError(err),
      message: 'Erreur lors de la récupération des données du brouillon'
    }))
    yield put(logError(err))
  }
}

export default [
  takeLatest(DRAFT__DATA_FETCH_START, startFetchingDraftData)
]
