export const fakeProject = {
  location: null,
  id: '90f0a5a3-e610-4f9d-99a0-ddc458526865',
  address: '40 Rue du Louvre, 75001 Paris, France',
  name: null,
  pipeline: '33247c37-23a4-4e9f-b999-a3c78697d750',
  dealstatus: '1313810',
  plotsIds: [],
  userId: 1243,
  workTypesIds: [13],
  facilitatorId: null,
  sellerId: 11,
  currentStepId: '6408c2f1e68e5c00318c225e',
  comment: null,
  statusUpdatedAt: null,
  status: 'formOnboarding',
  driveFolderId: '14behlEqslf_g3ZXE13lIMiemqhPXvMwT',
  lastVisit: '2023-03-10T07:08:28.263Z',
  createdAt: '2023-03-07T13:52:34.162Z',
  updatedAt: '2023-03-10T07:08:28.263Z',
  deletedAt: null,
  dealname: 'Dossier demo',
  projectname: 'Dossier demo',
  typeDeclarant: '',
  typeClient: 'client_BtoB',
  dealstage: '1313810',
  dateExpedition: '',
  dateDepotMontage: '',
  dateFinInstruction: '',
  dateReceptionDpc: '',
  dateDepotPc: '',
  dadeDecision: '',
  numeroInstruction: '',
  phoneCallDone: false,
  refusRecosA: false,
  isHemeaAvailable: '',
  isHemeaSubmitted: ''
}
