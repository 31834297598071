import jwtDecode from 'jwt-decode'

import {
  USER__CHECK_SESSION_START,
  USER__CHECK_SESSION_SUCCESS,
  USER__CHECK_SESSION_ERROR,

  USER__LOGIN_START,
  USER__LOGIN_SUCCESS,
  USER__LOGIN_ERROR,

  USER__LOGOUT_START,
  USER__LOGOUT_SUCCESS,
  USER__LOGOUT_ERROR,

  USER__FORCE_LOGIN,

  USER__FETCH_BY_ACCOUNT_START,
  USER__FETCH_BY_ACCOUNT_SUCCESS,
  USER__FETCH_BY_ACCOUNT_ERROR,

  USER__CONFIRMATION_TOKEN_RETRIEVE_START,
  USER__CONFIRMATION_TOKEN_RETRIEVE_SUCCESS,
  USER__CONFIRMATION_TOKEN_RETRIEVE_ERROR,

  USER__ACCOUNT_CONFIRMATION_START,
  USER__ACCOUNT_CONFIRMATION_SUCCESS,
  USER__ACCOUNT_CONFIRMATION_ERROR,

  USER__ACCOUNT_LINK_START,
  USER__ACCOUNT_LINK_SUCCESS,
  USER__ACCOUNT_LINK_ERROR,

  USER__CHECK_ACCOUNT_LINKS_START,
  USER__CHECK_ACCOUNT_LINKS_SUCCESS,
  USER__CHECK_ACCOUNT_LINKS_ERROR,

  USER__CREATE_EMAIL_PASSWORD_ACCOUNT_START,
  USER__CREATE_EMAIL_PASSWORD_ACCOUNT_SUCCESS,
  USER__CREATE_EMAIL_PASSWORD_ACCOUNT_ERROR,

  USER__CHANGE_PASSWORD_START,
  USER__CHANGE_PASSWORD_SUCCESS,
  USER__CHANGE_PASSWORD_ERROR,

  USER__UPDATE_CONNECTED_AT,

  ENABLE_HUBSPOT_CHAT
} from '../actions'

const initialState = {
  accessToken: null,
  decodedAccessToken: null,
  idToken: null,
  decodedIdToken: null,
  accessTokenLoading: false,
  accessTokenError: null,
  checkSessionFailed: false,
  loginOptions: null,

  profile: null,
  profileLoading: false,
  profileError: null,

  confirmationToken: null,
  confirmationTokenLoading: false,
  confirmationTokenError: null,

  logoutLoading: false,
  logoutError: null,

  accountConfirmationLoading: false,
  accountConfirmationError: null,
  confirmedProject: null,

  accountLinkingLoading: false,
  accountLinkingError: null,

  linkedAccountsData: null,
  linkedAccountsLoading: false,
  linkedAccountsError: null,

  createEmailPasswordAccountCompleted: false,
  createEmailPasswordAccountLoading: false,
  createEmailPasswordAccountError: null,

  passwordChangeLoading: false,
  passwordChangeError: null,
  passwordChangeSuccess: false,

  updatedConnectedAt: false,

  hubspotChatEnabled: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER__CHECK_SESSION_START:
      return {
        ...state,
        accessTokenLoading: true,
        accessTokenError: null,
        checkSessionFailed: false
      }

    case USER__CHECK_SESSION_SUCCESS:
      return {
        ...state,
        accessTokenLoading: false,
        accessToken: action.payload ? action.payload.accessToken : null,
        idToken: action.payload ? action.payload.idToken : null,
        decodedAccessToken: (action.payload && action.payload.accessToken) ? jwtDecode(action.payload.accessToken) : null,
        decodedIdToken: (action.payload && action.payload.idToken) ? jwtDecode(action.payload.idToken) : null
      }

    case USER__CHECK_SESSION_ERROR:
      return {
        ...state,
        accessTokenLoading: false,
        accessTokenError: action.payload,
        checkSessionFailed: true
      }

    case USER__LOGIN_START:
      return {
        ...state,
        accessTokenLoading: false,
        accessTokenError: null,
        loginOptions: action.payload
      }

    case USER__LOGIN_SUCCESS:
      return {
        ...state,
        accessTokenLoading: false,
        accessToken: action.payload ? action.payload.accessToken : null,
        idToken: action.payload ? action.payload.idToken : null,
        decodedAccessToken: (action.payload && action.payload.accessToken) ? jwtDecode(action.payload.accessToken) : null,
        decodedIdToken: (action.payload && action.payload.idToken) ? jwtDecode(action.payload.idToken) : null,
        loginRedirectUri: null
      }

    case USER__LOGIN_ERROR:
      return {
        ...state,
        accessTokenLoading: false,
        accessTokenError: action.payload,
        loginRedirectUri: null
      }

    case USER__LOGOUT_START:
      return {
        ...state,
        logoutLoading: true,
        logoutError: null
      }

    case USER__LOGOUT_SUCCESS:
      return {
        ...state,
        logoutLoading: false,
        profile: null,
        accessToken: null,
        decodedAccessToken: null,
        idToken: null,
        decodedIdToken: null
      }

    case USER__LOGOUT_ERROR:
      return {
        ...state,
        logoutLoading: false,
        logoutError: action.payload
      }

    case USER__FORCE_LOGIN:
      return {
        ...state,
        accessToken: null,
        profile: null,
        decodedAccessToken: null,
        idToken: null,
        decodedIdToken: null
      }

    case USER__FETCH_BY_ACCOUNT_START:
      return {
        ...state,
        profileLoading: true,
        profileError: null
      }

    case USER__FETCH_BY_ACCOUNT_SUCCESS:
      return {
        ...state,
        profileLoading: false,
        profile: action.payload
      }

    case USER__FETCH_BY_ACCOUNT_ERROR:
      return {
        ...state,
        profileLoading: false,
        profileError: action.payload
      }

    case USER__CONFIRMATION_TOKEN_RETRIEVE_START:
      return {
        ...state,
        confirmationTokenLoading: true
      }

    case USER__CONFIRMATION_TOKEN_RETRIEVE_SUCCESS:
      return {
        ...state,
        confirmationTokenLoading: false,
        confirmationToken: action.payload,
        confirmationTokenError: null
      }

    case USER__CONFIRMATION_TOKEN_RETRIEVE_ERROR:
      return {
        ...state,
        confirmationTokenLoading: false,
        confirmationToken: null,
        confirmationTokenError: action.payload
      }

    case USER__ACCOUNT_CONFIRMATION_START:
      return {
        ...state,
        accountConfirmationLoading: true,
        accountConfirmationError: null,
        confirmedProject: null
      }

    case USER__ACCOUNT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        accountConfirmationLoading: false,
        confirmedProject: action.payload
      }

    case USER__ACCOUNT_CONFIRMATION_ERROR:
      return {
        ...state,
        accountConfirmationLoading: false,
        accountConfirmationError: action.payload
      }

    case USER__ACCOUNT_LINK_START:
      return {
        ...state,
        accountLinkingLoading: true,
        accountLinkingError: null
      }

    case USER__ACCOUNT_LINK_SUCCESS:
      return {
        ...state,
        accountLinkingLoading: false
      }

    case USER__ACCOUNT_LINK_ERROR:
      return {
        ...state,
        accountLinkingLoading: false,
        accountLinkingError: action.payload
      }

    case USER__CHECK_ACCOUNT_LINKS_START:
      return {
        ...state,
        linkedAccountsLoading: true,
        linkedAccountsError: null
      }

    case USER__CHECK_ACCOUNT_LINKS_SUCCESS:
      return {
        ...state,
        linkedAccountsLoading: false,
        linkedAccountsData: action.payload
      }

    case USER__CHECK_ACCOUNT_LINKS_ERROR:
      return {
        ...state,
        linkedAccountsLoading: false,
        linkedAccountsError: action.payload
      }

    case USER__CREATE_EMAIL_PASSWORD_ACCOUNT_START:
      return {
        ...state,
        createEmailPasswordAccountLoading: true,
        createEmailPasswordAccountCompleted: false,
        createEmailPasswordAccountError: null
      }

    case USER__CREATE_EMAIL_PASSWORD_ACCOUNT_SUCCESS:
      return {
        ...state,
        createEmailPasswordAccountCompleted: true,
        createEmailPasswordAccountLoading: false
      }

    case USER__CREATE_EMAIL_PASSWORD_ACCOUNT_ERROR:
      return {
        ...state,
        createEmailPasswordAccountLoading: false,
        createEmailPasswordAccountError: action.payload
      }

    case USER__UPDATE_CONNECTED_AT:
      return {
        ...state,
        updatedConnectedAt: true
      }

    case USER__CHANGE_PASSWORD_START:
      return {
        ...state,
        passwordChangeLoading: true,
        passwordChangeError: null
      }

    case USER__CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChangeLoading: false,
        passwordChangeSuccess: true
      }

    case USER__CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        passwordChangeLoading: false,
        passwordChangeError: action.payload
      }

    case ENABLE_HUBSPOT_CHAT:
      return {
        ...state,
        hubspotChatEnabled: true,
      }

    default:
      return state
  }
}
