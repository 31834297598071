import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  NOTIFICATIONS__GET_BY_PROJECT_START,
  NOTIFICATIONS__LOAD_BY_PROJECT_START,
  NOTIFICATIONS__LOAD_BY_USER_START,
  NOTIFICATIONS__LOAD_META_START,
  NOTIFICATIONS__READ_NOTIFICATION_START,
} from '../actions'
import {
  completeActionError,
  completeActionSuccess,
  loadNotificationsByProjectError,
  loadNotificationsByProjectSuccess,
  loadNotificationsByUserError,
  loadNotificationsByUserSuccess,
  loadNotificationsMetaError,
  loadNotificationsMetaSuccess,
  readNotificationError,
  readNotificationSuccess,
  updatePaginationParams,
} from '../actions/notification'
import { logError } from '../actions/system'
import { getCodeFromError } from '../utils/error'
import PMDC from '../utils/pmdc'

function* loadNotificationsByProject() {
  try {
    const { user, project } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getNotifications,
      project.selectedProjectId
    )
    yield put(loadNotificationsByProjectSuccess(data))
  } catch (err) {
    yield put(
      loadNotificationsByProjectError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération des notifications',
      })
    )
    yield put(logError(err))
  }
}

function* loadNotificationsByUser(action) {
  try {
    const {
      notification: { paginationParams, userNotifications },
    } = yield select()

    const { limit, pageCount, nexPage } = paginationParams

    const { user, paginationParams: newParams } = action.payload

    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getNotificationsByUser,
      user.profile.id,
      newParams
    )

    const newUserNotifications = userNotifications
      ? [...userNotifications, ...data.data]
      : data.data

    yield put(loadNotificationsByUserSuccess(newUserNotifications, data.total))

    const newPageCount = Math.ceil(data.total / limit)

    if (pageCount === 0 || nexPage < newPageCount) {
      yield put(
        updatePaginationParams({
          ...paginationParams,
          pageCount: newPageCount,
          nexPage: nexPage + 1,
        })
      )
    }
  } catch (err) {
    yield put(
      loadNotificationsByUserError({
        code: getCodeFromError(err),
        message: `Erreur lors de la récupération des notifications de l'utilisateur`,
      })
    )
    yield put(logError(err))
  }
}

function* loadNotificationsMeta() {
  try {
    const { user } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getNotificationsMeta
    )
    yield put(loadNotificationsMetaSuccess(data))
  } catch (err) {
    yield put(
      loadNotificationsMetaError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération des données de notifications',
      })
    )
    yield put(logError(err))
  }
}

function* readNotification(action) {
  try {
    const { user } = yield select()
    yield call(
      PMDC(user.accessToken, user.decodedAccessToken).readNotification,
      action.payload
    )
    yield put(readNotificationSuccess(action.payload))
  } catch (err) {
    yield put(
      readNotificationError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la lecture de la notification',
        notificationId: action.payload,
      })
    )
    yield put(logError(err))
  }
}

export default [
  takeLatest(NOTIFICATIONS__GET_BY_PROJECT_START, loadNotificationsByProject),
  takeLatest(NOTIFICATIONS__LOAD_BY_PROJECT_START, loadNotificationsByProject),
  takeLatest(NOTIFICATIONS__LOAD_BY_USER_START, loadNotificationsByUser),
  takeLatest(NOTIFICATIONS__LOAD_META_START, loadNotificationsMeta),
  takeLatest(NOTIFICATIONS__READ_NOTIFICATION_START, readNotification),
]
