import styled from '@emotion/styled'
import { baseButtonCss } from '../styled'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'
import { headerHeight } from '../../constants/styles'

export const ParameterWrap = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacings.medium}px;
  padding-top: ${({ theme }) => theme.spacings.large}px;
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding-top: calc(${headerHeight}px + 16.5px);
  }
`

export const ParameterContent = styled('div')``

export const ParameterFooter = styled('div')``

const menuItemCss = (props) => css`
  ${baseButtonCss(props)}
  width: 16em;
  padding: ${props.theme.spacings.small * 1.2}px;
  color: ${props.theme.colors.dark};
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: ${props.theme.sizes.h5}px;

  &:hover,
  &:hover > * {
    color: ${props.theme.colors.primary};
  }

  & > span {
    display: inline-block;
    margin-left: ${props.theme.spacings.small}px;
  }
`

export const MenuLink = styled(Link)`
  ${menuItemCss}
  text-decoration: none;
`
export const MenuButton = styled('button')`
  ${menuItemCss}
`
