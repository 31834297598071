import { omit } from 'lodash'

const sources = {
  mapboxRaster: {
    id: 'mapbox-raster',
    type: 'raster',
    tiles: ['a', 'b'].map(srv => (
      `https://${srv}.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${window.processRuntime.env.MAPBOX_ACCESS_TOKEN}`
    )),
    tileSize: 256,
    maxZoom: 19
  },
  ignRaster: {
    id: 'ign-raster',
    type: 'raster',
    tiles: [
      `https://wxs.ign.fr/${window.processRuntime.env.GEO_KEY}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}`
    ],
    tileSize: 256,
    maxzoom: 19
  }
}

const layers = {
  backgroundTilesFallback: {
    id: 'background-tiles-fallback',
    type: 'raster',
    source: sources.mapboxRaster.id
  },
  backgroundTiles: {
    id: 'background-tiles',
    type: 'raster',
    source: sources.ignRaster.id
  }
}

const formattedLayers = Object.values(layers)

const formattedSources = Object.values(sources).reduce((acc, source) => ({
  ...acc,
  [source.id]: omit(source, 'id')
}), {})

export {
  formattedSources as sources,
  formattedLayers as layers
}
