import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import styles from './styles'

const SelectInput = (props) => {
  return (
    <Select
      {...props}
      styles={styles(props)}
      onClick={(e) => {
        e.stopPropagation()
      }}
      className="react-select-container"
      classNamePrefix="react-select"
      formatOptionLabel={(data) => {
        return <span dangerouslySetInnerHTML={{ __html: data.label }} />
      }}
    />
  )
}

SelectInput.defaultProps = {
  hideSelectedOptions: false
}

SelectInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  isMulti: PropTypes.bool.isRequired,
  hideSelectedOptions: PropTypes.bool
}

export default withTheme(SelectInput)
