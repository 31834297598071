import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { startLoadingProjects } from '../../actions/project'
import { projectSelector, userSelector, isBtoBSelector } from '../../selectors'
import ErrorPage from '../page-error'
import LoadingView from '../view-loading'
import { DEMO_ROUTE } from '../../constants/router'

const HomePage = () => {
  const dispatch = useDispatch()
  const { accessToken, profileLoading } = useSelector(userSelector)
  const { projects, projectsLoading, projectsError, paginationParams } =
    useSelector(projectSelector)
  const isBtob = useSelector(isBtoBSelector)

  const [shouldRedirect, setShouldRedirect] = useState(false)

  const redirectTo = useCallback(() => {

    if (projects && projects.data.length > 0) {
      const returnedPath = isBtob ? `/mes-dossiers` : `/projet/${projects.data[0].id}`
      return returnedPath

    } else {
      return DEMO_ROUTE
    }
  }, [projects])

  // Get projects
  React.useEffect(() => {
    if (accessToken && !projects && !projectsError) {
      dispatch(startLoadingProjects(paginationParams))
    }
  }, [accessToken, projects, projectsError])

  React.useEffect(() => {
    if (projects && !projectsLoading && !projectsError) {
      setShouldRedirect(true)
    }
  }, [projects, projectsLoading, projectsError])

  if (profileLoading || projectsLoading) {
    return <LoadingView />
  }

  if (projectsError) {
    return <ErrorPage error={projectsError} />
  }

  return shouldRedirect ? <Redirect to={redirectTo()} /> : null
}

export default HomePage
