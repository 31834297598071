import React from 'react'
import { Redirect } from 'react-router-dom'
import { If, Else, Then } from 'react-if'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const ProjectByConfirmationTokenPage = ({ fullConfirmationToken }) => (
  <If condition={!!(fullConfirmationToken && fullConfirmationToken.projectId)}>
    <Then>{() => <Redirect to={`/projet/${fullConfirmationToken.projectId}`} />}</Then>
    <Else>{() => null}</Else>
  </If>
)

const mapStateToProps = ({ project }) => ({
  fullConfirmationToken: project.fullConfirmationToken
})

ProjectByConfirmationTokenPage.propTypes = ({
  fullConfirmationToken: PropTypes.shape({
    projectId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired
  })
})

export default connect(mapStateToProps, null)(ProjectByConfirmationTokenPage)
