import { faCircleArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { startLoadingProjectById } from '../../actions/project'
import { selectedProjectSelector } from '../../selectors'
import { ProjectListWrapper } from '../page-project-list/styled'
import {
  BtnBack,
  TallyFormBody,
  TallyFormContainer,
  TallyFormIframe,
  TallyFormTitle
} from './styled'

const TallyForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const baseURL = window.processRuntime.env.TALLY_BASE_URL
  const { projectId, formId } = useParams()
  const { search } = useLocation()

  const formTitle = new URLSearchParams(search).get('formTitle')

  const selectedProject = useSelector(selectedProjectSelector)

  const handleClickBtnBack = useCallback(() => {
    history.push(`/projet/${projectId}`)
  }, [projectId])

  React.useEffect(() => {
    if (projectId && !selectedProject) {
      dispatch(startLoadingProjectById(projectId))
    }
  }, [projectId, selectedProject])

  React.useEffect(() => {
    Tally.loadEmbeds()
  }, [])

  return (
    <ProjectListWrapper>
      <TallyFormContainer>
        <TallyFormTitle>
          <BtnBack onClick={handleClickBtnBack}>
            <FontAwesomeIcon icon={faCircleArrowLeft} />
          </BtnBack>
          <span>Formulaires</span>
        </TallyFormTitle>
        <TallyFormBody>
          <TallyFormIframe
            data-tally-src={`${baseURL}/embed/${formId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`}
            loading="lazy"
            width="100%"
            height="100%"
          ></TallyFormIframe>
        </TallyFormBody>
      </TallyFormContainer>
    </ProjectListWrapper>
  )
}

export default TallyForm
