import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startLoadingWorktypes } from '../../actions/project'

import buildMiddleware from './template'

const withWorktypesFetch = (SubComponent) => {
  const WithWorktypesFetchWrapper = buildMiddleware(
    SubComponent,
    'WithWorktypes'
  )
  const WithWorktypesFetch = class extends Component {
    componentDidMount () {
      this.props.startLoadingWorktypes()
    }

    render () {
      const {
        startLoadingWorktypes,
        ...passThroughProps
      } = this.props

      return <WithWorktypesFetchWrapper {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ project }) => ({
    loading: project.workTypesLoading || (!project.workTypes && !project.workTypesError),
    error: project.workTypesError
  })

  const mapDispatchToProps = dispatch => ({
    startLoadingWorktypes: () => dispatch(startLoadingWorktypes())
  })

  WithWorktypesFetch.propTypes = {
    startLoadingWorktypes: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithWorktypesFetch)
}

export default withWorktypesFetch
