import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SwiperSlide } from 'swiper/react'
import { filesListingSuccess } from '../../actions/files'
import {
  loadProjectByIdSuccess,
  loadRecommendationsSuccess
} from '../../actions/project'
import { loadStepsByProjectSuccess } from '../../actions/step'
import { fakeActions } from '../../constants/fake-actions'
import { fakeFileList } from '../../constants/fake-file-list'
import { fakeProject } from '../../constants/fake-project'
import { fakeProjectSteps } from '../../constants/fake-project-steps'
import { fakeRecommendations } from '../../constants/fake-recommendations'
import { projectSelector, selectedProjectSelector } from '../../selectors'
import { filesListSelector, rootFilesSelector } from '../../selectors/file'
import { stepsMetaSelector } from '../../selectors/step'
import ActionItem from '../action-item'
import DetailsContainer from '../container-details'
import MapContainer from '../container-map'
import StatusContainer from '../container-status'
import {
  MyActionsContent,
  MyActionsStyled,
  MyActionsTitle
} from '../my-actions/styled'
import PageCreateProject from '../overlay-create-project/page-create-project'
import PageParameter from '../page-parameter'
import SwiperStyled from '../swiper/SwiperStyled'
import FilesView from '../view-files'
import ViewStepsV2 from '../view-steps/steps-v2'
import {
  FirstSlideContentWrapper,
  SecondSlideContentWrapper,
  ThirdSlideContentWrapper
} from './Layouts/DashboardMobile'
import { FilesAndStepsContainer } from './Layouts/DashboardTablet'
import {
  DashboardContentTopBlock,
  DashboardContentTopBlockColFirst,
  DashboardContentTopBlockColSecond,
  DashboardContentTopBlockLineFirst,
  DashboardContentTopBlockLineSecond,
  DashboardDesktopStyled
} from './Layouts/styled'
import { BottomBlock, PageCreateProjectContainer, ProjectBody, StepsBlock, ViewProject } from './styled'

const PageProjectDraft = () => {
  const dispatch = useDispatch()
  const selectedProject = useSelector(selectedProjectSelector)
  const { workTypes, page } = useSelector(projectSelector)
  const files = useSelector(filesListSelector)
  const rootFiles = useSelector(rootFilesSelector)
  const metaSteps = useSelector(stepsMetaSelector)

  const [{ isMobile, isTabletOrMobile }, setState] = useState({
    isMobile: false,
    isTabletOrMobile: false
  })

  const setIsMobile = () => {
    setState((prev) => ({
      ...prev,
      isMobile: window.innerWidth < 769,
      isTabletOrMobile: window.innerWidth < 1025
    }))
  }

  React.useEffect(() => {
    setIsMobile()
    window.addEventListener('resize', setIsMobile)

    return () => {
      window.removeEventListener('resize', setIsMobile)
    }
  }, [])

  React.useEffect(() => {
    dispatch(loadProjectByIdSuccess(fakeProject))
    dispatch(loadStepsByProjectSuccess(fakeProjectSteps))
    dispatch(loadRecommendationsSuccess(fakeRecommendations))
    dispatch(filesListingSuccess(fakeFileList))
  }, [])

  const renderActions = () => {
    return (
      <MyActionsStyled>
        <MyActionsTitle>Actions</MyActionsTitle>
        <MyActionsContent>
          {fakeActions.map((action) => (
            <ActionItem key={action.id} action={action} />
          ))}
        </MyActionsContent>
      </MyActionsStyled>
    )
  }

  const renderFiles = () => {
    return (
      <FilesView
        files={files}
        rootFiles={rootFiles}
        loadingFilesIds={[]}
        deleteFile={() => {}}
        handleFileUpload={() => {}}
        getFileDownloadLink={() => {}}
        previewFile={() => {}}
        uploadingFilesDescriptors={[]}
        accessToken=""
      />
    )
  }

  const renderSteps = () => {
    return (
      <StepsBlock>
        <ViewStepsV2 steps={metaSteps} />
      </StepsBlock>
    )
  }

  const renderMobileDash = () => {
    return (
      <SwiperStyled>
        <SwiperSlide>
          <FirstSlideContentWrapper>
            <MapContainer project={fakeProject} />
            <DetailsContainer project={fakeProject} />
            <StatusContainer project={fakeProject} />
          </FirstSlideContentWrapper>
        </SwiperSlide>
        <SwiperSlide>
          <SecondSlideContentWrapper>
            {renderActions()}
            {renderFiles()}
          </SecondSlideContentWrapper>
        </SwiperSlide>
        <SwiperSlide>
          <ThirdSlideContentWrapper>{renderSteps()}</ThirdSlideContentWrapper>
        </SwiperSlide>
      </SwiperStyled>
    )
  }

  const renderTabletDash = () => {
    return (
      <SwiperStyled>
        <SwiperSlide>{renderDashboardTopBlock()}</SwiperSlide>
        <SwiperSlide>
          <FilesAndStepsContainer>
            {renderFiles()}
            {renderSteps()}
          </FilesAndStepsContainer>
        </SwiperSlide>
      </SwiperStyled>
    )
  }

  const renderDashboardTopBlock = () => {
    return (
      <DashboardContentTopBlock>
        <DashboardContentTopBlockLineFirst>
          <DashboardContentTopBlockColFirst>
            <MapContainer project={fakeProject} />
          </DashboardContentTopBlockColFirst>
          <DashboardContentTopBlockColSecond>
            {renderActions()}
          </DashboardContentTopBlockColSecond>
        </DashboardContentTopBlockLineFirst>
        <DashboardContentTopBlockLineSecond>
          <DashboardContentTopBlockColFirst>
            <StatusContainer project={fakeProject} />
          </DashboardContentTopBlockColFirst>
          <DashboardContentTopBlockColSecond>
            <DetailsContainer project={fakeProject} />
          </DashboardContentTopBlockColSecond>
        </DashboardContentTopBlockLineSecond>
      </DashboardContentTopBlock>
    )
  }

  return selectedProject && workTypes ? (
    <ViewProject>
      <ProjectBody className="projectBody">
        {isTabletOrMobile ? (
          <>
            {page === 'projet' ? (
              <BottomBlock>
                {isMobile ? renderMobileDash() : renderTabletDash()}
              </BottomBlock>
            ) : page === 'parametres' ? (
              <PageParameter />
            ) : page === 'page-create-new-project' ? (
              <PageCreateProjectContainer>
                <PageCreateProject showTitle={false} />
              </PageCreateProjectContainer>
            ) : null}
          </>
        ) : (
          <>
            <BottomBlock>
              <DashboardDesktopStyled>
                {renderDashboardTopBlock()}
                {renderFiles()}
              </DashboardDesktopStyled>
            </BottomBlock>
            {renderSteps()}
          </>
        )}
      </ProjectBody>
    </ViewProject>
  ) : null
}

export default PageProjectDraft
