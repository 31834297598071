import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startLogin } from '../../actions/user'

import LoadingView from '../view-loading'

class LoginPage extends Component {
  componentDidMount () {
    const authOptions = {
      redirectUri: this.props.redirectUri,
      initialScreen: this.props.initialScreen,
      login_hint: this.props.loginHint
    }

    this.props.startLogin(authOptions)
  }

  render () {
    return <LoadingView />
  }
}

const mapDispatchToProps = dispatch => ({
  startLogin: authOptions => dispatch(startLogin(authOptions))
})

LoginPage.defaultProps = {
  redirectUri: new URL(window.location.href).origin + '/login-callback',
  initialScreen: undefined,
  loginHint: undefined
}

LoginPage.propTypes = {
  redirectUri: PropTypes.string,
  initialScreen: PropTypes.oneOf([
    'login',
    'signUp',
    'forgotPassword'
  ]),
  loginHint: PropTypes.string,
  startLogin: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(LoginPage)
