import React from 'react'
import DetailsContainer from '../../container-details'
import FilesContainer from '../../container-files'
import MapContainer from '../../container-map'
import StatusContainer from '../../container-status'
import MyActions from '../../my-actions'
import {
  DashboardContentTopBlock,
  DashboardContentTopBlockColFirst,
  DashboardContentTopBlockColSecond,
  DashboardContentTopBlockLineFirst,
  DashboardContentTopBlockLineSecond,
  DashboardDesktopStyled
} from './styled'
import PropTypes from 'prop-types'

export const DashboardContentTopBlockRenderer = ({ project }) => {
  return (
    <DashboardContentTopBlock>
      <DashboardContentTopBlockLineFirst>
        <DashboardContentTopBlockColFirst>
          <MapContainer project={project} />
        </DashboardContentTopBlockColFirst>
        <DashboardContentTopBlockColSecond>
          <MyActions />
        </DashboardContentTopBlockColSecond>
      </DashboardContentTopBlockLineFirst>
      <DashboardContentTopBlockLineSecond>
        <DashboardContentTopBlockColFirst>
          <StatusContainer project={project} />
        </DashboardContentTopBlockColFirst>
        <DashboardContentTopBlockColSecond>
          <DetailsContainer project={project} />
        </DashboardContentTopBlockColSecond>
      </DashboardContentTopBlockLineSecond>
    </DashboardContentTopBlock>
  )
}

DashboardContentTopBlockRenderer.propTypes = {
  project: PropTypes.object.isRequired
}

const DashboardDesktop = ({ project }) => {
  return (
    <DashboardDesktopStyled>
      <DashboardContentTopBlockRenderer project={project} />
      <FilesContainer project={project} />
    </DashboardDesktopStyled>
  )
}

DashboardDesktop.propTypes = {
  project: PropTypes.object.isRequired
}

export default DashboardDesktop
