import styled from '@emotion/styled'
import { textFontSizeCss } from '../../styles/common'

export const ActionItemStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  opacity: ${({ isDone }) => isDone ? 0.5 : 1}
`

export const ActionItemIcon = styled.div`
  color: ${({ theme, isDone }) => isDone ? theme.colors.green : theme.colors.tertiary};
  & > svg {
    width: 14.53px !important;
    height: 14.53px !important;
  }
`

export const ActionItemData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const ActionItemText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`
