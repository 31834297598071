import React, { useState, useEffect } from 'react'
import useHover from '../../hooks/use-hover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { chain } from 'lodash'
import { isMobile } from '../../utils'
import {
  SUB_FILE_SORTBY_CRITERIAS,
  SUB_FILE_SORTBY_ORDERS
} from '../../utils/render'

import LoadingSpinner from '../loading-spinner'

import {
  faFile,
  faFolder,
  faFolderOpen,
  faAngleRight,
  faAngleDown
} from '@fortawesome/pro-light-svg-icons'
import { faDownload, faTrash } from '@fortawesome/pro-solid-svg-icons'

import FileSpinner from '../styled/file-spinner'

import {
  FileContents,
  File,
  FileIcon,
  FileButton,
  FolderArrowIcon,
  FileName,
  FileLink,
  FolderContentsWrapper,
  FolderContents,
  DateWrapper
} from './styled'
import { formatDate } from '../../utils/date'

const defaultFileEmptyFolder = {
  id: 'empty',
  isFolder: false,
  name: 'Dossier vide'
}

const FileView = ({
  indent = 0,
  disabled,
  isEmptyFolderPlaceholder,
  onClick,
  onFileDelete,
  onFileDownload,
  getFileDownloadLink,
  expandedFolders,
  loadingFilesIds,
  files,
  theme,
  canRead,
  canWrite,
  ...file
}) => {
  const [clicked, setClicked] = useState(false)
  const expanded = expandedFolders.includes(file.id)
  const loading = loadingFilesIds.includes(file.id)

  useEffect(() => {
    setClicked(false)
  }, [expanded])

  const shouldSubRender = file.isFolder && expanded
  const icon = file.isFolder
    ? shouldSubRender
      ? faFolderOpen
      : faFolder
    : faFile
  const subFiles = shouldSubRender
    ? files.filter((f) => f.parent === file.id)
    : []

  const [isFileTreeHovered, bindFileTreeHover] = useHover()

  const showDeleteBtn = () => {
    if (files && file && file.parent) {
      const fileParent = files.find(({ id }) => id === file.parent)
      if (fileParent && fileParent.name === 'Mes documents') {
        return true
      }
    }

    return false
  }

  return (
    <>
      <FileContents
        {...bindFileTreeHover()}
        disabled={isEmptyFolderPlaceholder}
        onClick={() => setClicked(true)}
        isOpened={shouldSubRender}
      >
        {isMobile && expanded ? (
          <></>
        ) : (
          <File
            indent={indent}
            value={file.id}
            data-isfolder={file.isFolder}
            data-mimetype={file.type}
            isOpened={shouldSubRender}
            onClick={(typeof onClick === 'function' && onClick) || null}
            className={!isEmptyFolderPlaceholder && clicked ? 'highlight' : ''}
            disabled={loading}
          >
            {!isEmptyFolderPlaceholder && (
              <FileIcon isFolder={file.isFolder}>
                <FontAwesomeIcon
                  icon={icon}
                  size="lg"
                  color={theme.colors.primarySemiLight}
                  fixedWidth
                />
              </FileIcon>
            )}

            <FileName
              isPlaceholder={isEmptyFolderPlaceholder}
              isFolder={file.isFolder}
            >
              {file.name}
            </FileName>

            {file.isFolder && (
              <FolderArrowIcon>
                <FontAwesomeIcon
                  icon={shouldSubRender ? faAngleDown : faAngleRight}
                  size="lg"
                  color={
                    isFileTreeHovered
                      ? theme.colors.primary
                      : theme.colors.primarySemiLight
                  }
                />
              </FolderArrowIcon>
            )}
          </File>
        )}

        {!file.isFolder && !loading && !disabled && (
          <>
            {file?.createdAt && (
              <DateWrapper>{formatDate(file?.createdAt)}</DateWrapper>
            )}

            <FileLink
              name={file.name}
              value={file.id}
              data-mimetype={file.type}
              onClick={onFileDownload}
              showDeleteBtn={String(showDeleteBtn())}
            >
              <FontAwesomeIcon
                icon={faDownload}
                size="lg"
                color={theme.colors.primary}
                fixedWidth
              />
            </FileLink>

            {showDeleteBtn() && (
              <FileButton
                name={file.name}
                value={file.id}
                data-mimetype={file.type}
                onClick={onFileDelete}
                disabled={!canWrite}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  color="#F13426"
                  fixedWidth
                />
              </FileButton>
            )}
          </>
        )}

        {loading && (
          <FileSpinner>
            <LoadingSpinner size={16} />
          </FileSpinner>
        )}
      </FileContents>

      {shouldSubRender && (
        <FolderContents>
          <FolderContentsWrapper>
            {subFiles.length ? (
              chain(subFiles)
                .orderBy(SUB_FILE_SORTBY_CRITERIAS, SUB_FILE_SORTBY_ORDERS)
                .map((f) => (
                  <FileView
                    key={f.id}
                    indent={indent + 1}
                    expandedFolders={expandedFolders}
                    loadingFilesIds={loadingFilesIds}
                    disabled={disabled}
                    onClick={onClick}
                    onFileDelete={onFileDelete}
                    onFileDownload={onFileDownload}
                    theme={theme}
                    getFileDownloadLink={getFileDownloadLink}
                    files={files}
                    canRead={canRead}
                    canWrite={canWrite}
                    {...f}
                  />
                ))
                .value()
            ) : (
              <FileView
                indent={indent + 1}
                expandedFolders={expandedFolders}
                loadingFilesIds={loadingFilesIds}
                disabled
                onClick
                onFileDelete
                onFileDownload
                theme={theme}
                getFileDownloadLink={getFileDownloadLink}
                files={files}
                isEmptyFolderPlaceholder
                canRead={canRead}
                canWrite={canWrite}
                {...defaultFileEmptyFolder}
              />
            )}
          </FolderContentsWrapper>
        </FolderContents>
      )}
    </>
  )
}

export default FileView
