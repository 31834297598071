import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startFetchingTemplateFolder } from '../../actions/files'

import buildMiddleware from './template'

const withTemplateFolderFetch = (SubComponent) => {
  const ComponentWithMiddlewareWrapper = buildMiddleware(
    SubComponent,
    'WithFiles'
  )

  const WithFilesFetch = class extends Component {
    componentDidMount () {
      this.props.startFetchingTemplateFolder()
    }

    render () {
      const {
        startFilesListing,
        ...passThroughProps
      } = this.props

      return <ComponentWithMiddlewareWrapper {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ project, files }) => ({
    loading: files.templateFolderLoading || (!files.templateFolderContents && !files.templateFolderError),
    error: files.templateFolderError
  })

  const mapDispatchToProps = dispatch => ({
    startFetchingTemplateFolder: () => dispatch(startFetchingTemplateFolder())
  })

  WithFilesFetch.propTypes = {
    startFetchingTemplateFolder: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithFilesFetch)
}

export default withTemplateFolderFetch
