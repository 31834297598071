import {
  NOTIFICATIONS__GET_BY_PROJECT_START,

  NOTIFICATIONS__LOAD_BY_PROJECT_START,
  NOTIFICATIONS__LOAD_BY_PROJECT_SUCCESS,
  NOTIFICATIONS__LOAD_BY_PROJECT_ERROR,

  NOTIFICATIONS__LOAD_META_START,
  NOTIFICATIONS__LOAD_META_SUCCESS,
  NOTIFICATIONS__LOAD_META_ERROR,

  NOTIFICATIONS__READ_NOTIFICATION_START,
  NOTIFICATIONS__READ_NOTIFICATION_SUCCESS,
  NOTIFICATIONS__READ_NOTIFICATION_ERROR,

  NOTIFICATIONS__RESET_NB_CALL,

  NOTIFICATIONS__LOAD_BY_USER_START,
  NOTIFICATIONS__LOAD_BY_USER_SUCCESS,
  NOTIFICATIONS__LOAD_BY_USER_ERROR,

  NOTIFICATIONS__UPDATE_PAGINATION_PARAMS
} from '.'


export const startGetNotificationWithInterval = () => ({
  type: NOTIFICATIONS__GET_BY_PROJECT_START
})

export const startLoadingNotificationsByProject = () => ({
  type: NOTIFICATIONS__LOAD_BY_PROJECT_START
})

export const loadNotificationsByProjectSuccess = notifications => ({
  type: NOTIFICATIONS__LOAD_BY_PROJECT_SUCCESS,
  payload: notifications
})

export const loadNotificationsByProjectError = error => ({
  type: NOTIFICATIONS__LOAD_BY_PROJECT_ERROR,
  payload: error
})

export const startLoadingNotificationsByUser = (user, paginationParams) => ({
  type: NOTIFICATIONS__LOAD_BY_USER_START,
  payload: { user, paginationParams }
})

export const loadNotificationsByUserSuccess = (notifications, total) => ({
  type: NOTIFICATIONS__LOAD_BY_USER_SUCCESS,
  payload: { notifications, total }
})

export const loadNotificationsByUserError = error => ({
  type: NOTIFICATIONS__LOAD_BY_USER_ERROR,
  payload: error
})

export const startLoadingNotificationsMeta = () => ({
  type: NOTIFICATIONS__LOAD_META_START
})

export const loadNotificationsMetaSuccess = meta => ({
  type: NOTIFICATIONS__LOAD_META_SUCCESS,
  payload: meta
})

export const loadNotificationsMetaError = error => ({
  type: NOTIFICATIONS__LOAD_META_ERROR,
  payload: error
})

export const startReadingNotification = notificationId => ({
  type: NOTIFICATIONS__READ_NOTIFICATION_START,
  payload: notificationId
})

export const readNotificationSuccess = notificationId => ({
  type: NOTIFICATIONS__READ_NOTIFICATION_SUCCESS,
  payload: notificationId
})

export const readNotificationError = ({ notificationId, error }) => ({
  type: NOTIFICATIONS__READ_NOTIFICATION_ERROR,
  payload: { notificationId, error }
})

export const resetNbCallNotification = () => ({
  type: NOTIFICATIONS__RESET_NB_CALL
})

export const updatePaginationParams = (newParams) => ({
  type: NOTIFICATIONS__UPDATE_PAGINATION_PARAMS,
  payload: newParams
})
