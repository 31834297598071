import styled from '@emotion/styled'
import { CloseButton } from '../container-overlay/styled'
import facepaint from 'facepaint'

export const overlayCenteredMQ = facepaint([
  '@media screen and (max-width: 540px)',
])

export const OverlayCenteredContainer = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ isTransparent }) => isTransparent ? 'transparent' : 'rgba(63, 63, 63, 0.4)'};
  z-index: 10000;
`

export const OverlayCenteredChildrenContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${({ noPadding }) => (noPadding ? 0 : '30px')};
  background: ${({ theme, isTransparent }) => isTransparent ? 'transparent' : theme.colors.white};
  width: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'unset')};
  height: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'unset')};
  border-radius: 10px;

  @media (min-width: 769px) {
    box-shadow: 0px 0px 4px 2px rgba(0, 21, 86, 0.2);
  }
`

export const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  top: 0;
  right: 0;

  ${overlayCenteredMQ({
    marginTop: ['20px', '10px'],
    marginRight: ['20px', '10px'],
  })}

  & > svg {
    width: 15px;
    height: 15px;
    color: ${({ theme }) => theme.colors.primary};
  }
`
