import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {
  BtnClose,
  ButtonsWrapper,
  ContentBody,
  ContentButton,
  ContentButtonIcon,
  ContentHead,
  ContentWrapper,
  ExtendableOverlayContentWrapper,
  ModalOverlayContentWrapper
} from './styled'

const stopPropagation = (event) => {
  event.stopPropagation()
}

export const ExtendableOverlayContent = ({ children, ...props }) => (
  <ExtendableOverlayContentWrapper {...props} onClick={stopPropagation}>
    {children}
  </ExtendableOverlayContentWrapper>
)

export const ModalOverlayContent = ({
  children,
  container,
  className,
  ...props
}) => (
  <ExtendableOverlayContentWrapper
    noScroll
    container={container}
    className={className}
  >
    <ModalOverlayContentWrapper {...props} onClick={stopPropagation}>
      {children}
    </ModalOverlayContentWrapper>
  </ExtendableOverlayContentWrapper>
)

const CustomConfirmOverlay = ({
  title,
  body,
  handleConfirm,
  handleReject,
  confirmText,
  rejectText,
  confirmIcon,
  rejectIcon,
  actionType,
  className,
  container,
  confirmActionOnly,
  cancelActionOnly,
  confirmDisabled,
  confirmClassName
}) => {
  return (
    <ModalOverlayContent container={container} className={className}>
      <ContentWrapper>
        <ContentHead>
          {title}
          <BtnClose onClick={handleReject}>
            <FontAwesomeIcon icon={faXmark} />
          </BtnClose>
        </ContentHead>
        <ContentBody>{body}</ContentBody>
        <ButtonsWrapper>
          {confirmActionOnly ? (
            <ContentButton
              onClick={handleConfirm}
              color="tertiary"
              className={classnames(
                actionType?.length > 1 ? `delete` : `download`,
                confirmClassName
              )}
              disabled={confirmDisabled}
            >
              {confirmText || 'Supprimer'}
              {confirmIcon && <ContentButtonIcon icon={confirmIcon} />}
            </ContentButton>
          ) : cancelActionOnly ? (
            <ContentButton onClick={handleReject} color="medium">
              {rejectText || 'Annuler'}
              {rejectIcon && <ContentButtonIcon icon={rejectIcon} />}
            </ContentButton>
          ) : (
            <>
              <ContentButton
                onClick={handleConfirm}
                color="tertiary"
                className={classnames(
                  actionType?.length > 1 ? `delete` : `download`,
                  confirmClassName
                )}
                disabled={confirmDisabled}
              >
                {confirmText || 'Supprimer'}
                {confirmIcon && <ContentButtonIcon icon={confirmIcon} />}
              </ContentButton>
              <ContentButton onClick={handleReject} color="medium">
                {rejectText || 'Annuler'}
                {rejectIcon && <ContentButtonIcon icon={rejectIcon} />}
              </ContentButton>
            </>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </ModalOverlayContent>
  )
}

CustomConfirmOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleReject: PropTypes.func,
  confirmText: PropTypes.node,
  rejectText: PropTypes.node,
  container: PropTypes.string,
  confirmIcon: PropTypes.any,
  rejectIcon: PropTypes.any,
  actionType: PropTypes.string,
  className: PropTypes.string,
  confirmActionOnly: PropTypes.bool,
  cancelActionOnly: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  confirmClassName: PropTypes.string
}

export default CustomConfirmOverlay
