export const franceViewport = {
  latitude: 46.927638,
  longitude: 2.213749,
  zoom: 4,
  maxZoom: 20
}

export const parisViewport = {
  latitude: 48.868992,
  longitude: 2.310128,
  zoom: 10
}
