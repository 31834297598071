import { createSelector } from 'reselect'
import { BTOB_CUSTOMERS, TYPES_CLIENTS, TYPES_DEAL } from '../constants'
import step from './step'

export const projectSelector = (state) => state.project

export const selectedProjectSelector = (state) => state.project.selectedProject

export const projectPageSelector = (state) => state.project.page

export const overlaySelector = (state) => state.overlay

export const allProjectsSelector = (state) => state.project.projects

export const projectPaginationParamsSelector = (state) => {
  return state.project.paginationParams
}

export const createProjectSelector = (state) => {
  const { createdProject, createProjectLoading, createProjectError } =
    state.project
  return { createdProject, createProjectLoading, createProjectError }
}

export const worktypesSelector = (state) => state.project.workTypes

export const recommendationsSelector = (state) => state.project.recommendations

export const updateProjectNameSelector = (state) => ({
  selectedProject: state.project.selectedProject,
  updateNameLoading: state.project.updateNameLoading,
  updateNameError: state.project.updateNameError,
})

export const userSelector = (state) => state.user

export const userProfileSelector = (state) => state.user.profile

export const isInitialisationTerrealSelector = (state) => {
  const { user } = state
  return user?.profile?.isInitialisationTerreal
}

export const isTerrealCustomerSelector = (state) => {
  const { user } = state
  return user?.profile?.isTerrealCustomer
}

export const isBtoBSelector = (state) => {
  const { user } = state
  return user?.profile?.clientType === TYPES_CLIENTS.BTOB
}

export const isHemeaAvailableSelector = (state) => {
  return state?.project?.selectedProject?.isHemeaAvailable === 'true'
}

export const isHemeaSubmittedSelector = (state) => {
  return state?.project?.selectedProject?.isHemeaSubmitted === 'true'
}

export const isCicAvailableSelector = (state) => {
  return state?.project?.selectedProject?.isCicAvailable === 'true'
}

export const isCicSubmittedSelector = (state) => {
  return state?.project?.selectedProject?.isCicSubmitted === 'true'
}

//check if the current project is desk
export const isAlgarDeskSelector = (state) => {
  return state.project?.selectedProject?.typeClient === TYPES_DEAL.DESK
}

// check if the user is allowed to create desk projects
export const isAllowedToUseAlgarDeskSelector = (state) => {
  return state.user.profile?.isAlgarDesk ?? false
}

export const isOnlyDeskSelector = (state) => {
  const { user } = state
  return user?.profile?.isOnlyDesk ?? false
}

export const isEffySelector = (state) => {
  const { user } = state
  return (user?.profile?.effy === 'true' && user?.profile?.company?.toLowerCase().includes(BTOB_CUSTOMERS.effy.toLowerCase()))
}

export const projectWorkTypesSelector = createSelector(
  [selectedProjectSelector, worktypesSelector],
  (project, workTypes) =>
    project && workTypes ? project.workTypesIds.map((id) => workTypes[id]) : []
)

export default {
  selectedProjectSelector,
  worktypesSelector,
  projectWorkTypesSelector,
  step,
  projectPageSelector,
}
