export const geocodingAddressSelector = (state) => {
  const {
    suggestions,
    geocodeAddressLoading,
    geocodeAddressError,
    suggestionsOpened,
  } = state.geocodingAddress
  return {
    suggestions,
    geocodeAddressLoading,
    geocodeAddressError,
    suggestionsOpened,
  }
}
