import {
  DRAFT__DATA_FETCH_START,
  DRAFT__DATA_FETCH_SUCCESS,
  DRAFT__DATA_FETCH_ERROR
} from '../actions'

const initialState = {
  draftContent: null,
  draftDataLoading: false,
  draftDataError: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DRAFT__DATA_FETCH_START:
      return {
        ...state,
        draftContent: null,
        draftDataLoading: true,
        draftDataError: null
      }

    case DRAFT__DATA_FETCH_SUCCESS:
      return {
        ...state,
        draftContent: action.payload,
        draftDataLoading: false
      }

    case DRAFT__DATA_FETCH_ERROR:
      return {
        ...state,
        draftDataLoading: false,
        draftDataError: action.payload
      }

    default:
      return state
  }
}
