import { createSelector } from 'reselect'
import { groupBy, partition, minBy, maxBy } from 'lodash'

export const stepSelector = state => state.step
export const stepsSelector = state => state.step.steps || []
export const stepsMetaSelector = state => state.step.stepsMeta || []

// Get selected project currentStep id
export const currentStepIdSelector = state => (
  state.project.selectedProject
    ? state.project.selectedProject.currentStepId
    : null
)

// Get currentStep
export const currentStepSelector = createSelector(
  [stepsSelector, currentStepIdSelector],
  (steps, currentStepId) => currentStepId && steps
    ? steps.find(step => step.id === currentStepId)
    : null
)

// Get currentStep meta
export const currentStepMetaSelector = createSelector(
  [stepsMetaSelector, currentStepSelector],
  (stepsMeta, currentStep) => currentStep && stepsMeta
    ? stepsMeta.find(stepMeta => stepMeta.code === currentStep.metaCode)
    : null
)

// Get draft metaStepId
export const draftMetaStepIdSelector = state => (
  window.processRuntime.env.DRAFT_META_STEP_ID &&
  parseInt(window.processRuntime.env.DRAFT_META_STEP_ID, 10)
)

// Get draft metaStep
export const draftStepMetaSelector = createSelector(
  [stepsMetaSelector, draftMetaStepIdSelector],
  (stepsMeta, draftMetaStepId) => draftMetaStepId && stepsMeta
    ? stepsMeta.find(stepMeta => stepMeta.id === draftMetaStepId)
    : null
)

// Get fake metaStepId
export const fakeMetaStepIdSelector = state => (
  window.processRuntime.env.FAKE_META_STEP_ID &&
  parseInt(window.processRuntime.env.FAKE_META_STEP_ID, 10)
)

// Get fake metaStep
export const fakeStepMetaSelector = createSelector(
  [stepsMetaSelector, fakeMetaStepIdSelector],
  (stepsMeta, fakeMetaStepId) => fakeMetaStepId && stepsMeta
    ? stepsMeta.find(stepMeta => stepMeta.id === fakeMetaStepId)
    : null
)

// Get current group index
export const currentGroupIndexSelector = createSelector(
  [currentStepMetaSelector],
  (currentStepMeta) => currentStepMeta
    ? currentStepMeta.groupIndex
    : null
)

// Get grouped meta steps
export const stepsMetaGroupsSelector = createSelector(
  [stepsMetaSelector],
  (stepsMeta) => groupBy(stepsMeta, 'groupIndex')
)

const findDefaultPast = groupMetas => (
  groupMetas.find(meta => meta.isDefaultPast) ||
  maxBy(groupMetas, meta => meta.indexInGroup) ||
  (groupMetas.length && groupMetas[groupMetas.length - 1])
)

const findDefaultFuture = groupMetas => (
  groupMetas.find(meta => meta.isDefaultFuture) ||
  minBy(groupMetas, meta => meta.indexInGroup) ||
  (groupMetas.length && groupMetas[0])
)

const findGroupMetaStepMilestoneTupleToDisplay = (
  currentStepMeta
) => (
  groupIndex,
  groupMetas
) => {
  const [
    groupMiletonesMetas,
    groupStepsMetas
  ] = partition(groupMetas, meta => meta.isMilestone)

  if (
    !currentStepMeta ||
    groupIndex > currentStepMeta.groupIndex
  ) {
    return [
      findDefaultFuture(groupMiletonesMetas),
      findDefaultFuture(groupStepsMetas)
    ]
  }

  if (
    groupIndex < currentStepMeta.groupIndex
  ) {
    return [
      findDefaultPast(groupMiletonesMetas),
      findDefaultPast(groupStepsMetas)
    ]
  }

  if (
    groupIndex === currentStepMeta.groupIndex
  ) {
    if (currentStepMeta.isMilestone) {
      return [
        currentStepMeta,
        findDefaultFuture(groupStepsMetas)
      ]
    } else {
      return [
        findDefaultPast(groupMiletonesMetas),
        currentStepMeta
      ]
    }
  }

  return []
}

export const _createStepsListSelector = _currentStepMetaSelector => (
  createSelector(
    [stepsMetaGroupsSelector, _currentStepMetaSelector],
    (stepsMetaGroups, currentStepMeta) => (
      Object.entries(stepsMetaGroups)
        .flatMap(
          ([rawGroupIndex, groupMetas]) => {
            const [
              metaMilestoneToDisplay,
              metaStepToDisplay
            ] = findGroupMetaStepMilestoneTupleToDisplay(
              currentStepMeta
            )(
              parseInt(rawGroupIndex, 10),
              groupMetas
            )

            return [metaMilestoneToDisplay, metaStepToDisplay].filter(
              meta => !!meta
            )
          }
        )
    )
  )
)

export const stepsListSelector = _createStepsListSelector(currentStepMetaSelector)
export const draftStepsListSelector = _createStepsListSelector(draftStepMetaSelector)
export const fakeStepsListSelector = _createStepsListSelector(fakeStepMetaSelector)

export default {
  stepsSelector,
  stepsMetaSelector,
  currentStepIdSelector,
  currentStepSelector,
  currentStepMetaSelector,
  draftStepMetaSelector,
  fakeStepMetaSelector,
  currentGroupIndexSelector,
  stepsMetaGroupsSelector,
  stepsListSelector,
  draftStepsListSelector,
  fakeStepsListSelector
}
