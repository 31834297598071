import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
// import { Redirect } from 'react-router-dom'

import { startCheckingAccountLinks } from '../../actions/user'

import ErrorPage from '../page-error'
import LoadingView from '../view-loading'

class ConfirmationContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      confirmationLoadingStarted: false
    }
  }

  componentDidMount () {
    this.props.startCheckingAccountLinks()
  }

  static getDerivedStateFromProps (props, state) {
    return (
      props.linkedAccountsLoading &&
      !state.confirmationLoadingStarted
    )
      ? { ...state, confirmationLoadingStarted: true }
      : state
  }

  render () {
    return [{
      cond: this.props.accountConfirmationError,
      view: () => <Redirect to='/' />
    }, {
      cond: this.props.linkedAccountsError,
      view: () => <ErrorPage error={this.props.accountConfirmationError} />
    }, {
      cond: (
        !this.props.confirmedProject ||
        !this.state.confirmationLoadingStarted ||
        this.props.accountConfirmationLoading ||
        this.props.linkedAccountsLoading ||
        this.props.accountLinkingLoading ||
        (
          !this.props.linkedAccountsData &&
          !this.state.confirmationLoadingStarted
        )
      ),
      view: () => <LoadingView />
    }, {
      cond: true,
      view: () => <Redirect to='/mot-de-passe' />
    }].find(obj => obj.cond).view()
  }
}

const mapStateToProps = ({ user }) => ({
  accountConfirmationLoading: user.accountConfirmationLoading,
  accountConfirmationError: user.accountConfirmationError,
  linkedAccountsData: user.linkedAccountsData,
  linkedAccountsLoading: user.linkedAccountsLoading,
  linkedAccountsError: user.linkedAccountsError,
  accountLinkingLoading: user.accountLinkingLoading,
  accountLinkingError: user.accountLinkingError,
  confirmedProject: user.confirmedProject
})

const mapDispatchToProps = dispatch => ({
  startCheckingAccountLinks: () => dispatch(startCheckingAccountLinks())
})

ConfirmationContainer.propTypes = {
  accountConfirmationLoading: PropTypes.bool.isRequired,
  accountConfirmationError: PropTypes.object,
  linkedAccountsData: PropTypes.string,
  linkedAccountsLoading: PropTypes.bool.isRequired,
  linkedAccountsError: PropTypes.object,
  accountLinkingLoading: PropTypes.bool.isRequired,
  accountLinkingError: PropTypes.object,
  startCheckingAccountLinks: PropTypes.func.isRequired,
  confirmedProject: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationContainer)
