import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { closeMultiNotifsMenu } from '../../actions/menus'
import { startLoadingNotificationsByUser } from '../../actions/notification'
import { HiddenOnMobile } from '../styled/responsive'
import { userSelector } from '../../selectors'
import { menusSelector } from '../../selectors/menus'
import { notifSelector } from '../../selectors/notification'
import ErrorPageContainer from '../page-error'
import LoadingView from '../view-loading'
import MultiNotificationsList from './multi-notifs-list'
import {
  ShowMoreLinkWrapper,
  MenuWrapper,
  MultiNotificationsListContainer,
  EmptyNotifContainer,
  EmptyNotifText,
  MultiNotificationsListTitle
} from './styled'

const multiNotifsMenuId = 'id-container-multi-notifs-menu'

const MultiNotifsMenuContainer = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    userNotifications,
    userNotificationsError,
    userNotificationsLoading,
    userNotificationsTotal,
    paginationParams
  } = useSelector(notifSelector)

  const user = useSelector(userSelector)

  const { limit, nexPage } = paginationParams

  const { multiNotifsMenuOpened } = useSelector(menusSelector)

  const closeMenuListener = (event) => {
    event.stopPropagation()
    const menu = document.getElementById(multiNotifsMenuId)
    let clickedElement = event.target

    if (
      menu &&
      clickedElement !== menu &&
      multiNotifsMenuOpened &&
      clickedElement.parentElement &&
      clickedElement.parentElement.id !== multiNotifsMenuId &&
      !clickedElement.parentElement.id.includes('notif')
    ) {
      dispatch(closeMultiNotifsMenu())
    }
  }

  const handleScrollNotifications = React.useCallback(
    (ev) => {
      const el = ev.target
      const isBottom = el.scrollHeight - el.scrollTop === el.clientHeight

      if (
        isBottom &&
        user &&
        userNotifications &&
        userNotifications.length < userNotificationsTotal
      ) {
        dispatch(
          startLoadingNotificationsByUser(user, {
            limit: limit,
            offset: nexPage * limit
          })
        )
      }
    },
    [user, limit, nexPage, userNotifications, userNotificationsTotal]
  )

  React.useEffect(() => {
    document.addEventListener('click', closeMenuListener)
    return () => {
      document.removeEventListener('click', closeMenuListener)
    }
  }, [multiNotifsMenuOpened])

  return (
    <MenuWrapper id={multiNotifsMenuId} isMobile={props.isMobile}>
      {userNotificationsError && !userNotificationsLoading && (
        <ErrorPageContainer error={userNotificationsError} />
      )}
      {userNotifications && (
        <Fragment>
          <HiddenOnMobile>
            <MultiNotificationsListTitle>
              Notifications
            </MultiNotificationsListTitle>
          </HiddenOnMobile>
          {userNotifications.length > 0 ? (
            <Fragment>
              <MultiNotificationsListContainer
                onScroll={
                  window.screen.width < 769
                    ? handleScrollNotifications
                    : undefined
                }
              >
                <MultiNotificationsList
                  notifications={userNotifications}
                  isPage={false}
                />
              </MultiNotificationsListContainer>
              <ShowMoreLinkWrapper>
                <a
                  href="/mes-notifications"
                  onClick={(e) => {
                    e.preventDefault()
                    history.push('/mes-notifications')
                  }}
                >
                  Voir toutes mes notifications
                </a>
              </ShowMoreLinkWrapper>
            </Fragment>
          ) : (
            <EmptyNotifContainer>
              <EmptyNotifText>
                Vous n'avez aucune notification pour le moment
              </EmptyNotifText>
            </EmptyNotifContainer>
          )}
        </Fragment>
      )}
      {userNotificationsLoading && (
        <LoadingView size={50} style={{ marginBottom: 5 }} />
      )}
    </MenuWrapper>
  )
}

MultiNotifsMenuContainer.defaultProps = {
  isMobile: false
}

MultiNotifsMenuContainer.propTypes = {
  isMobile: PropTypes.bool
}

export default MultiNotifsMenuContainer
