/**
 * Responsive Helpers
 * Inspired by Bulma
 */
import styled from '@emotion/styled'

export const HiddenOnMobile = styled('div')`
  @media (max-width: 768px) {
    display: none !important;
  }
`

export const HiddenOnDesktop = styled('div')`
  @media (min-width: 769px) {
    display: none !important;
  }
`
