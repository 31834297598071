import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { descriptionFontSizeCss, textFontSizeCss } from '../../styles/common'
import { mq, reverseMq } from '../../utils/style'
import { FlexColumn } from '../styled'
import { titleFontSizeCss } from '../../styles/common'
import chroma from 'chroma-js'

export const stepStatus = {
  ACTIVE: 'active',
  CURRENT: 'current',
  INACTIVE: 'inactive',
  DANGER: 'danger'
}

export const statusColorsMap = {
  [stepStatus.ACTIVE]: (props) => props.theme.colors.primary,
  [stepStatus.INACTIVE]: (props) => props.theme.colors.medium,
  [stepStatus.CURRENT]: (props) => props.theme.colors.primary,
  [stepStatus.DANGER]: (props) => props.theme.colors.tertiary
}

export const pointStatusColorsMap = {
  [stepStatus.ACTIVE]: (props) => props.theme.colors.primary,
  [stepStatus.INACTIVE]: (props) => props.theme.colors.light,
  [stepStatus.CURRENT]: (props) => props.theme.colors.primary,
  [stepStatus.DANGER]: (props) => props.theme.colors.tertiary
}

export const getColorFromStatus = props => (
  props.isMilestone && props.status === stepStatus.CURRENT
    ? statusColorsMap.active(props)
    : statusColorsMap[props.status](props)
)

export const getPointColorFromStatus = props => (
  props.isMilestone && props.status === stepStatus.CURRENT
    ? pointStatusColorsMap.active(props)
    : pointStatusColorsMap[props.status](props)
)

const pulseAnimation = keyframes`
  to {
    transform: scale3d(2, 2, 1);
    opacity: 0;
  }
`

export const getPulseFromProps = props => (
  props.status === stepStatus.CURRENT
    ? (
      css`
        &:before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          background-color: ${getColorFromStatus(props)};
          animation-duration: 1.5s;
          animation-timing-function: cubic-bezier(0, 0.17, 0.2, 1);
          animation-iteration-count: infinite;
          animation-name: ${pulseAnimation};
        }
      `
    )
    : css``
)

export const getColorWithoutCurrent = (props, override) => ({
  ...statusColorsMap,
  current: override
}[props.status])(props)

// Constant values
export const stepSpacing = props => props.theme.spacings.small + props.theme.spacings.medium
export const pointSize = 22
export const smallPointSize = 11
export const spacerWidth = 1

export const StepsWrapper = styled(FlexColumn)`
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const commonPointStyles = props => css`
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  z-index: 2;
`

export const getBoxShadowForPoint = props => (
  props.status === stepStatus.CURRENT
    ? `0 0 0 3px ${props.theme.colors.light} inset, 0 0 0 2px ${props.theme.colors.light};`
    : `0 0 0 3px ${props.theme.colors.light} inset`
)

export const getBoxShadowForSmallPoint = props => (
  props.status === stepStatus.CURRENT
    ? `0 0 0 2px ${props.theme.colors.light}`
    : 'none'
)

export const Point = styled(FlexColumn)`
  ${commonPointStyles}
  width: ${pointSize}px;
  height: ${pointSize}px;
  margin-right: ${props => props.theme.spacings.normal}px;
  box-shadow: ${getBoxShadowForPoint};
  background-color: ${getPointColorFromStatus};
  transition: background-color 0.2s ease-in-out;
  border: 1px solid ${getColorFromStatus};

  ${props => mq(props)({
  marginTop: [`${props.theme.spacings.small}px`, 0],
  marginRight: [0, `${props.theme.spacings.large}px`]
})}
`

const squareSize = 20

const squareCss = () => css`
  width: ${squareSize}px;
  min-width: ${squareSize}px;
  height: ${squareSize}px;
  max-height: ${squareSize}px;
  position: relative;
  z-index: 1;
`

export const SquareContainer = styled.div`
  position: relative;
  z-index: 1;
  width: fit-content;
  height: fit-content;

  & > svg {
    background-color: ${({ theme }) => theme.colors.white} ;
    color: ${({ theme, done }) => done ? theme.colors.green : '#C4C4C4'};
  }

  &::after {
    position: absolute;
    z-index: 0;
    content: '';
    width: 1px;
    height: ${({ lineHeight }) => lineHeight}px;
    background-color: ${({ done, theme }) => done ? theme.colors.green : '#C4C4C4'};
    top: ${squareSize}px;
    left: ${(squareSize / 2) - 1}px;
    display: ${({ isLast }) => isLast ? 'none' : 'block'} ;
  }
`

export const Square = styled.div`
  ${squareCss}
  background: #C4C4C4;
  border-radius: 2px;
  color: #C4C4C4;
`

export const SquareIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
`

export const SmallPoint = styled('div')`
  ${commonPointStyles}
  width: ${smallPointSize}px;
  height: ${smallPointSize}px;
  margin: ${pointSize / 2 - smallPointSize / 2}px;
  box-shadow: ${getBoxShadowForSmallPoint};
  background-color: ${getColorFromStatus};
  transition: background-color 0.2s ease-in-out

  ${props => mq(props)({
  marginTop: [`${smallPointSize / 2 + props.theme.spacings.small}px`, `${smallPointSize / 2}px`],
  marginRight: [`${smallPointSize / 2}px`, `${smallPointSize / 2 + props.theme.spacings.large}px`]
})}

  ${getPulseFromProps}
`

const getStepNameFontWeight = props => (
  props.isMilestone
    ? 'bold'
    : 'normal'
)

export const StepName = styled('p')`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${getColorFromStatus};
  transition: color 0.3s ease-in-out;
  font-weight: ${getStepNameFontWeight};
  line-height: 1.5em;

  ${props => mq(props)({
  textAlign: ['center', 'left']
})}

  ${props => reverseMq(props)({
  margin: [null, 'auto 0']
})}
`

export const StepNameV2 = styled.div`
  & > p {
    width: 100%;
    transition: color 0.3s ease-in-out;
    text-align: left;
    font-weight: 500;

    &.title {
      font-size: 14px;
      line-height: normal;
      color: ${({ theme }) => theme.colors.primary};
      margin: 0 0 2px;
    }

    &.date {
      font-size: 12px;
      line-height: 0.875em;
      color: ${props => chroma(props.theme.colors.primary).alpha(0.45).hex()};
      margin: 0;

      @media (min-width: 1025px) {
        /* font-size: 0.813em; */
      }

      @media (min-width: 1441px) {
        /* font-size: 1.000em; */
        line-height: 	1.188em;
      }
    }
  }
`

// Colored step part
export const coloredStyles = (props) => css`
  &:not(:last-of-type) {
    &:after {
      background-color: ${getColorWithoutCurrent(props, statusColorsMap.inactive)};
    }
  }

  &:not(:first-of-type) {
    &:before {
      background-color: ${getColorWithoutCurrent(props, statusColorsMap.active)};
    }
  }
`

// Common style for :before and :after step spacer
export const stepSpacerStyle = props => css`
  content: ' ';
  position: absolute;
  background-color: ${props.theme.colors.semiLight};
`

export const Step = styled(FlexColumn)`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;

  width: 100%;
  flex-direction: row;

  &:first-of-type {
    padding-top: ${props => `${props.theme.spacings.small}px`};
  }

  &:last-of-type {
    padding-bottom: ${props => `${props.theme.spacings.small}px`};
  }

  &:not(:last-of-type) {
    &:after {
      ${stepSpacerStyle}
      top: 50%;
      left: ${pointSize / 2 - spacerWidth / 2}px;
      right: initial;
      bottom: 0;
      height: auto;
      width: ${spacerWidth}px;
    }
  }

  &:not(:first-of-type) {
    &:before {
      ${stepSpacerStyle}
      top: 0;
      left: ${pointSize / 2 - spacerWidth / 2}px;
      right: initial;
      bottom: 50%;
      height: auto;
      width: ${spacerWidth}px;
    }
  }

  ${coloredStyles}
`

export const StepV2 = styled(FlexColumn)`
  position: relative;
  width: 225px;
  height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  line-height: normal;
  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const SmallStep = styled(Step)``

export const StepSpacer = styled('div')`
  flex-grow: 1;
  background-color: ${props => getColorWithoutCurrent(props, statusColorsMap.inactive)};

  margin: 0 auto 0 ${pointSize / 2 - spacerWidth / 2}px;
  width: ${spacerWidth}px;
  height: 0;
  min-height: ${props => stepSpacing(props)}px;
  min-width: 0;

`

export const StepSpacerV2 = styled('div')`
  flex-grow: 1;
  background-color: ${props => getColorWithoutCurrent(props, statusColorsMap.inactive)};
  margin: 0 ${squareSize / 2}px 0 auto;
  width: ${spacerWidth}px;

  ${props => mq(props)({
  height: `${stepSpacing(props)}px`,
  minHeight: ['0', `${stepSpacing(props)}px`],
})}
`

export const StepsWrapperTitle = styled.p`
  font-weight: 700;
  ${titleFontSizeCss}
  line-height: 1.000em;
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  margin: 25px;
  margin-bottom: 10px;

  @media (min-width: 1441px) {
    line-height: 1.313em;
  }

  @media (max-width: 1024px) {
    margin: unset;
    padding: unset;
    width: 208px;
    margin: 20px 0;
  }
  @media (max-width: 768px) {
    margin: unset;
    padding: unset;
    width: 260px;
    margin: 20px 0 10px;
    text-align: center;
  }
`

export const StepsWrapperContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  overflow-y: hidden;
  padding: 25px;
  padding-top: 10px;

  & :hover {
    overflow-y: overlay;
  }
  @-moz-document url-prefix() {
    overflow-y: auto;
  }

  @media screen and (max-height: 586px) {
    padding-right: 5px;
  }
  @media screen and (max-width: 1024px) {
    margin: unset;
    padding: unset;
    overflow-y: auto;
    padding: 0 50px;
  }
  @media (max-width: 768px) {
    margin: unset;
    padding: unset;
    width: 100%;
  }
`