const forbiddenKeys = [
  'accessToken',
  'idToken'
]

const mergeArray = (acc, val) => acc.concat([val])
const mergeObject = (acc, val, key) => ({ ...acc, [key]: val })

const censor = mergeFn => (acc, [key, value]) => {
  if (key && forbiddenKeys.includes(key)) {
    return mergeFn(acc, '[CENSORED]', key)
  }

  const fn = [{
    cond: Array.isArray(value),
    fn: list => (
      list
        .map(v => [null, v])
        .reduce(censor(mergeArray), [])
    )
  }, {
    cond: value && typeof value === 'object',
    fn: obj => (
      Object.entries(obj)
        .reduce(censor(mergeObject), {})
    )
  }, {
    cond: true,
    fn: x => x
  }].find(x => x.cond).fn

  return mergeFn(acc, fn(value), key)
}

// Set redux State in extra data
export const setReduxStateScope = state => scope => {
  const censoredState = JSON.stringify(
    Object.entries(state).reduce(censor(mergeObject), {})
  )

  scope.setExtra('rawState', censoredState)
}

// Set user data in User scope
export const setUserScope = state => scope => {
  scope.setUser({
    ...(state.user && state.user.decodedAccessToken && {
      accountId: state.user.decodedAccessToken.sub
    }),
    ...(state.user && state.user.profile && {
      id: state.user.profile.id,
      email: state.user.profile.email,
      firstName: state.user.profile.firstName,
      lastName: state.user.profile.lastName
    })
  })
}
