import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { FlexRow, InvisibleButton } from '../styled'

export const maxFileNameLength = 13

export const UploadBarWrapper = styled(FlexRow)`
  width: 100%;
  flex-shrink: 0;
  padding: ${props => props.theme.spacings.small}px;
  align-items: center;
`

export const progressBarHeight = props => props.theme.spacings.small

export const UploadBar = styled(FlexRow)`
  flex: 1;
  justify-content: flex-start;
  background-color: ${props => props.theme.colors.semiLight};
  border-radius: ${props => progressBarHeight(props) / 2}px;
`

export const progressBarStyle = props => css`
  height: ${progressBarHeight(props)}px;
  border-radius: ${progressBarHeight(props) / 2}px;
`

export const ProgressBar = styled('div')`
  width: ${props => props.progress || 0}%;
  ${progressBarStyle}
  background-color: ${props => props.isError
    ? props.theme.colors.error
    : props.theme.colors.primary
};
  transition: 0.2s;
`

export const FileName = styled('div')`
  width: ${maxFileNameLength}ch;
  font-family: ${props => props.theme.fonts.workSans};
  margin: 0 ${props => props.theme.spacings.small}px;
`

export const RemoveButton = styled(InvisibleButton)`
  transition: 0.1s;
  &:disabled {
    opacity: 0.6;
  }
`
