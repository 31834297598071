import 'core-js'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/browser'
import TagManager from 'react-gtm-module'

import App from './app'

// Side-effect ftw
Sentry.init({
  dsn: window.processRuntime.env.SENTRY_DSN,
  environment: window.processRuntime.env.SENTRY_ENVIRONMENT,

  // ⚠️ build-time env is WANTED
  // for this specific one
  release: process.env.SENTRY_RELEASE,
})

// GTM launch
const tagManagerArgs = {
  gtmId: window.processRuntime.env.CONFIG_GTM_ID,
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('app-root'))
