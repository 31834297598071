import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { projectBodyGapCss } from '../styled'

export const flexCss = () => css`
  display: flex;
`

export const flexRowCss = () => css`
  ${flexCss()}
  flex-direction: row;
`

export const flexColCss = () => css`
  ${flexCss()}
  flex-direction: column;
`

export const DashboardDesktopStyled = styled.div`
  ${flexColCss}
  ${projectBodyGapCss}
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  @media(min-width: 1024px) {
    overflow: visible;
  }
`

export const DashboardContentTopBlock = styled.div`
  ${flexColCss}
  ${projectBodyGapCss}
  width: 100%;
`

export const dashboardContentTopBlockLineCss = () => css`
  ${flexRowCss()}
  ${projectBodyGapCss()}
  flex-wrap: nowrap;
  width: 100%;
`

export const DashboardContentTopBlockLineFirst = styled.div`
  ${dashboardContentTopBlockLineCss}
  min-height: 249px;

  @media screen and (min-width: 769px) {
    max-height: 48dvh;
  }

  @media screen and (min-width: 1025px) {
    min-height: 216px;
    max-height: 30dvh;
  }

  @media screen and (min-width: 1441px) {
    min-height: 235px;
    max-height: 25dvh;
  }
`

export const DashboardContentTopBlockLineSecond = styled.div`
  ${dashboardContentTopBlockLineCss}
  min-height: 204px;

  @media screen and (min-width: 769px) {
    max-height: 48dvh;
  }

  @media screen and (min-width: 1025px) {
    min-height: 162px;
    max-height: 30dvh;
  }

  @media screen and (min-width: 1441px) {
    min-height: 178px;
    max-height: 25dvh;
  }
`

export const dashboardContentTopBlockColCss = () => css`
  ${flexColCss()}
  width: 100%;
`

export const DashboardContentTopBlockColFirst = styled.div`
  ${dashboardContentTopBlockColCss}
  width: 279px;
  min-width: 279px;
  border-radius: 5px;

  @media screen and (min-width: 1025px) {
    width: 300px;
    min-width: 300px;
  }
`

export const DashboardContentTopBlockColSecond = styled.div`
  ${dashboardContentTopBlockColCss}
  max-width: 100%;
`
