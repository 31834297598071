import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { headerHeight } from '../../constants/styles'
import { mq } from '../../utils/style'
import { modalContentHeadCss } from '../overlay-custom-confirm/styled'
import { FlexRow, globalFluidContainerCss } from '../styled'

export const headerHeightDesktop = (props) => `${props.theme.spinner.size.desktop}px`

export const avatarSize = 2

export const ProjectHeader = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
  ${globalFluidContainerCss}

  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 500;
  width: 100%;
  height: ${headerHeight}px;
  padding: 0 10px;
  border-bottom: 0.05rem solid ${({ theme }) => theme.colors.greySemiLight};

  @media screen and (min-width: 1025px) {
    padding: 0 20px;
    border-bottom: none;
  }

  @media screen and (min-width: 1441px) {
    padding: 0 40px;
  }
`

export const HeaderContent = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: space-between;
`

export const HeaderHeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const LogoWrapper = styled.a`
  ${(props) =>
    mq(props)({
      width: props.isbtob ? ['130px', '138px'] : ['90px', '102px'],
      height: props.isbtob ? ['40px', '41px'] : ['40px', '41px'],
      marginLeft: ['15px', '0']
    })}

  img {
    width: 100%;
    height: 100%;
    object-fit: 'contain';
  }

  @media (max-width: 768px) {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
  }
`

export const UserContent = styled.div`
  display: flex;
  align-items: center;
`

export const NotifContainer = styled.div`
  position: relative;

  ${(props) =>
    mq(props)({
      marginRight: [0, '39px']
    })}
`

export const UserMenuContainer = styled.div`
  display: none;
  @media screen and (min-width: 1025px) {
    display: block;
  }
`

export const UserAccount = styled.a`
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.9;
  }

  span {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    @media (min-width: 1600px) {
      font-size: 16px;
    }

    ${(props) =>
    mq(props)({
      display: ['none', 'block'],
      margin: [null, '0 8px 0 10px']
    })}
  }
`

export const HeaderSvgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    mq(props)({
      margin: ['0 auto', '0 6px 0 0']
    })}
`

export const HeaderIcon = styled(FontAwesomeIcon)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.medium : '#FFF')};

  width: 18px !important;
  height: 20px !important;

  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 768px) {
    width: 25px !important;
    height: 25px !important;
  }
`

export const UserAvatar = styled(FontAwesomeIcon)`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.medium : '#FFF')};

  ${(props) =>
    mq(props)({
      width: ['22px', '30px'],
      height: ['22px', '30px']
    })}
`
export const UserArrow = styled(FontAwesomeIcon)`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.medium : '#FFF')};

  width: 10px !important;
  height: 16px !important;

  ${(props) =>
    mq(props)({
      display: ['none', 'block']
    })}
`

export const UserAccountMenu = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  width: 278px;
  background-color: #fff;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  box-shadow: 0px 0px 6px #00000029;
  z-index: 6;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 400;
  font-size: 14px;

  @media screen and (min-width: 1441px) {
    right: 40px;
  }
`

export const UserAccountMenuTitle = styled.h3`
  ${modalContentHeadCss}
`

export const UserAccountMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  cursor: pointer;
  padding: 10px 10px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.overOrSelected};
    border-radius: 5px;
  }
`

export const UserAccountMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 30px;
`

export const NotifIconContainer = styled.div`
  position: relative;
  & > svg:nth-of-type(1) {
    width: 10px !important ;
    height: 10px !important;
  }
`

export const RedPointIcon = styled(FontAwesomeIcon)`
  position: absolute;
  width: 5px !important;
  height: auto;
  top: 3%;
  left: 65%;
  color: ${({ theme }) => theme.colors.tertiary};
`

export const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 auto;
`

export const Title = styled('div')`
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
`
