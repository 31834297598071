import styled from '@emotion/styled'
import React from 'react'
import { Pagination } from 'swiper'
import { Swiper as ReactSwiper } from 'swiper/react'
import { css } from '@emotion/core'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

const paddingXSize = 40
const paddingXSizeMobile = 10

const widthCss = () => css`
  max-width: calc(100vw - ${paddingXSizeMobile * 2}px);

  @media screen and (min-width: 769px) {
    max-width: calc(100vw - ${paddingXSize * 2}px);
  }
`

const Swiper = styled(ReactSwiper)`
  max-width: 100vw;

  .swiper-wrapper {
    display: flex;
    gap: ${paddingXSizeMobile}px;
  }

  & .swiper-slide {
    ${widthCss}

    &:not(:nth-of-type(1)) {
      margin-left: ${paddingXSizeMobile}px;
    }
  }

  & .swiper-pagination {
    ${widthCss}
    position: fixed !important;
    bottom: 5px;
  }

  & .swiper-pagination-bullets {
    bottom: 3px;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (min-width: 769px) {
    .swiper-wrapper {
      gap: ${paddingXSize}px;
    }

    & .swiper-slide {
      &:not(:nth-of-type(1)) {
        margin-left: ${paddingXSize}px;
      }
    }
  }
`

const SwiperStyled = ({ className, children, initialSlide, ...restProps }) => {
  return (
    <Swiper
      {...restProps}
      initialSlide={initialSlide}
      pagination={true}
      modules={[Pagination]}
      className={className}
    >
      {children}
    </Swiper>
  )
}

export default SwiperStyled
