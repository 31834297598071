import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf'
import ReactResizeDetector from 'react-resize-detector'

import LoadingView from '../view-loading'
import { ModalFullwidthOverlayContent as ModalOverlayContent } from '../container-overlay/styled'
import { each } from '../../utils'

class PdfPreviewOverlay extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      pagesAmount: null,
      fingerprint: null
    }
    this.handleLoadSuccess = this.handleLoadSuccess.bind(this)
  }

  handleLoadSuccess ({ numPages, fingerprint }) {
    this.setState({
      loading: false,
      fingerprint,
      pagesAmount: numPages
    })
  }

  render () {
    return (
      <ModalOverlayContent squared noScroll={this.state.loading}>
        {this.state.loading && <LoadingView />}

        <ReactResizeDetector
          refreshMode='throttle'
          refreshRate={500}
          refreshOptions={{
            leading: false,
            trailing: true
          }}
          refre
          handleWidth
        >
          {({ width }) => (
            <Document
              file={this.props.src}
              loading={null}
              onLoadSuccess={this.handleLoadSuccess}
            >
              {this.state.pagesAmount && each(this.state.pagesAmount, idx => (
                <Page
                  key={`${this.state.fingerprint}-p${idx}`}
                  pageIndex={idx}
                  width={width}
                />
              ))}
            </Document>
          )}
        </ReactResizeDetector>
      </ModalOverlayContent>
    )
  }
}

PdfPreviewOverlay.propTypes = {
  src: PropTypes.string.isRequired
}

export default PdfPreviewOverlay
