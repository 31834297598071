import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { startLoadingStepMetas } from '../../actions/step'
import { isBtoBSelector, isAlgarDeskSelector } from '../../selectors'
import buildMiddleware from './template'

const withStepsMetaFetch = (SubComponent) => {
  const WithStepsMetaFetchWrapper = buildMiddleware(SubComponent, 'WithSteps')

  const WithStepsMetaFetch = (props) => {
    const dispatch = useDispatch()
    const isBtoB = useSelector(isBtoBSelector)
    const isDesk = useSelector(isAlgarDeskSelector)

    useEffect(() => {
      dispatch(startLoadingStepMetas(isDesk ? 'desk' : isBtoB ? 'btob' : null))
    }, [isBtoB, isDesk])

    const { ...passThroughProps } = props

    return <WithStepsMetaFetchWrapper {...passThroughProps} />
  }

  const mapStateToProps = ({ step }) => ({
    loading: step.stepsMetaLoading || (!step.stepsMeta && !step.stepsMetaError),
    error: step.stepsMetaError,
  })

  return connect(mapStateToProps, null)(WithStepsMetaFetch)
}

export default withStepsMetaFetch
