import { faCircle, faFolderOpen } from '@fortawesome/pro-solid-svg-icons'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { startReadingNotification } from '../../actions/notification'
import { changePage } from '../../actions/project'
import {
  FolderIcon,
  IconContainer,
  FolderContainer,
  MenuItem,
  NotifContainer,
  NotifDate,
  NotifNameRecap,
  NotifProjectName,
  NotifText,
  RedPointIcon,
} from './styled'

const ProjectName = ({ notif, isPage }) => (
  <NotifProjectName disabled={notif.isRead} isPage={isPage}>
    {notif.project.name || notif.project.projectname || notif.project.dealname}
  </NotifProjectName>
)

const NotifTextFormatted = ({ notif, isPage }) => {
  const splittedText = notif.meta.textMultiProject
    ? notif.meta.textMultiProject.split('nom_du_projet')
    : []

  return (
    <NotifText isPage={isPage}>
      {splittedText.length > 0 ? (
        <Fragment>
          {splittedText[0]}
          <ProjectName notif={notif} isPage={isPage} />
          {splittedText[1]}
        </Fragment>
      ) : (
        <Fragment>
          {`${notif.meta.text}`}
        </Fragment>
      )}
    </NotifText>
  )
}

const MultiNotificationsList = ({ notifications, isPage }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const onClick = (e, notif) => {
    e.stopPropagation()
    if (!notif.isRead) {
      dispatch(startReadingNotification(notif._id))
    }
    history.push(`/projet/${notif.projectId}`)
    dispatch(changePage('projet'))
  }


  return (
    <Fragment>
      {notifications.map((notif, index) => {
        return (
          <MenuItem
            key={index}
            disabled={notif.isRead}
            isPage={isPage}
            onClick={(e) => onClick(e, notif)}
          >
            <IconContainer>
              <FolderContainer>
                <FolderIcon
                  size="1x"
                  icon={faFolderOpen}
                  ispage={isPage.toString()}
                />
                {!notif.isRead && (
                  <RedPointIcon icon={faCircle} ispage={isPage.toString()} />
                )}
              </FolderContainer>
            </IconContainer>
            <NotifContainer id={`notif-text-${index}`} disabled={notif.isRead}>
              <NotifTextFormatted notif={notif} isPage={isPage} />
              <NotifNameRecap>{notif.project.name || notif.project.projectname || notif.project.dealname}</NotifNameRecap>
              <NotifDate isPage={isPage}>
                {dayjs(notif.createdAt).fromNow()}
              </NotifDate>
            </NotifContainer>
          </MenuItem>
        )
      })}
    </Fragment>
  )
}

MultiNotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  isPage: PropTypes.bool,
}

MultiNotificationsList.defaultProps = {
  notifications: [],
  isPage: false,
}

export default MultiNotificationsList
