import {
  ACTIONS__LOAD_BY_PROJECT_START,
  ACTIONS__LOAD_BY_PROJECT_SUCCESS,
  ACTIONS__LOAD_BY_PROJECT_ERROR,

  ACTIONS__LOAD_META_START,
  ACTIONS__LOAD_META_SUCCESS,
  ACTIONS__LOAD_META_ERROR,

  ACTIONS__LOAD_BY_USER_START,
  ACTIONS__LOAD_BY_USER_SUCCESS,
  ACTIONS__LOAD_BY_USER_ERROR,

  ACTIONS__UPDATE_STATUS_START,
  ACTIONS__UPDATE_STATUS_SUCCESS,
  ACTIONS__UPDATE_STATUS_ERROR,

  ACTIONS__UPDATE_LINK_STATUS_START,
  ACTIONS__UPDATE_LINK_STATUS_SUCCESS,
  ACTIONS__UPDATE_LINK_STATUS_ERROR,

  ACTIONS__UPDATE_PAGINATION_PARAMS,
} from '../actions'

const initialState = {
  actions: null,
  actionsLoading: false,
  actionsError: null,

  actionsMeta: null,
  actionsMetaLoading: false,
  actionsMetaError: null,

  userActions: null,
  userActionsLoading: false,
  userActionsError: null,
  userActionsTotal: 0,

  paginationParams: {
    limit: 15,
    offset: 0,
    nexPage: 0,
    pageCount: 0,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS__LOAD_BY_PROJECT_START:
      return {
        ...state,
        actionsLoading: true,
        actionsError: null,
      }

    case ACTIONS__LOAD_BY_PROJECT_SUCCESS:
      return {
        ...state,
        actionsLoading: false,
        actions: action.payload,
      }

    case ACTIONS__LOAD_BY_PROJECT_ERROR:
      return {
        ...state,
        actionsLoading: false,
        actionsError: action.payload,
      }

    case ACTIONS__LOAD_BY_USER_START:
      return {
        ...state,
        userActionsLoading: true,
        userActionsError: null,
      }

    case ACTIONS__LOAD_BY_USER_SUCCESS:
      return {
        ...state,
        userActionsLoading: false,
        userActions: action.payload.actions,
        userActionsTotal: action.payload.total,
      }

    case ACTIONS__LOAD_BY_USER_ERROR:
      return {
        ...state,
        userActionsLoading: false,
        userActionsError: action.payload,
      }

    case ACTIONS__LOAD_META_START:
      return {
        ...state,
        actionsMetaLoading: true,
        actionsMetaError: null,
      }

    case ACTIONS__LOAD_META_SUCCESS:
      return {
        ...state,
        actionsMetaLoading: false,
        actionsMeta: action.payload,
      }

    case ACTIONS__LOAD_META_ERROR:
      return {
        ...state,
        actionsMetaLoading: false,
        actionsMetaError: action.payload,
      }

    case ACTIONS__UPDATE_STATUS_START:
      return {
        ...state,
        actions: state.actions.map((act) => {
          if (act.id !== action.payload.actionId) {
            return act
          }
          return { ...act, status: action.payload.status }
        }),
      }
    case ACTIONS__UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        actions: state.actions.map((act) => {
          if (act.id !== action.payload.actionId) {
            return act
          }
          return { ...act, status: action.payload.status }
        }),
      }
    case ACTIONS__UPDATE_STATUS_ERROR: {
      return {
        ...state,
        actions: state.actions.map((act) => {
          if (act.id !== action.payload.actionId) {
            return act
          }
          return { ...act, status: act.status }
        }),
      }
    }

    case ACTIONS__UPDATE_LINK_STATUS_START:
      return {
        ...state,
        actions: state.actions.map((act) => {
          const { actionId, linkId, status } = action.payload
          if (act._id === actionId) {
            return {
              ...act,
              links: act.links.map((link) => {
                return link._id === linkId ? { ...link, status } : link
              })
            }
          }
          return act
        })
      }
    case ACTIONS__UPDATE_LINK_STATUS_SUCCESS:
      return {
        ...state,
        actions: state.actions.map((act) => {
          const { updatedAction } = action.payload
          if (act._id === updatedAction._id) {
            return { ...act, links: updatedAction.links }
          }
          return act
        })
      }
    case ACTIONS__UPDATE_LINK_STATUS_ERROR: {
      return {
        ...state,
        actions: state.actions.map((act) => {
          const { actionId, linkId } = action.payload
          if (act._id === actionId) {
            return {
              ...act,
              links: act.links.map((link) => {
                return link._id === linkId ? { ...link, status: link.status } : link
              })
            }
          }
          return act
        })
      }
    }

    case ACTIONS__UPDATE_PAGINATION_PARAMS:
      return {
        ...state,
        paginationParams: action.payload,
      }

    default:
      return state
  }
}
