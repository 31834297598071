import { faFolderOpen, faListTree } from '@fortawesome/pro-solid-svg-icons'
import { faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React, { Fragment, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toggleMultiNotifsMenu, toggleOptionsMenu } from '../../actions/menus'
import { startLoadingNotificationsByUser } from '../../actions/notification'
import {
  openCreateProjectOverlay,
  setAccountActivationOverlay, setNewProjectOverlay
} from '../../actions/overlay'
import useWindowSize from '../../hooks/useWindowSize'
import { isBtoBSelector, projectSelector, userSelector, isTerrealCustomerSelector } from '../../selectors'
import { notifSelector } from '../../selectors/notification'
import ProjectHeaderContainer from '../container-project-header'
import TreeList from '../sidebar-tree'
import TextTooltip from '../tooltip'
import {
  HomeIconContainer,
  MenuText,
  SidebarIcon,
  SidebarWrapper,
  SideWrapper
} from '../view-sidebar/styled'
import { HomeText, SidebarSvgContainer, CreateFolderButton } from './styled'
import { DEMO_ROUTE, MY_FOLDERS_ROUTE } from '../../constants/router'
import { fakeProject } from '../../constants/fake-project'

const SidebarContainer = (props) => {
  const history = useHistory()
  const { pathname } = history.location
  const isDemoProject = pathname === DEMO_ROUTE

  const user = useSelector(userSelector)
  const tunnelBaseUrl = window.processRuntime.env.PAYMENT_TUNNEL_BASE_URL
  const dispatch = useDispatch()
  const { isTabletOrMobile } = useWindowSize()
  const {
    userNotifications,
    userNotificationsLoading,
    userNotificationsError
  } = useSelector(notifSelector)

  const { projects, selectedProject } = useSelector(projectSelector)

  let homeText = ''
  if (selectedProject) {
    const { name, projectname, dealname } = selectedProject
    homeText = name || projectname || dealname
  }

  const homeIconContainerRef = useRef(null)
  const homeTextRef = useRef(null)
  let showHomeTextTooltip = false

  if (homeIconContainerRef.current && homeTextRef.current) {
    showHomeTextTooltip =
      homeIconContainerRef.current.offsetWidth < homeTextRef.current.scrollWidth
  }

  const isBtoB = useSelector(isBtoBSelector)
  const isTerrealCustomer = useSelector(isTerrealCustomerSelector)

  const isProjectsPage = pathname === MY_FOLDERS_ROUTE
  const isHomePage = pathname.startsWith('/projet/') || isDemoProject

  // Get userNotifications
  React.useMemo(() => {
    if (
      user &&
      user.profile &&
      !userNotifications &&
      !userNotificationsLoading &&
      !userNotificationsError
    ) {
      dispatch(startLoadingNotificationsByUser(user, { limit: 15, offset: 0 }))
    }
  }, [
    user,
    userNotifications,
    userNotificationsLoading,
    userNotificationsError
  ])

  const handleSwitchProject = (event) => {
    event.preventDefault()
    history.push(MY_FOLDERS_ROUTE)
  }

  const showOverlaySwitchProject = (event) => {
    if (isDemoProject) {
      dispatch(setAccountActivationOverlay());
    } else {
      handleSwitchProject(event);
    }
  };

  const handleClickHome = (event) => {
    event.preventDefault()
    if (isDemoProject || selectedProject.id === fakeProject.id) {
      history.push(DEMO_ROUTE)
    } else {
      history.push(`/projet/${selectedProject.id}`)
    }
  }

  const handleClickCreateProject = (event) => {
    event.preventDefault()

    if (isDemoProject) {
      dispatch(setAccountActivationOverlay())
    } else {
      if (isBtoB) {
        dispatch(openCreateProjectOverlay())
      } else {
        dispatch(setNewProjectOverlay())
      }
    }
  }

  return (
    user.accessToken && (
      <Fragment>
        {!isTabletOrMobile && (
          <SidebarWrapper>
            <SideWrapper>
              {/* Home */}
              <HomeIconContainer
                title="Liste des projets"
                onClick={showOverlaySwitchProject}
                active={isProjectsPage}
                disabled={projects?.totalWithoutFilter === 0}
              >
                <SidebarSvgContainer>
                  <SidebarIcon size="3x" icon={faListTree} />
                </SidebarSvgContainer>
                <MenuText>Mes dossiers</MenuText>
              </HomeIconContainer>

              <HomeIconContainer
                onClick={handleClickHome}
                active={isHomePage}
                ref={homeIconContainerRef}
              >
                <SidebarSvgContainer>
                  <SidebarIcon size="3x" icon={faFolderOpen} />
                </SidebarSvgContainer>
                {showHomeTextTooltip ? (
                  <TextTooltip
                    element={<HomeText ref={homeTextRef}>{homeText}</HomeText>}
                    text={homeText}
                    multiline={true}
                  />
                ) : (
                  <HomeText ref={homeTextRef}>{homeText}</HomeText>
                )}
              </HomeIconContainer>

              {!isBtoB && !isDemoProject && <TreeList />}

              {/* Create new project */}
              {!isTerrealCustomer &&
                <CreateFolderButton onClick={handleClickCreateProject} isBtoB>
                  <FontAwesomeIcon
                    color={props.theme.colors.primary}
                    icon={faLayerPlus}
                  />
                  <span>Créer un dossier</span>
                </CreateFolderButton>
              }
            </SideWrapper>
          </SidebarWrapper>
        )}
        <ProjectHeaderContainer style={{ paddingLeft: 75 }} />
      </Fragment>
    )
  )
}

const mapStateToProps = ({ router, menus, project }) => ({
  currentRoute: router.location.pathname,
  optionsMenuOpened: menus.optionsMenuOpened,
  multiNotifsMenuOpened: menus.multiNotifsMenuOpened,
  project: project.projects
})

const mapDispatchToProps = (dispatch) => ({
  handleOptionsClick: (event) => {
    event.stopPropagation()
    return dispatch(toggleOptionsMenu())
  },
  handleNotifIconClick: (event) => {
    event.stopPropagation()
    return dispatch(toggleMultiNotifsMenu())
  }
})

SidebarContainer.propTypes = {
  theme: PropTypes.object.isRequired,
  currentRoute: PropTypes.string.isRequired,
  optionsMenuOpened: PropTypes.bool.isRequired,
  handleOptionsClick: PropTypes.func.isRequired,
  multiNotifsMenuOpened: PropTypes.bool.isRequired,
  handleNotifIconClick: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SidebarContainer))
