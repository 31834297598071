import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { textFontSizeCss } from '../../styles/common'
import DatepickerInput from '../DatepickerInput/DatepickerInput'

const flexCenter = () => css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProjectListFilterContainer = styled.div`
  position: absolute;
  top: 32px;
  z-index: 2;

  min-width: 205px;
  min-height: 205px;
  height: -moz-max-content;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 0px 4px 2px rgba(0, 21, 86, 0.12);
  border-radius: 3px;
  padding: 0 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: grid;

  @media (max-width: 768px) {
    top: 0;
    box-shadow: none;
    width: 100%;
    height: 100%;
    padding: 0;
    grid-template-rows: repeat(3, min-content);
    overflow: hidden;
  }
`

export const FilterTitle = styled.div`
  ${flexCenter}
  position: relative;
  width: auto;
  height: 50px;
  text-align: center;
  border-bottom: 0.3px solid #c4c4c4;
  margin: 0 5px;
  gap: 13px;
  justify-content: ${({ open }) => (open ? 'flex-start' : 'center')};
  font-size: ${({ open }) => (open ? textFontSizeCss : 'inherit')};
  font-weight: ${({ open }) => (open ? 500 : 'inherit')};

  & > svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: flex;
    font-size: 18px;
    padding: 0 25px;
    border-bottom: 1px solid #e4e4e4;
    color: ${({ theme }) => theme.colors.primary};
    height: 70px;
    min-height: 70px;
    align-items: center;
    margin: 0;
  }
`

export const FilterContent = styled.div`
  width: 100%;
  padding: 15px 0;

  @media (max-width: 768px) {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 50px - 30px - 85px - 18px);
    padding: 20px;
  }
`

export const BtnClearFilterContainer = styled.div`
  ${flexCenter}
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 15px;
  }
`

export const BtnClearFilter = styled.button`
  appearance: none;
  border: none;
  background: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.workSans};

  &:focus-visible {
    outline: none;
  }
`

export const FilterItemContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const filterItemsWrapperCss = (props) => css`
  column-count: 1;
  @media (min-width: 768px) {
    column-count: ${props.gridColumns};
  }
`

export const StatusFilterContainer = styled.ul`
  ${filterItemsWrapperCss}
`

export const WorkTypesFilterContainer = styled.ul``

export const DatesFilterContainer = styled.ul`
  ${filterItemsWrapperCss}
`

export const filterItem = () => css`
  display: grid;
  grid-template-columns: 22px 1fr min-content;
  gap: 10px;
  align-items: center;
  height: 36px;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;

  &:hover {
    background-color: rgba(0, 21, 86, 0.1);
  }
`
export const FilterItem = styled.li`
  ${filterItem}
`

export const FilterItemDivider = styled.span`
  display: none;
  width: calc(100% - 10px);
  height: 0.3px;
  margin: 0 auto;
  background-color: #c4c4c4;

  @media (max-width: 768px) {
    display: block;
  }
`

export const FilterSubItem = styled(FilterItem)`
  grid-template-columns: max-content 10px;
  gap: 7px;
  height: 25px;
  line-height: 25px;
  ${textFontSizeCss}
  background-color: ${({ selected }) => selected ? 'rgba(0, 21, 86, 0.15)' : 'inherit'};
  color: ${({ selected, theme }) => selected ? theme.colors.primary : 'inherit'};
  width: max-content;
`

export const FilterSubItemCheckbox = styled.span`
  position: relative;
  top: 0px;
  width: 10px;
  height: 10px;
  border-radius: ${({ selected }) => (selected ? 0 : 3)}px;

  border: 0.5px solid ${({ color, theme }) => color || theme.colors.primary};
  background-color: ${({ selected, color, theme }) => selected ? color || theme.colors.primary : '#FFF'};

`

export const StatusGroupWrapper = styled(FilterSubItem)`
  display: block;
  height: max-content;
  cursor: default;
  // break-inside: avoid;

  &:hover {
    background-color: initial;
  }
`

export const StatusTitle = styled.p`
  ${textFontSizeCss}  
  line-height: 25px;
  color: #8A90A2;
  padding-left: 5px;
`

export const StatusFilterSubItem = styled.p`
  ${filterItem}
  grid-template-columns: max-content 10px;
  gap: 7px;
  height: 25px;
  line-height: 25px;
  ${textFontSizeCss}
  width: max-content;

  color: ${({ color }) => color};
  background-color: ${({ selected, bgColor }) =>
    selected ? bgColor : 'inherit'};
  &:hover {
    background-color: ${({ selected, bgColor, theme }) => selected ? bgColor : theme.colors.primaryHover};
  }
`

export const StatusFilterSubItemColor = styled.span`
  width: 2px;
  height: 14px;
  background-color: ${({ color }) => color};
`

export const WorkTypesFilterSubItem = styled(FilterSubItem)`
  order: ${({ index, gridRows }) => (index < gridRows ? 1 : 2)};
`

export const FilterItemIconContainer = styled.div`
  ${flexCenter}
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgba(0, 21, 86, 0.3);
`

export const DatesFilterSubItem = styled(FilterSubItem)``

export const FilterDatepicker = styled(DatepickerInput)`
  .react-datepicker,
  .react-datepicker__header {
    border: 0;
    width: 100%;
  }
  .react-datepicker__month-container {
    float: none;
    font-size: 1rem;
    * {
      font-size: 1rem;
    }
  }
`

export const BtnCloseFilter = styled.button`
  display: none;

  position: absolute;
  right: 0;
  appearance: none;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};

  & > svg {
    width: 20px !important;
    height: 20px !important;
  }

  @media (max-width: 768px) {
    display: block;
    right: 25px;
  }
`
