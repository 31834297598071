export const fakeProjectSteps = [
  {
    _id: '6412b6e59527a4203ab1ec4c',
    projectId: 'a0f8a1fd-6144-4ec0-bf87-2d21ea8debcb',
    metaCode: 'formOnboarding',
    createdAt: '2023-03-16T06:27:49.041Z',
    updatedAt: '2023-03-16T06:27:49.041Z',
    __v: 0,
    id: '6412b6e59527a4203ab1ec4c',
    meta: {
      id: 601,
      name: 'Dossier ouvert',
      code: 'formOnboarding',
      clientTypes: ['client_BtoC', 'client_BtoB', 'client_DESK'],
      isMilestone: false,
      indexInGroup: 2000,
      groupIndex: 0,
      isDefaultPast: false,
      isDefaultFuture: false,
      state: 'default',
      appearanceCodes: null,
      replaceByCodes: null
    }
  }
]
