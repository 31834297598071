import Api from '../api'

export default (accessToken, decodedAccessToken, axiosOptions) => {
  const pmdcAxios = Api.pmdc(accessToken, axiosOptions)
  const pmdcAxiosV1_1 = Api.pmdc(accessToken, axiosOptions, '/v1.1')

  return {
    // Expose axios instance so
    // we can make low-level calls
    axios: pmdcAxios,

    // User & Account
    associateToken: (confirmationToken, ...restParams) => (
      pmdcAxios.post(`/confirmation-tokens/${confirmationToken}/accounts/${decodedAccessToken.sub}`, ...restParams)
    ),
    getConfirmationTokenByValue: (confirmationToken, ...restParams) => (
      pmdcAxios.get(`/confirmation-tokens/${confirmationToken}`, ...restParams)
    ),
    getUserByAccount: (...restParams) => (
      pmdcAxios.get(`/accounts/${decodedAccessToken.sub}/user`, ...restParams)
    ),
    accountExistsByConfirmationToken: (confirmationToken, ...restParams) => (
      pmdcAxios.head(`/confirmation-tokens/${confirmationToken}/account`, ...restParams)
    ),
    getAccountLinks: (...restParams) => (
      pmdcAxios.get('/linked-accounts', ...restParams)
    ),
    getHubspotToken: (...restParams) => (
      pmdcAxios.get(`/accounts/${decodedAccessToken.sub}/user/hubspot-token`, ...restParams)
    ),
    createEmailPasswordAccount: (password, ...restParams) => (
      pmdcAxios.post('/create-credentials-account', { password }, ...restParams)
    ),
    linkAccount: (...restParams) => (
      pmdcAxios.post('/link-account', null, ...restParams)
    ),
    updateConnectedAt: (userId, ...restParams) => (
      pmdcAxios.post(`/users/${userId}/connection`, null, ...restParams)
    ),
    changePassword: (userId, password, ...restParams) => (
      pmdcAxios.put(`/users/${userId}/password`, { password }, ...restParams)
    ),

    // Steps
    getStepsMeta: (clientType, ...restParams) => (
      pmdcAxiosV1_1.get(`/meta-steps?clientType=${clientType}`, ...restParams)
    ),
    getStepsByProject: (projectId, ...restParams) => (
      pmdcAxiosV1_1.get(`/projects/${projectId}/steps`, ...restParams)
    ),

    // Projects
    getProjectsByUser: (userId, ...restParams) => (
      pmdcAxios.get(`/users/${userId}/projects`, ...restParams)
    ),
    getProjectsByUserSortedByLast: (userId, paginationParams, axiosOptions, ...restParams) => {
      const { dealstatus, workTypeCodes, dates } = paginationParams

      return pmdcAxios.get(`/users/${userId}/projects`, {
        params: {
          ...paginationParams,
          'order-by': '-updatedAt',
          dealstatus: dealstatus ? JSON.stringify(dealstatus) : null,
          workTypeCodes: workTypeCodes ? JSON.stringify(workTypeCodes) : null,
          dealStatusTitles: null,
          dates: dates ? JSON.stringify(dates) : null
        },
        ...axiosOptions
      }, ...restParams)
    },
    getProjectById: (projectId, ...restParams) => (
      pmdcAxios.get(`/projects/${projectId}`, ...restParams)
    ),
    getWorkTypes: (...restParams) => (
      pmdcAxios.get('/work-types', ...restParams)
    ),
    getWorkTypesWithoutPrice: (...restParams) => (
      pmdcAxios.get('/work-types-without-price', ...restParams)
    ),
    getFacilitatorByProject: (projectId, ...restParams) => (
      pmdcAxios.get(`/projects/${projectId}/facilitator`, ...restParams)
    ),
    getSellerByProject: (projectId, ...restParams) => (
      pmdcAxios.get(`/projects/${projectId}/seller`, ...restParams)
    ),
    updateLastVisit: (projectId, ...restParams) => (
      pmdcAxios.post(`/projects/${projectId}/visit`, null, ...restParams)
    ),

    // Notifications
    getNotifications: (projectId, ...restParams) => (
      pmdcAxiosV1_1.get(`/projects/${projectId}/notifications`, ...restParams)
    ),
    getNotificationsByUser: (userId, paginationParams, ...restParams) =>
      pmdcAxiosV1_1.get(
        `/users/${userId}/notifications`,
        { params: { ...paginationParams, sort: { createdAt: -1 } } },
        ...restParams
      ),
    getNotificationsMeta: (...restParams) => (
      pmdcAxiosV1_1.get('/meta-notifications', ...restParams)
    ),
    readNotification: (notificationId, ...restParams) => (
      pmdcAxiosV1_1.put(`/notifications/${notificationId}`, { isRead: true }, ...restParams)
    ),

    // Actions
    getActions: (projectId, ...restParams) => (
      pmdcAxiosV1_1.get(`/projects/${projectId}/actions`, ...restParams)
    ),
    getActionsByUser: (userId, paginationParams, ...restParams) =>
      pmdcAxiosV1_1.get(
        `/users/${userId}/actions`,
        { params: { ...paginationParams, sort: { createdAt: -1 } } },
        ...restParams
      ),
    getActionsMeta: (...restParams) => (
      pmdcAxiosV1_1.get('/meta-actions', ...restParams)
    ),
    updateActionStatus: (actionId, status, ...restParams) => (
      pmdcAxiosV1_1.put(`/actions/${actionId}`, { status }, ...restParams)
    ),
    updateActionLinkStatus: (actionId, linkId, status, ...restParams) => (
      pmdcAxiosV1_1.put(`/actions/${actionId}/update-link-status/${linkId}`, { status }, ...restParams)
    ),

    // Files
    getFilesByProject: (projectId, ...restParams) => (
      pmdcAxios.get(`/projects/${projectId}/files`, ...restParams)
    ),
    deleteFileById: (fileId, ...restParams) => (
      pmdcAxios.delete(`/files/${fileId}`, ...restParams)
    ),
    uploadFileByProject: (projectId, body, ...restParams) => (
      pmdcAxios.post(`/projects/${projectId}/files`, body, ...restParams)
    ),
    getTemplateFolderContents: (...restParams) => (
      pmdcAxios.get('/folders/template', ...restParams)
    ),
    getRecommendations: (projectId, ...restParams) => (
      pmdcAxios.get(`/projects/${projectId}/recommendations`, ...restParams)
    ),
    updateProjectName: (dealId, newProjectname, ...restParams) => (
      pmdcAxios.put(`/hubspot/deals/${dealId}/update-name`, { projectname: newProjectname }, ...restParams)
    ),
    createProject: (userId, payload, ...restParams) => (
      pmdcAxios.post(`/users/${userId}/projects`, payload, ...restParams)
    ),
    updateDealStage: (dealId, dealStageId, ...restParams) => (
      pmdcAxios.put(`/hubspot/deals/${dealId}/update-dealstage`, { dealStageId }, ...restParams)
    ),
  }
}
