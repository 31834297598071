import styled from '@emotion/styled'
import { mq } from '../../utils/style'
import { HomeBlock } from '../styled'

export const ThrottleButton = styled('button')`
  flex: 1;
  border: none;
  padding: 0;
  border-radius: 2px;
`

export const MapHomeBlock = styled(HomeBlock)`
  border: none;
  padding: 0;
  min-height: 141px;

  /* fix Safari not rouding */
  -webkit-clip-path: border-box;
  cursor: default !important;
  & * {
    cursor: default !important;
  }

  & div {
    min-width: inherit;
    min-height: inherit;
    border-radius: 5px;
  }
  ${props => mq(props)({
    padding: ['0 !important']
  })}

  @media (max-width: 1024px) {
    height: unset;
  }
`
