import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  startFetchingDraftData
} from '../../actions/draft'

import buildMiddleware from './template'

const withDraftData = (SubComponent) => {
  const WithDraftDataWrapper = buildMiddleware(
    SubComponent,
    'withDraftData'
  )

  const withDraftData = class extends Component {
    componentDidMount () {
      this.props.startFetchingDraftData()
    }

    render () {
      const {
        startFetchingDraftData,
        ...passThroughProps
      } = this.props

      return <WithDraftDataWrapper {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ draft }) => ({
    loading: draft.draftDataLoading || (!draft.draftContent && !draft.draftDataError),
    error: draft.draftDataError
  })

  const mapDispatchToProps = dispatch => ({
    startFetchingDraftData: () => dispatch(startFetchingDraftData())
  })

  withDraftData.propTypes = {
    startFetchingDraftData: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(withDraftData)
}

export default withDraftData
