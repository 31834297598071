import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  ACTIONS__LOAD_BY_PROJECT_START,
  ACTIONS__LOAD_BY_USER_START,
  ACTIONS__LOAD_META_START,
  ACTIONS__UPDATE_LINK_STATUS_START,
  ACTIONS__UPDATE_STATUS_START,
} from '../actions'
import {
  loadActionsByProjectError,
  loadActionsByProjectSuccess,
  loadActionsByUserError,
  loadActionsByUserSuccess,
  loadActionsMetaError,
  loadActionsMetaSuccess,
  updateActionLinkStatusError,
  updateActionLinkStatusSuccess,
  updateActionStatusError,
  updateActionStatusSuccess,
  updatePaginationParams,
} from '../actions/action'
import { logError } from '../actions/system'
import { getCodeFromError } from '../utils/error'
import PMDC from '../utils/pmdc'

function* loadActionsByProject() {
  try {
    const { user, project } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getActions,
      project.selectedProjectId
    )
    yield put(loadActionsByProjectSuccess(data))
  } catch (err) {
    yield put(
      loadActionsByProjectError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération des actions',
      })
    )
    yield put(logError(err))
  }
}

function* loadActionsByUser(action) {
  try {
    const {
      action: { paginationParams, userActions },
    } = yield select()

    const { limit, pageCount, nexPage } = paginationParams

    const { user, paginationParams: newParams } = action.payload

    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getActionsByUser,
      user.profile.id,
      newParams
    )

    const newUserActions = userActions
      ? [...userActions, ...data.data]
      : data.data

    yield put(loadActionsByUserSuccess(newUserActions, data.total))

    const newPageCount = Math.ceil(data.total / limit)

    if (pageCount === 0 || nexPage < newPageCount) {
      yield put(
        updatePaginationParams({
          ...paginationParams,
          pageCount: newPageCount,
          nexPage: nexPage + 1,
        })
      )
    }
  } catch (err) {
    yield put(
      loadActionsByUserError({
        code: getCodeFromError(err),
        message: `Erreur lors de la récupération des actions de l'utilisateur`,
      })
    )
    yield put(logError(err))
  }
}

function* loadActionsMeta() {
  try {
    const { user } = yield select()
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).getActionsMeta
    )
    yield put(loadActionsMetaSuccess(data))
  } catch (err) {
    yield put(
      loadActionsMetaError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la récupération des données de actions',
      })
    )
    yield put(logError(err))
  }
}

function* updateActionStatus(action) {
  const { actionId, status } = action.payload
  const { user } = yield select()

  try {
    const { data } = yield call(
      PMDC(user.accessToken, user.decodedAccessToken).updateActionStatus,
      actionId,
      status
    )
    yield put(updateActionStatusSuccess(data))
  } catch (err) {
    yield put(
      updateActionStatusError({
        code: getCodeFromError(err),
        message: 'Erreur lors de la mise à jour de statut',
        actionId,
      })
    )
    yield put(logError(err))
  }
}

function* updateActionLinkStatus(action) {
  const { actionId, linkId, status } = action.payload
  const { user } = yield select()

  try {
    const { data } = yield call(PMDC(user.accessToken, user.decodedAccessToken).updateActionLinkStatus, actionId, linkId, status)
    yield put(updateActionLinkStatusSuccess(data))
  } catch (err) {
    yield put(updateActionLinkStatusError({
      actionId,
      linkId,
      error: 'Erreur lors de la mise à jour de statut du lien',
    }))
    yield put(logError(err))
  }
}

export default [
  takeLatest(ACTIONS__LOAD_BY_PROJECT_START, loadActionsByProject),
  takeLatest(ACTIONS__LOAD_BY_USER_START, loadActionsByUser),
  takeLatest(ACTIONS__LOAD_META_START, loadActionsMeta),
  takeLatest(ACTIONS__UPDATE_STATUS_START, updateActionStatus),
  takeLatest(ACTIONS__UPDATE_LINK_STATUS_START, updateActionLinkStatus),
]
