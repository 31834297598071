import {
  setUserScope,
  setReduxStateScope
} from '../sentry'

const sentryMiddleware = setScope => Sentry => store => next => action => {
  const state = store.getState()

  Sentry.configureScope(setScope(state))

  return next(action)
}

export const sentryUserMiddleware = sentryMiddleware(setUserScope)
export const sentryReduxStateMiddleware = sentryMiddleware(setReduxStateScope)
