import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'

export const StyledTextTooltip = styled.span`
  display: flex;
  & > svg {
    height: 0.8em;
    width: 0.8em;
  }
`

export const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 350px !important;
  padding: 15px 20px !important;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  white-space: break-spaces !important;

  &.show {
    opacity: 1 !important;
    transition: opacity 0.8s ease-in-out;
  }
`
