import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  InputLabel,
  PasswordForm,
  PasswordInput,
  PasswordInputContainer,
  PasswordInputContainerLast,
  ShowHidePassword
} from './styled'

const SetPasswordView = ({ state, setState, error }) => {
  const [showPassword, setShowPassword] = useState(false)

  const { password, confirmPassword } = state

  const inputType = showPassword ? 'text' : 'password'
  const passwordIcon = showPassword ? faEye : faEyeSlash

  const handleInputChange = (event) => {
    const value = event.currentTarget.value
    const name = event.currentTarget.name
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState)
  }

  return (
    <PasswordForm>
      <InputLabel htmlFor="password">Nouveau mot de passe</InputLabel>
      <PasswordInputContainer>
        <PasswordInput
          type={inputType}
          id="password"
          name="password"
          value={password}
          onChange={handleInputChange}
          error={!!error}
          autoComplete="password"
        />
        <ShowHidePassword
          showPassword={showPassword}
          onClick={handleTogglePassword}
        >
          <FontAwesomeIcon icon={passwordIcon} />
        </ShowHidePassword>
      </PasswordInputContainer>
      <InputLabel htmlFor="confirmPassword">Confirmation</InputLabel>
      <PasswordInputContainerLast>
        <PasswordInput
          type={inputType}
          id="confirmPassword"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleInputChange}
          error={!!error}
          autoComplete="confirmPassword"
        />
        <ShowHidePassword
          showPassword={showPassword}
          onClick={handleTogglePassword}
        >
          <FontAwesomeIcon icon={passwordIcon} />
        </ShowHidePassword>
      </PasswordInputContainerLast>
    </PasswordForm>
  )
}

SetPasswordView.propTypes = {
  state: PropTypes.shape({
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired
  }),
  setState: PropTypes.func.isRequired,
  error: PropTypes.object
}

export default SetPasswordView
