import {
  MENUS__OPTIONS_OPEN,
  MENUS__OPTIONS_CLOSE,
  MENUS__OPTIONS_TOGGLE,
  MENUS__MULTI_NOTIFS_OPEN,
  MENUS__MULTI_NOTIFS_CLOSE,
  MENUS__MULTI_NOTIFS_TOGGLE
} from '.'

export const openOptionsMenu = () => ({
  type: MENUS__OPTIONS_OPEN
})

export const closeOptionsMenu = () => ({
  type: MENUS__OPTIONS_CLOSE
})

export const toggleOptionsMenu = () => ({
  type: MENUS__OPTIONS_TOGGLE
})

export const openMultiNotifsMenu = () => ({
  type: MENUS__MULTI_NOTIFS_OPEN
})

export const closeMultiNotifsMenu = () => ({
  type: MENUS__MULTI_NOTIFS_CLOSE
})

export const toggleMultiNotifsMenu = () => ({
  type: MENUS__MULTI_NOTIFS_TOGGLE
})
