import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startRetreivingConfirmationToken } from '../../actions/user'
import { startLoadingFullConfirmationToken } from '../../actions/project'

import withWait from './with-wait'
import buildMiddleware from './template'

const withConfirmationTokenRetrieve = (SubComponent) => {
  const WithConfirmationTokenWrapper = buildMiddleware(
    SubComponent,
    'WithConfirmationToken'
  )

  const WithQueryParamConfirmationToken = class extends Component {
    componentDidMount () {
      this.props.retrieveConfirmationTokenFromQuery()
    }

    render () {
      const {
        retrieveConfirmationTokenFromQuery,
        ...passThroughProps
      } = this.props

      return <WithConfirmationTokenWrapper {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ user }) => ({
    loading: user.confirmationTokenLoading || (!user.confirmationToken && !user.confirmationTokenError),
    error: user.confirmationTokenError
  })

  const mapDispatchToProps = dispatch => ({
    retrieveConfirmationTokenFromQuery: () => dispatch(startRetreivingConfirmationToken())
  })

  WithQueryParamConfirmationToken.propTypes = {
    retrieveConfirmationTokenFromQuery: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithQueryParamConfirmationToken)
}

const withFullConfirmationTokenFetch = (SubComponent, mandatory) => {
  const SubComponentWithWait = withWait(SubComponent, mandatory)

  const WithFullConfirmationTokenFetch = class extends Component {
    componentDidMount () {
      this.props.startLoadingFullConfirmationToken()
    }

    render () {
      const {
        startLoadingFullConfirmationToken,
        ...passThroughProps
      } = this.props

      return <SubComponentWithWait {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ project }) => ({
    loading: project.fullConfirmationTokenLoading || (!project.fullConfirmationToken && !project.fullConfirmationTokenError),
    error: project.fullConfirmationTokenError
  })

  const mapDispatchToProps = dispatch => ({
    startLoadingFullConfirmationToken: () => dispatch(startLoadingFullConfirmationToken())
  })

  WithFullConfirmationTokenFetch.propTypes = {
    startLoadingFullConfirmationToken: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithFullConfirmationTokenFetch)
}

export {
  withConfirmationTokenRetrieve,
  withFullConfirmationTokenFetch
}
