import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalize } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { closeOverlay } from '../../actions/overlay'
import { animationDelay } from '../../constants/styles'
import {
  isAlgarDeskSelector,
  isBtoBSelector,
  projectSelector,
  selectedProjectSelector,
} from '../../selectors'
import { formatDate } from '../../utils/date'
import OverlayCentered from '../overlay-centered'
import { getProjectStatus } from '../page-project-list/helpers'
import { ProjectStatus } from '../page-project-list/styled'
import {
  closeAnimationName,
  GotToFolderBtn,
  openAnimationName,
  ProjectAdditionalInfoBody,
  ProjectAdditionalInfoBodyLine,
  ProjectAdditionalInfoBtnClose,
  ProjectAdditionalInfoFooter,
  ProjectAdditionalInfoHeader,
  ProjectAdditionalInfoStyled,
  StyledProjectStatusDesk,
} from './styled'

const ProjectAdditionalInfoOverlay = () => {
  const dispatch = useDispatch()

  const [animation, setAnimation] = React.useState(openAnimationName)

  const isBtoB = useSelector(isBtoBSelector)
  const isDesk = useSelector(isAlgarDeskSelector)
  const project = useSelector(selectedProjectSelector)

  let { workTypes } = useSelector(projectSelector)
  workTypes = Object.values(workTypes)

  const workTypeNames = workTypes
    .filter((wt) => project.workTypesIds.includes(wt.id))
    .map((wt) => capitalize(wt.name))

  const projectName = project.name || project.projectname || project.dealname

  const projectStatus = getProjectStatus(project.dealstage, isBtoB, isDesk, project.pipeline)

  const isTypeDesk = project.typeClient.toLowerCase() === 'desk'

  const datas = [
    { id: 1, label: 'Nom du dossier', value: projectName },
    {
      id: 2,
      label: 'Type',
      value: isDesk
        ? 'Création du dossier, dépôt & gestion administrative'
        : '-',
    },
    { id: 3, label: 'Statut', value: projectStatus.title },
    { id: 4, label: 'Types de projets', value: workTypeNames.join(', ') },
    { id: 5, label: 'Adresse', value: project.address },
    { id: 6, label: 'Créé le', value: formatDate(project.createdAt) },
    { id: 7, label: 'Déposé le', value: formatDate(project.dateExpedition) },
    {
      id: 8,
      label: "Début d'instruction",
      value: formatDate(project.dateDepotMontage),
    },
    {
      id: 9,
      label: "Numéro de dossier",
      value: project.numeroInstruction,
    },
    {
      id: 10,
      label: "Fin d'instruction estimée",
      value: formatDate(project.dateFinInstruction),
    },
    {
      id: 11,
      label: "Réception d'une DPC",
      value: formatDate(project.dateReceptionDpc),
    },
    {
      id: 12,
      label: 'Dépôt des pièces complémentaires',
      value: formatDate(project.dateDepotPc),
    },
    {
      id: 13,
      label: 'Décision',
      value: formatDate(project.dateDecision),
    },
  ]

  const handleClose = (e) => {
    e.stopPropagation()
    setAnimation(closeAnimationName)

    setTimeout(() => {
      dispatch(closeOverlay())
    }, animationDelay * 1000)
  }

  const handleAccessToFolder = () => {
    window.location.href = `/projet/${project.id}`
  }

  return (
    <OverlayCentered
      isFullScreen={true}
      withCloseButton={false}
      noPadding={true}
      isTransparent={true}
    >
      <ProjectAdditionalInfoStyled animation={animation}>
        <ProjectAdditionalInfoHeader>
          <ProjectAdditionalInfoBtnClose onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} size="2x" />
          </ProjectAdditionalInfoBtnClose>
        </ProjectAdditionalInfoHeader>
        <ProjectAdditionalInfoBody>
          {datas.map(({ id, label, value }) => {
            return (
              <ProjectAdditionalInfoBodyLine key={`project-data-${id}`}>
                <span>{label}</span>
                {id === 2 ? (
                  <React.Fragment>
                    {isTypeDesk ? (
                      <StyledProjectStatusDesk
                        statusColor={projectStatus.color}
                      >
                        SILVER
                      </StyledProjectStatusDesk>
                    ) : (
                      <span>{value}</span>
                    )}
                  </React.Fragment>
                ) : id === 3 ? (
                  <ProjectStatus statusColor={projectStatus.color}>
                    {projectStatus.title}
                  </ProjectStatus>
                ) : (
                  <span>{value}</span>
                )}
              </ProjectAdditionalInfoBodyLine>
            )
          })}
        </ProjectAdditionalInfoBody>
        <ProjectAdditionalInfoFooter>
          <GotToFolderBtn onClick={handleAccessToFolder}>
            Accéder au dossier
          </GotToFolderBtn>
        </ProjectAdditionalInfoFooter>
      </ProjectAdditionalInfoStyled>
    </OverlayCentered>
  )
}

export default ProjectAdditionalInfoOverlay
