import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React from 'react'
import SelectInput from '../select-input'
import { useFormattedWorkTypes } from './use-formatted-work-types'

const SelectInputProjectTypes = (props) => {
  const formattedWorkTypes = useFormattedWorkTypes(props)

  return (
    <SelectInput
      {...props}
      options={formattedWorkTypes}
      isMulti={true}
      placeholder={props.placeholder || 'Choisir un type de projet'}
    />
  )
}

SelectInputProjectTypes.defaultProps = {
  isBtoB: false,
  isGetir: false,
}

SelectInputProjectTypes.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  isBtoB: PropTypes.bool,
  isGetir: PropTypes.bool,
}

export default withTheme(SelectInputProjectTypes)
