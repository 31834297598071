import { faKey, faLockAlt, faSignOut } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTheme } from 'emotion-theming'
import PropTypes from 'prop-types'
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { closeOptionsMenu, toggleOptionsMenu } from '../../actions/menus'
import {
  setChangePasswordOverlay,
  setSelectProject,
} from '../../actions/overlay'
import { menusSelector } from '../../selectors/menus'
import { MenuButton, MenuLink, MenuWrapper } from './styled'

const isLogged = (props) => props.accessToken

const menuId = 'id-container-options-menu'

const OptionsMenuContainer = (props) => {
  const { optionsMenuOpened } = useSelector(menusSelector)
  const dispatch = useDispatch()

  const closeMenuListener = (event) => {
    event.stopPropagation()
    const menu = document.getElementById(menuId)
    let clickedElement = event.target
    if (menu && clickedElement !== menu && optionsMenuOpened) {
      dispatch(closeOptionsMenu())
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', closeMenuListener)
    return () => {
      document.removeEventListener('click', closeMenuListener)
    }
  }, [optionsMenuOpened])

  return (
    <MenuWrapper id={menuId}>
      {isLogged(props) ? (
        <>
          <MenuButton onClick={props.onSetPasswordClick}>
            <FontAwesomeIcon
              size="1x"
              icon={faLockAlt}
              color={props.theme.colors.dark}
            />
            <span>Changer de mot de passe</span>
          </MenuButton>
          <MenuLink to="/logout">
            <FontAwesomeIcon
              size="1x"
              icon={faSignOut}
              color={props.theme.colors.dark}
            />
            <span>Déconnexion</span>
          </MenuLink>
        </>
      ) : (
        <MenuLink to="/login">
          <FontAwesomeIcon
            size="1x"
            icon={faKey}
            color={props.theme.colors.dark}
          />
          <span>Connexion</span>
        </MenuLink>
      )}
    </MenuWrapper>
  )
}

const mapStateToProps = ({ user }) => ({
  accessToken: user.accessToken,
})

const mapDispatchToProps = (dispatch) => ({
  onSetPasswordClick: (event) => {
    event.stopPropagation()
    return dispatch(setChangePasswordOverlay())
  },
  onSetProjectListClick: (event) => {
    event.stopPropagation()
    return dispatch(setSelectProject())
  },
  handleOptionsClick: (event) => {
    event.stopPropagation()
    return dispatch(toggleOptionsMenu())
  },
})

OptionsMenuContainer.propTypes = {
  accessToken: PropTypes.string,
  onSetPasswordClick: PropTypes.func.isRequired,
  onSetProjectListClick: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(OptionsMenuContainer))
