import styled from '@emotion/styled'
import chroma from 'chroma-js'
import { mq } from '../../utils/style'

import {
  HomeBlockTitle,
  InvisibleButton,
  baseButtonCss,
} from '../styled'

export const FilesBlockTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: sticky;
  top: 0;
  background-color: white;
  ${(props) => mq(props)({
  padding: [0, '25px 35px 0 25px'],
  marginBottom: ['13px', '7px']
})}

  @media (max-width: 768px) {
    justify-content:  ${props => props.isFolderExpanded ? 'flex-start' : 'center'};
    top: 7px;
  }
`

export const FilesBlockTitle = styled(HomeBlockTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0;
  align-self: flex-start;

  p {
    display: flex;
    align-items: center;
  }
`

export const FileUploadText = styled.span`
  margin-left: 4px;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1em;
  display: none;

  @media (min-width: 1025px) {
    display: block;
    margin-left: 8px;
  }
`

export const FileUploadTextMobile = styled(FileUploadText)`
  display: block;
  @media (min-width: 1025px) {
    display: none;
  }
`

export const FileUploadButton = styled(InvisibleButton)`
  ${baseButtonCss}

  font-size: 1em;
  display: flex;
  align-items: center;
  max-height: 40px;

  border-radius: 2px;
  font-weight: ${({ theme }) => theme.fontWeights.primary.medium};

  ${(props) => mq(props)({
  backgroundColor: [props.invertColor],
  border: [`1px solid ${props.color}`],
  color: [props.color],
  padding: [`10px`, `12px`]
})}

  &:not(:disabled):hover {
    background-color: ${props => chroma(props.color).darken(0.15).hex()};
    border-color: ${props => chroma(props.color).darken(0.15).hex()};

    span {
      color: ${props => props.invertColor};
    }

    svg {
      path {
        fill: ${props => props.invertColor};
      }
    }
  }

  & > svg {
    ${(props) => mq(props)({
  width: ['15px !important', '16px !important'],
  height: ['15px !important', '16px !important']
})}

    path {
      ${(props) => mq(props)({
  fill: [props.color],
})}
    }
  }

  ${(props) => props.hasExpandedFiles &&
    `@media (max-width: 1024px) {
      width: 31px;
      height: 31px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        display: none;
      }
    }`
  }
  @media (max-width: 768px) {
    position: absolute;
    right: 10px
  }
`

export const FilesBlockBackBtn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }

  svg:nth-of-type(2) {
    width: 25px !important;
    height: 18.75px !important;
    margin: 0 10px 0 15px;
  }
`;
