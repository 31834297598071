import React from 'react'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropType from 'prop-types'
import { v4 } from 'uuid'
import { StyledReactTooltip, StyledTextTooltip } from './styled'

const TextTooltip = ({ element, text, place }) => {
  const dataFor = v4()

  return (
    <StyledTextTooltip data-for={dataFor} data-tip={text}>
      {element ? element : <FontAwesomeIcon icon={faInfoCircle} size="1x" />}
      <StyledReactTooltip
        id={dataFor}
        place={place}
        type="dark"
        effect="solid"
        multiline={true}
      />
    </StyledTextTooltip>
  )
}

TextTooltip.defaultProps = {
  place: 'bottom',
  multiline: true,
}

TextTooltip.propTypes = {
  element: PropType.node,
  text: PropType.string.isRequired,
  place: PropType.oneOf(['left', 'top', 'right', 'bottom']),
  multiline: PropType.bool,
}

export default TextTooltip
