import React, { useEffect, useState } from "react"
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { PageCicContainer, MainTitle, PartnerContent, Content, SideImage, MainLogo, Title, Text, TextInput, TextLabel, AdviseBlock, Advise, Photo, ConfirmButton, CheckboxWrapper, CheckboxContainer } from './styled'
import { ProjectListWrapper } from '../page-project-list/styled'
import { useSelector } from 'react-redux'
import {
    projectSelector,
    selectedProjectSelector,
    userSelector,
    isCicSubmittedSelector
} from '../../selectors'
import aHighRes from '../../static/cicillu.jpeg'
import logo from '../../static/cic.jpeg'

const PageCic = () => {
    const [isAccepted, setIsAccepted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [selectedDays, setSelectedDays] = useState([])
    const [selectedHours, setSelectedHours] = useState([])

    const history = useHistory()

    // we don't want the user to go directly to this page. In case of change, data from users & projects must be load first.
    if (history.action === 'POP') {
        history.push('/')
        return null
    }

    const isCicSubmitted = useSelector(isCicSubmittedSelector)
    const availableDays = ["Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]

    const selectedProject = useSelector(selectedProjectSelector)
    const { profile } = useSelector(userSelector)
    const { workTypesIds } = (selectedProject || {})
    const { workTypes } = useSelector(projectSelector)
    const projectWorkTypes = Object.values(workTypes).filter((wt) =>
        workTypesIds.includes(wt.id)
    ).map(w => w = w.tunnelCode)
    const { lastName, firstName, phoneNumber } = profile
    const { address } = selectedProject
    const { dealId } = selectedProject.dealAssociation

    const handleChange = (value, stateUpdater) => {
        stateUpdater((prevState) => {
            const isSelected = prevState.includes(value);

            if (isSelected) {
                return prevState.filter((item) => item !== value);
            } else {
                return [...prevState, value];
            }
        })
    }

    const sendInfo = () => {
        const dataForCic = {
            dealId,
            projectWorkTypes,
            address,
            firstName,
            lastName,
            phoneNumber,
            isAccepted,
            selectedDays,
            selectedHours
        }
        axios.post(window.processRuntime.env.MAKE_CIC_SCENARIO, dataForCic)
            .then((response) => {
                setIsSubmitted(true)
                console.log(response)
            }, (error) => {
                console.log(error)
            })
    }

    return (
        <ProjectListWrapper>
            <PageCicContainer>
                <MainTitle>Banques</MainTitle>
                <PartnerContent>
                    <Content>
                        <MainLogo src={logo} alt="Cic Logo" />
                        <Title>Présentation de notre partenaire CIC Nord-Ouest</Title>
                        <Text>Nous vous proposons <span>un rendez-vous personnalisé de 20 mn</span> avec un conseiller du CIC Nord-Ouest afin de trouver
                            la meilleure solution pour vous accompagner dans votre projet travaux.</Text>
                        <Text>Je choisis mes préférences de contact :</Text>
                        <form>
                            <CheckboxContainer>
                                {availableDays.map((el, index) => (
                                    <CheckboxWrapper key={index} isFirst={index === 0}>
                                        <TextInput type="checkbox" onChange={() => handleChange(el, setSelectedDays)}
                                            checked={selectedDays.includes(el)}></TextInput>
                                        <TextLabel>{el}{el === "Samedi" && ` *`}</TextLabel>
                                    </CheckboxWrapper>
                                ))}
                            </CheckboxContainer>
                            <CheckboxContainer lowMargin>
                                <CheckboxWrapper isFirst>
                                    <TextInput type="checkbox" onChange={() => handleChange('10-12h', setSelectedHours)} checked={selectedHours.includes('10-12h')}></TextInput>
                                    <TextLabel>10-12h</TextLabel>
                                </CheckboxWrapper>
                                <CheckboxWrapper>
                                    <TextInput type="checkbox" onChange={() => handleChange('14-16h', setSelectedHours)} checked={selectedHours.includes('14-16h')}></TextInput>
                                    <TextLabel>14-16h</TextLabel>
                                </CheckboxWrapper>
                            </CheckboxContainer>
                        </form>
                        <Text>* Le samedi uniquement 10-12h</Text>
                        <AdviseBlock>
                            <Advise>
                                <input type="checkbox" onChange={e => setIsAccepted(e.target.checked)} checked={isCicSubmitted ? true : isAccepted} />
                                En cochant cette case, j’accepte que mes coordonnées et les informations de mon projet
                                soient transmises au CIC Nord Ouest et uniquement au CIC Nord Ouest de manière
                                sécurisée. J’accepte également que le CIC Nord Ouest prenne contact avec moi par
                                téléphone et /ou par email.  Cette mise en relation est gratuite.
                            </Advise>
                        </AdviseBlock>
                        <ConfirmButton disabled={!isAccepted || isCicSubmitted || isSubmitted || selectedDays.length === 0 || selectedHours.length === 0} onClick={() => sendInfo()}>
                            {isSubmitted || isCicSubmitted ? 'Demande envoyée' : "Envoyer ma demande"}
                        </ConfirmButton>
                    </Content>
                    <SideImage>
                        <Photo src={aHighRes} alt="" />
                    </SideImage>
                </PartnerContent>
            </PageCicContainer>
        </ProjectListWrapper>
    )
}

export default PageCic