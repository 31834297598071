import {
  NOTIFICATIONS__GET_BY_PROJECT_START,

  NOTIFICATIONS__LOAD_BY_PROJECT_START,
  NOTIFICATIONS__LOAD_BY_PROJECT_SUCCESS,
  NOTIFICATIONS__LOAD_BY_PROJECT_ERROR,

  NOTIFICATIONS__LOAD_META_START,
  NOTIFICATIONS__LOAD_META_SUCCESS,
  NOTIFICATIONS__LOAD_META_ERROR,

  NOTIFICATIONS__READ_NOTIFICATION_START,
  NOTIFICATIONS__READ_NOTIFICATION_SUCCESS,
  NOTIFICATIONS__READ_NOTIFICATION_ERROR,

  NOTIFICATIONS__RESET_NB_CALL,

  NOTIFICATIONS__LOAD_BY_USER_START,
  NOTIFICATIONS__LOAD_BY_USER_SUCCESS,
  NOTIFICATIONS__LOAD_BY_USER_ERROR,

  NOTIFICATIONS__UPDATE_PAGINATION_PARAMS
} from '../actions'

const initialState = {
  notifications: null,
  notificationsLoading: false,
  notificationsError: null,

  notificationsMeta: null,
  notificationsMetaLoading: false,
  notificationsMetaError: null,

  nbCallNotifications: 0,

  userNotifications: null,
  userNotificationsLoading: false,
  userNotificationsError: null,
  userNotificationsTotal: 0,

  paginationParams: {
    limit: 15,
    offset: 0,
    nexPage: 0,
    pageCount: 0,
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS__GET_BY_PROJECT_START:
      return {
        ...state,
        nbCallNotifications: state.nbCallNotifications + 1,
        notificationsError: null
      }

    case NOTIFICATIONS__LOAD_BY_PROJECT_START:
      return {
        ...state,
        notificationsLoading: true,
        notificationsError: null
      }

    case NOTIFICATIONS__LOAD_BY_PROJECT_SUCCESS:
      return {
        ...state,
        notificationsLoading: false,
        notifications: action.payload
      }

    case NOTIFICATIONS__LOAD_BY_PROJECT_ERROR:
      return {
        ...state,
        notificationsLoading: false,
        notificationsError: action.payload
      }

    case NOTIFICATIONS__LOAD_BY_USER_START:
      return {
        ...state,
        userNotificationsLoading: true,
        userNotificationsError: null
      }

    case NOTIFICATIONS__LOAD_BY_USER_SUCCESS:
      return {
        ...state,
        userNotificationsLoading: false,
        userNotifications: action.payload.notifications,
        userNotificationsTotal: action.payload.total
      }

    case NOTIFICATIONS__LOAD_BY_USER_ERROR:
      return {
        ...state,
        userNotificationsLoading: false,
        userNotificationsError: action.payload
      }

    case NOTIFICATIONS__LOAD_META_START:
      return {
        ...state,
        notificationsMetaLoading: true,
        notificationsMetaError: null
      }

    case NOTIFICATIONS__LOAD_META_SUCCESS:
      return {
        ...state,
        notificationsMetaLoading: false,
        notificationsMeta: action.payload
      }

    case NOTIFICATIONS__LOAD_META_ERROR:
      return {
        ...state,
        notificationsMetaLoading: false,
        notificationsMetaError: action.payload
      }
    case NOTIFICATIONS__READ_NOTIFICATION_START:
      return {
        ...state,
        userNotifications: state.userNotifications.map(
          notif => {
            if (notif.id !== action.payload) {
              return notif
            }

            return {
              ...notif,
              isReading: true
            }
          }
        )
      }
    case NOTIFICATIONS__READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        userNotifications: state.userNotifications.map(
          notif => {
            if (notif.id !== action.payload) {
              return notif
            }

            return {
              ...notif,
              isReading: false,
              isRead: true,
              wasJustRead: true
            }
          }
        )
      }
    case NOTIFICATIONS__READ_NOTIFICATION_ERROR: {
      return {
        ...state,
        userNotifications: state.userNotifications.map(
          notif => {
            if (notif.id !== action.payload.notificationId) {
              return notif
            }

            return {
              ...notif,
              isReading: false,
              isRead: false
            }
          }
        )
      }
    }

    case NOTIFICATIONS__RESET_NB_CALL:
      return {
        ...state,
        nbCallNotifications: 0
      }

    case NOTIFICATIONS__UPDATE_PAGINATION_PARAMS:
      return {
        ...state,
        paginationParams: action.payload
      }

    default:
      return state
  }
}
