import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  startLoadingFacilitatorByProject,
  startLoadingSellerByProject
} from './actions/project'
import { selectedProjectSelector, userSelector } from './selectors'

const ShowHideXeno = () => {
  const dispatch = useDispatch()

  const { profile } = useSelector(userSelector)
  const selectedProject = useSelector(selectedProjectSelector)
  const currentProjectId = selectedProject?.id

  React.useEffect(() => {
    if (profile && currentProjectId) {
      dispatch(startLoadingFacilitatorByProject(currentProjectId))
      dispatch(startLoadingSellerByProject(currentProjectId))
    }
  }, [profile, currentProjectId])

  return <div style={{ display: 'none' }}></div>
}

export default ShowHideXeno