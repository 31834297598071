import React, { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { ModalOverlayContent } from '../container-overlay/styled'

import LoadingView from '../view-loading'

const Image = styled('img')`
  max-height: 100%;
  max-width: 100%;
`

class ImageOverlay extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
    this.handleLoadEnd = this.handleLoadEnd.bind(this)
  }

  handleLoadEnd () {
    this.setState({ loading: false })
  }

  render () {
    return (
      <ModalOverlayContent noScroll={this.state.loading}>
        {this.state.loading && <LoadingView />}
        <Image onLoad={this.handleLoadEnd} src={this.props.src} />
      </ModalOverlayContent>
    )
  }
}

ImageOverlay.propTypes = {
  src: PropTypes.string.isRequired
}

export default ImageOverlay
