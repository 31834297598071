const styles = (props) => {
  const {
    theme: { colors, fonts }
  } = props

  return {
    option: (provided, state) => {
      const { isSelected } = state
      return {
        ...provided,
        position: 'relative',
        width: 'fit-content',
        borderRadius: 3,
        color: isSelected ? colors.white : colors.primary,
        backgroundColor: isSelected ? colors.primary : colors.white,
        fontSize: 14,
        fontFamily: fonts.workSans,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        '&:hover, &:focus': {
          backgroundColor: isSelected ? colors.primary : 'rgba(0,21,86,0.04)'
        },
        '&:before, &:after': {
          position: 'relative',
          content: '""',
          display: 'block',
          marginRight: 10
        },
        '&:before': {
          minWidth: 10,
          minHeight: 10,
          border: `0.5px solid ${isSelected ? colors.white : colors.primary}`
        },
        '&:after': {
          position: 'absolute',
          top: '11px',
          left: '14px',
          display: isSelected ? 'block' : 'none',
          width: 6,
          height: 8,
          border: `0.5px solid ${colors.white}`,
          borderLeft: 0,
          borderTop: 0,
          transform: 'rotate(45deg)'
        },
      }
    },
    control: () => ({
      overflow: 'hidden',
      minHeight: 32,
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      border: `0.5px solid ${colors.primary}`,
      borderRadius: 3,
      '& *': {
        fontSize: 14,
        fontFamily: fonts.workSans
      }
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1

      return {
        ...provided,
        opacity,
        color: colors.primary,
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
        margin: 0
      }
    },
    placeholder: (provided) => ({
      ...provided,
      color: colors.placeholder,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px'
    }),
    valueContainer: (provided, state) => {
      return {
        ...provided,
        paddingLeft: state.isMulti ? 5 : 15,
        color: colors.primary
      }
    },
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      paddingRight: 15,
      '& div, & span': {
        display: 'none'
      },
      position: 'relative',
      '& svg': {
        color: colors.primary,
        display: 'none'
      },
      '&:before': {
        content: '""',
        display: 'block',
        height: 0,
        width: 0,
        borderRight: '6px solid transparent',
        borderLeft: '6px solid transparent',
        borderTop: `8px solid ${colors.primary}`
      }
    }),
    menu: (provided) => ({
      ...provided,
      margin: 0,
      padding: '7px 10px',
      boxShadow: `0px 0px 6px ${colors.black}29`,
      borderRadius: 5,
      backgroundColor: colors.white,
      '& *': {
        fontSize: 14,
        fontFamily: fonts.workSans
      }
    }),
    multiValue: (provided, state) => ({
      ...provided,
      height: 26,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 3,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '16px',
      fontFamily: fonts.workSans,
      backgroundColor: colors.primary,
      margin: '0 5px',
      '& *': {
        color: colors.white
      },
      '&:nth-of-type(1)': {
        marginLeft: 0,
        paddingRight: props.name === "project-types" ? 0 : 4
      },
      '& > div:nth-of-type(2)': {
        display: props.name === "project-types" ? 'inherit' : 'none'
      }
    })
  }
}

export default styles
