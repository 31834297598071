import { faTimes } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { useDispatch } from 'react-redux'

import { closeOverlay } from '../../actions/overlay'
import CustomConfirmOverlay from '../overlay-custom-confirm'
import styled from '@emotion/styled'

const Body = styled.p`
  max-width: 424px;
  min-height: 95px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`

const TerrealAccountActivationOverlay = () => {
    const dispatch = useDispatch()

    const handleCloseModal = () => {
        dispatch(closeOverlay())
    }

    return (
        <CustomConfirmOverlay
            cancelActionOnly={true}
            title="Pensez à prendre RDV"
            body={
                <Body>
                    Bonjour,
                    <br />
                    <br />
                    Vous avez reçu un e-mail pour prendre rdv avec votre facilitateur, il vous expliquera le fonctionnement de la plateforme ainsi que les étapes jusqu'à obtenir votre autorisation.
                    <br />
                    <br />
                    Merci
                </Body>
            }
            rejectText="Fermer"
            rejectIcon={faTimes}
            handleReject={handleCloseModal}
            handleConfirm={() => { }}
        />
    )
}

export default TerrealAccountActivationOverlay