import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { titleFontSizeCss } from '../../styles/common'
import { mq, reverseMq } from '../../utils/style'

// Global page responsive styles
export const globalContainerCss = (props) => css`
  margin: 0 auto;
  ${mq(props)({
  maxWidth: [null, '1008px'],
  width: [null, '1008px']
})}
`

// Global page responsive styles
export const globalFluidContainerCss = () => css`
  max-width: none;
  width: auto;
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (min-width: 769px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`

// Flex styles
export const FlexRow = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
  min-height: 0;
`

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`

// Button styles
export const baseButtonCss = (props) => css`
  padding: ${props.theme.spacings.small}px;
  font-family: ${props.theme.fonts.workSans};
  text-align: center;
  outline: none;

  &:disabled {
    opacity: 0.7;
  }
`

export const BaseButton = styled('button')`
  ${baseButtonCss}
`

export const buttonCss = (props) => css`
  ${baseButtonCss(props)}
  margin: ${props.theme.spacings.small}px;
  color: ${props.theme.colors.light};
  border-radius: ${props.theme.radiuses.normal};
  background-color: ${props.color};
  border: 2px solid ${props.color};
`

export const Button = styled('button')`
  ${buttonCss}
`

export const ghostButtonCss = (props) => css`
  ${buttonCss}
  color: ${props.theme.colors.mediumGray};
  transition: 0.2s;

  :not(:hover) {
    background-color: transparent;
  }

  :hover {
    color: #fff;
  }
`

export const GhostButton = styled('button')`
  ${ghostButtonCss}
`

export const InvisibleButton = styled('button')`
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
`

// Link exports
export const BaseLink = styled('a')`
  ${baseButtonCss}
`

export const Link = styled('a')`
  ${buttonCss}
`

// HomeBlock, used for view-home
export const HomeBlock = styled(FlexColumn)`
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 25px 25px 0 25px;
  text-align: ${({ centerOnMobile }) => (centerOnMobile ? 'center' : 'left')};
  border: 1px solid ${({ theme }) => theme.colors.beige};
  box-shadow: ${({ theme }) => theme.boxshadow.primary};

  @media screen and (min-width: 769px) {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    overflow: auto;
    padding: 15px 15px 0 15px;
  }
`

export const HomeBlockTitle = styled('h3')`
  /* font-size: 1.125em; */
  ${titleFontSizeCss}
  line-height: 1.313em;
  font-weight: 700;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 2;
`

export const HomeBlockContent = styled.div`
  font-weight: 300;
  position: relative;
  display: inline-flex;
  flex: 1;
  z-index: 1;
  padding-top: 3px;

  ${(props) =>
    reverseMq(props)({
      WebkitOverflowScrolling: [null, 'touch']
    })}
  @media screen and (min-width: 1024px) {
    overflow-y: hidden;
    & :hover {
      overflow-y: overlay;
    }
    @-moz-document url-prefix() {
      overflow-y: auto;
    }
  }
`

export const homeBlockDetailBlockCss = (props) => css`
  &:not(:last-of-type) {
    padding-bottom: ${props.theme.spacings.medium}px;
  }
  &:not(:first-of-type) {
    padding-top: ${props.theme.spacings.medium}px;
  }
`

export const CloseButton = styled(BaseButton)`
  position: absolute;
  color: ${(props) => props.theme.colors.medium};
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  z-index: 4;
`
