import { css } from '@emotion/core'

export const titleFontSizeCss = ({ theme }) => css`
  font-size: ${theme.fontSizes.title.small}px;

  @media screen and (min-width: 1025px) {
    font-size: ${theme.fontSizes.title.medium}px;
  }
`

export const textFontSizeCss = ({ theme }) => css`
  font-size: ${theme.fontSizes.text.small}px;

  @media screen and (min-width: 1025px) {
    font-size: ${theme.fontSizes.text.medium}px;
  }
`

export const descriptionFontSizeCss = ({ theme }) => css`
  font-size: ${theme.fontSizes.description.small}px;

  @media screen and (min-width: 1025px) {
    font-size: ${theme.fontSizes.description.medium}px;
  }
`
