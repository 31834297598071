import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import { SwiperSlide } from 'swiper/react'
import DetailsContainer from '../../container-details'
import FilesContainer from '../../container-files'
import MapContainer from '../../container-map'
import StatusContainer from '../../container-status'
import MyActions from '../../my-actions'
import SwiperStyled from '../../swiper/SwiperStyled'
import { StepsRenderer } from '../index'
import { projectBodyGapCss } from '../styled'
import { flexColCss } from './styled'
import PropTypes from 'prop-types'

const slideContentWrapperCss = () => css`
  ${flexColCss()}
  ${projectBodyGapCss()}
  width: 100%;
  height: 100%;
`

export const FirstSlideContentWrapper = styled.div`
  ${slideContentWrapperCss}

  & > div:nth-of-type(1) {
    min-height: 33%;
  }

  & > div:nth-of-type(2) {
    min-height: 33%;
  }

  & > div:nth-of-type(3) {
    min-height: 33%;
    height: unset;
  }
`

export const SecondSlideContentWrapper = styled.div`
  ${slideContentWrapperCss}

  & > div:nth-of-type(1) {
    min-height: 300px;
  }

  & > div:nth-of-type(2) {
    min-height: 226px;
  }
`

export const ThirdSlideContentWrapper = styled.div`
  ${slideContentWrapperCss}
`

const DashboardMobile = ({ project }) => {
  return (
    <SwiperStyled>
      <SwiperSlide>
        <FirstSlideContentWrapper>
          <MapContainer project={project} />
          <DetailsContainer project={project} />
          <StatusContainer project={project} />
        </FirstSlideContentWrapper>
      </SwiperSlide>
      <SwiperSlide>
        <SecondSlideContentWrapper>
          <MyActions />
          <FilesContainer project={project} />
        </SecondSlideContentWrapper>
      </SwiperSlide>
      <SwiperSlide>
        <ThirdSlideContentWrapper>
          <StepsRenderer />
        </ThirdSlideContentWrapper>
      </SwiperSlide>
    </SwiperStyled>
  )
}

DashboardMobile.propTypes = {
  project: PropTypes.object.isRequired
}

export default DashboardMobile
