import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef } from 'react'
import chroma from 'chroma-js'
import { textFontSizeCss } from '../../styles/common'
import { mq } from '../../utils/style'
import { ErrorMessageSmall } from '../page-error'
import { HomeBlockContent } from '../styled'

const ForwardedFontAwesomeIcon = forwardRef((props, ref) => (
  <FontAwesomeIcon {...props} forwardedRef={ref} />
));

export const SingleDetail = styled('div')`
  white-space: pre-wrap;
  width: 100%;
  margin-bottom: 0px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
`
export const DetailTitle = styled('h4')`
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 0.75em;
  color: ${({ theme }) => chroma(theme.colors.primary)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (min-width: 769px) {
    justify-content: flex-start;
  }

  @media screen and (min-width: 1025px) {
  }

  @media screen and (min-width: 1441px) {
    line-height: 1em;
  }
`

export const DetailContainer = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`

export const DetailContent = styled('p')`
  color: ${({ theme }) => chroma(theme.colors.primary).alpha(0.45).hex()};
  font-weight: 500;
  font-size: 12px;
  line-height: 0.875em;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  padding: 0 5px 0 0;

  @media screen and (min-width: 1441px) {
    /* font-size: 1em; */
    line-height: 1.188em;
  }
  @media screen and (min-width: 1024px) {
    overflow-y: overlay;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    width: auto;
    max-width: unset;
    margin 5px auto;
    font-size: 14px;
    text-overflow: unset;
  }
`

export const EditProjectNameIcon = styled(FontAwesomeIcon)`
  display: block;
  width: 11px !important;
  height: 11px !important;
  color: ${(props) => props.disabled ? props.theme.colors.medium : props.theme.colors.primary};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`

export const RedirectProjectNameIcon = styled(ForwardedFontAwesomeIcon)`
    width: 12px !important;
    height: 12px !important;
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 3px;
`

export const SaveProjectNameIcon = styled(FontAwesomeIcon)`
  color: ${({ disabled, theme }) => disabled ? theme.colors.medium : `rgba(110, 184, 148, 0.15)`};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  --fa-primary-color: #6eb894;
  --fa-secondary-opacity: 1;
  font-size: 1.5em;
  width: 25px !important;
  height: 25px !important;
  margin-left: 3px;
`

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & input {
    width: 193px;
    display: flex;
    align-items: center;
    font-family: ${props => props.theme.fonts.workSans};
    font-size: 1em;
    font-weight: 500;
  }
`

export const FormContainerProject = styled(FormContainer)`
  width: 60%;
  ${(props) =>
    mq(props)({
      width: ['max-content', 'calc(100% - 30px)'],
      margin: ['0 auto', '0']
    })}
`

export const Input = styled.input`
  border: 0.5px solid ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  min-width: 116px;

  &:focus,
  &:focus-visible {
    outline: none;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    font-family: Gotham;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.colors.primary};
  }

  width: max-content;
  height: 25px;
  padding: 5px;

  ${(props) =>
    mq(props)({
      marginLeft: ['3px', 0]
    })}
`

export const ErrorMessage = styled(ErrorMessageSmall)`
  margin-top: 5px;
`

export const HomeBlockDetails = styled(HomeBlockContent)`
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 0px;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`