export const fakeFileList = [
  {
    id: '1vQ4EzbyGSfmFRzL7ikTZQ4ZirXaG3C1c',
    name: 'Urbanisme',
    type: 'application/vnd.google-apps.folder',
    isFolder: true,
    isRoot: true,
    parent: null,
    canRead: true,
    canWrite: false,
    createdAt: '2020-07-03T15:39:39.565Z',
    index: 4000
  },
  {
    id: '1BIO_Qy0_Iw1L_yy8EO6ngJTP3U_7ThHI',
    name: 'Production du dossier',
    type: 'application/vnd.google-apps.folder',
    isFolder: true,
    isRoot: true,
    parent: null,
    canRead: true,
    canWrite: false,
    createdAt: '2020-07-03T15:39:39.565Z',
    index: 2000
  },
  {
    id: '1vbkQECnbPI1ojZuExmQdELgOQ_4yKbta',
    name: 'Contrat & Facturation',
    type: 'application/vnd.google-apps.folder',
    isFolder: true,
    isRoot: true,
    parent: null,
    canRead: true,
    canWrite: false,
    createdAt: '2020-07-03T15:39:39.565Z',
    index: 2
  },
  {
    id: '1LZLVSFWWg2kcQCn23z05xmW03NaZTdWa',
    name: 'Mes documents',
    type: 'application/vnd.google-apps.folder',
    isFolder: true,
    isRoot: true,
    parent: null,
    canRead: true,
    canWrite: true,
    createdAt: '2020-07-03T15:39:39.565Z',
    index: 1000
  },
  {
    id: '1kAQZZG4FhxQPj_ffP9RVZf5ToKLS0NO_',
    name: 'Documents reçus de la mairie',
    type: 'application/vnd.google-apps.folder',
    isFolder: true,
    isRoot: true,
    parent: null,
    canRead: true,
    canWrite: false,
    createdAt: '2020-07-03T15:39:39.565Z',
    index: 3000
  },
  {
    id: '10L0SLKsN3oEZ7MDCRf945_Wx6d-PsRzj',
    name: 'Mandat - Eric RAJAONARISON.pdf',
    type: 'application/pdf',
    isFolder: false,
    isRoot: false,
    parent: '1vbkQECnbPI1ojZuExmQdELgOQ_4yKbta',
    createdAt: '2023-03-08T17:16:29.255Z',
    index: 5
  },
  {
    id: '16jKPV_ogHLibisXD6qQ6b7KApHGheITB',
    name: "Conditions d'exécution du mandat - Eric RAJAONARISON.pdf",
    type: 'application/pdf',
    isFolder: false,
    isRoot: false,
    parent: '1vbkQECnbPI1ojZuExmQdELgOQ_4yKbta',
    createdAt: '2023-03-08T17:16:29.231Z',
    index: 6
  },
  {
    id: '1kvQpTzaBKVN4QujswHXq3BVZwRytRYJu',
    name: "Conditions d'exécution du mandat - Eric RAJAONARISON.pdf",
    type: 'application/pdf',
    isFolder: false,
    isRoot: false,
    parent: '1vbkQECnbPI1ojZuExmQdELgOQ_4yKbta',
    createdAt: '2023-03-08T15:15:33.936Z',
    index: 7
  },
  {
    id: '1weDW8rq5g-m7xsGkFQDpQ0YzNylM3Yzs',
    name: 'Mandat - Eric RAJAONARISON.pdf',
    type: 'application/pdf',
    isFolder: false,
    isRoot: false,
    parent: '1vbkQECnbPI1ojZuExmQdELgOQ_4yKbta',
    createdAt: '2023-03-08T15:15:33.833Z',
    index: 8
  },
  {
    id: '1_9JwoVVwIHLGTczS85ZAhMiiWSiIcmwo',
    name: "Conditions d'exécution du mandat - Eric RAJAONARISON.pdf",
    type: 'application/pdf',
    isFolder: false,
    isRoot: false,
    parent: '1vbkQECnbPI1ojZuExmQdELgOQ_4yKbta',
    createdAt: '2023-03-08T14:47:15.667Z',
    index: 9
  },
  {
    id: '1aAF7NRzdLKd0A7OZ5b12uFC2GSgWONxV',
    name: 'Mandat - Eric RAJAONARISON.pdf',
    type: 'application/pdf',
    isFolder: false,
    isRoot: false,
    parent: '1vbkQECnbPI1ojZuExmQdELgOQ_4yKbta',
    createdAt: '2023-03-08T14:47:15.593Z',
    index: 10
  }
]
