import {
  GEOCODING__ADDRESS_START,
  GEOCODING__ADDRESS_SUCCESS,
  GEOCODING__ADDRESS_ERROR,
  GEOCODING__TOGGLE_SUGGESTIONS,
  GEOCODING__OPEN_SUGGESTIONS,
  GEOCODING__CLOSE_SUGGESTIONS,
} from '../actions'

const initialState = {
  suggestions: null,
  suggestionsOpened: false,
  geocodeAddressLoading: false,
  geocodeAddressError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GEOCODING__ADDRESS_START:
      return {
        ...state,
        geocodeAddressLoading: true,
        geocodeAddressError: null,
        suggestions: null,
      }

    case GEOCODING__ADDRESS_SUCCESS:
      return {
        ...state,
        geocodeAddressLoading: false,
        suggestions: action.payload,
      }

    case GEOCODING__ADDRESS_ERROR:
      return {
        ...state,
        geocodeAddressLoading: false,
        geocodeAddressError: action.payload,
      }

    case GEOCODING__TOGGLE_SUGGESTIONS:
      return {
        ...state,
        suggestionsOpened: !state.suggestionsOpened,
      }

    case GEOCODING__OPEN_SUGGESTIONS:
      return {
        ...state,
        suggestionsOpened: true,
      }
    case GEOCODING__CLOSE_SUGGESTIONS:
      return {
        ...state,
        suggestionsOpened: false,
        // Empty suggestions
        suggestions: null,
      }

    default:
      return state
  }
}
