import 'react-datepicker/dist/react-datepicker.css'

import React from 'react'
import { DatepickerInputStyled } from './styled'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'

registerLocale('fr-FR', fr)

const DatepickerInput = ({ className, ...props }) => {
    return (
        <DatepickerInputStyled className={className}>
            <ReactDatePicker {...props} locale="fr-FR" />
        </DatepickerInputStyled>
    )
}

export default DatepickerInput