import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { FlexColumn } from '../styled'

import PropTypes from 'prop-types'

const wheelAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const SpinnerWrapper = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: ${(props) => props.size || props.theme.spinner.size.mobile}px;
  min-width: ${(props) => props.size || props.theme.spinner.size.mobile}px;

  @media (min-width: 769px) {
    min-height: ${(props) => props.size || props.theme.spinner.size.desktop}px;
    min-width: ${(props) => props.size || props.theme.spinner.size.desktop}px;
  }
`

const SpinnerWheel = styled('div')`
  width: ${(props) => props.size || props.theme.spinner.size.mobile}px;
  height: ${(props) => props.size || props.theme.spinner.size.mobile}px;
  border: 3px solid ${(props) => props.theme.colors.semiLight};
  border-top-color: ${(props) => props.theme.colors.primary};
  border-right-color: ${(props) => props.theme.colors.primary};

  @media (min-width: 769px) {
    width: ${(props) => props.size || props.theme.spinner.size.desktop}px;
    height: ${(props) => props.size || props.theme.spinner.size.desktop}px;
    border: 5px solid ${(props) => props.theme.colors.semiLight};
    border-top-color: ${(props) => props.theme.colors.primary};
    border-right-color: ${(props) => props.theme.colors.primary};
  }

  border-radius: 50%;
  animation: ${wheelAnimation} 0.8s ease-in-out infinite;
`

const LoadingSpinner = (props) => (
  <SpinnerWrapper size={props.size || null}>
    <SpinnerWheel
      size={props.size || null}
      style={{ borderWidth: props.borderWidth }}
    />
  </SpinnerWrapper>
)

export const LoadingSpinnerSmall = (props) => (
  <SpinnerWrapper size={props.size || null} style={{ width: 'fit-content' }}>
    <SpinnerWheel
      size={props.size || null}
      style={{ borderWidth: props.borderWidth }}
    />
  </SpinnerWrapper>
)

const propType = {
  size: PropTypes.number,
  borderWidth: PropTypes.number
}

LoadingSpinner.propType = propType

LoadingSpinnerSmall.propType = propType

export default LoadingSpinner
