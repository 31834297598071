import { Global } from '@emotion/core'
import styled from '@emotion/styled'
import * as Sentry from '@sentry/browser'
import { ThemeProvider } from 'emotion-theming'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { closeOptionsMenu } from './actions/menus'
import AlertsContainer from './components/container-alerts'
import OverlayContainer from './components/container-overlay'
import SidebarContainer from './components/container-sidebar'
import MainPageSwitch from './components/pageswitch-main'
import { FlexColumn } from './components/styled'
import {
  sidebarWrapperWidthLarge,
  sidebarWrapperWidthMedium
} from './components/view-sidebar/styled'
import { headerHeight } from './constants/styles'
import ReduxProvider, { ConnectedRouter as Router } from './redux-provider'
import globalStyle from './styles/global'
import resetStyle from './styles/reset'
import theme from './theme'
import { mq } from './utils/style'
import ShowHideXeno from './xeno'

import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/fr'
import LoadingView from './components/view-loading'

dayjs.locale('fr')
dayjs.extend(RelativeTime)

const AppWrapper = styled(FlexColumn)`
  height: 100%;
  flex-direction: column-reverse;
  ${(props) =>
    mq(props)({
      flexDirection: [null, 'row']
    })}
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    height: 100vh;
  }
`

const MainWrapper = styled.main`
  height: calc(100vh - ${headerHeight}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin: 0;

  @media screen and (min-width: 1025px) {
    width: calc(100vw - ${sidebarWrapperWidthMedium}px);
    margin: ${headerHeight}px 0 0 ${sidebarWrapperWidthMedium}px;
  }

  @media screen and (min-width: 1441px) {
    width: calc(100vw - ${sidebarWrapperWidthLarge}px);
    margin: ${headerHeight}px 0 0 ${sidebarWrapperWidthLarge}px;
  }
`

class _AppContainer extends Component {
  constructor(props) {
    super(props)
    this.handleClosings = this.handleClosings.bind(this)
  }

  handleClosings() {
    if (this.props.optionsMenuOpened) {
      this.props.closeOptionsMenu()
    }
  }

  render() {
    return this.props.loading ? (
      <LoadingView />
    ) : (
      <AppWrapper onClick={this.handleClosings}>
        <SidebarContainer />
        <MainWrapper>
          <MainPageSwitch />
        </MainWrapper>
      </AppWrapper>
    )
  }
}

const mapStateToProps = ({ user, menus }) => ({
  optionsMenuOpened: menus.optionsMenuOpened,
  isAuthenticated: !!user.accessToken,
  email: (user.profile && user.profile.email) || null,
  userId: (user.profile && user.profile.id) || null,
  userName:
    (user.profile &&
      [user.profile.firstName, user.profile.lastName]
        .filter((x) => x)
        .join(' ')) ||
    null,
  loading: user.profileLoading
})

const mapDispatchToProps = (dispatch) => ({
  closeOptionsMenu: () => dispatch(closeOptionsMenu())
})

_AppContainer.propTypes = {
  optionsMenuOpened: PropTypes.bool.isRequired,
  closeOptionsMenu: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  email: PropTypes.string,
  userId: PropTypes.number,
  userName: PropTypes.string
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(_AppContainer)

class App extends Component {
  componentDidCatch(err) {
    console.error('Error from component')
    console.error(err)
    Sentry.captureException(err)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Global styles={resetStyle} />
        <Global styles={globalStyle} />
        <ReduxProvider>
          <AlertsContainer />
          <OverlayContainer />
          <Router>
            <ShowHideXeno />
            <AppContainer />
          </Router>
        </ReduxProvider>
      </ThemeProvider>
    )
  }
}

export default App
