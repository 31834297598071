import styled from '@emotion/styled'
import chroma from 'chroma-js'
import { descriptionFontSizeCss, textFontSizeCss } from '../../styles/common'
import { HomeBlockContent, HomeBlockTitle } from '../styled'

export const StatusHomeBlockTitle = styled(HomeBlockTitle)`
  text-align: center;

  @media screen and (min-width: 769px) {
    text-align: left;
  }
`

export const StatusHomeBlockContent = styled(HomeBlockContent)`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: 769px) {
    align-items: flex-start;
  }
  @media screen and (max-width: 1023px) {
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

export const StatusBadge = styled.div`
  height: fit-content;
  background: ${({ colors }) => colors.bgColor};
  border-radius: 3px;

  font-weight: 500;
  /* font-size: 0.75em; */
  ${textFontSizeCss}
  line-height: 0.875em;

  color: ${({ colors }) => colors.color};
  width: max-content;
  padding: 12px 12.5px;
  display: flex;
  align-items: center;

  @media (min-width: 1025px) {
    /* font-size: 0.813em; */
  }

  @media (min-width: 1441px) {
    /* font-size: 1em; */
    line-height: 1.188em;
  }
`

export const StatusIndicator = styled.p`
  font-weight: 500;
  /* font-size: 0.625em; */
  /* line-height: 0.75em; */
  ${descriptionFontSizeCss}
  line-height: normal;
  text-align: center;
  color: ${({ theme }) => chroma(theme.colors.primary).alpha(0.45).hex()};

  @media screen and (min-width: 769px) {
    text-align: left;
  }
`
