import {
  DEALSTAGES,
  actionRequise,
  montageDossier,
  piecesComplementaires,
  refusDossier,
  validationDossier,
  fallback
} from '../page-project-list/helpers'

const dealStageKeys = Object.keys(DEALSTAGES)

export const dealStageArray = dealStageKeys.map((key) => ({
  key: key,
  value: DEALSTAGES[key],
}))

const paiementEnAttenteKeys = [
  'signup',
  'prospects',
  'callTrying',
  'callScheduled',
  'callMissed',
  'conversation',
  'callScheduled2',
  'callMissed2',
  'conversation',
  'conversation2',
  'closing',
  'onRadar',
  'payementSetup',
]

const formulaireDeRetourKeys = [
  "caseV1Feedback",
  "caseV2Feedback",
  "caseV3Feedback",
  "mayorRequestV1Feedback",
  "mayorRequestV2Feedback",
  "mayorRequestUpload",
  "panelReceived",
  'waitFeedbackHabV1',
  'waitFeedbackHabV2',
  'waitFeedbackHabV3',
  'waitFeedbackHabDPCV1',
  'waitFeedbackHabDPCV2',
  'caseV1SentToCustomer',
  'caseV2SentToCustomer',
  'caseV3SentToCustomer',
  'caseV1FeedbackReceived',
  'caseV2FeedbackReceived',
  'caseV3FeedbackReceived',
  'caseV3CraftingExternal',
  'modV1SentToCustomer',
  'modV1FeedbackReceived',
  'finalisationInitial',
  'finalisationDPC',
  'caseFinalCraftingExternal',
]

const connectionAndConsuelKeys = [
  "connectionTempo",
  "connectionWaitingForm",
  "connectionNew",
  "connectionSent",
  "connectionRequest",
  "connectionSet",
  "connectionRefused",
  "connectionValidated",
  "consuelWaitingCertificate",
  "consuelFeedback",
  "connectionConsuelCertificateOrdered",
  "connectionConsuelRequest",
  "connectionConsuelSet",
  "connectionNewProcedure",
  "connectionError",
  "connectionConsuelRefused",
  "connectionComplete",
  "connectionMayorRequestReceived",
  "connectionReady",
  "connectionWaitingForValidation",
  "connectionNotSupported"
]

const preparationExpeditionKeys = [
  'preparingPrint',
  'readyToPrint',
  'printInProgress',
  'dpcPreparingPrint',
  'dpcReadyToPrint',
  'dpcPrintInProgress',
  'printing',
  'casePrinting',

  "preparingDispatch",
  "preparingDematerializedDispatch",
  "dispatchOrdered",
  'preparingPrint',
  'casePrinted',
]

const enInstructionKeys = [
  'caseExaminating',
  'caseUnderInvestigation'
]

const enAnalyseKeys = [
  "analyzingMayorRequest",
  "adviceCallMayorRequest",
  'dpcAnalyzing',
  'dpcCallAdvice',
  'modAnalyzing',
  'modRequired',
]

const enProductionKeys = [
  'caseV1Crafting',
  'productionV1',
  'productionV2',
  'productionV3',
  'dpcV1',
  'dpcV2',
  'caseV1CraftingExternal',
]

const enModificationKeys = [
  'caseV2Crafting',
  'caseV3Crafting',
  'caseV2CraftingExternal',
]

const enProductionComplementKeys = [
  "mayorRequestV1Crafting",
  "mayorRequestAnnex",
  "modV1CraftingExternal",
]

const expeditionComplementKeys = [
  "mayorRequestSent",
  "mayorRequestReceived",
  "mayorRequestPreparingDispatch",
  "mayorRequestPreparingDematerializedDispatch",
  "mayorRequestDispatchOrdered",
  "mayorRequestPrinting",
  'modPrepartingPrint',
  'modSent',
  'modPrinting',
  'modPrinted',
  'modReceived',
  'preparingDematerializedDispatch',
  'prepareDematerialization',
  'modAnalyzingDematerialized',
  'prepareDematerialization',
  'modDematerialization',
  'modDematerializatedReceived',
  'modReceivedExpired',
]

const refusAnalyseKeys = [
  "refusalAnalysis",
  'caseAnalysingDeny',
  'callAdviceDeny',
  'deskDenied',
  'caseProcessedDenied',
]

const refusCallKeys = [
  "refusalCall",
  'caseAnalysingDeny',
]

const envoiDuPanneauKeys = [
  "panelPreparing",
  "panelReady",
  "panelSent",
  "panelReceived",
  'validPushQR',
  'panelInPreparation',
  'permitBoardPreparing',
  'permitBoardPrepared',
  'permitBoardSent',
  'permitBoardReceived',
]

const enDemandeValideKeys = [
  "accepted",
  "qrCodeCheck",
  "qrCodeOrdering",
  "qrCodeDispatch",
  'caseProcessedAccepted',
]

const termineKeys = [
  "panelSet",
  'permitBoardInstalled',
  'mayorDeny',
]

const electraKeys = [
  'ProdEtudefaisa',
  'AttRetourEtudefaisa',
  'ProdAPR',
  'AttRetourProdAPR',
  'Prod3D',
  'AttRetour3D',
]

const getValues = (keys) => {
  const values = dealStageArray
    .filter((stage) => keys.includes(stage.key))
    .map((stage) => stage.value)
  return values
}

export const getData = (key, isBtoB) => {
  console.log('ici là')
  console.log(key)
  if (paiementEnAttenteKeys.includes(key)) {
    return {
      title: 'Attente paiement',
      color: '#999999',
      bgColor: fallback.bgColor,
      group: 'attentePaiement',
      values: getValues(paiementEnAttenteKeys),
      order: 1,
      client: ['BtoC']
    }
  }

  if (formulaireDeRetourKeys.includes(key)) {
    return {
      title: 'Attente retour',
      color: actionRequise.color,
      bgColor: actionRequise.bgColor,
      group: 'actionRequise',
      values: getValues(formulaireDeRetourKeys),
      order: 4,
      client: ['BtoC', 'BtoB']
    }
  }

  if (preparationExpeditionKeys.includes(key)) {
    return {
      title: 'Préparation expédition',
      color: montageDossier.color,
      bgColor: montageDossier.bgColor,
      group: 'montageDossier',
      values: getValues(preparationExpeditionKeys),
      order: 9,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }

  if (enInstructionKeys.includes(key)) {
    return {
      title: 'Instruction',
      color: montageDossier.color,
      bgColor: montageDossier.bgColor,
      group: 'montageDossier',
      values: getValues(enInstructionKeys),
      order: 11,
      client: ['BtoC']
    }
  }

  if (enAnalyseKeys.includes(key)) {
    return {
      title: "Analyse complément",
      color: piecesComplementaires.color,
      bgColor: piecesComplementaires.bgColor,
      group: 'piecesComplementaires',
      values: getValues(enAnalyseKeys),
      order: 12,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }

  if (enProductionComplementKeys.includes(key)) {
    return {
      title: "Production complément",
      color: piecesComplementaires.color,
      bgColor: piecesComplementaires.bgColor,
      group: 'piecesComplementaires',
      values: getValues(enProductionComplementKeys),
      order: 13,
      client: ['BtoC']
    }
  }

  if (enProductionKeys.includes(key)) {
    return {
      title: 'Production',
      color: montageDossier.color,
      bgColor: montageDossier.bgColor,
      group: 'montageDossier',
      values: getValues(enProductionKeys),
      order: 6,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }
  if (enModificationKeys.includes(key)) {
    return {
      title: 'Modification',
      color: montageDossier.color,
      bgColor: montageDossier.bgColor,
      group: 'montageDossier',
      values: getValues(enModificationKeys),
      order: 7,
      client: ['BtoC', 'BtoB']
    }
  }

  if (expeditionComplementKeys.includes(key)) {
    return {
      title: "Complément expédié",
      color: montageDossier.color,
      bgColor: montageDossier.bgColor,
      group: 'montageDossier',
      values: getValues(expeditionComplementKeys),
      order: 16,
      client: ['BtoC', 'BtoB']
    }
  }

  if (enDemandeValideKeys.includes(key)) {
    return {
      title: "Demande validée",
      color: validationDossier.color,
      bgColor: validationDossier.bgColor,
      group: 'validationDossier',
      values: getValues(enDemandeValideKeys),
      order: 17,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }
  if (connectionAndConsuelKeys.includes(key)) {
    return {
      title: "Raccordement / Consuel",
      color: montageDossier.color,
      bgColor: montageDossier.bgColor,
      group: 'raccordementConsuel',
      values: getValues(connectionAndConsuelKeys),
      order: 22,
      client: ['BtoB']
    }
  }
  if (electraKeys.includes(key)) {
    return {
      title: "Electra",
      color: montageDossier.color,
      bgColor: montageDossier.bgColor,
      group: 'raccordementConsuel',
      values: getValues(connectionAndConsuelKeys),
      order: 24,
      client: ['BtoB']
    }
  }

  if (refusAnalyseKeys.includes(key)) {
    return {
      title: "Analyse refus",
      color: refusDossier.color,
      bgColor: refusDossier.bgColor,
      group: 'refusDossier',
      values: getValues(refusAnalyseKeys),
      order: 20,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }

  if (refusCallKeys.includes(key)) {
    return {
      title: "Appel refus",
      color: refusDossier.color,
      bgColor: refusDossier.bgColor,
      group: 'refusDossier',
      values: getValues(refusCallKeys),
      order: 21,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }

  if (envoiDuPanneauKeys.includes(key)) {
    return {
      title: "Envoi panneau",
      color: validationDossier.color,
      bgColor: validationDossier.bgColor,
      group: 'validationDossier',
      values: getValues(envoiDuPanneauKeys),
      order: 18,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }

  if (termineKeys.includes(key)) {
    return {
      title: 'Terminé',
      color: validationDossier.color,
      bgColor: validationDossier.bgColor,
      group: 'validationDossier',
      values: getValues(termineKeys),
      order: 19,
      client: ['BtoC', 'BtoB', 'Desk']
    }
  }

  switch (key) {
    case 'newProject':
      return {
        title: 'Création dossier',
        color: '#0C65BE',
        bgColor: fallback.bgColor,
        group: 'fallback',
        values: [DEALSTAGES[key]],
        order: 0,
        client: ['BtoC', 'BtoB', 'Desk']
      }
    case 'formWaiting':
    case 'formOnboarding':
      return {
        title: 'Formulaire départ',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        group: 'actionRequise',
        values: [DEALSTAGES[key]],
        order: 2,
        client: ['BtoC', 'BtoB', 'Desk']
      }
    case 'callOnboarding':
    case 'welcomeCall':
    case 'caseHandledByFacilitator':
      return {
        title: 'Appel de départ',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        group: 'montageDossier',
        values: [DEALSTAGES[key]],
        order: 5,
        client: ['BtoC', 'BtoB', 'Desk']
      }
    case 'waitingForMandate':
    case 'mandateSign':
      return {
        title: "Attente signature",
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        group: 'actionRequise',
        values: [DEALSTAGES[key]],
        order: 3,
        client: ['BtoC']
      }
    case 'thermalStudy':
    case "annex":
      return {
        title: "Étude annexes",
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        group: 'montageDossier',
        values: [DEALSTAGES[key]],
        order: 8,
        client: ['BtoC']
      }
    case 'caseSent':
    case "caseReceived":
    case 'dpcSent':
    case 'dpcReceived':
      return {
        title: "Expédié",
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        group: 'montageDossier',
        values: [DEALSTAGES[key]],
        order: 10,
        client: ['BtoC', 'BtoB', 'Desk']
      }
    case "mayorRequestV2Crafting":
      return {
        title: "Modification complément",
        color: piecesComplementaires.color,
        bgColor: piecesComplementaires.bgColor,
        group: 'piecesComplementaires',
        values: [DEALSTAGES[key]],
        order: 14,
        client: ['BtoC']
      }
    case "mayorRefusal":
      return {
        title: "Refusé",
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        group: 'refusDossier',
        values: [DEALSTAGES[key]],
        order: 22,
        client: ['BtoC', 'BtoB', 'Desk']
      }
    default:
      return {
        title: "Statut introuvable",
        color: fallback.color,
        bgColor: fallback.bgColor,
        group: 'fallback',
        values: [DEALSTAGES[key]],
        order: 23,
      }
  }
}

export const formatDealStages = (isBtoB) => {
  let results = dealStageKeys.map((key) => {
    const data = getData(key, isBtoB)
    console.log("ici")
    console.log(key)
    return { ...data, key }
  })

  results = results.reduce((previous, current) => {
    const existElement = previous.find(({ title }) => title === current.title)
    if (existElement) {
      return previous.map((stage) => {
        if (stage.title === current.title) {
          return {
            ...stage,
            values: [...stage.values, ...current.values],
            keys: stage.keys
              ? [...stage.keys, current.key]
              : [stage.key, current.key],
          }
        }
        return stage
      })
    }

    return [...previous, current]
  }, [])

  return results.sort((a, b) => a.order - b.order)
}

export const groupDealStages = (arrStatus) => {
  const statusMapping = [
    { id: 'actionRequise', title: 'Action requise' },
    { id: 'montageDossier', title: 'Montage dossier' },
    { id: 'piecesComplementaires', title: 'Pièces complémentaires' },
    { id: 'validationDossier', title: 'Validation dossier' },
    { id: 'refusDossier', title: 'Refus dossier' },
    { id: 'attentePaiement', title: 'Attente paiement' },
    { id: 'raccordementConsuel', title: 'Raccordement / Consuel' }
  ]

  const groupedStatus = _.mapValues(
    _.groupBy(arrStatus, 'group'),
    clist => clist.map(status => _.omit(status, 'group'))
  );

  return statusMapping.map((st) => {
    return {
      id: st.id,
      title: st.title,
      items: groupedStatus[st.id] || []
    }
  }).filter(g => g.items.length > 0)
}