import {
  INTERVAL__RESET_ID,
  INTERVAL__SET_ID,
  OVERLAY__ACCOUNT_ACTIVATION,
  OVERLAY__TERREAL_ACCOUNT_ACTIVATION,
  OVERLAY__NEW_PROJECT_ACTIVATION,
  OVERLAY__CLOSE,
  OVERLAY__CLOSE_CREATE_PROJECT_IN_OTHER_OVERLAY,
  OVERLAY__OPEN_CREATE_PROJECT,
  OVERLAY__OPEN_CREATE_PROJECT_IN_OTHER_OVERLAY,
  OVERLAY__OPEN_PROJECT_ADDITIONAL_INFO,
  OVERLAY__SELECT_PROJECTS_LIST,
  OVERLAY__SET_CHANGE_PASSWORD,
  OVERLAY__SET_CONFIRMATION,
  OVERLAY__SET_IFRAME,
  OVERLAY__SET_IMAGE,
  OVERLAY__SET_PDF,
  OVERLAY__SET_TYPEFORM
} from '../actions'

import { OVERLAYS } from '../constants'

const initialOverlayProperties = {
  iframeOverlaySrc: null,
  confirmationActionId: null,
  imageOverlaySrc: null,
  pdfOverlaySrc: null,
  typeformOverlayUrl: null,
  typeformActionId: null,
  intervalID: null
}

const initialState = {
  currentOverlayType: null,
  createProjectOverlayOpened: false,
  ...initialOverlayProperties
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OVERLAY__SET_IFRAME:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.IFRAME,
        iframeOverlaySrc: action.payload
      }

    case OVERLAY__SET_IMAGE:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.IMAGE,
        imageOverlaySrc: action.payload
      }

    case OVERLAY__SET_PDF:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.PDF,
        pdfOverlaySrc: action.payload
      }

    case OVERLAY__SET_TYPEFORM:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.TYPEFORM,
        typeformOverlayUrl: action.payload[0],
        typeformActionId: action.payload[1]
      }

    case OVERLAY__SET_CONFIRMATION:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.CONFIRMATION[action.payload.subKey],
        confirmationActionId: action.payload.actionId
      }

    case OVERLAY__SET_CHANGE_PASSWORD:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.CHANGEPASSWORD
      }

    case OVERLAY__SELECT_PROJECTS_LIST:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.SELECTPROJECTSLIST
      }

    case OVERLAY__CLOSE:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: null
      }

    case INTERVAL__SET_ID:
      return {
        ...state,
        intervalID: action.payload
      }

    case INTERVAL__RESET_ID:
      return {
        ...state,
        intervalID: null
      }
    case OVERLAY__OPEN_CREATE_PROJECT:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.CREATE_PROJECT
      }

    case OVERLAY__OPEN_CREATE_PROJECT_IN_OTHER_OVERLAY:
      return {
        ...state,
        ...initialOverlayProperties,
        createProjectOverlayOpened: true
      }

    case OVERLAY__CLOSE_CREATE_PROJECT_IN_OTHER_OVERLAY:
      return {
        ...state,
        ...initialOverlayProperties,
        createProjectOverlayOpened: false
      }

    case OVERLAY__OPEN_PROJECT_ADDITIONAL_INFO:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.PROJECT_ADDITIONAL_iNFO
      }

    case OVERLAY__ACCOUNT_ACTIVATION:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.ACCOUNT_ACTIVATION
      }

    case OVERLAY__TERREAL_ACCOUNT_ACTIVATION:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.TERREAL_ACCOUNT_ACTIVATION
      }

      case OVERLAY__NEW_PROJECT_ACTIVATION:
      return {
        ...state,
        ...initialOverlayProperties,
        currentOverlayType: OVERLAYS.NEW_PROJECT
      }

    // PROJECT_ADDITIONAL_iNFO

    default:
      return state
  }
}
