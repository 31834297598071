import * as Sentry from '@sentry/browser'

import { takeEvery } from 'redux-saga/effects'
import { SYSTEM__ERROR } from '../actions'

function logError ({ payload: error }) {
  console.error('Error from saga')
  console.error(error)
  Sentry.captureException(error)
}

export default [
  takeEvery(SYSTEM__ERROR, logError)
]
