import {
  SYNC__SESSION_START,
  SYNC__SESSION_STOP
} from '.'

export const startSessionSync = () => ({
  type: SYNC__SESSION_START
})

export const stopSessionSync = () => ({
  type: SYNC__SESSION_STOP
})
