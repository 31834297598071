import styled from '@emotion/styled'
import { mq } from '../../utils/style'
import { MenuText, sidebarImageCss } from '../view-sidebar/styled'

export const SidebarSvgContainer = styled.div`
  ${sidebarImageCss}
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    mq(props)({
      margin: ['0 auto', '0 6px 0 0']
    })}
`

export const HomeText = styled(MenuText)`
  position: relative;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CreateFolderButton = styled.button`
  display: flex;
  align-self: baseline;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 2px;
  min-width: 156px;
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  opacity: 1;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.workSans};
  margin-top: ${(props => props.isBtoB) ? '10px' : '20px'};

  & > span {
    margin-left: 10px;
    margin-right: 10px;
  }

  & > svg {
    width: 20px !important;
    height: 20px !important;
    color: ${({ theme }) => theme.colors.white};
  }

  // & :hover {
  //   background-color: ${({ theme }) => theme.colors.primarySemiLight};
  //   color: ${({ theme }) => theme.colors.primary};
  //   border: 1px solid ${({ theme }) => theme.colors.primary};
  //   & > svg {
  //     color: ${({ theme }) => theme.colors.primary};
  //   }
  // }

`