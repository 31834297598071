import axios from 'axios'

export const getSuggestions = (address) => {
  return axios
    .get(`https://api-adresse.data.gouv.fr/search?q=${address}&autocomplete=1`)
    .then(({ data }) => {
      // Format data for house number
      const houseNumberArr = data.query.split(' ')
      const houseNumberFromQuery =
        houseNumberArr.filter(
          (i) => i.match(/^.{1,4}$/) && i.match(/[0-9]{1,}/)
        )[0] || ''
      const houseNumberBis =
        houseNumberArr
          .map((i) => i.toLocaleLowerCase())
          .find((i) => i === 'bis') || ''

      return {
        ...data,
        features: data.features.map((feature) => {
          if (feature && feature.properties) {
            if (!feature.properties.housenumber) {
              const housenumber =
                `${houseNumberFromQuery}${houseNumberBis}`.trim()
              return {
                ...feature,
                properties: {
                  ...feature.properties,
                  housenumber: housenumber ? housenumber : '',
                },
              }
            }
          }
          return feature
        }),
      }
    })
}
