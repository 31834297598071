import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const withQueryParamStore = (
  queryParamGetter,
  storeDispatcher
) => (SubComponent) => {
  const WithQueryParamStore = class extends Component {
    componentDidMount () {
      const queryParam = queryParamGetter(this.props.match.params, this.state)
      this.props.dispatchFunctionToStore(queryParam)
    }

    render () {
      return <SubComponent {...this.props} />
    }
  }

  WithQueryParamStore.propTypes = {
    retreivedPropFromStore: PropTypes.any,
    dispatchFunctionToStore: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired
    }).isRequired
  }

  const mapDispatchToProps = dispatch => ({
    dispatchFunctionToStore: storeDispatcher(dispatch)
  })

  return connect(null, mapDispatchToProps)(WithQueryParamStore)
}

export default withQueryParamStore
