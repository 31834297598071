import getBrowserLang from 'browser-lang'
import axios from 'axios'
import { push } from 'connected-react-router'
import {
  all, put, call, select, fork, take, takeLatest, takeLeading
} from 'redux-saga/effects'

import PMDC from '../utils/pmdc'
import * as jwt from 'jsonwebtoken'
import AuthClient, { checkSession, parseHash, HelperClient } from '../utils/auth'
import { getCodeFromError, buildErrFromCheckSessionError } from '../utils/error'
import { AUTH0_AVAILABLE_LANGUAGES } from '../constants'
import { loadScript } from '../utils/dom'

import {
  USER__CHECK_SESSION_START,
  USER__CHECK_SESSION_SUCCESS,
  USER__REFRESH_SESSION_START,
  USER__LOGIN_START,
  USER__LOGOUT_START,
  USER__FORCE_LOGIN,
  USER__FETCH_BY_ACCOUNT_START,
  USER__FETCH_BY_ACCOUNT_SUCCESS,
  USER__CONFIRMATION_TOKEN_RETRIEVE_START,
  USER__ACCOUNT_CONFIRMATION_START,
  USER__CHECK_ACCOUNT_LINKS_START,
  USER__CREATE_EMAIL_PASSWORD_ACCOUNT_START,
  USER__CHANGE_PASSWORD_START,
  USER__UPDATE_CONNECTED_AT,
  USER__ACCOUNT_LINK_START,
  USER__CHECK_SESSION_ERROR,
  PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS,
  PROJECT__LOAD_SELLER_BY_PROJECT_ERROR,
  PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR
} from '../actions'

import {
  checkSessionSuccess,
  checkSessionError,
  loginSuccess,
  loginError,
  logoutSuccess,
  logoutError,
  fetchUserByAccountSuccess,
  fetchUserByAccountError,
  confirmationTokenRetrieveSuccess,
  confirmationTokenRetrieveError,
  startAccountConfirmation,
  accountConfirmationSuccess,
  accountConfirmationError,
  checkAccountLinksSuccess,
  checkAccountLinksError,
  createEmailPasswordAccountSuccess,
  createEmailPasswordAccountError,
  passwordChangeSuccess,
  passwordChangeError,
  startLinkingAccount,
  accountLinkingSuccess,
  accountLinkingError,
  forceLogin,
  enableHubspotChat
} from '../actions/user'

import { startSessionSync } from '../actions/sync'

import { addErrorAlert } from '../actions/alert'

import { logError } from '../actions/system'

const QUERY_KEY_CONFIRMATION_TOKEN = window.processRuntime.env.QUERY_KEY_CONFIRMATION_TOKEN

const browserLangForAuth = getBrowserLang({
  languages: AUTH0_AVAILABLE_LANGUAGES,
  fallback: 'fr'
})

const removeHash = () => {
  if ('pushState' in window.history) {
    const url = `${window.location.pathname}${window.location.search}`
    window.history.pushState('', document.title, url)
  } else {
    window.location.hash = ''
  }
}

function* checkUserSession(a) {
  try {
    try {
      const hash = yield call(parseHash)
      if (!hash) {
        throw new Error('CheckSession : No hash could be found')
      }
      // updateOrCreateUser(user.name, user.email, user.phone, user.subscriptionStatus);
      yield put(checkSessionSuccess(hash))
    } catch (err) {
      const session = yield call(checkSession)
      yield put(checkSessionSuccess(session))
    }

    yield (window.location.hash && removeHash())
  } catch (err) {
    yield put(checkSessionError({
      code: getCodeFromError(err),
      message: 'Erreur lors de la vérification de la session'
    }))
    yield put(logError(buildErrFromCheckSessionError(err)))
  }
}

function* loginUser() {
  try {
    const hash = yield call(parseHash)

    if (hash && hash.accessToken) {
      removeHash()
      yield put(loginSuccess(hash))
    } else {
      const { user: { loginOptions } } = yield select()

      const loginUrl = yield HelperClient.buildAuthorizeUrl({
        ...loginOptions,
        responseType: 'token',
        redirectUri: new URL(window.location.href).origin,
        scope: 'openid profile email',
        language: browserLangForAuth,
        audience: window.processRuntime.env.AUTH0_AUDIENCE
      })
      yield window.location.replace(loginUrl)
    }
  } catch (err) {
    yield put(loginError({
      code: getCodeFromError(err),
      message: 'Erreur lors de la connexion'
    }))
    yield put(logError(err))
  }
}

function* forceUserLogin() {
  yield put(push('/login'))
}

function* fetchUserByAccount() {
  try {
    const { user } = yield select()
    const { data } = yield call(PMDC(user.accessToken, user.decodedAccessToken).getUserByAccount)

    const payloadData = {
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      phone: data.phoneNumber,
      subscription_status: 'active',
      externalId: data.accountId
    }
    yield put(fetchUserByAccountSuccess(data))
    yield put(enableHubspotChat())
  } catch (err) {
    yield put(fetchUserByAccountError({
      code: getCodeFromError(err),
      message: 'Erreur lors de la récupération du profil utilisateur'
    }))
    yield put(logError(err))
  }
}



// function * loadXeno () {
//   const { payload: data } = yield take(USER__FETCH_BY_ACCOUNT_SUCCESS)
//   yield all([
//     take([PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS, PROJECT__LOAD_SELLER_BY_PROJECT_ERROR]),
//     take([PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS, PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR])
//   ])

//   const {
//     project: {
//       selectedProjectSeller,
//       selectedProjectFacilitator
//     }
//   } = yield select()
//   const linkedWorker = selectedProjectFacilitator || selectedProjectSeller

//   try {
//     const name = [
//       data.firstName,
//       data.lastName
//     ].filter(x => x).join(' ')

//     const rawXenoId = linkedWorker && linkedWorker.xenoId
//       ?  parseInt(linkedWorker.xenoId, 10)
//       : null

//     const xenoId = rawXenoId //&& (isNaN(rawXenoId) ? linkedWorker.xenoId : rawXenoId)

//     yield window._xenoSettings = {
//       key: window.processRuntime.env.XENO_KEY,
//       identify: function () {
//         if (data.id && data.email && name) {
//           return {
//             id: data.id,
//             email: data.email,
//             name,
//             ...(xenoId ? {
//               interlocuteur: xenoId
//             } : null),
//           }
//         }
//       }
//     }

//     // Prevent Xeno error without Gtag
//     if (!window.gtag) {
//       window.gtag = () => {}
//     }

//     yield loadScript('https://cdn.xeno.app/chat_loader.js')

//     const interval = window.setInterval(() => {
//       if (window._xeno) {
//         window.clearInterval(interval)
//         window._xeno.identifyUser()
//       }
//     }, 1000)
//   } catch (err) {
//     console.warn(err)
//   }
// }

function* logHubspotUser() {
  // const { payload: data } = yield take(USER__FETCH_BY_ACCOUNT_SUCCESS)
  //   yield all([
  //     take([PROJECT__LOAD_SELLER_BY_PROJECT_SUCCESS, PROJECT__LOAD_SELLER_BY_PROJECT_ERROR]),
  //     take([PROJECT__LOAD_FACILITATOR_BY_PROJECT_SUCCESS, PROJECT__LOAD_FACILITATOR_BY_PROJECT_ERROR])
  //   ])

  //   const {
  //     project: {
  //       selectedProjectSeller,
  //       selectedProjectFacilitator
  //     }
  //   } = yield select()
  //   const linkedWorker = selectedProjectFacilitator || selectedProjectSeller
}

function* associateAccountToUser() {
  try {
    const { user } = yield select()
    const { data: projectId } = yield call(PMDC(
      user.accessToken,
      user.decodedAccessToken
    ).associateToken, user.confirmationToken)
    yield put(accountConfirmationSuccess(projectId))
  } catch (err) {
    const message = 'Erreur lors de la confirmation du compte utilisateur'
    yield put(accountConfirmationError({
      code: getCodeFromError(err),
      message
    }))
    yield put(addErrorAlert({ message }))
    yield put(logError(err))
  }
}

function* retrieveConfirmationToken() {
  try {
    const confirmationToken = new URL(window.location)
      .searchParams
      .get(QUERY_KEY_CONFIRMATION_TOKEN)

    if (!confirmationToken) {
      throw new Error('Confirmation token not found in query string')
    }

    yield put(confirmationTokenRetrieveSuccess(confirmationToken))
  } catch (err) {
    yield put(confirmationTokenRetrieveError({
      message: 'Jeton de confirmation introuvable'
    }))
    yield put(logError(err))
  }
}

function* logoutUser() {
  try {
    yield AuthClient.logout({
      returnTo: new URL(window.processRuntime.env.HP_BASE_URL).origin
    })
    yield put(logoutSuccess())
  } catch (err) {
    yield put(logoutError({
      code: getCodeFromError(err),
      message: 'Erreur lors de la déconnexion'
    }))
    yield put(logError(err))
  }
}

function* checkUserLinkedAccounts() {
  try {
    const { user } = yield select()
    const { data: accountId } = yield call(PMDC(
      user.accessToken,
      user.decodedAccessToken
    ).getAccountLinks)

    yield put(checkAccountLinksSuccess(accountId))
    yield put(startAccountConfirmation())
  } catch (err) {
    const isConflict = err.response && err.response.status === 409
    if (isConflict) {
      yield put(startLinkingAccount())
    }

    yield put(checkAccountLinksError(isConflict ? null : {
      code: getCodeFromError(err),
      message: 'Erreur lors de la vérification du compte'
    }))

    yield put(logError(err))
  }
}

function* createEmailPasswordAccount(action) {
  try {
    const { user } = yield select()
    yield call(PMDC(
      user.accessToken,
      user.decodedAccessToken
    ).createEmailPasswordAccount, action.payload)

    yield call(PMDC(
      user.accessToken,
      user.decodedAccessToken
    ).linkAccount)

    yield window.location.replace('/login')
    yield put(createEmailPasswordAccountSuccess())
  } catch (err) {
    yield put(createEmailPasswordAccountError({
      code: getCodeFromError(err),
      message: 'Erreur lors de la création du compte'
    }))
    yield put(logError(err))
  }
}

function* linkUserAccount() {
  try {
    const { user } = yield select()
    yield call(PMDC(
      user.accessToken,
      user.decodedAccessToken
    ).linkAccount)
    yield put(accountLinkingSuccess())
  } catch (err) {
    yield put(accountLinkingError({
      code: getCodeFromError(err),
      message: 'Erreur lors de la liaison du compte'
    }))
    yield put(logError(err))
  }
}

function* changePassword(action) {
  try {
    const password = action.payload

    if (!password) {
      throw new Error('Change Password : payload is falsey or empty')
    }

    const { user } = yield select()
    yield call(PMDC(
      user.accessToken,
      user.decodedAccessToken
    ).changePassword, user.profile.id, action.payload)

    yield put(passwordChangeSuccess())
  } catch (err) {
    yield put(passwordChangeError({
      code: getCodeFromError(err),
      message: 'Erreur lors du changement de mot de passe'
    }))
    yield put(logError(err))
  }
}

function* updateConnectedAt() {
  try {
    const { user } = yield select()
    yield call(PMDC(user.accessToken, user.decodedAccessToken).updateConnectedAt, user.profile.id)
  } catch (err) {
    yield put(logError(err))
  }
}

function* refreshSession() {
  yield fork(checkUserSession)
  yield take(USER__CHECK_SESSION_ERROR)
  yield put(forceLogin())
}

function* startRefresh() {
  yield put(startSessionSync())
}

export default [
  takeLatest(USER__CHECK_SESSION_START, checkUserSession),
  takeLatest(USER__REFRESH_SESSION_START, refreshSession),
  takeLatest(USER__LOGIN_START, loginUser),
  takeLatest(USER__FETCH_BY_ACCOUNT_START, fetchUserByAccount),
  takeLatest(USER__CONFIRMATION_TOKEN_RETRIEVE_START, retrieveConfirmationToken),
  takeLatest(USER__ACCOUNT_CONFIRMATION_START, associateAccountToUser),
  takeLatest(USER__LOGOUT_START, logoutUser),
  takeLatest(USER__FORCE_LOGIN, forceUserLogin),
  takeLatest(USER__CHECK_ACCOUNT_LINKS_START, checkUserLinkedAccounts),
  takeLatest(USER__CREATE_EMAIL_PASSWORD_ACCOUNT_START, createEmailPasswordAccount),
  takeLatest(USER__CHANGE_PASSWORD_START, changePassword),
  takeLatest(USER__UPDATE_CONNECTED_AT, updateConnectedAt),
  takeLatest(USER__ACCOUNT_LINK_START, linkUserAccount),
  takeLeading(USER__CHECK_SESSION_SUCCESS, startRefresh)
]
