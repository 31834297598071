import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { faLockAlt } from '@fortawesome/pro-duotone-svg-icons'

import { startCreatingEmailPasswordAccount } from '../../actions/user'

import LoadingView from '../view-loading'
import SetPasswordView from '../view-set-password'

import {
  SetPasswordWrapper,
  SetPasswordIcon,
  SetPasswordTitle
} from './styled'

const SetPasswordPage = props => [{
  cond: (
    props.hasCredentialsAccount
  ),
  view: () => <Redirect to='/' />
}, {
  cond: props.accountLinkingLoading,
  view: () => <LoadingView />
}, {
  cond: true,
  view: () => (
    <SetPasswordWrapper>
      <SetPasswordTitle>
        <SetPasswordIcon icon={faLockAlt} />
        <br />
        C'est le moment de créer
        <br />
        votre mot de passe !
      </SetPasswordTitle>
      <SetPasswordView
        submitPassword={props.startCreatingEmailPasswordAccount}
        loading={props.createEmailPasswordAccountLoading}
        error={
          props.createEmailPasswordAccountError ||
          props.accountLinkingError
        }
      />
    </SetPasswordWrapper>
  )
}].find(x => x.cond).view()

const mapStateToProps = ({ user }) => ({
  createEmailPasswordAccountLoading: user.createEmailPasswordAccountLoading,
  createEmailPasswordAccountError: user.createEmailPasswordAccountError,
  accountLinkingLoading: user.accountLinkingLoading,
  accountLinkingError: user.accountLinkingError,
  hasCredentialsAccount: (
    user.decodedAccessToken[
      window.processRuntime.env.AUTH0_CUSTOM_CLAIM_DOMAIN + '/hasCredentialsIdentity'
    ] || false
  )
})

const mapDispatchToProps = dispatch => ({
  startCreatingEmailPasswordAccount: password => dispatch(startCreatingEmailPasswordAccount(password))
})

SetPasswordPage.propTypes = {
  createEmailPasswordAccountLoading: PropTypes.bool.isRequired,
  createEmailPasswordAccountError: PropTypes.object,
  accountLinkingLoading: PropTypes.bool.isRequired,
  accountLinkingError: PropTypes.object,
  startCreatingEmailPasswordAccount: PropTypes.func.isRequired,
  hasCredentialsAccount: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage)
