import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { draftProjectForMapSelector } from '../../selectors/draft'

import MapContainer from '../container-map'

const FakeDetailsContainer = ({ project }) => (
  <MapContainer project={project} />
)

const mapStateToProps = state => ({
  project: draftProjectForMapSelector(state)
})

FakeDetailsContainer.propTypes = {
  project: PropTypes.shape({
    location: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
    }),
    plotsIds: PropTypes.array.isRequired
  }).isRequired
}

export default connect(mapStateToProps, null)(FakeDetailsContainer)
