export const loadScript = src => {
  const script = document.createElement('script')
  script.async = false
  script.src = src
  document.querySelector('body').appendChild(script)
}

export const isEllipsisActive = (element) => {
  return (element.offsetWidth < element.scrollWidth);
}
