import dayjs from 'dayjs'
import { isOnlyNumbers } from './string'

export const formatDate = (date) => {
  if (!date) return '-'
  const _date = isOnlyNumbers(date) ? Number(date) : date
  return dayjs(new Date(_date)).format('DD/MM/YYYY')
}

export const convertUTCToLocaleDate = (date) => {
  if (!date) return null

  const _date = date instanceof Date ? date : new Date(date)
  return new Date(
    _date.getUTCFullYear(),
    _date.getUTCMonth(),
    _date.getUTCDate()
  )
}

export const convertLocaleToUTCDate = (
  date,
  hours,
  minutes,
  seconds,
  ms = 0
) => {
  if (!date) return null

  const _date = date instanceof Date ? date : new Date(date)
  return new Date(
    Date.UTC(
      _date.getFullYear(),
      _date.getMonth(),
      _date.getDate(),
      hours,
      minutes,
      seconds,
      ms
    )
  )
}