import { useMemo, useEffect, useState } from 'react'

export default function useWindowSize() {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const isTabletOrMobile = useMemo(() => width < 1025, [width])
  const isMobile = useMemo(() => width < 769, [width])

  useEffect(() => {
    const changeWindowSize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    changeWindowSize()

    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return { width, height, isMobile, isTabletOrMobile }
}
