import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  startFetchingUserByAccount,
  updateConnectedAt
} from '../../actions/user'

import buildMiddleware from './template'

const withUserFetch = (SubComponent) => {
  const WithUserFetchWrapper = buildMiddleware(
    SubComponent,
    'WithUser'
  )

  const WithUserFetch = class extends Component {
    componentDidMount () {
      if (!this.props.profile && !this.props.error) {
        this.props.startFetchingUserByAccount()
      }
    }

    componentDidUpdate () {
      if (!this.props.updatedConnectedAt && this.props.profile && this.props.profile.id) {
        this.props.updateConnectedAt()
      }
    }

    render () {
      const {
        startFetchingUserByAccount,
        ...passThroughProps
      } = this.props

      return <WithUserFetchWrapper {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ user }) => ({
    loading: user.profileLoading || (!user.profile && !user.profileError),
    updatedConnectedAt: user.updatedConnectedAt,
    error: user.profileError,
    profile: user.profile
  })

  const mapDispatchToProps = dispatch => ({
    startFetchingUserByAccount: () => dispatch(startFetchingUserByAccount()),
    updateConnectedAt: () => dispatch(updateConnectedAt())
  })

  WithUserFetch.propTypes = {
    startFetchingUserByAccount: PropTypes.func.isRequired,
    updatedConnectedAt: PropTypes.bool.isRequired,
    profile: PropTypes.object
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithUserFetch)
}

export default withUserFetch
