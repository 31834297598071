import { all } from 'redux-saga/effects'
import _createSagaMiddleware from 'redux-saga'

import project from './project'
import user from './user'
import step from './step'
import notification from './notification'
import action from './action'
import files from './files'
import draft from './draft'
import sync from './sync'
import system from './system'
import geocodingAddress from './geocoding-address'

export function * combineSagas (sagas) {
  yield all(sagas.flat())
}

export function createSagaMiddleware (sentryInstance) {
  return _createSagaMiddleware({
    ...(sentryInstance
      ? {
        onError: (action, err) => {
          console.error('Uncaught error in saga')
          console.error(err)
          sentryInstance.captureException(err)
        }
      }
      : {}
    )
  })
}

export default [
  project,
  user,
  step,
  notification,
  action,
  files,
  draft,
  sync,
  system,
  geocodingAddress
]
