import React from 'react'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import MultiNotifsMenuContainer from '../container-multi-notifs-menu'
import { IconContainer } from '../container-multi-notifs-menu/styled'
import { SidebarIcon } from '../view-sidebar/styled'
import {
  NotificationOverlayContentWrap,
  NotificationOverlayContentWrapper,
  NotificationOverlayTitle,
  NotificationOverlayTitleWrap,
  NotificationOverlayWrapper,
} from './styled'
import PropTypes from 'prop-types'

const NotificationsContainerOverlay = ({ onClose, animation }) => {
  return (
    <NotificationOverlayWrapper>
      <NotificationOverlayContentWrapper animation={animation}>
        <NotificationOverlayTitleWrap>
          <IconContainer onClick={onClose}>
            <SidebarIcon size="3x" icon={faArrowLeft} />
          </IconContainer>
          <NotificationOverlayTitle>Notifications</NotificationOverlayTitle>
        </NotificationOverlayTitleWrap>
        <NotificationOverlayContentWrap>
          <MultiNotifsMenuContainer isMobile={true} />
        </NotificationOverlayContentWrap>
      </NotificationOverlayContentWrapper>
    </NotificationOverlayWrapper>
  )
}

NotificationsContainerOverlay.propTypes = {
  animation: PropTypes.string.isRequired,
}

export default NotificationsContainerOverlay
