import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSpring, a } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { Container, Title, Frame, Content, Partner } from './styled'
import { SidebarSvgContainer } from '../container-sidebar/styled'
import { SidebarIcon } from '../view-sidebar/styled'
import { faHammer } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'react-redux'
import { isHemeaAvailableSelector, isCicAvailableSelector } from '../../selectors'

function usePrevious() {
    const ref = useRef()
    if (ref) ref.current
}


const Tree = React.memo(({ children, name, style, defaultOpen = false }) => {
    const [isOpen, setOpen] = useState(defaultOpen)
    const previous = usePrevious(isOpen)

    const [ref, { height: viewHeight }] = useMeasure()
    const { height, opacity, y } = useSpring({
        from: { height: 0, opacity: 0, y: 20 },
        to: {
            height: isOpen ? viewHeight : 0,
            opacity: isOpen ? 1 : 0,
            y: isOpen ? 0 : 0,
        },
    })

    return (
        <Frame>
            <Title style={style} onClick={() => setOpen(!isOpen)} isOpen={isOpen}>
                <SidebarSvgContainer>
                    <SidebarIcon size="3x" icon={faHammer} />
                </SidebarSvgContainer>
                {name}
            </Title>
            <Content
                style={{
                    opacity,
                    height: isOpen && previous === isOpen ? 'auto' : height,
                }}
            >
                <a.div ref={ref} style={{ y }} children={children} />
            </Content>
        </Frame>
    )
})

export default function TreeList() {
    // const isHemeaAvailable = useSelector(isHemeaAvailableSelector)
    // const isCicAvailable = useSelector(isCicAvailableSelector)
    // const history = useHistory()
    // const handleSwitchPage = (event, path) => {
    //     event.preventDefault()
    //     history.push(path)
    // }

    return (
        <Container>
          {/* Partie Partenaires exclusifs commentée */}
          {/*<Tree name="Partenaires exclusifs">*/}
            {/*    <Partner onClick={(e) => isHemeaAvailable ? handleSwitchPage(e, '/hemea') : null} available={isHemeaAvailable} isHemeaAvailable={isHemeaAvailable}>Artisans</Partner>*/}
            {/*    <Partner onClick={(e) => handleSwitchPage(e, '/luko')} available>Assurances</Partner>*/}
            {/*    /!* <Partner onClick={(e) => isCicAvailable ? handleSwitchPage(e, '/cic') : null} available={isCicAvailable} isCicAvailable={isCicAvailable}>Banques</Partner> *!/*/}
            {/*</Tree>*/}
        </Container>
    )
}
