import React from 'react'
import { useSelector } from 'react-redux'
import { algarDeskFolderTypes } from '../../../constants/project'
import {
  isAllowedToUseAlgarDeskSelector,
  isBtoBSelector,
  userProfileSelector,
} from '../../../selectors'

const useDefaultProjectType = (folderType) => {
  const [defaultProjectType, setDefaultProjectType] = React.useState([])
  const { contactWorktypes } = useSelector(userProfileSelector)
  const isBtoB = useSelector(isBtoBSelector)
  const isAllowedToUseDesk = useSelector(isAllowedToUseAlgarDeskSelector)
  const isClientDesk = isAllowedToUseDesk && folderType.value === algarDeskFolderTypes[0].value

  React.useEffect(() => {
    if (isBtoB) {
      let projectType = isClientDesk
        ? 'house'
        : contactWorktypes
          ? contactWorktypes[0]
          : []

      // ATM projectType for custom dashboards here are set only if the user has 1 project Type,
      // if he has more, we don't set any to prevent any mistake validating a wrong worktype.
      // We use this in case we change the logic
      if (projectType.length === 1) {
        projectType = projectType[0]
      }

      setDefaultProjectType(projectType)
    }
  }, [isBtoB, isClientDesk, contactWorktypes])

  return defaultProjectType
}

export default useDefaultProjectType
