import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  startLoadingStepMetas,
  startLoadingStepsByProject
} from '../../actions/step'
import { TYPES_CLIENTS } from '../../constants'
import { isAlgarDeskSelector, isBtoBSelector } from '../../selectors'
import {
  stepSelector,
  stepsMetaSelector,
  stepsSelector
} from '../../selectors/step'
import LoadingSpinner from '../loading-spinner'
import ErrorPage from '../page-error'
import ViewStepsV2 from '../view-steps/steps-v2'
import { LoadingContainer } from './styled'

const StepsContainer = () => {
  const dispatch = useDispatch()
  const metaSteps = useSelector(stepsMetaSelector)
  const projectSteps = useSelector(stepsSelector)

  const {
    steps,
    stepsLoading,
    stepsError,
    stepsMetaLoading,
    stepsMetaError
  } = useSelector(stepSelector)

  const loading = stepsLoading || stepsMetaLoading

  const error = stepsError || stepsMetaError

  const isBtoB = useSelector(isBtoBSelector)
  const isDesk = useSelector(isAlgarDeskSelector)

  React.useEffect(() => {
    if (!stepsMetaError || steps) {
      dispatch(startLoadingStepMetas(isDesk ? TYPES_CLIENTS.DESK : isBtoB ? TYPES_CLIENTS.BTOB : null))
    }
  }, [stepsMetaError, steps])

  React.useEffect(() => {
    if (!stepsError) {
      dispatch(startLoadingStepsByProject())
    }
  }, [stepsError])

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    )
  }

  if (error) {
    return (
      <LoadingContainer>
        <ErrorPage error={error} />
      </LoadingContainer>
    )
  }

  return metaSteps && projectSteps ? <ViewStepsV2 steps={metaSteps} /> : null
}

export default StepsContainer
