import styled from '@emotion/styled'
import { textFontSizeCss } from '../../styles/common'

export const inputHeight = 32
export const inputWidth = 280

export const PasswordForm = styled('form')`
  width: 100%;
  max-width: 283px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
`

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.primary};
`

export const PasswordInputContainer = styled.div`
  position: relative;
  width: fit-content;
  margin-bottom: 20px;
`

export const PasswordInputContainerLast = styled(PasswordInputContainer)`
  margin-bottom: 0;
`

export const PasswordInput = styled('input')`
  width: ${inputWidth}px;
  max-width: 100%;
  height: ${inputHeight}px;
  padding: 8px 10px;
  border: 0.5px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.primary)};
  border-radius: 3px;
  flex-shrink: 0;
  outline: none;
`

export const ShowHidePassword = styled.span`
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;

  & > svg {
    width: 12px;
    height: 10px;
    color: ${({ showPassword, theme }) =>
      showPassword ? theme.colors.primary : '#C4C4C4'};
  }
`

export const ValidateButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  min-width: 68px;
  padding: 8px 10px;
  border: 0;
  box-shadow: none;
  margin-top: 6px;
  font-weight: 500;
  // font-size: 14px;
  ${textFontSizeCss}
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:disabled {
    opacity: 0.7;
  }
`
