import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startUpdateActionStatus } from '../../actions/action'
import { isBtoBSelector } from '../../selectors'
import ActionItemLinks from '../action-item-links'
import {
  ActionItemData,
  ActionItemIcon,
  ActionItemStyled,
  ActionItemText,
} from './styled'

const ActionItem = ({ action }) => {
  const dispatch = useDispatch()

  let isAllActionsDone = false

  if (action.links && action.links.length) {
    isAllActionsDone = action.links.every(({ status }) => status === 'done')
  }

  const isDone = isAllActionsDone || action.status === 'done'

  const isBtoB = useSelector(isBtoBSelector)

  const handleClickActionItemIcon = React.useCallback(() => {
    if (action.status === 'unread') {
      dispatch(startUpdateActionStatus(action.id, 'read'))
    }
  }, [action])


  // WIP : Just a temporary fix to handle old onboarding form text for btob.
  const handleBtoBOnboardingFormText = (action) => {
    if((action.metaCode === "formOnboarding") &&  isBtoB && action.links.length === 1) {
      return 'Votre formulaire de départ est prêt ! Merci de le compléter au plus vite pour lancer la production de votre dossier.'
    } else {
      return action.meta.text
    }
  }

  // TODO (eric) : update style of ActionItemIcon if action.status === 'read' ?

  return (
    <ActionItemStyled isDone={isDone}>
      <ActionItemIcon onClick={handleClickActionItemIcon} isDone={isDone}>
        <FontAwesomeIcon icon={isDone ? faCircleCheck : faInfoCircle} size="2x" />
      </ActionItemIcon>
      <ActionItemData>
        <ActionItemText>{handleBtoBOnboardingFormText(action)}</ActionItemText>
        <ActionItemLinks action={action} />
      </ActionItemData>
    </ActionItemStyled>
  )
}

ActionItem.propTypes = {
  action: PropTypes.object.isRequired,
}

export default ActionItem
