import React from 'react'
import styled from '@emotion/styled'

import { FlexColumn } from '../styled'

import LoadingSpinner from '../loading-spinner'

const LoadingWrapper = styled(FlexColumn)`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
`

const LoadingView = (props) => (
  <LoadingWrapper {...props} size={props.size || null}>
    <LoadingSpinner size={props.size || null} />
  </LoadingWrapper>
)

export default LoadingView
