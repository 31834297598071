import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { ValidateButtonDisabled } from '../overlay-set-projects-list/styled'
import { overlayCenteredMQ } from '../overlay-centered/styled'
import CustomConfirmOverlay from '../overlay-custom-confirm'

const width = () => css`
  ${overlayCenteredMQ({
  width: ['470px', '80vw']
})}
`

export const CreateProjectConatainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  ${width}
  height: auto;

  & > label {
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
  }

  & > label:nth-of-type(1) {
    margin-top: ${({ allowDesk }) => (allowDesk ? 'unset' : '15px')};
  }

  & > div:nth-of-type(1) {
    margin-top: ${({ allowDesk }) => (allowDesk ? 0 : 'unset')};
  }
`

export const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  ${(props) =>
    overlayCenteredMQ({
      fontSize: [`${props.theme.sizes.h3}px`, `${props.theme.sizes.h4}px`]
    })}
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacings.medium}px;
  & > svg {
    margin-right: 10px;
  }
`

const subTitleCss = (props) => css`
  color: ${props.theme.colors.greySemiLight};
  text-align: left;
  font-weight: normal;
  font-size: 15px;
`

export const SubTitle = styled.p`
  width: 100%;
  ${subTitleCss}
`

export const ErrorMessage = styled(SubTitle)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 20px;
  font-weight: 600;
`

export const BaseInputStyle = (props) => css`
  outline: none;
  &:focus {
    outline: none;
  }
`

export const InputText = styled.input`
  ${BaseInputStyle}
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  border-color: transparent;
  border: 0.3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  height: 32px;
  padding: 6.5px 15px;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.greySemilightest};
  }
`

export const InputLabel = styled.label`
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.workSans};
  margin-bottom: 8px;
`

export const ButtonDisabled = styled(ValidateButtonDisabled)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const AutocompleteAddressContainer = styled.div`
  width: 100%;
  ${width}
  position: relative;
  margin-top: 20px;
`

export const ValidateButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  margin-top: ${(props) => (props.isbtob ? '30px' : '40px')};
`

export const CreateButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  box-sizing: border-box;
  border-radius: 2px;
  min-width: 156px;
  padding: 10px 15px;
  cursor: pointer;
  opacity: 1;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.workSans};

  & > span {
    margin-left: 10px;
    margin-right: 10px;
  }

  & > svg {
    width: 20px !important;
    height: 20px !important;
    color: ${({ theme }) => theme.colors.white};
  }
  & :hover:enabled {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    & > svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const HelperText = styled.span`
  width: 100%;
  font-size: 8px;
  font-weight: normal;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.greySemiLight};
`

export const LoadingContainer = styled.div`
  width: auto;
  position: absolute;
  top: 6.5px;
  right: 10px;
`

export const InputContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  font-family: ${({ theme }) => theme.fonts.workSans};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .react-select-container {
    width: 100%;
  }
`

export const InputContainerDesk = styled(InputContainer)`
  margin-bottom: 14px;

  & div.react-select__single-value {
    & > span {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
`

export const StyledUl = styled.ul`
  width: 100%;
  margin-top: 20px;
  list-style: disc;
  padding-left: ${({ theme }) => theme.spacings.medium}px;
`

export const StyledLi = styled.li`
  ${subTitleCss}
  line-height: 1.5;
  max-width: 381px;
`

export const InputInfo = styled.span`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  margin-top: 5px;
  color: #c4c4c4;
`

export const PlacesAutocompleteInputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const PlacesAutocompleteInput = styled.input`
  border: 0.3px solid ${(props) => props.theme.colors.primary};
  border-radius: 3px;
  height: 32px;
  padding: 6.5px 15px;
  outline: none;
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`
export const StyledAutocompleteLabel = styled.label`
  width: 100%;
  display: block;
  margin-top: 20px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  line-height: 15px;
  font-family: ${({ theme }) => theme.fonts.workSans};

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const SuggestionsList = styled.div`
  display: block;
  position: absolute;
  z-index: 1000;
  width: 100%;
  background-color: white;
  list-style-type: none;
  background: ${({ theme }) => theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  max-height: 175px;
  overflow: overlay;
  padding: 0;

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.greyLighter};
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: ${({ theme }) => theme.colors.greyLighter};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 45px;
    -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.colors.greyLight};
    background-color: #555;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`
export const hexaToRgba = (hexaColor, opacity) => {
  return `${hexaColor}${opacity}`
}

export const SuggestionListItem = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 9px 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0;
  border: none;

  font-family: ${({ theme }) => theme.fonts.workSans};
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => hexaToRgba(theme.colors.primary, '4D')};
  }
`

export const ModalBodyContainer = styled.div`
  width: 100%;
  min-width: 570px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 610px) {
    min-width: unset;
  }
`

export const ConfirmTextContent = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CustomConfirmOverlayStyled = styled(CustomConfirmOverlay)`
  & > div:nth-of-type(1) {
    overflow: unset;
  }
`
export const WarningUniqueProject = styled.p`
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.greySemilightest};
  font-size: 14px;
`