import React, { Component } from 'react'

import AnonymousHeaderContainer from '../container-anonymous-header'
import FakeMapContainer from '../container-draft-map'
import AnonymousDetailsContainer from '../container-fake-details'
import FakeNotificationsContainer from '../container-fake-notifications'
import FakeFilesContainer from '../container-fake-files'
import FakeStepsContainer from '../container-fake-steps'

import {
  ViewProject,
  ProjectBody,
  BottomBlock,
  StepsBlock,
  StatusBlock,
  MapBlock,
  DossierBlock,
  FileBlock,
  ActionsBlock
} from '../page-project/styled'

class PageFakeProject extends Component {
  render () {
    return (
      <ViewProject>
        <AnonymousHeaderContainer />
        <ProjectBody>
          <BottomBlock>
            <MapBlock>
              <FakeMapContainer />
            </MapBlock>

            <StatusBlock>
              <AnonymousDetailsContainer />
            </StatusBlock>

            <ActionsBlock>
              <FakeNotificationsContainer forActions />
            </ActionsBlock>

            <DossierBlock>
              <FakeNotificationsContainer />
            </DossierBlock>

            <FileBlock>
              <FakeFilesContainer />
            </FileBlock>
          </BottomBlock>

          <StepsBlock>
            <FakeStepsContainer />
          </StepsBlock>
        </ProjectBody>
      </ViewProject>
    )
  }
}

export default PageFakeProject
