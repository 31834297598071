import { utils, writeFileXLSX } from 'xlsx'

export const convertJsonToXlsx = (
  arrayOfDsonData,
  fileName,
  sheetName = 'Data',
  headers,
  headersOrigin = 'A1'
) => {
  // Generate worksheet and workbook
  const worksheet = utils.json_to_sheet(arrayOfDsonData)
  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, worksheet, sheetName)

  // Fix headers
  if (headers) {
    utils.sheet_add_aoa(worksheet, [headers], { origin: headersOrigin })
  }

  // Create an XLSX file and try to save fileName
  writeFileXLSX(workbook, `${fileName}.xlsx`)
}
