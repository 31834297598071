import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadingActionsByProject } from '../../actions/action'
import { actionStateSelector } from '../../selectors/action'
import ActionItem from '../action-item'
import LoadingSpinner from '../loading-spinner'
import ErrorPageContainer from '../page-error'
import { MyActionsContent, MyActionsStyled, MyActionsTitle } from './styled'

const MyActions = () => {
  const dispatch = useDispatch()
  const { actions, actionsError, actionsLoading } = useSelector(actionStateSelector)

  React.useEffect(() => {
    dispatch(startLoadingActionsByProject())
  }, [])

  return (
    <MyActionsStyled>
      <MyActionsTitle>Actions</MyActionsTitle>
      <MyActionsContent>
        {actionsLoading ? (
          <LoadingSpinner />
        ) : actionsError ? (
          <ErrorPageContainer error={{ message: actionsError.message || actionsError }} />
        ) : actions?.length ? (
          actions.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)).map((action) => (
            <ActionItem key={action.id} action={action} />
          ))
        ) : null}
      </MyActionsContent>
    </MyActionsStyled>
  )
}

export default MyActions
