import {
  faArrowLeft,
  faArrowTrendUp,
  faChevronRight,
  faRectangleVerticalHistory,
  faXmark,
  faCalendarDays
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _, { capitalize } from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dateFilters } from '../../constants/filters'
import {
  startLoadingProjects,
  updateProjectPaginationParams
} from '../../actions/project'
import {
  isBtoBSelector,
  projectPaginationParamsSelector,
  projectSelector,
  allProjectsSelector
} from '../../selectors'
import { formatDealStages, groupDealStages } from './helpers'
import {
  BtnClearFilter,
  BtnClearFilterContainer,
  BtnCloseFilter,
  DatesFilterContainer,
  DatesFilterSubItem,
  FilterContent,
  FilterDatepicker,
  FilterItem,
  FilterItemContainer,
  FilterItemDivider,
  FilterItemIconContainer,
  FilterSubItemCheckbox,
  FilterTitle,
  ProjectListFilterContainer,
  StatusGroupWrapper,
  StatusTitle,
  StatusFilterContainer,
  StatusFilterSubItem,
  WorkTypesFilterContainer,
  WorkTypesFilterSubItem
} from './styled'

import { paginationParamsDefaultDates } from '../../reducers/project'
import { hasFilterDateSelector } from '../../selectors/project'
import {
  convertLocaleToUTCDate,
  convertUTCToLocaleDate
} from '../../utils/date'

const filterItems = [
  { code: 'statuts', label: 'Statuts', icon: faArrowTrendUp },
  {
    code: 'worktypes',
    label: 'Types de projet',
    icon: faRectangleVerticalHistory,
    noDivider: true
  }
  // { code: 'dates', label: 'Dates', icon: faCalendarDays }
]

const statusFilterCode = filterItems[0].code
const worktypesFilterCode = filterItems[1].code
// const datesFilterCode = filterItems[2].code

const ProjectListFilter = ({ setOpenFilter }) => {
  const dispatch = useDispatch()

  const { workTypes } = useSelector(projectSelector)
  const isBtoB = useSelector(isBtoBSelector)
  const paginationParams = useSelector(projectPaginationParamsSelector)
  const { dealstatus, dealStatusTitles, workTypeCodes, dates } = paginationParams
  const isHasFilterDate = useSelector(hasFilterDateSelector)

  const projectsWithTotal = useSelector(allProjectsSelector)
  const projects = projectsWithTotal ? projectsWithTotal.data : []

  const formattedStatus = formatDealStages(isBtoB)
  const formattedWorkTypes = workTypes
    ? Object.values(workTypes)
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      .filter(({ tunnelCode }) => tunnelCode !== 'other')
    : []

  const [currentFilter, setCurrentFilter] = React.useState(null)
  const [selectedWorkTypes, setSelectedWorkTypes] = React.useState([])
  const [userProjectsWorktypes, setUserProjectsWorktypes] = React.useState([])

  const [clickedDateFilter, setClickedDateFilter] = React.useState(null)

  const currentDateFilter = clickedDateFilter
    ? dateFilters.find(({ code }) => code === clickedDateFilter)
    : null

  const handleChangeDate = React.useCallback(
    (dates) => {
      const [start, end] = dates
      let startUTC = null
      let endUTC = null

      if (start instanceof Date) {
        startUTC = convertLocaleToUTCDate(start, 0, 0, 0)
      }

      if (end instanceof Date) {
        endUTC = convertLocaleToUTCDate(end, 23, 59, 59)
      }

      if (clickedDateFilter) {
        const newParams = {
          ...paginationParams,
          dates: {
            ...paginationParams.dates,
            [clickedDateFilter]: {
              startDate: startUTC ? startUTC.toISOString() : null,
              endDate: endUTC ? endUTC.toISOString() : null
            }
          }
        }
        dispatch(updateProjectPaginationParams(newParams))
        if (start && end) {
          dispatch(startLoadingProjects(newParams))
        }
      }
    },
    [clickedDateFilter]
  )

  const showStatusFilter = currentFilter === statusFilterCode
  const showWorktypesFilter = currentFilter === worktypesFilterCode
  // const showDatesFilter = currentFilter === datesFilterCode

  const filterTitle = showStatusFilter
    ? 'Statuts'
    : showWorktypesFilter
      ? 'Types de projet'
      // : showDatesFilter
      //   ? currentDateFilter
      //     ? `Dates / ${currentDateFilter.name}`
      //     : 'Dates'
      : 'Sélectionnez vos filtres'

  const clearFilterText = currentFilter
    ? 'Effacer la sélection'
    : 'Tout effacer'

  const handleClickFilterItem = React.useCallback(
    (event, code) => {
      event.stopPropagation()
      if (currentDateFilter) {
        setClickedDateFilter(null)
      } else {
        setCurrentFilter(code)
      }
    },
    [currentDateFilter]
  )

  const handleClickWorkTypeItem = React.useCallback(
    (event, workTypeCode) => {
      event.stopPropagation()
      const newSelectedWorkTypes = selectedWorkTypes.includes(workTypeCode)
        ? selectedWorkTypes.filter((i) => i !== workTypeCode)
        : [...selectedWorkTypes, workTypeCode]

      dispatch(
        updateProjectPaginationParams({
          ...paginationParams,
          workTypeCodes: newSelectedWorkTypes
        })
      )

      dispatch(
        startLoadingProjects({
          ...paginationParams,
          workTypeCodes: newSelectedWorkTypes
        })
      )
      setSelectedWorkTypes(newSelectedWorkTypes)
    },
    [selectedWorkTypes, paginationParams, dispatch]
  )

  const handleClickStatusItem = React.useCallback(
    (event, title, values) => {
      event.stopPropagation()
      const newDealStatusTitles = dealStatusTitles.includes(title)
        ? dealStatusTitles.filter((t) => t !== title)
        : [...dealStatusTitles, title]

      let newDealstatus = formattedStatus
        .filter((s) => newDealStatusTitles.includes(s.title))
        .map((s) => s.values)
        .flat()

      newDealstatus = newDealstatus.map((i) => (i.id ? i.id : i))
      newDealstatus = [...new Set(newDealstatus)]

      dispatch(
        updateProjectPaginationParams({
          ...paginationParams,
          dealStatusTitles: newDealStatusTitles,
          dealstatus: newDealstatus
        })
      )

      dispatch(
        startLoadingProjects({
          ...paginationParams,
          dealstatus: newDealstatus
        })
      )
    },
    [dealStatusTitles, paginationParams, dispatch]
  )

  const handleClickClearAll = React.useCallback(
    (event) => {
      event.stopPropagation()
      dispatch(
        updateProjectPaginationParams({
          ...paginationParams,
          dealStatusTitles: [],
          dealstatus: [],
          workTypeCodes: [],
          dates: paginationParamsDefaultDates
        })
      )

      dispatch(
        startLoadingProjects({
          ...paginationParams,
          dealstatus: [],
          workTypeCodes: [],
        })
      )
      setSelectedWorkTypes([])
    },
    [paginationParams, dispatch]
  )

  const handleClickClearWorkTypes = React.useCallback(
    (event) => {
      event.stopPropagation()
      dispatch(
        updateProjectPaginationParams({
          ...paginationParams,
          workTypeCodes: []
        })
      )
      dispatch(
        startLoadingProjects({
          ...paginationParams,
          workTypeCodes: []
        })
      )
      setSelectedWorkTypes([])
    },
    [paginationParams, dispatch]
  )

  const handleClickClearStatus = React.useCallback(
    (event) => {
      event.stopPropagation()

      dispatch(
        updateProjectPaginationParams({
          ...paginationParams,
          dealStatusTitles: [],
          dealstatus: []
        })
      )

      dispatch(
        startLoadingProjects({
          ...paginationParams,
          dealstatus: []
        })
      )
    },
    [paginationParams, dispatch]
  )

  const handleClickFilterDate = (event, code) => {
    event.stopPropagation()
    setClickedDateFilter(code)
  }

  const handleClearOneDate = React.useCallback(
    (event) => {
      event.stopPropagation()

      if (clickedDateFilter) {
        const newParams = {
          ...paginationParams,
          dates: {
            ...paginationParams.dates,
            [clickedDateFilter]: { startDate: null, endDate: null }
          }
        }
        dispatch(updateProjectPaginationParams(newParams))
        dispatch(startLoadingProjects(newParams))
      }
    },
    [paginationParams, clickedDateFilter]
  )

  const handleClearAllDates = React.useCallback(
    (event) => {
      event.stopPropagation()

      const newParams = {
        ...paginationParams,
        dates: paginationParamsDefaultDates
      }
      dispatch(updateProjectPaginationParams(newParams))
      dispatch(startLoadingProjects(newParams))
    },
    [paginationParams]
  )

  React.useEffect(() => {
    if (userProjectsWorktypes.length === 0) {
      const userProjectsWorktypesList = projects.map((p) => p.workTypesIds).flat()
      setUserProjectsWorktypes(_.uniq(userProjectsWorktypesList))
    }
  }, [userProjectsWorktypes])

  const filteredStatus = isBtoB
    ? formattedStatus.filter((st) => st.client && st.client.includes('BtoB'))
    : formattedStatus

  const groupedStatus = groupDealStages(filteredStatus)

  return (
    <ProjectListFilterContainer
      open={!!currentFilter}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <FilterTitle open={!!currentFilter}>
        {currentFilter &&
          <FontAwesomeIcon
            icon={faArrowLeft}
            size="1x"
            onClick={() => setCurrentFilter(null)}
          />
        }
        <span>{filterTitle}</span>
      </FilterTitle>
      <FilterContent>
        {!currentFilter && (
          <FilterItemContainer>
            {filterItems.map(({ code, icon, label, noDivider }, index) => (
              <Fragment key={`${index}-${code}`}>
                <FilterItem
                  onClick={(event) => handleClickFilterItem(event, code)}
                >
                  <FilterItemIconContainer>
                    <FontAwesomeIcon icon={icon} size="sm" />
                  </FilterItemIconContainer>
                  <span>{label}</span>
                  <FontAwesomeIcon icon={faChevronRight} size="1x" />
                </FilterItem>
                {!noDivider && <FilterItemDivider />}
              </Fragment>
            ))}
          </FilterItemContainer>
        )}
        {showStatusFilter && filteredStatus.length > 0 && (
          <StatusFilterContainer
            gridColumns={Math.ceil(groupedStatus.length / 2)}
          >
            {groupedStatus.map((group, iGroup) => (
              <StatusGroupWrapper key={group.id}>
                {group.items.map(
                  ({ title, values, color, order, bgColor }, index) => (
                    <StatusFilterSubItem
                      key={`${index}-${title}`}
                      order={order}
                      gridRows={Math.ceil(filteredStatus.length / 2)}
                      color={color}
                      bgColor={bgColor}
                      onClick={(event) =>
                        handleClickStatusItem(event, title, values)
                      }
                      selected={dealStatusTitles.includes(title)}
                    >
                      <span>{title}</span>
                      <FilterSubItemCheckbox
                        selected={dealStatusTitles.includes(title)}
                        color={color}
                      />
                    </StatusFilterSubItem>
                  )
                )}
              </StatusGroupWrapper>
            ))}
          </StatusFilterContainer>
        )}
        {showWorktypesFilter && formattedWorkTypes.length > 0 && (
          <WorkTypesFilterContainer
            gridColumns={
              formattedWorkTypes.length < 10
                ? 1
                : Math.ceil(formattedWorkTypes.length / 10)
            }
          >
            {formattedWorkTypes
              .filter((wt) => userProjectsWorktypes.includes(wt.id))
              .map(({ name, tunnelCode }, index) => (
                <WorkTypesFilterSubItem
                  key={`${index}-${tunnelCode}`}
                  gridRows={Math.ceil(formattedWorkTypes.length / 2)}
                  index={index}
                  onClick={(event) =>
                    handleClickWorkTypeItem(event, tunnelCode)
                  }
                  selected={selectedWorkTypes.includes(tunnelCode)}
                >
                  <span>{capitalize(name)}</span>
                  <FilterSubItemCheckbox
                    selected={selectedWorkTypes.includes(tunnelCode)}
                  />
                </WorkTypesFilterSubItem>
              ))}
          </WorkTypesFilterContainer>
        )}
        {/* {showDatesFilter && (
          <DatesFilterContainer>
            {clickedDateFilter ? (
              <FilterDatepicker
                selectsRange
                inline
                selected={convertUTCToLocaleDate(
                  dates[clickedDateFilter].startDate
                )}
                startDate={convertUTCToLocaleDate(
                  dates[clickedDateFilter].startDate
                )}
                endDate={convertUTCToLocaleDate(
                  dates[clickedDateFilter].endDate
                )}
                onChange={handleChangeDate}
              />
            ) : (
              dateFilters.map(({ code, name }, index) => (
                <DatesFilterSubItem
                  key={`${index}-${code}`}
                  selected={dates[code].startDate && dates[code].endDate}
                  onClick={(event) => handleClickFilterDate(event, code)}
                >
                  <span>{capitalize(name)}</span>
                  <FilterSubItemCheckbox
                    selected={dates[code].startDate && dates[code].endDate}
                  />
                </DatesFilterSubItem>
              ))
            )}
          </DatesFilterContainer>
        )} */}
      </FilterContent>
      <BtnClearFilterContainer>
        <BtnClearFilter
          // onClick={(event) => {
          //   if (showStatusFilter && dealstatus.length) {
          //     handleClickClearStatus(event)
          //   } else if (showWorktypesFilter && workTypeCodes.length) {
          //     handleClickClearWorkTypes(event)
          //   } else if (showDatesFilter && clickedDateFilter) {
          //     handleClearOneDate(event)
          //   } else if (showDatesFilter) {
          //     handleClearAllDates(event)
          //   } else if (
          //     dealstatus.length ||
          //     workTypeCodes.length ||
          //     isHasFilterDate
          //   ) {
          //     handleClickClearAll(event)
          //   }
          // }}
          onClick={
            showStatusFilter && dealstatus.length
              ? handleClickClearStatus
              : showWorktypesFilter && workTypeCodes.length
                ? handleClickClearWorkTypes
                : dealstatus.length || workTypeCodes.length
                  ? handleClickClearAll
                  : undefined
          }
        >
          {clearFilterText}
        </BtnClearFilter>
      </BtnClearFilterContainer>
    </ProjectListFilterContainer>
  )
}

ProjectListFilter.propTypes = {
  setOpenFilter: PropTypes.func
}

export default ProjectListFilter
