import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { startLoadingStepsByProject } from '../../actions/step'

import buildMiddleware from './template'

const withStepsFetch = (SubComponent) => {
  const WithStepsFetchWrapper = buildMiddleware(
    SubComponent,
    'WithSteps'
  )

  const WithStepsFetch = class extends Component {
    componentDidMount () {
      this.props.startLoadingStepsByProject()
    }

    render () {
      const {
        startLoadingStepsByProject,
        ...passThroughProps
      } = this.props

      return <WithStepsFetchWrapper {...passThroughProps} />
    }
  }

  const mapStateToProps = ({ step }) => ({
    loading: step.stepsLoading || (!step.steps && !step.stepsError),
    error: step.profileError
  })

  const mapDispatchToProps = dispatch => ({
    startLoadingStepsByProject: () => dispatch(startLoadingStepsByProject())
  })

  WithStepsFetch.propTypes = {
    startLoadingStepsByProject: PropTypes.func.isRequired
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithStepsFetch)
}

export default withStepsFetch
