import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isTypeformNotification } from '../../utils/notification'
import { FlexColumn, FlexRow, homeBlockDetailBlockCss } from '../styled'

export const NotificationBlock = styled(FlexRow)`
  ${homeBlockDetailBlockCss}
  align-items: flex-start;
  flex-shrink: 0;
  padding-right: ${(props) => props.theme.spacings.small}px;
  padding-bottom: ${(props) =>
    isTypeformNotification(props.notification)
      ? '0px !important'
      : `${props.theme.spacings.medium}px;`};
  padding-top: ${(props) =>
    !props.notification.actionOnly ? `8px` : '0px !important'};

  & + & {
    border-top: ${(props) =>
      !props.notification.actionOnly
        ? `1px solid ${(props) => props.theme.colors.primaryLight}`
        : 'none'};
  }
`

export const NotificationContentBlock = styled(FlexColumn)``

export const NotificationIconButton = styled('div')`
  margin-right: 8px;
  &:hover {
    ${(props) =>
      props.isReadable && !props.notification.action && 'cursor: pointer'}
  }

  & > svg {
    width: 16px !important;
    height: 16px !important;
  }
`

export const NotificationIcon = styled(FontAwesomeIcon)`
  user-select: none;
`

export const NotificationDate = styled('p')`
  margin-bottom: ${(props) => props.theme.spacings.small}px;

  color: ${(props) =>
    !props.notification.isRead || props.notification.wasJustRead
      ? props.theme.colors.medium
      : props.theme.colors.semiLight};
`

export const NotificationDescription = styled('p')`
  line-height: 1.5em;

  color: ${(props) =>
    !props.notification.isRead || props.notification.wasJustRead
      ? props.theme.colors.dark
      : props.theme.colors.medium};
`

export const ButtonChoice = styled('button')`
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 1;
  box-shadow: inset 0px 0px 2px ${({ theme }) => theme.colors.primary};
  border: 0.5px solid ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  opacity: 1;
  background: white;
  padding: 5px;
  width: 175px;
`
export const ButtonChoiceWrap = styled('div')`
  display: flex;
  justify-content: center;
  padding: 5px 0;
  margin: auto;
`
