import styled from '@emotion/styled'
import React from 'react'
import { SwiperSlide } from 'swiper/react'
import FilesContainer from '../../container-files'
import SwiperStyled from '../../swiper/SwiperStyled'
import { StepsRenderer } from '../index'
import { projectBodyGapCss } from '../styled'
import { DashboardContentTopBlockRenderer } from './DashboardDesktop'

export const FilesAndStepsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  ${projectBodyGapCss}
`

const DashboardTablet = ({ project }) => {
  return (
    <SwiperStyled>
      <SwiperSlide>
        <DashboardContentTopBlockRenderer project={project} />
      </SwiperSlide>
      <SwiperSlide>
        <FilesAndStepsContainer>
          <FilesContainer project={project} />
          <StepsRenderer />
        </FilesAndStepsContainer>
      </SwiperSlide>
    </SwiperStyled>
  )
}

export default DashboardTablet
